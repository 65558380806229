import {useState} from "react";

export const useReceipting = (callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const receipting = async () => {
        try {
            setIsLoading(true);
            setError('');
            await callback();
        } catch (e) {
            console.log(e)
            // console.log(e.response.data.message)
            if (e.response.data.hasOwnProperty('errors')) {
                setError(e.response.data.errors);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return [receipting, isLoading, error];
}