import React, {useContext, useEffect, useState} from 'react';
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import AuthContext from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import useScript from "../../hooks/useScript";
import {httpAxios} from "../../API/HttpAxios";

const UserGoogleAuthPage = () => {
    const {isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser} = useContext(AuthContext);
    const navigate = useNavigate();

    useScript("https://accounts.google.com/gsi/client");

    const [googleClient, setGoogleClient] = useState(null);

    const initTokenClient = () => {
        if (window.google !== undefined) {
            setGoogleClient(window.google.accounts.oauth2.initTokenClient({
                client_id: '680576407687-5evpc4hc9h361ifnni4gmg3qlduapf7i.apps.googleusercontent.com',
                scope: 'https://www.googleapis.com/auth/userinfo.email',
                ux_mode: 'popup',
                callback: async (response) => {
                    const apiResponse = await httpAxios.post("https://testapi.hidden-burg.com/api/user/gmail_auth_result", {
                        access_token: response.access_token,
                        expires_in: response.expires_in,
                        token_type: response.token_type,
                        scope: response.scope
                    })

                    console.log("APIResponse: ")
                    console.log(apiResponse);
                    setUser(apiResponse.data);
                    setIsAuth(true);
                    // Change to previous location
                    navigate("/");
                },

                // callback: (response) => {
                //     const xhr = new XMLHttpRequest();
                //     xhr.open('POST', "https://testapi.hidden-burg.com/api/user/gmail_auth_result", true);
                //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                //     // Set custom header for CRSF
                //     xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest');
                //     xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                //     xhr.onload = function() {
                //         // console.log('Auth code response: ');
                //         // console.log(response);
                //         console.log(JSON.parse(xhr.responseText));
                //         setUser(JSON.parse(xhr.responseText));
                //         setIsAuth(true);
                //         // Change to previous location
                //         navigate("/");
                //     };
                //
                //     xhr.send("access_token=" + response.access_token + "&expires_in=" + response.expires_in + "&token_type=" + response.token_type + "&scope=" + response.scope);
                // },
            }))
        }else {
            setTimeout(initTokenClient, 500);
        }
    }

    useEffect(() => {
        console.log(window)

        initTokenClient();
        setIsAuthVisible(false);
    }, [])

    useEffect(() => {
        if (googleClient !== null) {
            googleClient.requestAccessToken();
        }
    }, [googleClient])

    return (
        <div>
            <PageLoader/>
        </div>
    );
};

export default UserGoogleAuthPage;