import React, {useEffect, useRef, useState} from 'react';
import classes from "./HbCheckbox.module.scss";

const HbCheckbox = ({text = false, isChecked = false, setIsChecked}) => {
    // const [isChecked, setIsChecked] = useState(false);
    const checkboxRef = useRef();
    const switchCheckbox = () => {
        setIsChecked(!isChecked);
    }

    useEffect(() => {
        if (isChecked) {
            checkboxRef.current.classList.add(classes.checkbox_active);
        }else {
            checkboxRef.current.classList.remove(classes.checkbox_active);
        }
    }, [isChecked])

    return (
        <div
            className={classes.checkbox_container}
            onClick={switchCheckbox}
        >
            <div className={classes.checkbox_block}>
                <div
                    className={classes.checkbox}
                    ref={checkboxRef}
                >

                </div>
            </div>


            {
                text &&
                <div className={classes.checkbox_text}>
                    {text}
                </div>
            }
        </div>
    );
};

export default HbCheckbox;