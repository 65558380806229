import React from 'react';
import classes from "./SearchInput.module.scss";

const SearchInput = ({className, placeholder, value, onChange}) => {
    return (
        <div className={[classes.search_block, className].join(" ")}>
            <input
                className={[classes.search_input].join(" ")}
                type={"text"}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)}
            />

            {/*<div className={classes.search_button}>&#9658;</div>*/}
        </div>
    );
};

export default SearchInput;