import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFetching } from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { useCookies } from "react-cookie";
import { YaMetrikaGoal } from 'helpers/YaMetrikaGoal';
import classes from "./UserYandexAuthPage.module.scss";

const UserYandexAuthPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();

    const [yandexAuthing, isYandexAuthing, yandexAuthingError] = useFetching(async () => {
        const response = await UserService.yandexAuth(searchParams.get("code"));
        console.log(response);
        setUser(response.data.user);
        setIsAuth(true);

        // YaMetrika
        if (response.data.is_new_user) {
            YaMetrikaGoal({ goalName: 'SUCCESS_REGISTRATION' })
        }
        // Change to previous page from cookie
        if (cookies.excursion_back_route) {
            navigate(cookies.last_page);
        } else {
            navigate('/');
        }

    })

    useEffect(() => {
        if (searchParams.has('code') && searchParams.get('code') !== "") {
            console.log(searchParams.get('code'))
            // setVkCode(searchParams.get("code"));
            yandexAuthing();
        }
    }, [searchParams])

    return (
        <div>
            <PageLoader />

            {
                yandexAuthingError &&
                <div className={classes.error}>{yandexAuthingError}</div>
            }
        </div>
    );
};

export default UserYandexAuthPage;