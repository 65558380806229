import React, {useContext, useEffect, useState} from 'react';
import classes from "./SupportChatContainer.module.scss";
import {httpAxios} from "../../../../API/HttpAxios";
import LaravelEcho from "../../../../API/LaravelEcho";
import AuthContext from "../../../../context/AuthContext";
import {useOutletContext, useSearchParams} from "react-router-dom";
import NewChatBlock from "../../client/NewChatBlock/NewChatBlock";

const SupportChatContainer = ({clientId, isArchiveChatBtnVisible, setIsArchiveChatBtnVisible}) => {
    const {...context} = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isNewMessagesRequest, setIsNewMessagesRequest] = useState(false);
    const [myNewMessage, setMyNewMessage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const {...outletContext} = useOutletContext();

    // Set current page
    useEffect(() => {
        if (clientId !== undefined) {
            if (currentPage === 1) {
                getMessages(1);
            }else {
                setCurrentPage(1);
            }
        }
    }, [clientId])

    // Get messages portion
    const [isMessagesLoading, setIsMessagesLoading] = useState(true);
    const getMessages = (page) => {
        setIsMessagesLoading(true);
        httpAxios.get(`/api/support/get_messages`, {
            params: {
                client_id: clientId,
                page: page
            }
        }).then(response => {
            setCurrentPage(response.data.current_page);
            setLastPage(response.data.last_page);
            console.log(response)
            if (currentPage === 1) {
                setMessages(response.data.data.reverse());
            }else {
                setMessages([...response.data.data.reverse(), ...messages]);
            }

            setMessageAsRead();
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsMessagesLoading(false))
    }

    useEffect(() => {
        if (currentPage !== null) {
            getMessages(currentPage);
        }
    }, [currentPage])

    // Handle new messages request
    useEffect(() => {
        if (isNewMessagesRequest && currentPage < lastPage && isMessagesLoading !== true) {
            setCurrentPage(currentPage => currentPage + 1);
            setIsNewMessagesRequest(false);
        }
    }, [isNewMessagesRequest, currentPage, isMessagesLoading])

    // Send new message function
    const sendNewMessage = (message) => {
        httpAxios.post('/api/support/save_message', {
            client_id: clientId,
            message: message
        }).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    // Handle my new message
    useEffect(() => {
        if (myNewMessage !== null) {
            setMessages([...messages, myNewMessage]);
            sendNewMessage(myNewMessage);
            setMyNewMessage(null);
        }
    }, [myNewMessage]);

    // Set message as read
    const setMessageAsRead = () => {
        httpAxios.post('/api/support/set_message_as_read', {
            client_id: clientId,
        }).then(response => {
            console.log(response)
            // outletContext.setSupportNotifications(supportNotifications => supportNotifications.filter(el => el.client_id != clientId));
            outletContext.setSupportNotifications(supportNotifications => {
                return supportNotifications.map(supportNotification => {
                    if (supportNotification.client_id == clientId) {
                        return {
                            ...supportNotification,
                            unread_message_count: 0
                        }
                    }

                    return supportNotification
                })
            })
        }).catch(error => {
            console.log(error)
        })
    }

    // Listen new message
    const [newMessage, setNewMessage] = useState(null);
    useEffect(() => {
        if (clientId === undefined) return;


        LaravelEcho.private(`support.${clientId}`)
            .listen('.support.message.received', (e) => {
                console.log(e);
                if (e.message.user.id !== context.user.id) {
                    setNewMessage(e.message)
                    setMessageAsRead();
                }
            });

        console.log('CONNECT CHANNEL')

        return () => {
            LaravelEcho.leave(`support.${clientId}`);
            console.log('LEAVE CHANNEL')
        }
    }, [clientId]);

    useEffect(() => {
        if (newMessage !== null) {
            setMessages([...messages, newMessage]);
            setNewMessage(null);
        }
    }, [newMessage])

    // Archive chat
    const [isArchiveChatLoading, setIsArchiveChatLoading] = useState(false);

    const archiveChat = () => {
        setIsArchiveChatLoading(true);
        httpAxios.post('/api/support/archive_chat', {
            client_id: clientId
        }).then(response => {
            console.log(response)
            if (response.status === 200) {
                setIsArchiveChatBtnVisible(false);
                searchParams.delete('client_id');
                setSearchParams(searchParams);
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsArchiveChatLoading(false))
    }


    return (
        <div className={[classes.chat_container, "temp_scrollbar"].join(" ")}>
            <div className={classes.question_resolve_container}>
                {
                    isArchiveChatBtnVisible &&
                    <div
                        className={[classes.question_resolve_btn, "temp_btn", "temp_btn_success"].join(" ")}
                        onClick={archiveChat}
                    >
                        Вопрос решен!
                    </div>
                }

            </div>

            <NewChatBlock
                // isVisible={true}
                messages={messages}
                // setMessages={setMessages}
                // isNewMessageRequest={isNewMessagesRequest}
                setIsNewMessageRequest={setIsNewMessagesRequest}
                // setIsNewMessageSend={setIsNewMessageSend}
                // myNewMessage={myNewMessage}
                setMyNewMessage={setMyNewMessage}
            />

        </div>
    );
};

export default SupportChatContainer;