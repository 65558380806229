import Bell from '../images/icons/Bell.svg';
import Pen from '../images/icons/Pen.svg';
import Star from '../images/icons/Star.svg';
import Support from '../images/icons/Support.svg';
import People from '../images/icons/People.svg';
import Calendar from '../images/icons/Calendar.svg';
import BellWhite from '../images/icons/BellWhite.svg';
import PenWhite from '../images/icons/PenWhite.svg';
import StarWhite from '../images/icons/StarWhite.svg';
import SupportWhite from '../images/icons/SupportWhite.svg';
import PeopleWhite from '../images/icons/PeopleWhite.svg';
import CalendarWhite from '../images/icons/CalendarWhite.svg';


const UserMenuPoints = Object.freeze({
    MANAGER: [
        {
            to: '/manager/dashboard/bookings',
            "data-main-path": '/manager/dashboard/bookings',
            title: 'Заказы',
            alias: "bookings",
            icon_src: Bell,
            active_icon_src: BellWhite,
        },

        {
            to: '/manager/dashboard/experiences/active',
            "data-main-path": '/manager/dashboard/experiences',
            title: 'Впечатления',
            alias: "experiences",
            icon_src: Pen,
            active_icon_src: PenWhite,
        },

        {
            to: '/manager/dashboard/reviews/new',
            "data-main-path": '/manager/dashboard/reviews',
            title: 'Отзывы',
            alias: "reviews",
            icon_src: Star,
            active_icon_src: StarWhite,
        },

        {
            to: '/manager/dashboard/support',
            "data-main-path": '/manager/dashboard/support',
            title: 'Поддержка',
            alias: "support",
            icon_src: Support,
            active_icon_src: SupportWhite,
        },

        {
            to: '/manager/dashboard/profiles',
            "data-main-path": '/manager/dashboard/profiles',
            title: 'Пользователи',
            alias: "profile",
            icon_src: People,
            active_icon_src: PeopleWhite,
        },

        {
            to: '/manager/dashboard/calendar/bookings',
            "data-main-path": '/manager/dashboard/calendar',
            title: 'Календарь',
            alias: "calendar",
            icon_src: Calendar,
            active_icon_src: CalendarWhite,
        },
    ],

    ORGANIZER: [
        {
            to: '/organizer/dashboard/bookings',
            "data-main-path": '/organizer/dashboard/bookings',
            title: 'Заказы',
            alias: "bookings",
            icon_src: Bell,
            active_icon_src: BellWhite,
        },

        {
            to: '/organizer/dashboard/experiences/active',
            "data-main-path": '/organizer/dashboard/experiences',
            title: 'Мои впечатления',
            alias: "experiences",
            icon_src: Pen,
            active_icon_src: PenWhite,
        },

        {
            to: '/organizer/dashboard/calendar/bookings',
            "data-main-path": '/organizer/dashboard/calendar',
            title: 'Календарь',
            alias: "calendar",
            icon_src: Calendar,
            active_icon_src: CalendarWhite,
        },

        {
            to: '/organizer/dashboard/reviews/need_answer',
            "data-main-path": '/organizer/dashboard/reviews',
            title: 'Отзывы',
            alias: "reviews",
            icon_src: Star,
            active_icon_src: StarWhite
        },

        {
            to: '/organizer/dashboard/support',
            "data-main-path": '/organizer/dashboard/support',
            title: 'Поддержка',
            alias: "support",
            icon_src: Support,
            active_icon_src: SupportWhite,
        },

        {
            to: '/organizer/dashboard/profile',
            "data-main-path": '/organizer/dashboard/profile',
            title: 'Профиль',
            alias: "profile",
            icon_src: People,
            active_icon_src: PeopleWhite
        },
    ],

    CLIENT: [
        {
            to: '/client/dashboard/bookings',
            "data-main-path": '/client/dashboard/bookings',
            title: 'Заказы',
            alias: "bookings",
            icon_src: Bell,
            active_icon_src: BellWhite,
        },

        {
            to: '/client/dashboard/favorites',
            "data-main-path": '/client/dashboard/favorites',
            title: 'Избранное',
            alias: "favorites",
            icon_src: Star,
            active_icon_src: StarWhite,
        },

        {
            to: '/client/dashboard/support',
            "data-main-path": '/client/dashboard/support',
            title: 'Поддержка',
            alias: "support",
            icon_src: Support,
            active_icon_src: SupportWhite,
        },
    ]
});

export default UserMenuPoints;