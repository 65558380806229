import React, {useMemo} from 'react';
import classes from './RatingStarsRow.module.scss';

const RatingStarsRow = ({selectedRating = 5, onStarClick = () => {}, className = ''}) => {

    // Rating stars
    const ratingStars = [1, 2, 3, 4, 5];

    // Mark stars hovered
    const onMouseOverStar = (e) => {
        e.currentTarget.classList.add(classes.rating_star_icon_hovered);
        let previousEl = e.currentTarget.previousSibling;

        while (previousEl) {
            previousEl.classList.add(classes.rating_star_icon_hovered);
            previousEl = previousEl.previousSibling;
        }
    }

    // Unmark stars hovered
    const onMouseLeaveStar = (e) => {
        e.currentTarget.classList.remove(classes.rating_star_icon_hovered);
        let previousEl = e.currentTarget.previousSibling;

        while (previousEl) {
            previousEl.classList.remove(classes.rating_star_icon_hovered);
            previousEl = previousEl.previousSibling;
        }
    }

    // Render stars
    const ratingStarsList = useMemo(() => ratingStars.map((ratingStar) => {
        let starClasses = [classes.rating_star_icon];

        if (ratingStar <= selectedRating) {
            starClasses.push(classes.rating_star_icon_active);
        }
        return (
            <div
                className={starClasses.join(' ')}
                key={ratingStar}
                data-value={ratingStar}
                onClick={(e) => onStarClick(e.target.getAttribute('data-value'))}
                onMouseOver={(e) => onMouseOverStar(e)}
                onMouseLeave={(e) => onMouseLeaveStar(e)}
            >
            </div>
        );
    }), [ratingStars]);

    return (
        <div className={[classes.rating_stars_container, className].join(' ')}>
            {ratingStarsList}
        </div>
    );
};

export default RatingStarsRow;