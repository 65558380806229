import React, {useEffect, useState} from 'react';
import classes from "./BookingsBlock.module.scss";
import userIcon from "../../../../images/icons_temp/user.svg";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {useFetching} from "../../../../hooks/useFetching";
import ExcursionService from "../../../../API/ExcursionService";
import moment from "moment/moment";
import BookingEditBlock from "../BookingEditBlock/BookingEditBlock";

const BookingsBlock = ({setBookingsBlockHeaderText, setChatContainerHeaderText}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {...outletContext} = useOutletContext();

    useEffect(() => {
        console.log('outletContext BOOKING BLOCK')
        console.log(outletContext)
        console.log(outletContext.bookingNotifications)
    }, [outletContext])

    // Show booking info
    const switchBookingVisibility = (e) => {
        let isVisible = e.currentTarget.getAttribute("data-is-visible");
        let id = e.currentTarget.parentNode.getAttribute("data-booking-id");

        if (isVisible === "false") {
            e.currentTarget.textContent = "Свернуть заказ";
            document.getElementById(`booking_more_info_row_${id}`).classList.add(classes.booking_more_info_row_active);
            document.getElementById(`change_booking_btn_${id}`).classList.add(classes.change_booking_block_active);
            e.currentTarget.setAttribute("data-is-visible", "true");
        }else {
            e.currentTarget.textContent = "Показать заказ"
            document.getElementById(`booking_more_info_row_${id}`).classList.remove(classes.booking_more_info_row_active);
            document.getElementById(`change_booking_btn_${id}`).classList.remove(classes.change_booking_block_active);
            e.currentTarget.setAttribute("data-is-visible", "false");
        }
    }

    // Choosing chat
    const chooseChat = (e) => {
        let chatId = e.currentTarget.getAttribute("data-chat-id");
        searchParams.set("chat", chatId);
        setSearchParams(searchParams);
    }

    // Switch booking editing visibility
    const switchBookingEditingVisibility = (e) => {
        let bookingId = e.currentTarget.getAttribute("data-booking-id");
        document.getElementById(`booking_inner_block_${bookingId}`).classList.toggle("hidden");
        document.getElementById(`booking_edit_block_${bookingId}`).classList.toggle("hidden");
    }

    // Function for fetch bookings
    const [bookings, setBookings] = useState([]);

    const [fetchBookings, isBookingsLoading, bookingsError] = useFetching(async () => {
        const response = await ExcursionService.getBookingsWhere([
            ['excursion_type_id', '=', searchParams.get("experience_type_id")],
            ['date', '=', searchParams.get("date")],
            ['time', '=', searchParams.get("time")],
        ]);
        const data = response.data;
        setBookings(data);
        console.log('fetchBookings')
        console.log(data)
    })

    // Fetch bookings
    useEffect(() => {
        if (searchParams.get("experience_type_id") && searchParams.get("date") && searchParams.get("time")) {
            fetchBookings();
        }
    }, [searchParams])

    // useEffect(() => {
    //     console.log("Bookings block:")
    //     console.log(bookings)
    // }, [bookings])

    // Render bookings
    const renderBookings = bookings.map((booking, i) => {
        let datetime = moment(booking.created_at);
        // let datetime = moment(booking.date + ' ' + booking.time);

        return (
            <div
                className={classes.booking_block}
                key={`book_${booking.id}`}
            >
                {/*Block for show booking*/}
                <div
                    className={classes.booking_inner_block}
                    data-booking-id={booking.id}
                    id={`booking_inner_block_${booking.id}`}
                >
                    <div
                        className={[classes.booking_info_switch_btn, "temp_btn"].join(" ")}
                        onClick={e => switchBookingVisibility(e)}
                        data-is-visible={"false"}
                    >
                        Показать заказ
                    </div>

                    <div className={classes.booking_main_info_row}>
                        <div className={classes.main_info_block}>
                            <div className={classes.member_count_block}>
                                <div className={classes.member_count}>{booking.total_member_count}</div>
                                <img src={userIcon} alt={"чел."}/>
                            </div>

                            <div>{booking.user.name}</div>

                            <div>{booking.user.phone_number}</div>

                            <div>№{booking.id}</div>
                        </div>

                        <div className={classes.experience_type_block}>
                            {booking.type_info.name}
                        </div>

                        <div>
                            {
                                booking.payment_status === "paid" ?
                                    <div className={classes.paid_status}>Оплачено</div>
                                :
                                    <div className={classes.unpaid_status}>Не оплачено</div>
                            }
                        </div>
                    </div>

                    <div
                        className={[classes.booking_more_info_row].join(" ")}
                        id={`booking_more_info_row_${booking.id}`}
                    >
                        <div className={classes.member_types_block}>
                            {
                                booking.members.map((member, i) => {
                                    return (
                                        <div
                                            className={classes.member_type_row}
                                            key={`member_type_row_${i}`}
                                        >
                                            <div>{member.count}</div>
                                            <div>x</div>
                                            <div>{member.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={classes.prepayment_block}>
                            Предоплата: <span>{booking.prepayment_amount}</span> рублей
                        </div>

                        <div className={classes.postpayment_block}>
                            Сумма оплаты на месте: <span>{booking.total_amount - booking.prepayment_amount}</span> рублей
                        </div>

                        {
                            booking.employee_comment &&
                            <div className={classes.manager_comment_block}>
                                Комментарий менеджера: <span>{booking.employee_comment.body}</span>
                            </div>
                        }


                    </div>

                    <div className={classes.booking_btns_row}>

                        <div
                            className={classes.change_booking_block}
                            id={`change_booking_btn_${booking.id}`}
                        >
                            {
                                booking.status !== 'canceled' && booking.status !== 'completed' && moment(booking.date + ' ' + booking.time).isAfter(moment()) ?
                                    <div
                                        className={[classes.change_booking_btn, "temp_btn"].join(" ")}
                                        data-booking-id={booking.id}
                                        onClick={e => switchBookingEditingVisibility(e)}
                                    >
                                        Изменить заказ
                                    </div>
                                : ''
                            }
                        </div>


                        <div className={classes.time_chat_block}>
                            <div className={classes.new_message_count}>
                                {
                                    outletContext.bookingNotifications.find(el => el.booking_id == booking.id) !== undefined && outletContext.bookingNotifications.find(el => el.booking_id == booking.id).unread_messages_count > 0 &&
                                    `Новых сообщений: ${outletContext.bookingNotifications.find(el => el.booking_id == booking.id).unread_messages_count}`
                                }
                                {
                                    // booking.messages_count > 0 &&
                                    // `Новых сообщений: ${booking.messages_count}`
                                }
                            </div>

                            <div className={classes.time_chat_btn_block}>
                                <div className={classes.time_block}>
                                    {datetime.format('HH:mm') + ' ' + datetime.format('DD MMMM YYYY') + ' г.'}
                                </div>

                                <div
                                    className={[classes.chat_btn, "temp_btn"].join(" ")}
                                    data-chat-id={booking.id}
                                    onClick={e => chooseChat(e)}
                                >
                                    Связаться с гостем
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Block for editing booking*/}
                <BookingEditBlock
                    booking={booking}
                    switchBookingEditingVisibility={switchBookingEditingVisibility}
                />
            </div>
        )
    })

    // Set headers text
    useEffect(() => {
        if (bookings.length > 0) {
            let datetime = moment(bookings[0].date + ' ' + bookings[0].time);
            setBookingsBlockHeaderText(`${bookings[0].excursion.title} на ${datetime.format('DD MMMM')} в ${datetime.format('HH:mm')}`)
            setChatContainerHeaderText(`${bookings[0].user.name} заказ №${bookings.find(el => el.id == searchParams.get('chat')) && bookings.find(el => el.id == searchParams.get('chat')).id}`)
        }
    }, [bookings, searchParams])

    return (
        <div className={classes.bookings_container}>
            {renderBookings}
        </div>
    );
};

export default BookingsBlock;