import React, {useEffect, useRef, useState} from 'react';
import classes from "./ProfileEditBlock.module.scss";
import {priceFormatting} from "../../../../helpers/PriceHelper";
import {ReactComponent as RatingStarFilled} from "../../../../images/icons/RatingStarFilled.svg";
import {baseURL, httpAxios} from "../../../../API/HttpAxios";
import {BackgroundImgStretching} from "../../../../helpers/BackgroundImgStretching";
import {ImgStretchingFixedDiv} from "../../../../helpers/ImgStretching";
import {useParams} from "react-router-dom";


const ProfileEditBlock = ({profile, setProfile, updateProfiles}) => {

    // Save btn text
    const [saveBtnText, setSaveBtnText] = useState('Сохранить');

    // Stretch avatar
    useEffect(() => {
        if (profile.avatar_path !== null) {
            if (!profile.avatar_path.includes('http')) {
                setProfile({...profile, avatar_path: baseURL + profile.avatar_path});
            }
        }

        setSaveBtnText('Сохранить');
    }, [profile]);

    // Change avatar
    const userAvatar = useRef();

    const changeAvatar = () => {
        userAvatar.current.click();
    }

    const updateAvatar = (e) => {
        console.log(e)
        setProfile({...profile, avatar_path: URL.createObjectURL(e.target.files[0])});
    }

    const changeName = (e) => {
        setProfile({...profile, name: e.target.value});
    }

    const changeCompanyName = (e) => {
        setProfile({...profile, organizer_info: {...profile.organizer_info, company_name: e.target.value}});
    }

    const changeInn = (e) => {
        setProfile({...profile, organizer_info: {...profile.organizer_info, inn: e.target.value}});
    }

    const changePhone = (e) => {
        setProfile({...profile, phone_number: e.target.value});
    }

    const changeEmail = (e) => {
        setProfile({...profile, email: e.target.value});
    }

    const [isAccountVerified, setIsAccountVerified] = useState(profile.organizer_info && profile.organizer_info.account_verified_at !== null);
    const changeAccountVerified = () => {
        setIsAccountVerified(!isAccountVerified);
        setProfile({...profile, organizer_info: {...profile.organizer_info, account_verified_at: !isAccountVerified}});
    }

    const [isContractSigned, setIsContractSigned] = useState(profile.organizer_info && profile.organizer_info.contract_signed_at !== null);
    const changeContractSigned = () => {
        setIsContractSigned(!isContractSigned);
        setProfile({...profile, organizer_info: {...profile.organizer_info, contract_signed_at: !isContractSigned}});
    }

    const changeFee = (e) => {
        setProfile({...profile, organizer_info: {...profile.organizer_info, fee: e.target.value / 100}});
    }

    // Update profile
    const [profileUpdatingError, setProfileUpdatingError] = useState('');
    const [isProfileUpdating, setIsProfileUpdating] = useState(false);
    const updateProfile = () => {
        setIsProfileUpdating(true);
        setProfileUpdatingError('');
        
        const formData = new FormData();
        if (userAvatar.current.files.length > 0) {
            formData.append('avatar', userAvatar.current.files[0]);
        }

        formData.append('user', JSON.stringify(profile));
        

        httpAxios.post('/api/user/update_user', formData)
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    updateProfiles(profile);
                    setSaveBtnText('Сохранено');
                }
            }).catch(error => {
                setProfileUpdatingError(error.response.data.message);
                setSaveBtnText('Сохранить');
            }).finally(() => {
                setIsProfileUpdating(false);
            })
    }

    return (
        <div className={classes.profile_edit_container}>
            <div className={classes.profile_edit_block}>
                <div className={classes.profile_img_container}>
                    <div
                        className={classes.profile_img_block}
                    >
                        {
                            profile.avatar_path !== null &&
                            <img
                                className={'center_overflow_img'}
                                src={profile.avatar_path}
                                onLoad={ImgStretchingFixedDiv}
                            />
                        }

                        <div
                            className={classes.profile_img_load_block}
                            onClick={changeAvatar}
                        >
                            Загрузить<br/>фото
                        </div>
                    </div>
                </div>

                <input
                    ref={userAvatar}
                    className={classes.profile_avatar_input}
                    type="file"
                    accept="image/*"
                    onChange={updateAvatar}
                />

                <div className={classes.main_info_edit_block}>
                    <div className={classes.profile_top_row}>
                        <input
                            className={["temp_input", classes.profile_name_input].join(" ")}
                            type="text"
                            value={profile.name || ''}
                            onChange={changeName}
                        />

                        <div className={classes.profile_rating}>
                            {profile.rating}
                        </div>

                        <RatingStarFilled/>
                    </div>

                    <div className={classes.profile_middle_row}>
                        <div className={classes.organizer_company}>
                            <input
                                className={["temp_input", classes.company_input].join(" ")}
                                type="text"
                                value={profile.organizer_info && profile.organizer_info.company_name ? profile.organizer_info.company_name : ''}
                                onChange={changeCompanyName}
                            />
                        </div>

                        <div className={classes.organizer_inn}>
                            ИНН
                            <input
                                className={["temp_input", classes.inn_input].join(" ")}
                                type="text"
                                value={profile.organizer_info && profile.organizer_info.inn ? profile.organizer_info.inn : ''}
                                onChange={changeInn}
                            />
                        </div>
                    </div>

                    <div className={classes.profile_bottom_row}>
                        <div className={classes.account_status}>
                            {
                                !isAccountVerified ? "✖️️аккаунт не подтвержден" : "✔️аккаунт подтвержден"
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Info */}
            <div className={classes.info_edit_container}>
                <div className={classes.phone_edit_container}>
                    {/* Phone number */}
                    <div className={classes.phone_edit_label}>
                        Номер телефона
                    </div>

                    <input
                        className={["temp_input", classes.phone_edit_input].join(" ")}
                        type={"text"}
                        value={profile.phone_number || ''}
                        onChange={changePhone}
                    />
                </div>

                {/* Email */}
                <div className={classes.email_edit_container}>
                    <div className={classes.email_edit_label}>
                        Электронная почта
                    </div>

                    <input
                        className={["temp_input", classes.email_edit_input].join(" ")}
                        value={profile.email}
                        onChange={changeEmail}
                    />
                </div>

                {/* Verified */}
                <div className={classes.verified_edit_container}>
                    <div className={classes.verified_edit_label}>
                        Аккаунт подтвержден
                    </div>

                    <input
                        className={["temp_input", classes.verified_edit_input].join(" ")}
                        type={"checkbox"}
                        checked={isAccountVerified}
                        onChange={changeAccountVerified}
                    />
                </div>

                {/* Agreement */}
                <div className={classes.agreement_edit_container}>
                    <div className={classes.agreement_edit_label}>
                        Договор подписан
                    </div>

                    <input
                        className={["temp_input", classes.agreement_edit_input].join(" ")}
                        type={"checkbox"}
                        checked={isContractSigned}
                        onChange={changeContractSigned}
                    />
                </div>

                {/* Marketplace Fee */}
                <div className={classes.marketplace_fee_edit_container}>
                    <div className={classes.marketplace_fee_edit_label}>
                        Комиссия маркетплейса
                    </div>

                    <input
                        className={["temp_input", classes.marketplace_fee_edit_input].join(" ")}
                        value={profile.organizer_info && profile.organizer_info.fee ? profile.organizer_info.fee * 100 : ''}
                        onChange={changeFee}
                        type={'text'}
                    />

                    %
                </div>

                {/* Orders Completed */}
                <div className={classes.order_complited_container}>
                    {/*Всего проведенных заказов: {priceFormatting(1000)}*/}
                </div>

                {/* Amount Earned */}
                <div className={classes.amount_earned_container}>
                    {/*Заработано организатором: {priceFormatting(10000000)} руб.*/}
                </div>

                {/* Save Button */}
                <div
                    className={[classes.save_btn, "temp_btn", "temp_btn_success"].join(" ")}
                    onClick={isProfileUpdating ? () => {console.log('Не тыкай!')} : updateProfile}
                >
                     {isProfileUpdating ? "Гружу..." : saveBtnText}
                </div>

                <div className={classes.error_block}>
                    {profileUpdatingError}
                </div>
            </div>
        </div>
    );
};

export default ProfileEditBlock;