import React, { forwardRef } from 'react';
import Close from '../../../images/icons/Close.svg';
import classes from './HbCloseButton.module.scss';

const HbCloseButton = forwardRef((props, ref) => {
    // const HbCloseButton = forwardRef(({className = [], onClick = () => {}, ref = null}) => {
    return (
        <div
            ref={ref}
            className={[props.className, classes.container].join(" ")}
            onClick={props.onClick}
        >
            <img
                src={Close}
                width={10}
                alt="x"
            />
        </div>
    );
});

export default HbCloseButton;