import React, {useEffect, useRef, useState} from 'react';
import classes from "./MultiRangeSlider.module.scss";

const MultiRangeSlider = ({min, max, setPrices, currentMin, currentMax, visible}) => {
    const rangeBlock = useRef(null);
    const sliderRange = useRef(null);
    const sliderTrack = useRef(null);
    const [minValue, setMinValue] = useState(currentMin);
    const [maxValue, setMaxValue] = useState(currentMax);
    const [isVisible, setIsVisible] = useState(visible);

    const firstRange = useRef(null);
    const secondRange = useRef(null);

    // Change by outside data
    useEffect(() => {
        setMinValue(currentMin);
        setMaxValue(currentMax);
    }, [currentMin, currentMax])

    // Handle visible
    useEffect(() => {
        setIsVisible(visible);
    }, [visible])

    // Change first value
    const handleValueFirst = (event) => {
        let currentValue = Math.min(+event.target.value, maxValue - 1);
        setMinValue(currentValue);
        event.target.value = currentValue.toString();
    }

    // Change second value
    const handleValueSecond = (event) => {
        let currentValue = Math.max(+event.target.value, minValue + 1);
        setMaxValue(currentValue);
        event.target.value = currentValue.toString();
    }

    // Change size of fulfill line
    useEffect(() => {
        if (minValue < maxValue) {
            if (minValue >= min) {
                let left = firstRange.current.clientWidth / (max - min) * (minValue - min);
                sliderRange.current.style.left = `${Math.round(left)}px`;
                let resize = firstRange.current.clientWidth / (max - min) * (maxValue - minValue);
                sliderRange.current.style.width = `${Math.round(resize + 20)}px`;
            } else {
                sliderRange.current.style.left = 0;
                let resize = firstRange.current.clientWidth / (max - min) * (maxValue - min);
                sliderRange.current.style.width = `${Math.round(resize + 20)}px`;
            }
        } else {
            sliderRange.current.style.width = 0;
        }
        setPrices(minValue, maxValue);
    }, [minValue, maxValue, isVisible])



    return (
        <div
            ref={rangeBlock}
            className={classes.price_range_block}
        >
            <div className={classes.range_dot}></div>
            <div className={[classes.range_dot, classes.range_dot_right].join(' ')}></div>
            <div ref={sliderTrack} className={classes.slider_track}></div>
            <div ref={sliderRange} className={classes.slider_range}></div>

            <input
                ref={firstRange}
                id={'first_range'}
                className={[classes.price_range, classes.first_range].join(' ')}
                type={`range`}
                min={min}
                max={max}
                value={minValue}
                onChange={handleValueFirst}
            />

            <input
                ref={secondRange}
                id={'second_range'}
                className={[classes.price_range, classes.second_range].join(' ')}
                type={`range`}
                min={min}
                max={max}
                value={maxValue}
                onChange={handleValueSecond}
            />
        </div>
    );
};

export default MultiRangeSlider;