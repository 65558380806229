import React, {useContext, useEffect, useState} from 'react';
import classes from "./ExcursionBookingPage.module.scss";
import ExcursionBookingCard
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingCard/ExcursionBookingCard";
import BookingCalendar from "../../components/ExcursionPageComponents/BookingCalendar/BookingCalendar";
import ExcursionService from "../../API/ExcursionService";
import {useSearchParams} from "react-router-dom";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import {useFetching} from "../../hooks/useFetching";
import moment from "moment";
import {DaysWeek} from "../../lang/ru/DaysWeek";
import ArrowDrop from '../../images/icons/ArrowDrop.svg';
import useOutsideClick from "../../hooks/useOutsideClick";
import ExcursionBookingTimes
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingTimes/ExcursionBookingTimes";
import ExcursionBookingTypes
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingTypes/ExcursionBookingTypes";
import ExcursionBookingMembers
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingMembers/ExcursionBookingMembers";
import ExcursionBookingImportants
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingImportants/ExcursionBookingImportants";
import ExcursionBookingUserData
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingUserData/ExcursionBookingUserData";
import ExcursionBookingResult
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingResult/ExcursionBookingResult";
import UserAuth from "../../components/UserAuth/UserAuth";
import AuthContext from "../../context/AuthContext";
import { ExcursionBookingPromocode } from '../../components/ExcursionBookingPageComponents/ExcursionBookingPromocode/ExcursionBookingPromocode';
// import user_agreement from "documents/user_agreement.pdf";
// import oferta from "documents/oferta.pdf";
// import personal_data from "documents/personal_data.pdf";


const ExcursionBookingPage = () => {
    const {isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser} = useContext(AuthContext);
    const [excursion, setExcursion] = useState();
    const [excursionType, setExcursionType] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [schedule, setSchedule] = useState();
    const [date, setDate] = useState(searchParams.has('date') ? moment(searchParams.get('date')) : moment());
    const [formattingDayWeek, setFormattingDayWeek] = useState({});
    const [time, setTime] = useState('');
    const [promocode, setPromocode] = useState('');

    // Set search params by states
    useEffect(() => {
        if (!isExcursionLoading && !isScheduleLoading) {
            searchParams.set('date', date.format('YYYY-MM-DD'));
            setFormattingDayWeek(DaysWeek.find(el => el.name.toLowerCase() === date.format('dddd')));

            // if (time !== '') {
                
            // }
            time !== '' && searchParams.set('time', time);
            // searchParams.set('time', time);
            searchParams.set('type', excursionType.type_name);

            setSearchParams(searchParams);
        }
    }, [excursion, date, time, excursionType])

    // Get excursion by id
    const [fetchExcursion, isExcursionLoading, excursionError] = useFetching(async () => {
        const response = await ExcursionService.getCurrentExcursion(searchParams.has('id') && searchParams.get('id'));
        const data = response.data;
        setExcursion(data);

        if (searchParams.has('type')) {
            console.log(searchParams.get('type'))
            let findType = data.types.find(el => el.type_name === searchParams.get('type'));

            if (findType !== undefined) {
                setExcursionType(findType);
            }else {
                setExcursionType(data.types[0]);
            }
        }else {
            setExcursionType(data.types[0]);
        }

        console.log('EXCURSION:')
        console.log(data)
    })

    // Get excursion schedule
    const [fetchSchedule, isScheduleLoading, scheduleError] = useFetching(async () => {
        const response = await ExcursionService.getSchedule(searchParams.has('id') && searchParams.get('id'));
        const data = response.data;
        setSchedule(data);
        // console.log('SCHEDULE:')
        // console.log(data)
    })

    useEffect(() => {
        fetchExcursion();
        fetchSchedule();
    }, [])

    // Calendar visibility
    const [calendarClasses, setCalendarClasses] = useState(['roundblock_angle_rt', 'hidden', classes.calendar_block]);
    const [datesButtonClasses, setDatesButtonClasses] = useState([classes.dates_button]);
    const [datesButtonImgClasses, setDatesButtonImgClasses] = useState([classes.dates_button_img]);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const shiftCalendarVisibility = () => {
        setIsCalendarVisible(!isCalendarVisible);
    }

    useEffect(() => {
        if (isCalendarVisible) {
            setCalendarClasses(['roundblock_angle_rt', classes.calendar_block]);
            setDatesButtonClasses([classes.dates_button, classes.dates_button_active]);
            setDatesButtonImgClasses([classes.dates_button_img, classes.dates_button_img_active]);
        }else {
            setCalendarClasses(['roundblock_angle_rt', 'hidden', classes.calendar_block]);
            setDatesButtonClasses([classes.dates_button]);
            setDatesButtonImgClasses([classes.dates_button_img]);
        }
    }, [isCalendarVisible])

    const datesContainer = useOutsideClick(() => setIsCalendarVisible(false));

    // MemberTypes BLOCK
    const [members, setMembers] = useState([]);
    // const [members, setMembers] = useState({});

    // Set members by members component
    const getMembers = (members) => {
        setMembers(members);
    }

    useEffect(() => {
        console.log('members')
        console.log(members)
    }, [members]);

    return (
        <div className={'container_fluid'}>
            <div className={'container'}>
                {isExcursionLoading
                    ?
                    <PageLoader/>
                    :
                    <div className={classes.excursion_booking_container}>
                        <div className={classes.booking_card_container}>
                            <ExcursionBookingCard
                                excursion={excursion}
                                excursionType={excursionType}
                            />
                        </div>

                        <div className={classes.booking_container}>
                            {/* Title */}
                            <div className={classes.booking_title_container}>
                                <div className={classes.booking_title}>
                                    Бронирование
                                </div>
                            </div>

                            {/* Dates */}
                            <div className={classes.dates_container}>
                                <div className={classes.dates_title}>
                                    Выберите дату
                                </div>

                                <div
                                    className={classes.dates_block}
                                    ref={datesContainer}
                                >
                                    <div
                                        className={datesButtonClasses.join(' ')}
                                        onClick={shiftCalendarVisibility}
                                    >
                                        <div className={classes.dates_button_inner_bock}>
                                            <div className={classes.dates_button_date_info}>
                                                <div className={classes.dates_button_date}>
                                                    {date.format('DD.MM.YY')}
                                                </div>

                                                <div className={classes.dates_button_day_week}>
                                                    {formattingDayWeek && formattingDayWeek.name}
                                                </div>
                                            </div>

                                            <div className={classes.dates_button_img_block}>
                                                <img
                                                    className={datesButtonImgClasses.join(' ')}
                                                    src={ArrowDrop}
                                                    alt={'drop'}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={calendarClasses.join(' ')}>
                                        {
                                            isScheduleLoading
                                                ? <PageLoader/>
                                                :
                                                <BookingCalendar
                                                    excursionType={excursionType}
                                                    schedule={schedule}
                                                    setDate={setDate}
                                                    date={date}
                                                    setIsCalendarVisible={setIsCalendarVisible}
                                                    isCalendarVisible={isCalendarVisible}
                                                />
                                        }

                                    </div>
                                </div>
                            </div>

                            {/* Times */}
                            <div className={classes.times_container}>
                                <div className={classes.times_title}>
                                    Выберите время
                                </div>

                                {
                                    isScheduleLoading
                                        ? <PageLoader/>
                                        :
                                        <ExcursionBookingTimes
                                            schedule={schedule}
                                            date={date}
                                            excursionType={excursionType}
                                            setTime={setTime}
                                            time={time}
                                        />
                                }

                            </div>

                            {/* Types */}
                            <div className={classes.types_container}>
                                <ExcursionBookingTypes
                                    excursion={excursion}
                                    excursionType={excursionType}
                                    setExcursionType={setExcursionType}
                                />
                            </div>

                            {/* MemberTypes count */}
                            <div
                                className={classes.members_container_fluid}
                                id={'members_container'}
                            >
                                <div className={classes.members_container}>
                                    <div
                                        className={classes.members_title}
                                        id={'members_title'}
                                    >
                                        Количество участников
                                    </div>

                                    <ExcursionBookingMembers
                                        excursionType={excursionType}
                                        getMembers={getMembers}
                                    />
                                </div>
                            </div>



                            {/* Important info */}
                            <div className={classes.important_info_container}>
                                <div className={classes.important_info_title}>
                                    Важная информация
                                </div>

                                <ExcursionBookingImportants
                                    excursionType={excursionType}
                                />
                            </div>

                            {/* User data */}
                            {
                                isAuth
                                ?
                                    <div
                                        id={'user_data_container'}
                                        className={classes.user_data_container}
                                    >
                                        <div className={classes.user_data_title}>
                                            Основные данные для бронирования
                                        </div>

                                        <ExcursionBookingUserData/>
                                    </div>
                                : ''

                            }

                            {/* Promocode */}
                            <div className={classes.promocode_container}>
                                <div className={classes.promocode_title}>
                                    У вас есть промокод?
                                </div>

                                <ExcursionBookingPromocode
                                    excursionType={excursionType}
                                    setExcursionType={setExcursionType}
                                    promocode={promocode}
                                    setPromocode={setPromocode}
                                />
                            </div>

                            {/* Booking result */}
                            <div className={classes.booking_result_container}>
                                <ExcursionBookingResult
                                    members={members}
                                    excursionType={excursionType}
                                    paymentMethods={excursion.payment_methods}
                                    promocode={promocode}
                                />
                            </div>

                            {/* Agreement */}
                            <div className={classes.agreement_container}>
                                Завершая бронирование, вы соглашаетесь с <a href={'/documents/user_agreement.pdf'} target={'_blank'}>Пользовательским соглашением</a>, <a href={'/documents/personal_data.pdf'} target={'_blank'}>Политикой обработки персональных данных</a> и <a href={'/documents/oferta.pdf'} target={'_blank'}>Договором оферты.</a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ExcursionBookingPage;