import React, {useEffect, useRef, useState} from 'react';
import classes from "./SearchLine.module.scss";
import searchIconWhite from "../../../images/icons/SearchWhite.svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import moment from "moment";
import ExcursionService from "../../../API/ExcursionService";
import {
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";
import ArrowLeft from '../../../images/icons/ArrowLeft.svg';
import ArrowRight from '../../../images/icons/ArrowRight.svg';
import CloseButton from '../../../images/icons/Close.svg';
import {baseURL} from "../../../API/HttpAxios";
import {Months} from "../../../lang/ru/Months";
import HbButton from "../HbButton/HbButton";


const SearchLine = (props) => {
    const navigate = useNavigate();
    let mainParams = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    // Visibility location select
    const [searchFirstBlock, setSearchFirstBlock] = useState([classes.search_include_block, classes.search_first_block]);
    const [locationBlockClasses, setLocationBlockClasses] = useState([classes.location_block]);
    const [visibleLocations, setVisibleLocations] = useState(false);
    const searchVerticalLine = useRef()

    useEffect(() => {
        if (props.visibleLocations) {
            setVisibleLocations(props.visibleLocations);
        }
    }, [props.visibleLocations])

    const focusFirstBlock = () => {
        setVisibleLocations(true);
        searchVerticalLine.current.classList.add('hidden');
    }

    const outsideFirstBlockClick = () => {
        setVisibleLocations(false);
    }

    const locationMenu = useOutsideClick(outsideFirstBlockClick)

    const handleLocationClick = (event) => {
        if (event.target === event.currentTarget) {
            event.stopPropagation()
        }
    }

    useEffect(() => {
        if(visibleLocations) {
            setSearchFirstBlock([...searchFirstBlock, classes.search_first_block_active]);
            setLocationBlockClasses([...locationBlockClasses, classes.location_block_active]);
        }else {
            setSearchFirstBlock([classes.search_include_block, classes.search_first_block]);
            setLocationBlockClasses([classes.location_block]);
        }
    }, [visibleLocations])


    // Visibility dates select (calendar)
    const [searchSecondBlock, setSearchSecondBlock] = useState([classes.search_include_block, classes.search_second_block])
    const [datesBlockClasses, setDatesBlockClasses] = useState([classes.calendar_block]);
    const [visibleDates, setVisibleDates] = useState(false)

    const focusSecondBlock = () => {
        setVisibleDates(true)
    }

    const outsideSecondBlockClick = () => {
        setVisibleDates(false);
    }

    const datesMenu = useOutsideClick(outsideSecondBlockClick)

    const handleDatesClick = (event) => {
        event.stopPropagation()
    }

    useEffect(() => {
        if(visibleDates) {
            setSearchSecondBlock([...searchSecondBlock, classes.search_second_block_active, 'roundblock_angle_lb']) //???round
            setDatesBlockClasses([...datesBlockClasses, classes.calendar_block_active])
        }else {
            setSearchSecondBlock([classes.search_include_block, classes.search_second_block])
            setDatesBlockClasses([classes.calendar_block])
        }
    }, [visibleDates])

    useEffect(() => {
        if (props.visibleDates) {
            setVisibleDates(props.visibleDates);
        }
    }, [props.visibleDates])

    // Func for display/hidden vertical line
    useEffect(() => {
        if (visibleLocations || visibleDates) {
            searchVerticalLine.current.classList.add('hidden');
        } else {
            searchVerticalLine.current.classList.remove('hidden')
        }
    }, [visibleLocations, visibleDates])

    // Func to receive cities from backend
    const [cities, setCities] = useState([])
    const [location, setLocation] = useState({link: ''})

    async function fetchCities() {
        const response = await ExcursionService.getAllCities();
        const data = response.data;
        setCities(data);
        props.cities(data);
    }

    useEffect(() => {
        fetchCities();
    }, [])

    // Set city name by mainParams
    useEffect(() => {
        if (mainParams.city && cities !== []) {
            let cityName = cities.map((city) => city.alias === mainParams.city && city.name);
            setLocation({name: cityName});
            props.selectCity(cityName);
        }
        props.cities(cities);
    }, [mainParams, cities])

    // Func for select location
    const selectLocation = (event) => {
        setLocation({
            link: event.currentTarget.id,
            name: event.currentTarget.getAttribute('name')
        })
        props.selectCity(event.currentTarget.getAttribute('name'))
    }

    // Func for create calendar
    const calendarList = () => {
        const currentDate = moment();
        const monthCount = 18;
        const ruMonths = Months;
        let content = [];

        for (let m = 0; m < monthCount; m++) {
            let date = moment().add(m, 'month')
            let month = date.month()
            let year = date.year()
            let daysInMonth = date.daysInMonth()
            let monthStart = date.startOf('month')
            let weekdayStart =  monthStart.weekday()
            let monthDays = []

            // if(weekdayStart === 0) {
            //     weekdayStart = 7
            // }

            for (let i = 1; i <= weekdayStart; i++) {
                monthDays.push(
                    <div
                        key={`empty_${i}_${m}_${year}`}
                        className={`empty_day`}
                        id={`empty_day_${i}_${m}_${year}`}
                    >
                    </div>
                )
            }

            for (let d = 1; d <= daysInMonth; d++) {
                let dayClasses = [classes.day];
                if (d === currentDate.date() && month === currentDate.month() && year === currentDate.year()) {
                    dayClasses.push(classes.current_day);
                } else if (d < currentDate.date() && month === currentDate.month() && year === currentDate.year()) {
                    dayClasses.push(classes.disabled_day);
                }

                monthDays.push(
                    <div
                        key={`${d}_${m}_${year}`}
                        className={dayClasses.join(' ')}
                        id={`${year}_${month}_${d}`}
                        onClick={selectDate}
                    >
                        {d}
                    </div>
                )
            }

            content.push(
                <div key={`month_block_${m}_${year}`} className={`${classes.month_block}`}>
                    <div key={`month_name_${m}_${year}`} className={classes.month_name}>
                        {ruMonths[month]} {year}
                    </div>

                    <div key={`week_days_${m}_${year}`} className={classes.week_days}>
                        <div key={`monday_${m}_${year}`}>
                            Пн
                        </div>
                        <div key={`tuesday_${m}_${year}`}>
                            Вт
                        </div>
                        <div key={`wednesday_${m}_${year}`}>
                            Ср
                        </div>
                        <div key={`thursday_${m}_${year}`}>
                            Чт
                        </div>
                        <div key={`friday_${m}_${year}`}>
                            Пт
                        </div>
                        <div  key={`saturday_${m}_${year}`}>
                            Сб
                        </div>
                        <div  key={`sunday_${m}_${year}`}>
                            Вс
                        </div>
                    </div>

                    <div key={`month_days_${m}_${year}`} className={classes.month_days}>
                        {monthDays}
                    </div>
                </div>
            )
        }

        return content
    }

    // Func for select dates by get parameters
    useEffect(() => {
        if (searchParams.get('date_start')) {
            let dateStart = moment(searchParams.get('date_start'));
            let dateStartId = dateStart.toArray().slice(0, 3);
            dateStartId = dateStartId.join('_');
            document.getElementById(dateStartId).classList.add(classes.selected_day)

            if (searchParams.get('date_end')) {
                let dateEnd = moment(searchParams.get('date_end'));
                let dateEndId = dateEnd.toArray().slice(0, 3);
                dateEndId = dateEndId.join('_');

                let nextDate = dateStart.add(1, 'd');
                let nextDateId = nextDate.toArray().slice(0, 3).join('_')
                while (nextDateId !== dateEndId) {
                    document.getElementById(nextDateId).classList.add(classes.selected_day_between)
                    nextDate = dateStart.add(1, 'd');
                    nextDateId = nextDate.toArray().slice(0, 3).join('_')
                }
                document.getElementById(dateEndId).classList.add(classes.selected_day)
            }
        }
    }, [])


    // Func for display dates by get parameters
    let userDateStart;
    if (searchParams.get('date_start')) {
        userDateStart = searchParams.get('date_start').split('-')
        userDateStart = `${userDateStart[2]}.${userDateStart[1]}`;
    }

    let userDateEnd;
    if (searchParams.get('date_end')) {
        userDateEnd = searchParams.get('date_end').split('-');
        userDateEnd = `${userDateEnd[2]}.${userDateEnd[1]}`;
    }

    const [datesCount, setDatesCount] = useState(0)
    const [dates, setDates] = useState({})
    const [userDates, setUserDates] = useState({
        date_start: userDateStart,
        date_end: userDateEnd
    })

    useEffect(() => {
        props.selectDates(userDates)
    }, [userDates])

    const [datesId, setDatesId] = useState({})


    // Func for select dates by click on calendar
    const selectDate = (event) => {
        if (event.currentTarget.classList.contains(classes.disabled_day)) {
            return;
        }

        let selectedDate = event.currentTarget.id.split('_').map(Number);
        const nextDatesCount = datesCount + 1;

        if (nextDatesCount === 1) {
            let days = document.getElementsByClassName(classes.day);

            for (let day of days) {
                day.classList.remove(classes.selected_day, classes.selected_day_between)
            }
            event.currentTarget.classList.add(classes.selected_day);

            setDatesId({date_start: event.currentTarget.id});
            setDates({date_start: moment(selectedDate).format('YYYY-MM-DD')});
            setUserDates({date_start: moment(selectedDate).format('DD.MM')});
            props.selectDates({date_start: moment(selectedDate).format('DD.MM')})
            setDatesCount(1);
            searchParams.delete('date_end');
            setSearchParams(searchParams);
        } else if (nextDatesCount === 2) {
            event.currentTarget.classList.add(classes.selected_day);
            if (dates.date_start < moment(selectedDate).format('YYYY-MM-DD')) {
                setDates({...dates, date_end: moment(selectedDate).format('YYYY-MM-DD')});
                setUserDates({...userDates, date_end: moment(selectedDate).format('DD.MM')});
                props.selectDates({...userDates, date_end: moment(selectedDate).format('DD.MM')})

                setDatesId({...datesId, date_end: event.currentTarget.id});
                setDatesCount(0)
            } else if (dates.date_start > moment(selectedDate).format('YYYY-MM-DD')) {
                setDates({...dates, date_end: dates.date_start, date_start: moment(selectedDate).format('YYYY-MM-DD')});
                setUserDates({...userDates, date_end: userDates.date_start, date_start: moment(selectedDate).format('DD.MM')});
                props.selectDates({...userDates, date_end: userDates.date_start, date_start: moment(selectedDate).format('DD.MM')});
                setDatesId({...datesId, date_end: datesId.date_start, date_start: event.currentTarget.id})
                setDatesCount(0)
            }
        }
    }

    // Func for highlight between selected dates
    useEffect(() => {
        if(datesId.date_end !== undefined) {
            let dayMoment = moment(datesId.date_start.split('_').map(Number));
            dayMoment.add(1, 'd')
            let nextId = dayMoment.toArray().slice(0, 3).join('_');
            while (datesId.date_end !== nextId) {
                document.getElementById(nextId).classList.add(classes.selected_day_between)
                dayMoment.add(1, 'd')
                nextId = dayMoment.toArray().slice(0, 3).join('_');
            }
        }
    }, [datesId])

    // Func for reset calendar
    const calendarReset = () => {
        let days = document.getElementsByClassName(classes.day);
        for (let day of days) {
            day.classList.remove(classes.selected_day, classes.selected_day_between)
        }

        console.log(searchParams)
        setDates({});
        setUserDates({});
        props.selectDates({});
        setDatesCount(0);
        searchParams.delete('date_start');
        searchParams.delete('date_end');
        setSearchParams(searchParams);
    }

    // Func for scroll calendar by buttons or scrollbar
    const [scrollCalendarPos, setScrollCalendarPos] = useState(0)

    const calendarScroll = (event) => {
        const scrollPosition = document.getElementById(`date_block`).scrollLeft;
        const scrollStep = document.getElementById(`calendar_block`).clientWidth / 2;
        let scrollSize = 0
        if (event.currentTarget.id === 'calendarScrollLeft') {
            scrollSize = scrollPosition - scrollStep;
            document.getElementById(`date_block`).scrollLeft = scrollSize;
        }else {
            scrollSize = scrollPosition + scrollStep;
            document.getElementById(`date_block`).scrollLeft = scrollSize;
        }

        setScrollCalendarPos(scrollSize)
    }

    // Func for hidden arrows for extreme left & right positions
    const hiddenArrows = () => {
        const scrollPosition = document.getElementById(`date_block`).scrollLeft;
        const calendarSize = document.getElementById(`date_block`).scrollWidth - document.getElementById(`date_block`).clientWidth;
        setScrollCalendarPos((scrollCalendarPos) => {
            scrollCalendarPos = scrollPosition;
            if (scrollCalendarPos <= 10) {
                document.getElementById(`calendarScrollLeft`).classList.add('hidden')
            } else if (scrollCalendarPos >= calendarSize) {
                document.getElementById(`calendarScrollRight`).classList.add('hidden')
            } else {
                document.getElementById(`calendarScrollLeft`).classList.remove('hidden')
                document.getElementById(`calendarScrollRight`).classList.remove('hidden')
            }

            return scrollCalendarPos;
        })
    }

    useEffect(() => {
        hiddenArrows()
    }, [scrollCalendarPos])

    // Navigate to page with country, city, dates after click search button
    const searchExcursions = (event) => {
        event.stopPropagation();
        setVisibleDates(false);
        setVisibleLocations(false);

        let mainLink = '';
        if (location.link !== undefined) {
            mainLink = location.link;
        } else if (mainParams.country || mainParams.city) {
            mainLink += `/${mainParams.country}`;
            mainLink += `/${mainParams.city}`;
        }

        if (dates.date_start !== undefined) {
            searchParams.set('date_start', dates.date_start);
        }

        if (dates.date_end !== undefined) {
            searchParams.set('date_end', dates.date_end);
        }

        navigate({
            pathname: `${mainLink}`,
            search: searchParams.toString()
        })
    }



    return (
        <div className={`roundblock_angle_lb ${classes.search_block}`}>
            {/*Locations row select*/}
            <div ref={locationMenu} onClick={focusFirstBlock} className={`${searchFirstBlock.join(' ')}`}>
                <input
                    type={"text"}
                    placeholder={location.name ? location.name : 'Какой город?'}
                />
            </div>

            <div
                onClick={handleLocationClick}
                className={`roundblock_angle_lt ${locationBlockClasses.join(' ')}`}
            >
                <div className={classes.location_close_button}>
                    <img src={CloseButton} alt={'close'}/>
                </div>

                {cities.map((city) => {
                    return (
                        <div
                            onClick={selectLocation}
                            className={classes.location}
                            key={`location_${city.id}`}
                            id={`/${city.country.alias}/${city.alias}`}
                            name={city.name}
                        >
                            <div className={classes.location_img} key={`location_img_${city.id}`}>
                                <img src={baseURL + city.img_path} alt={city.alias}/>
                            </div>

                            <div className={classes.location_info} key={`location_info_${city.id}`}>
                                <div className={classes.location_name} key={`location_name_${city.id}`}>
                                    {city.name}
                                </div>

                                <div className={classes.excursions_count} key={`excursion_count_${city.id}`}>
                                    {/*{city.excursions_count} впечатлен*/}
                                    {/*{*/}
                                    {/*    city.excursions_count === 1 */}
                                    {/*    ?*/}
                                    {/*        'ие'*/}
                                    {/*    :*/}
                                    {/*        city.excursions_count >= 5 || city.excursions_count === 0*/}
                                    {/*        ?*/}
                                    {/*            'ий'*/}
                                    {/*        :*/}
                                    {/*            'ия'*/}
                                    {/*}*/}
                                    Впечатлений: {city.excursions_count}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div ref={searchVerticalLine} className={`${classes.search_vertical_line}`}></div>

            {/*Dates select*/}
            <div ref={datesMenu} onClick={focusSecondBlock} className={`${searchSecondBlock.join(' ')}`}>
                <span className={`truncate`}>
                    {
                        userDates.date_start
                        ?
                            `${userDates.date_start}${userDates.date_end === undefined ? '' : `-${userDates.date_end}`}`
                        :
                            'Когда?'
                    }
                </span>
                <button
                    className={classes.search_btn}
                    onClick={searchExcursions}
                >
                    <img src={searchIconWhite} alt={'s'}/>
                </button>
            </div>

            <div
                className={`roundblock_angle_rt ${datesBlockClasses.join(' ')}`}
                id={`calendar_block`}
                onClick={handleDatesClick}
            >
                <div
                    className={classes.date_close_button}
                    onClick={() => setVisibleDates(false)}
                >
                    <img src={CloseButton} alt={'close'}/>
                </div>

                <div
                    className={`${classes.arrow} ${classes.arrow_left}`}
                    onClick={calendarScroll}
                    id={`calendarScrollLeft`}
                >
                    <img src={ArrowLeft} alt={'left'}/>
                </div>
                    <div
                        className={classes.date_block}
                        id={`date_block`}
                        onScroll={hiddenArrows}
                    >


                        {calendarList()}
                        {/*<Calendar/>*/}
                    </div>
                <div
                    className={`${classes.arrow} ${classes.arrow_right}`}
                    onClick={calendarScroll}
                    id={`calendarScrollRight`}
                >
                    <img src={ArrowRight} alt={'right'}/>
                </div>

                <div className={classes.calendar_btns_block}>
                    <HbButton
                        color={'black_white'}
                        className={classes.calendar_reset}
                        onClick={calendarReset}
                    >
                        Сбросить
                    </HbButton>

                    {/*<div className={classes.calendar_search_btn_container}>*/}
                        <HbButton
                            color={'pink'}
                            className={classes.calendar_search_btn}
                            onClick={searchExcursions}
                        >
                            Поиск
                        </HbButton>
                    {/*</div>*/}
                </div>


            </div>
        </div>
    );
};

export default SearchLine;