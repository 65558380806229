import React from 'react';
import classes from "./ExcursionBookingImportants.module.scss";

const ExcursionBookingImportants = ({excursionType}) => {
    const getImportantsList = () => {
        let content = [];
        excursionType.importants.map((important, index) => {
            content.push(
                // <div
                //     className={classes.important_row}
                //     key={`important_row_${index}`}
                // >
                //     {important.body}
                // </div>

                <div
                    className={classes.important_info_row}
                    key={`important_${index}`}
                >
                    <div className={classes.important_info_dot}>

                    </div>

                    <div className={classes.important_info}>
                        {important.body}
                    </div>
                </div>
            )
        })

        return content;
    }

    const importantsList = getImportantsList();

    return (
        <div>
            {/*<div className={classes.important_row}>*/}
            {/*    участникам со студенческим или пенсионным билетом необходимо иметь с собой удостоверение*/}
            {/*</div>*/}

            {/*<div className={classes.important_row}>*/}
            {/*    Если вам не понравится экскурсия - мы вернем вам деньги. Без чека.*/}
            {/*</div>*/}

            {/*<div className={classes.important_row}>*/}
            {/*    Гарантированный возврат при отмене за 10 часов до начала впечатления*/}
            {/*</div>*/}

            {importantsList}
        </div>
    );
};

export default ExcursionBookingImportants;