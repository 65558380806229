import React, {useEffect, useState} from 'react';
import classes from "./MiniCalendar.module.scss";
import moment from "moment";
import 'moment/locale/ru';
import shortWeekDaysRu from "../../../../enums/WeekDays";

const MiniCalendar = ({
    className = [],
    onDateClick = () => {},
    availableDates = [],
    selectedDate = moment()
}) => {
    // useEffect(() => {
    //     console.log("Available dates:")
    //     console.log(availableDates)
    // }, [availableDates])
    const [now, setNow] = useState(moment());
    // const now = moment();
    const [month, setMonth] = useState(now.month());
    const [year, setYear] = useState(now.year());

    // Get the week days
    const weekDays = shortWeekDaysRu;
    const renderWeekDays = weekDays.map((day, index) => <div key={`week_day_${index}`} className={classes.week_day_title}>{day}</div>);

    /**
     * Get the days in the specified month and year.
     *
     * @param {number} month - The month (0-11).
     * @param {number} year - The year.
     * @return {Array} An array of objects representing each day in the month, with day, month, weekDay, and year properties.
     */
    const getDaysInMonth = (month, year) => {
        let days = [];

        const firstDay = moment();
        firstDay.set("year", year);
        firstDay.set("month", month);
        firstDay.set("date", 1);

        while (firstDay.month() === month) {
            days.push(firstDay.clone());

            firstDay.add(1, 'd');
        }

        return days;
    }

    /**
     * Function to generate a list of days for the given month and year.
     *
     * @return {array} list of days for the specified month and year
     */
    const daysList = () => {
        const days = getDaysInMonth(month, year);

        let result = [];

        if (days[0].weekday() > 0) {
            let emptyCells = days[0].weekday();

            for (let i = 0; i < emptyCells; i++) {
                result.push(
                    <div
                        key={`empty_${i}`}
                        className={classes.empty_day}>
                    </div>
                )
            }
        }

        let today = moment();

        for (const day of days) {
            let styleClass = [classes.day];
            let onDateClickPrepared = () => onDateClick(day);


            // Set days style
            if (day.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD")) {
                if (availableDates.find(availableDate => availableDate.date === selectedDate.format("YYYY-MM-DD"))) {
                    styleClass.push(classes.selected_day);
                }else {
                    styleClass.push(classes.selected_day_unavailable);
                    onDateClickPrepared = () => {};
                }
            }else if (day.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
                styleClass.push(classes.today);

                if (availableDates.find(availableDate => availableDate.date === day.format("YYYY-MM-DD"))) {

                }else {
                    onDateClickPrepared = () => {};
                    // console.log('loh')
                }
            }else if (day.diff(today, 'days') >= 0 && availableDates.find(availableDate => availableDate.date === day.format("YYYY-MM-DD"))) {
                styleClass.push(classes.next_day);
            }else {
                styleClass.push(classes.unavailable_day);
                onDateClickPrepared = () => {};
            }

            result.push(
                <div
                    key={`day_${day.date()}`}
                    onClick={onDateClickPrepared}
                >
                    <div className={styleClass.join(" ")}>
                        {day.date()}
                    </div>
                </div>
            )
        }

        return result;
    }

    /**
     * Function to handle the click to next month.
     *
     */
    const switchNextMonth = () => {
        if (month === 11) {
            now.set("month", 0);
            now.set("year", year + 1);
            setMonth(now.month());
            setYear(now.year());
        } else {
            now.set("month", month + 1);
            setMonth(now.month());
        }
    }

    /**
     * Function to handle the click to previous month.
     *
     */
    const switchPreviousMonth = () => {
        if (month === 0) {
            now.set("month", 11);
            now.set("year", year - 1);
            setMonth(now.month());
            setYear(now.year());
        } else {
            now.set("month", month - 1);
            setMonth(now.month());
        }
    }

    // Render calendar after set today
    useEffect(() => {
        setMonth(now.month());
        setYear(now.year());
    }, [now])



    return (
        <div className={[classes.main_container, ...className].join(" ")}>
            <div className={classes.calendar_top_row}>
                <div
                    className={classes.today_btn}
                    onClick={() => setNow(moment())}
                >
                    Сегодня
                </div>

                <div className={classes.month_year_container}>
                    <div
                        className={[classes.left_arrow, classes.month_arrow].join(" ")}
                        onClick={switchPreviousMonth}
                    >
                        &lt;
                    </div>

                    <div className={classes.month_year_title}>
                        {now.format('MMMM')} {now.format('YYYY')}
                    </div>

                    <div
                        className={[classes.right_arrow, classes.month_arrow].join(" ")}
                        onClick={switchNextMonth}
                    >
                        &gt;
                    </div>
                </div>
            </div>


            <div className={classes.calendar_container}>
                <div className={classes.week_days_row}>
                    {renderWeekDays}
                </div>

                <div className={classes.days_container}>
                    {daysList()}
                </div>
            </div>
        </div>
    );
};

export default MiniCalendar;