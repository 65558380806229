import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from "./NewChatBlock.module.scss";
import moment from "moment";
import AuthContext from "../../../../context/AuthContext";
import ArrowSend from "../../../../images/icons/ArrowSend.svg";

const NewChatBlock = ({messages, setIsNewMessageRequest, setMyNewMessage}) => {
    const {...context} = useContext(AuthContext);
    const [inputMessage, setInputMessage] = useState("");
    const input_message_ref = useRef();

    // Handle message
    const typeMessage = (e) => {
        setInputMessage(e.currentTarget.innerHTML);
        console.log(e.currentTarget.innerHTML)
    }

    // Handle Ctrl + Enter for send message
    const handleCtrlEnter = (e) => {
        if (e.shiftKey && e.key === "Enter") {
            // setInputMessage((inputMessage) => inputMessage + "11");
            // input_message_ref.current.innerHTML = input_message_ref.current.innerHTML + "<br>";
        }else if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
        }else if (e.key === "Enter") {
            sendMessage();
            setTimeout(() => input_message_ref.current.innerHTML = '', 100);
        }
    }

    // Prepare message before send
    const preparingMessage = (message) => {
        let prepareMessage = message.replaceAll(/<div>/g, "<br>");
        prepareMessage = prepareMessage.replaceAll(/<\/div>/g, "");
        prepareMessage = prepareMessage.replaceAll(/<br>+/g, "\n");
        prepareMessage = prepareMessage.replaceAll("&nbsp;", " ");
        prepareMessage = prepareMessage.trim();

        console.log(prepareMessage)
        return prepareMessage;
    }

    // Send message
    const sendMessage = () => {
        let prepareMessage = preparingMessage(inputMessage);

        if (prepareMessage === "") {
            return false;
        }

        setMyNewMessage({
            // id: messages[messages.length - 1].id + 1,
            body: prepareMessage,
            by_user_id: context.user.id,
            is_read: 1,
            updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            user: context.user
        });

        setInputMessage("");
        input_message_ref.current.innerHTML = "";
    }

    // Load messages by scroll
    const observerBlock = useRef();
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('INNN')
                setIsObserverBlockEnter(true);
                setIsNewMessageRequest(true);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    // Scroll chat container down
    const penultimateMessage = useRef();
    // const observerPenultimateMessage = useObserverBlockEntry()
    const [isPenultimateMessageVisible, setIsPenultimateMessageVisible] = useState(true);

    const observerPenultimateMessage = useRef();
    // const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);


    useEffect(() => {
        if (observerPenultimateMessage.current) observerPenultimateMessage.current.disconnect();

        let callback = function (entries, observer) {
            console.log(entries)
            if (entries.length > 1 ? entries[1].isIntersecting : entries[0].isIntersecting) {
                console.log('INNNqqqqqq')
                setIsPenultimateMessageVisible(true);
            }else {
                console.log('OUTTTTqqqqqq')
                setIsPenultimateMessageVisible(false);
            }
        }

        observerPenultimateMessage.current = new IntersectionObserver(callback);
        observerPenultimateMessage.current.observe(penultimateMessage.current);
    }, [])

    // Preparing messages
    const messagesList = messages.map((message, index) => {
        // Choose username color
        let messageUsernameClasses = [classes.message_username];

        if (message.user.role === 'client') {
            messageUsernameClasses.push(classes.message_client_username);
        }else if (message.user.role === 'organizer') {
            messageUsernameClasses.push(classes.message_organizer_username);
        }

        // Choose class for message
        let messageClass;
        if (message.by_user_id == context.user.id) {
            messageClass = classes.my_message_block;
        }else {
            if (message.user.role == context.user.role) {
                messageClass = classes.my_message_block;
            }else {
                messageClass = classes.opponent_message_block;
            }
        }



        return (
            <div
                className={classes.message_row}
                // className={context.user.id != message.by_user_id ? classes.opponent_message_row : classes.my_message_row}
                key={index}
                id={index}
            >
                {/*{*/}
                {/*    index === messages.length - 1 &&*/}
                {/*    <div className={classes.penultimate_message} ref={penultimateMessage}></div>*/}
                {/*}*/}
                <div className={messageClass}>
                    <div className={messageUsernameClasses.join(" ")}>
                        {message.user.name}
                    </div>

                    <div className={classes.message_body}>
                        {message.body}
                    </div>

                    <div className={classes.message_time}>
                        {moment(message.updated_at).format("HH:mm")}
                    </div>
                </div>
            </div>
        )
    })

    // Scroll chat container down
    const messages_container = useRef(null);

    const scrollChatToBottom = () => {
        const lastMessage = messages_container?.current?.lastElementChild;
        lastMessage?.scrollIntoView();
    }

    const observerPenultimate = useRef();
    // const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    // useEffect(() => {
    //     if (observerPenultimate.current) observerPenultimate.current.disconnect();
    //
    //     let callback = function (entries, observer) {
    //         if (entries[0].isIntersecting) {
    //             console.log('INNNFFF')
    //             // setIsObserverBlockEnter(true);
    //         }
    //     }
    //
    //     observer.current = new IntersectionObserver(callback);
    //     observer.current.observe(document.getElementById(messages.length - 1).target);
    // }, [messages])

    useEffect(() => {
        // if (messages[messages.length - 1].user.id != context.user.id) {
        //     scrollChatToBottom()
        // }
        if (isPenultimateMessageVisible || messages.length <= 15) {
            scrollChatToBottom()
            // setIsPenultimateMessageVisible(false)
        }
        // scrollChatToBottom()
        console.log(messages)
    }, [messages, isPenultimateMessageVisible])

    return (
        <div className={classes.chat_container}>
            <div
                className={[classes.messages_container, "temp_scrollbar"].join(" ")}
                id={"messages_container"}
                ref={messages_container}
            >
                <div ref={observerBlock} style={{height: '10px'}}></div>

                <div className={classes.date_row}>
                    {/*14 ноября 2023 г.*/}
                </div>

                {
                    messagesList
                }

                <div className={classes.penultimate_message_container}>
                    <div className={classes.penultimate_message} ref={penultimateMessage}></div>
                </div>
            </div>

            <div className={classes.message_input_container}>
                <div className={classes.message_input_wrapper}>
                    <div
                        contentEditable={true}
                        className={[classes.message_input, "temp_scrollbar"].join(" ")}
                        onKeyUp={e => typeMessage(e)}
                        id={"message_input"}
                        onKeyDown={e => handleCtrlEnter(e)}
                        ref={input_message_ref}
                    >

                    </div>
                </div>

                <div className={classes.send_btn_container}>
                    <div
                        className={[classes.send_btn].join(" ")}
                        onClick={sendMessage}
                    >
                        <img
                            src={ArrowSend}
                            alt=""
                            width={16}
                        />
                    </div>

                    {/*<div className={classes.send_btn_help}>*/}
                    {/*    Ctrl +<br/>Enter*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default NewChatBlock;