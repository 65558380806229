import React, {useEffect, useState} from 'react';
import classes from "./PageLoader.module.scss";
import LogoPinkDesktop from '../../../images/logos/LogoDesktopPinkNEW.svg';

const PageLoader = ({className}) => {
    const [loaderClasses, setLoaderClasses] = useState([classes.page_loader_block]);

    useEffect(() => {
        if (className) {
            setLoaderClasses([...loaderClasses, className]);
        }
    }, [className])

    return (
        <div
            className={loaderClasses.join(' ')}
        >
            <img
                className={classes.img_page_loader}
                src={LogoPinkDesktop}
                alt={'Logo'}
            />
        </div>
    );
};

export default PageLoader;