import React, { useContext, useEffect, useState } from 'react';
import classes from './AddReviewPage.module.scss';
import HbButton from 'components/UI/HbButton/HbButton';
import { ImgStretchingFixedDiv } from 'helpers/ImgStretching';
import Close from "../../images/icons/Close.svg";
import { httpAxios } from 'API/HttpAxios';
import PageLoader from 'components/UI/PageLoader/PageLoader';
import { getRandomName } from 'helpers/HumanNames';
import AuthContext from 'context/AuthContext';

export const AddReviewPage = () => {
    // Open page only for special user
    const [userName, setUserName] = useState('');
    const [excursionId, setExcursionId] = useState(0);
    const [date, setDate] = useState('');
    const [rating, setRating] = useState(5);
    const [text, setText] = useState('');
    const {...context} = useContext(AuthContext);

    // Redirect if user_id !== 872
    useEffect(() => {
        if (context.isAuth && context.user.id !== 872) {
            window.location.href = '/';
        }
    }, [context])

    const changeExcursionId = (e) => {
        setExcursionId(e.target.value);
    }

    const changeDate = (e) => {
        setDate(e.target.value);
    }

    const changeRating = (e) => {
        setRating(e.target.value);
    }

    const changeText = (e) => {
        setText(e.target.value);
    }

    // Add new photos
    const [reviewPhotos, setReviewPhotos] = useState([]);

    const addPhotos = (e) => {
        const files = e.target.files;
        setReviewPhotos([...reviewPhotos, ...Array.from(files).map((file, index) => {
            return {
                file: file,
                img_path: URL.createObjectURL(file),
                img_order: reviewPhotos.length + index + 1,
            }
        })])
        e.target.value = null;
    }

    // Delete photo
    const deletePhoto = (indexInList) => {
        setReviewPhotos(reviewPhotos.filter((photo, index) => {
            return index != indexInList;
        }))
    }

    // Render photos
    let photoBlocks = reviewPhotos.map((photo, index) => {
        return (
            <div
                className={classes.review_photo}
                key={`review_photo_${index}`}
            >
                <img
                    src={photo.img_path}
                    onLoad={ImgStretchingFixedDiv}
                />

                <div
                    className={classes.remove_review_photo_btn}
                    data-photo-index={index}
                    onClick={() => deletePhoto(index)}
                >
                    <img src={Close} alt="x" />
                </div>
            </div>
        );
    })

    // Get all excursions data
    const [excursions, setExcursions] = useState([]);
    const [excursionsLoading, setExcursionsLoading] = useState(true);

    const getExcursions = async () => {
        setExcursionsLoading(true);
        const response = await httpAxios.get('/api/excursions/get_all_excursions_for_save_review');
        const data = response.data;
        setExcursions(data);
        setExcursionsLoading(false);
    }

    useEffect(() => {
        getExcursions();
        setUserName(getRandomName());
    }, [])


    const [isReviewSaving, setIsReviewSaving] = useState(false);
    const [reviewError, setReviewError] = useState('');
    const [reviewResult, setReviewResult] = useState('');

    const saveReview = () => {
        setIsReviewSaving(true);
        setReviewError('');

        const formData = new FormData();
        reviewPhotos.forEach((img, index) => {
            formData.append('reviewPhotos[]', img.file);
        })

        formData.append('reviewData', JSON.stringify({
            user_name: userName.trim(),
            excursion_id: excursionId,
            date: date,
            rating: rating,
            text: text.trim(),
        }))


        httpAxios.post('/api/review/save_review_by_special_user', formData)
            .then((response) => {
                if (response.status === 201) {
                    setReviewResult(response.data);

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
            }).catch(e => {
                setReviewError(e.response.data.message);
            }).finally(() => {
                setIsReviewSaving(false);
            })
    }

    if (excursionsLoading) {
        return <PageLoader />
    }

    return (
        <div className={classes.container}>
            <h1>Добавление отзыва к впечатлению</h1>

            <div>
                <h3>Укажите имя гостя (можно менять или дописать)</h3>

                <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
            </div>

            <div>
                <h3>Выберите впечатление</h3>

                <select value={excursionId} onChange={changeExcursionId}>
                    <option value={0}>Выберите экскурсию</option>

                    {
                        excursions.map((excursion, index) => {
                            return (
                                <option
                                    key={index}
                                    value={excursion.id}
                                >
                                    ID: {excursion.id} - {excursion.title}
                                </option>
                            )
                        })
                    }
                </select>
            </div>

            <div>
                <h3>Выберите дату отзыва</h3>

                <input
                    value={date}
                    onChange={changeDate}
                    type="date"
                // type="datetime-local"
                />
            </div>

            <div>
                <h3>Выберите рейтинг отзыва</h3>

                <select value={rating} onChange={changeRating}>
                    <option value={5}>5</option>
                    <option value={4}>4</option>
                    <option value={3}>3</option>
                </select>
            </div>

            <div>
                <h3>Напишите отзыв</h3>

                <textarea
                    cols={50}
                    rows={15}
                    value={text}
                    onChange={changeText}
                ></textarea>
            </div>

            <div>
                <h3>Загрузите фотографии (до 10 штук, до 8 Мб)</h3>
                <input
                    type="file"
                    name='reviewPhotos'
                    onChange={addPhotos}
                    accept="image/*"
                    multiple
                />

            </div>

            {
                photoBlocks.length === 0 ? null :
                    <div className={classes.review_photos_block}>
                        {photoBlocks}
                    </div>
            }

            {
                reviewError === '' ? null :
                    <div className={classes.review_error}>
                        {reviewError}
                    </div>
            }

            {
                reviewResult === '' ? null :
                    <div className={classes.review_result}>
                        {reviewResult}
                        <br />
                        Страница перезагрузится через 1 сек...
                    </div>
            }

            <HbButton
                color={'blue'}
                className={classes.send_btn}
                isLoading={isReviewSaving}
                onClick={saveReview}
            >
                Сохранить
            </HbButton>
        </div>
    )
}
