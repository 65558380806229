import React, {useEffect} from 'react';
import classes from './TopMenuBtns.module.scss';
import {useLocation, useMatches, useNavigate, useParams, useSearchParams} from "react-router-dom";

const TopMenuBtns = ({menuBtns}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const mainParams = useParams();
    const location = useLocation();
    const matches = useMatches();
    const navigate = useNavigate();


    // Set menu btn - active
    useEffect(() => {
        console.log(location)
    }, [location])

    useEffect(() => {
        searchParams.forEach((key, value) => console.log(key, value))
        console.log(menuBtns)
        console.log(matches)
    }, [searchParams])

    const selectMenuPoint = (e) => {
        let path = e.currentTarget.getAttribute('data-path');
        navigate(path);
    }

    // Rendering btns
    const renderingBtns = () => {
        let result = [];

        menuBtns.forEach((el, index) => {
            let btnClasses = [classes.btn];

            if (location.pathname === el.path) {
                btnClasses.push(classes.btn_active);
            }

            result.push(
                <div
                    className={btnClasses.join(" ")}
                    key={index}
                    data-path={el.path}
                    onClick={e => selectMenuPoint(e)}
                >
                    {el.name}

                    {
                        el.notificationCount !== undefined && el.notificationCount > 0 &&
                        <div className={classes.notification_count_block}>
                            {el.notificationCount}
                        </div>
                    }
                </div>
            );
        })

        return result;
    }

    return (
        <div className={classes.main_container}>
            {
                renderingBtns()
            }
        </div>
    );
};

export default TopMenuBtns;