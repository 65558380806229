export function isEmptyObj(obj) {
    if (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype) {
        return true;
    }

    return false;
}


export function isFullObj(obj) {
    if (obj && Object.keys(obj).length !== 0 && Object.getPrototypeOf(obj) === Object.prototype) {
        return true;
    }

    return false;
}