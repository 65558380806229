import React, {useEffect, useRef, useState} from 'react';
import ArrowDrop from "../../../../images/icons/ArrowDrop.svg";
import classes from "./TimeSelector.module.scss";
import moment from "moment";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const TimeSelector = ({availableTimes = [], selectedTime = moment(), selectTime = () => {}}) => {


    // Handle select time
    const selectTimeClick = (time) => {
        selectTime(time);
        setIsAvailableTimesVisible(false);
    }

    // Switch visible available times
    const [isAvailableTimesVisible, setIsAvailableTimesVisible] = useState(false);
    const availableTimesContainer = useRef();
    const arrowImgBlock = useOutsideClick(() => setIsAvailableTimesVisible(false));


    useEffect(() => {
        console.log(isAvailableTimesVisible)
        if (isAvailableTimesVisible) {
            availableTimesContainer.current.classList.remove("hidden");
            arrowImgBlock.current.classList.add(classes.rotate_arrow);
        } else {
            availableTimesContainer.current.classList.add("hidden");
            arrowImgBlock.current.classList.remove(classes.rotate_arrow);
        }
    }, [isAvailableTimesVisible])

    // Render available times
    const timeList = availableTimes.map((time) => {
        return (
            <div
                className={classes.available_time}
                onClick={() => selectTimeClick(time)}
                key={time.format('HH:mm')}
            >
                {time.format('HH:mm')}
            </div>
        )
    })

    return (
        <div className={classes.time_selector_container}>
            <div className={classes.time_selector_block}>
                <div className={classes.selected_time_block}>
                    {selectedTime.format('HH:mm')}
                </div>

                <div
                    className={classes.show_available_times_block}
                    onClick={() => setIsAvailableTimesVisible(!isAvailableTimesVisible)}
                    ref={arrowImgBlock}
                >
                    <img
                        src={ArrowDrop}
                        alt=""
                    />
                </div>
            </div>

            <div
                className={classes.available_times_container}
                ref={availableTimesContainer}
            >
                {timeList}
            </div>
        </div>
    );
};

export default TimeSelector;