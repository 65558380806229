import React, { createContext, useContext, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import MainPage from "../pages/MainPage/MainPage";
import MainLayout from "../components/layouts/MainLayout/MainLayout";
import FilteredExcursionsPage from "../pages/FilteredExcursions/FilteredExcursionsPage";
import ExcursionLayout from "../components/layouts/ExcursionLayout/ExcursionLayout";
import ExcursionPage from "../pages/ExcursionPage/ExcursionPage";
import ExcursionBookingPage from "../pages/ExcursionBookingPage/ExcursionBookingPage";
import ExcursionBookingResultPage from "../pages/ExcursionBookingResultPage/ExcursionBookingResultPage";
import UserVkAuthPage from "../pages/UserVkAuthPage/UserVkAuthPage";
import UserYandexAuthPage from "../pages/UserYandexAuthPage/UserYandexAuthPage";
import UserGoogleAuthPage from "../pages/UserGoogleAuthPage/UserGoogleAuthPage";
import UserAuth from "../components/UserAuth/UserAuth";
import CookieNotification from "../components/UI/CookieNotification/CookieNotification";

import SoketiPage from "../pages/test/SoketiPage/SoketiPage";
import PrivateSoketiPage from "../pages/test/PrivateSoketiPage/PrivateSoketiPage";
import Board from "../pages/test/DnD/Board/Board";
import Chess from "../pages/test/DnD/Chess/Chess";

import WorkerDashboardLayout from "../components/layouts/WorkerDashboardLayout/WorkerDashboardLayout";
import WorkerBookingsPage from "../pages/ManagerDashboardPages/BookingsPage/BookingsPage";
import WorkerExperiencesPage from "../pages/ManagerDashboardPages/ExperiencesPage/ExperiencesPage";
import WorkerReviewsPage from "../pages/ManagerDashboardPages/ReviewsPage/ReviewsPage";
import WorkerProfilesPage from "../pages/ManagerDashboardPages/ProfilesPage/ProfilesPage";
import WorkerSupportPage from "../pages/ManagerDashboardPages/SupportPage/SupportPage";
import WorkerCalendarPage from "../pages/ManagerDashboardPages/CalendarPage/CalendarPage";

import ClientDashboardLayout from "../components/layouts/ClientDashboardLayout/ClientDashboardLayout";
import ClientBookingsPage from "../pages/ClientDashboardPages/BookingsPage/BookingsPage";
import FavoritesPage from "../pages/ClientDashboardPages/FavoritesPage/FavoritesPage";
import ClientSupportPage from "../pages/ClientDashboardPages/SupportPage/SupportPage";
import UserService from "../API/UserService";
import UserRoutes from "../components/UserRoutes/UserRoutes";
import NewPrivateSoketiPage from "../pages/test/NewPrivateSoketiPage/NewPrivateSoketiPage";
import OrganizerSupportPage from "../pages/OrganizerDashboardPages/OrganizerSupportPage/OrganizerSupportPage";
import OrganizerReviewsPage from "../pages/OrganizerDashboardPages/OrganizerReviewsPage/OrganizerReviewsPage";
import OrganizerProfilePage from "../pages/OrganizerDashboardPages/OrganizerProfilePage/OrganizerProfilePage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import DnDnew from "../pages/test/DnDnew/DnDnew";
import { BitrixWidget } from '../components/BitrixWidget/BitrixWidget';
import { SetDocumentHead } from 'components/SetDocumentHead/SetDocumentHead';
import { YandexMetrikaHit } from 'components/YandexMetrikaHit/YandexMetrikaHit';
import { AddReviewPage } from 'pages/AddReviewPage/AddReviewPage';

// const {...props} = useContext()

// const isManager = async () => {
//     const user = await UserService.getUser();
//     console.log(user)
//     if (user && user.role === 'manager') {
//         return true;
//     }
//
//     return false;
// }
//
// const ManagerRoutes = () => {
//     // const i
//     return isManager() ? (
//         <Outlet/>
//     ) : (
//         <Navigate to="/" replace />
//     );
// };
//
// const OrganizerRoutes = () => {
//     return UserService.isOrganizer() ? (
//         <Outlet/>
//     ) : (
//         <Navigate to="/" replace />
//     );
// };
//
// const isClient = async () => {
//     const user = await UserService.getUser();
//     console.log(user)
//     if (user && user.role === 'client') {
//         return true;
//     }
//
//     return false;
// }
//
// const ClientRoutes = async () => {
//     const result = await isClient();
//     return result ? (
//         <Outlet/>
//     ) : (
//         <Navigate to="/" replace />
//     );
// };

const Root = createBrowserRouter([
    // Main
    {
        element: (
            <div id={'helper_container'}>
                <UserAuth />
                <CookieNotification />
                <Outlet />
                <BitrixWidget />
                {/* <SetDocumentHead/> */}
                <YandexMetrikaHit/>
                {/* <YandexMetrika/> */}
            </div>
        ),
        // path: '/',
        errorElement: <ErrorPage />,
        children: [
            // Main Page
            {
                element: <MainLayout />,
                // errorElement: <ErrorPage />,
                // path: '/',
                children: [
                    // {
                    //     path: '/',
                    // element: <MainPage/>
                    // },

                    {
                        path: '/:country?/:city?',
                        element: <FilteredExcursionsPage />,
                    },

                    // About us
                    {
                        path: '/about',
                        element: <AboutUsPage />
                    }
                ]
            },

            // FilteredExcursions Page
            // {
            //     element: <MainLayout/>,
            //     // errorElement: <ErrorPage />,
            //     children: [
            //         {
            //             path: '/s/:country?/:city?',
            //             element: <FilteredExcursionsPage/>,
            //         }
            //     ]
            // },

            //ExcursionPage Page
            // {
            //     element: <ExcursionLayout/>,
            //     errorElement: <ErrorPage />,
            //     children: [
            //         {
            //             path: '/e/:id',
            //             element: <ExcursionPage/>,
            //         }
            //     ]
            // },

            // User Pages
            {
                element: <MainLayout />,
                // errorElement: <ErrorPage/>,
                path: 'user/',
                children: [
                    // Vk auth
                    {
                        path: 'vk_auth',
                        element: <UserVkAuthPage />
                    },

                    // Yandex auth
                    {
                        path: 'yandex_auth',
                        element: <UserYandexAuthPage />
                    },

                    // Google auth
                    {
                        path: 'google_auth',
                        element: <UserGoogleAuthPage />
                    },
                ]
            },

            //ExcursionBookingPage Page
            {
                element: <ExcursionLayout />,
                // errorElement: <ErrorPage />,

                children: [
                    // Excursion page
                    {
                        path: '/e/:id',
                        element: <ExcursionPage />,
                    },

                    // Booking page
                    {
                        path: '/e/b',
                        element: <ExcursionBookingPage />,
                    },

                    // Booking result page
                    {
                        path: '/e/b/:id',
                        element: <ExcursionBookingResultPage />
                    },

                ]
            },

            // Manager Pages
            {
                element: <UserRoutes userRole={'manager'} />,
                children: [
                    {
                        element: <WorkerDashboardLayout
                            // role={}
                            role={"manager"}
                        />,
                        path: 'manager/dashboard/',
                        // errorElement: <ErrorPage />,
                        children: [
                            // Bookings
                            {
                                path: 'bookings',
                                element: <WorkerBookingsPage />
                            },

                            // Experiences
                            {
                                path: 'experiences/:page?',
                                element: <WorkerExperiencesPage />
                            },

                            // Reviews
                            {
                                path: 'reviews/:page?',
                                element: <WorkerReviewsPage />
                            },

                            // Profiles
                            {
                                path: "profiles/:organizerId?",
                                element: <WorkerProfilesPage />
                            },

                            // Support
                            {
                                path: "support/:page?",
                                element: <WorkerSupportPage />
                            },

                            // Calendar
                            {
                                path: "calendar/:page?",
                                element: <WorkerCalendarPage />
                            },


                        ]
                    },
                ]
            },

            // Organizer Pages
            {
                element: <UserRoutes userRole={'organizer'} />,
                children: [
                    {
                        element: <WorkerDashboardLayout
                            role={"organizer"}
                        />,
                        path: 'organizer/dashboard/',
                        // errorElement: <ErrorPage />,
                        children: [
                            // Bookings
                            {
                                path: 'bookings',
                                element: <WorkerBookingsPage />
                            },

                            // Experiences
                            {
                                path: 'experiences/:page?',
                                element: <WorkerExperiencesPage />
                            },

                            // Reviews
                            {
                                path: 'reviews/:page?',
                                element: <OrganizerReviewsPage />
                            },

                            // Profile
                            {
                                path: "profile",
                                element: <OrganizerProfilePage />
                            },

                            // Support
                            {
                                path: "support/:clientId?",
                                element: <OrganizerSupportPage />
                            },

                            // Calendar
                            {
                                path: "calendar/:page?",
                                element: <WorkerCalendarPage />
                            }
                        ]
                    },
                ]
            },



            // Client Pages
            {
                element: <UserRoutes userRole={'client'} />,
                children: [
                    {
                        element: <WorkerDashboardLayout
                            role={"client"}
                        />,
                        path: 'client/dashboard/',
                        children: [
                            // Bookings
                            {
                                path: 'bookings/:status?',
                                element: <ClientBookingsPage />
                            },

                            // Favorites
                            {
                                path: 'favorites',
                                element: <FavoritesPage />
                            },

                            // Support
                            {
                                path: "support/:clientId?",
                                element: <OrganizerSupportPage />
                                // element: <ClientSupportPage/>
                            },
                        ]
                    },
                ]
            },

            // Test Pages
            {
                element: <MainLayout />,
                // errorElement: <ErrorPage/>,
                path: 'test/',
                children: [
                    // New private Soketi test
                    // {
                    //     path: 'new_private_soketi/:chatId',
                    //     element: <NewPrivateSoketiPage/>
                    // },

                    // Test Soketi
                    // {
                    //     path: 'soketi',
                    //     element: <SoketiPage/>
                    // },

                    // Test Private Soketi
                    // {
                    //     path: 'private_soketi/:room_id',
                    //     element: <PrivateSoketiPage/>
                    // },

                    // Test DnD
                    // {
                    //     path: 'dnd',
                    //     element: <Chess />
                    // },

                    // // New test DnD
                    // {
                    //     path: 'dndnew',
                    //     element: <DnDnew />
                    // },

                    //// HIDDEN
                    // Add reviews
                    {
                        path: "add_reviews",
                        element: <AddReviewPage />
                    }
                ]
            }
        ]
    },
],
    {
        basename: "/"
    });

// const Root = createBrowserRouter([
//         // Main Page
//         {
//             element: <MainLayout/>,
//             errorElement: <ErrorPage />,
//             children: [
//                 {
//                     path: '/',
//                     element: <MainPage/>
//                 }
//             ]
//         },
//
//         // FilteredExcursions Page
//         {
//             element: <MainLayout/>,
//             errorElement: <ErrorPage />,
//             children: [
//                 {
//                     path: '/s/:country?/:city?',
//                     element: <FilteredExcursionsPage/>,
//                 }
//             ]
//         },
//
//         //ExcursionPage Page
//         // {
//         //     element: <ExcursionLayout/>,
//         //     errorElement: <ErrorPage />,
//         //     children: [
//         //         {
//         //             path: '/e/:id',
//         //             element: <ExcursionPage/>,
//         //         }
//         //     ]
//         // },
//
//         // User Pages
//         {
//             element: <MainLayout/>,
//             errorElement: <ErrorPage/>,
//             path: 'user/',
//             children: [
//                 // Vk auth
//                 {
//                     path: 'vk_auth',
//                     element: <UserVkAuthPage/>
//                 },
//
//                 // Yandex auth
//                 {
//                     path: 'yandex_auth',
//                     element: <UserYandexAuthPage/>
//                 },
//
//                 // Google auth
//                 {
//                     path: 'google_auth',
//                     element: <UserGoogleAuthPage/>
//                 },
//             ]
//         },
//
//         //ExcursionBookingPage Page
//         {
//             element: <ExcursionLayout/>,
//             errorElement: <ErrorPage />,
//             children: [
//                 // Excursion page
//                 {
//                     path: '/e/:id',
//                     element: <ExcursionPage/>,
//                 },
//
//                 // Booking page
//                 {
//                     path: '/e/b',
//                     element: <ExcursionBookingPage/>,
//                 },
//
//                 // Booking result page
//                 {
//                     path: '/e/b/:id',
//                     element: <ExcursionBookingResultPage/>
//                 }
//             ]
//         },
//     ],
//     {
//         basename: "/"
//     });

export default Root;