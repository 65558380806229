import React, { useEffect, useState } from 'react';
import classes from "./ExcursionPage.module.scss";
import ExcursionService from "../../API/ExcursionService";
import { createSearchParams, Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import ExcursionImgCarousel from "../../components/ExcursionPageComponents/ExcursionImgCarousel/ExcursionImgCarousel";
import ExcursionDescription from "../../components/ExcursionPageComponents/ExcursionDescription/ExcursionDescription";
import ExcursionBookingPreview
    from "../../components/ExcursionPageComponents/ExcursionBookingPreview/ExcursionBookingPreview";
import ExcursionReviews from "../../components/ExcursionPageComponents/ExcursionReviews/ExcursionReviews";
import HiddenReviews from "../../components/ExcursionPageComponents/HiddenReviews/HiddenReviews";
import BookingTimes from "../../components/ExcursionPageComponents/BookingTimes/BookingTimes";
import BookingCalendar from "../../components/ExcursionPageComponents/BookingCalendar/BookingCalendar";
import { useFetching } from "../../hooks/useFetching";
import { useUserWishlist } from "../../hooks/useUserWishlist";
import HbButton from 'components/UI/HbButton/HbButton';
import { PhotoGallery } from 'components/UI/PhotoGallery/PhotoGallery';
import { baseURL } from 'API/HttpAxios';
import { AdditionalExcursions } from 'components/ExcursionPageComponents/AdditionalExcursions/AdditionalExcursions';
import { CustomerSurvey } from 'components/UI/CustomerSurvey/CustomerSurvey';

const ExcursionPage = () => {
    const [excursion, setExcursion] = useState();
    const mainParams = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [excursionType, setExcursionType] = useState();
    const [isReviewsVisible, setIsReviewsVisible] = useState(false);
    const [schedule, setSchedule] = useState();
    const navigate = useNavigate();

    // Get excursion by id
    const [fetchExcursion, isExcursionLoading, excursionError] = useFetching(async () => {
        const response = await ExcursionService.getCurrentExcursion(mainParams.id);
        const data = response.data;
        setExcursion(data);
        setExcursionType(data.types[0]);
        // console.log('EXCURSION:')
        // console.log(data)
    })

    ///
    useEffect(() => {
        console.log('EXCURSION ')
        console.log(excursion)
    }, [excursion])

    useEffect(() => {
        console.log('EXCURSION TYPE')
        console.log(excursionType)
    }, [excursionType])

    // Get excursion schedule
    const [fetchSchedule, isScheduleLoading, scheduleError] = useFetching(async () => {
        const response = await ExcursionService.getSchedule(mainParams.id);
        const data = response.data;
        setSchedule(data);
        console.log('SCHEDULE:')
        console.log(data)
    })

    // Find excursion type
    const setTypeBySearchParams = () => {
        if (searchParams.has('type')) {
            let findType = excursion.types.find(el => el.type_name === searchParams.get('type'));
            findType !== undefined && setExcursionType(findType);
        }
    }

    useEffect(() => {
        if (!isExcursionLoading) {
            setTypeBySearchParams()
        }
    }, [searchParams, excursion])

    useEffect(() => {
        fetchExcursion();
        fetchSchedule();
    }, [])

    // Shift reviews visibility
    const shiftReviewsVisible = (isVisible) => {
        setIsReviewsVisible(isVisible);
    }

    // Func for navigate by selected date
    const [date, setDate] = useState('');

    const getDate = (date) => {
        setDate(date);
    }

    useEffect(() => {
        if (date !== '') {
            let nextSearchParams = {
                id: excursionType.excursion_id,
                type: excursionType.type_name,
                date: date
            };

            if (searchParams.has('promocode')) {
                nextSearchParams = {
                    ...nextSearchParams,
                    promocode: searchParams.get('promocode'),
                }
            }

            navigate({
                pathname: '/e/b',
                search: `?${createSearchParams(nextSearchParams)}`,
            })
        }
    }, [date])

    // Get user wishlist
    useUserWishlist();

    // Stop scrolling on page while gallery is open
    // const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    // useEffect(() => {
    //     if (isGalleryOpen) {
    //         document.querySelector('#excursion_layout').style.overflow = 'hidden';
    //         document.querySelector('#helper_container').style.overflowY = 'hidden';
    //         document.querySelector('#root').style.overflow = 'hidden';
    //         document.querySelector('body').style.overflow = 'hidden';
    //     } else {
    //         document.querySelector('#excursion_layout').style.overflow = '';
    //         document.querySelector('#helper_container').style.overflow = '';
    //         document.querySelector('#root').style.overflow = '';
    //         document.querySelector('body').style.overflow = '';
    //     }
    // }, [isGalleryOpen])

    // Show CAT button
    useEffect(() => {
        const switchCatVisible = () => {
            // const excursionDescription = document.getElementById('excursion_description');
            const palceCallToAction = document.getElementById('place_call_to_action');
            const callToAction = document.getElementById('call_to_action');

            if (palceCallToAction === null) {
                return;
            }

            if (window.scrollY - window.innerHeight > palceCallToAction.offsetTop) {
                callToAction.classList.add(classes.call_to_action_active);
            } else {
                callToAction.classList.remove(classes.call_to_action_active);
            }
        }

        window.addEventListener('scroll', switchCatVisible);

        return () => {
            window.removeEventListener('scroll', switchCatVisible);
        }
    }, [])
// }, [excursion])

    // Review gallery
    const [isReviewGalleryOpen, setIsReviewGalleryOpen] = useState(false);

    useEffect(() => {
        if (searchParams.has('reviews_img_src')) {
            setIsReviewGalleryOpen(true);
        }
    }, [searchParams])

    // Get review img srcs
    const [reviewImgSrcs, setReviewImgSrcs] = useState([]);

    useEffect(() => {
        let imgSrcs = [];

        if (excursion !== undefined && excursion.review_imgs !== undefined) {
            excursion.review_imgs.forEach(element => {
                imgSrcs.push(baseURL + element.img_src);
            });
    
            setReviewImgSrcs(imgSrcs);
        }
    }, [excursion])

    return (
        <div className={`container_fluid`}>
            {
                isExcursionLoading
                    ?
                    <PageLoader />
                    :
                    <div className={`container_fluid`}>
                        <ExcursionImgCarousel
                            excursion={excursion}
                        />

                        <div className={classes.excursion_pretitle_block}></div>

                        <div className={`container`}>
                            <div className={classes.breadcrumbs}>
                                {/* <Link
                                    to={'/'}
                                >
                                    Экскурсии
                                </Link>

                                <span>&gt;</span> */}

                                <Link
                                    to={`/${excursion.country_alias}/${excursion.city_alias}`}
                                >
                                    {excursion.city_name}
                                </Link>

                                <span>&gt;</span>

                                <Link
                                    to={`/${excursion.country_alias}/${excursion.city_alias}?category=${excursion.main_category.alias}`}
                                >
                                    {excursion.main_category.name.charAt(0).toUpperCase() + excursion.main_category.name.slice(1)}
                                </Link>
                            </div>

                            <h1 className={classes.excursion_title}>
                                {excursion.title}
                            </h1>

                            <div className={classes.excursion_description_booking_container}>
                                <ExcursionDescription
                                    excursion={excursion}
                                    excursionType={excursionType}
                                />

                                <ExcursionBookingPreview
                                    excursion={excursion}
                                    excursionType={excursionType}
                                    setIsReviewsVisible={setIsReviewsVisible}
                                />
                            </div>

                            {/* Reviews */}
                            <ExcursionReviews
                                excursion={excursion}
                                reviewsVisible={shiftReviewsVisible}
                            />
                        </div>

                        {/* Hidden reviews */}
                        {
                            isReviewsVisible
                            ?
                                <HiddenReviews
                                    excursion={excursion}
                                    reviewsVisible={shiftReviewsVisible}
                                    isReviewsVisible={isReviewsVisible}
                                />
                            :
                                null
                        }

                        {/* Review photos gallery */}
                        {
                            isReviewGalleryOpen
                            ?
                                <PhotoGallery
                                    setIsVisible={setIsReviewGalleryOpen}
                                    galleryName={'reviews'}
                                    photos={reviewImgSrcs}
                                />
                            :
                                null
                        }

                        {/* Preview dates & times */}
                        <div className={'container'}>
                            {
                                isScheduleLoading
                                    ?
                                    <PageLoader />
                                    :
                                    <div className={classes.dates_times_container}>
                                        <BookingTimes
                                            excursionType={JSON.parse(JSON.stringify(excursionType))}
                                            schedule={JSON.parse(JSON.stringify(schedule))}
                                        />

                                        <div className={classes.dates_main_container}>
                                            <div className={classes.dates_title_block}>
                                                <h2 className={classes.dates_title}>
                                                    Выберите дату:
                                                    {/* Дата: */}
                                                </h2>
                                            </div>

                                            <BookingCalendar
                                                excursionType={excursionType}
                                                schedule={schedule}
                                                getDate={getDate}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>

                        <div 
                            className={classes.call_to_action}
                            id={'call_to_action'}    
                        >
                            <HbButton
                                className={classes.booking_button}
                                color={'green'}
                                // color={'pink'}
                                onClick={() => navigate(`/e/b?id=${excursion.id}&type=${excursionType.type_name}${searchParams.has('promocode') ? `&promocode=${searchParams.get('promocode')}` : ''}`)}
                            >
                                Выбрать даты
                                {/* Забронировать */}
                            </HbButton>
                        </div>

                        {/* Additional excursions */}
                        <div className={'container'}>
                            <AdditionalExcursions
                                excursion_id={excursion.id}
                            />
                        </div>
                    </div>
            }

            <CustomerSurvey/>
        </div>
    );
};

export default ExcursionPage;