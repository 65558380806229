import React, {useEffect, useState, useRef, useContext} from 'react';
import classes from "./UserDropdownMenu.module.scss";
import arrowDropIcon from '../../../images/icons/ArrowDrop.svg';
import userIcon from '../../../images/icons/User.svg'
import {Link} from "react-router-dom"

import useOutsideClick from '../../../hooks/useOutsideClick'
import AuthContext from "../../../context/AuthContext";
import {isEmptyObj, isFullObj} from "../../../helpers/ObjHelper";
import UserMenuPoints from "../../../enums/UserMenuPoints";
import {httpAxios} from "../../../API/HttpAxios";
import {useCookies} from "react-cookie";

const UserDropdownMenu = () => {
    const {isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser} = useContext(AuthContext);
    const [cookies, setCookie, removeCookie] = useCookies(['user_name', 'user_phone', 'email']);

    useEffect(() => {
        console.log(cookies)
    }, [])

    // Check user auth
    useEffect(() => {
        console.log('USER: ')
        console.log(user)
        if (isEmptyObj(user)) {
            console.log('need to auth')
        }
    }, [user])

    const [visibleUserMenu, setVisibleUserMenu] = useState(false)
    const [userMenuClasses, setUserMenuClasses] = useState(['roundblock_angle_rt', classes.user_menu_modal])

    const handleClickOutside = () => {
        setVisibleUserMenu(false)
    }

    const userMenu = useOutsideClick(handleClickOutside)

    const menuState = () => {
        setVisibleUserMenu(!visibleUserMenu)
    }

    const handleMenuClick = (event) => {
        event.stopPropagation();
    }


    useEffect(() => {
        if (visibleUserMenu) {
            setUserMenuClasses([...userMenuClasses, classes.active])
        }else{
            setUserMenuClasses(['roundblock_angle_rt', classes.user_menu_modal])
        }
    }, [visibleUserMenu])

    // Set menu points for user role
    const [userMenuPoints, setUserMenuPoints] = useState([]);

    useEffect(() => {
        if (user !== undefined && isFullObj(user)) {
            switch (user.role) {
                case 'manager':
                    setUserMenuPoints(UserMenuPoints.MANAGER)
                    break;

                case 'organizer':
                    setUserMenuPoints(UserMenuPoints.ORGANIZER)
                    break;

                case 'client':
                    setUserMenuPoints(UserMenuPoints.CLIENT)
                    break;

                default:
                    setUserMenuPoints(UserMenuPoints.CLIENT)
                    break;
            }
        }
    }, [user])

    let renderedUserMenuPoints = userMenuPoints.map((point, index) => {
        return (
            <li key={index}>
                <Link
                    to={point.to}
                >
                    <span className={classes.menu_dot}></span>
                    {point.title}
                </Link>
            </li>
        )
    })

    // Logout
    const logout = () => {
        console.log('logout')

        httpAxios.post('/api/user/logout')
            .then((response) => {
                if (response.status === 200) {
                    document.cookie = "user_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "user_phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    // removeCookie('user_name');
                    // removeCookie(['user_name']);
                    // removeCookie('user_phone');
                    // removeCookie(['user_phone']);
                    // removeCookie('email');
                    setUser(null);
                    setIsAuth(false);
                    window.location.reload();
                }
            })
    }

    return (
        <div className={classes.user_menu}>
            {/*Desktop menu icon*/}
            <div ref={userMenu} onClick={menuState} className={classes.rectangle}>
                <div className={classes.circle}>
                    <img src={userIcon} alt={'u'}/>
                </div>
                <div className={classes.arrow_drop}>
                    <img src={arrowDropIcon} alt={'a'}/>
                </div>
            </div>

            <div onClick={handleMenuClick} className={userMenuClasses.join(' ')}>
                <ul className={`${classes.menu_list}`}>
                    {
                        isAuth &&
                        <li
                           
                        >
                            <Link
                                className={[classes.menu_row, classes.user_name].join(' ')}
                                to={userMenuPoints[0] && userMenuPoints[0].to}
                            >
                                {/* <div className={classes.menu_dot}></div> */}
                                {
                                    user.name === null
                                    ?
                                        user.email.replace(/@.+/gm, '')
                                    :
                                        user.name
                                }
                            </Link>
                        </li>
                    }

                    {
                        userMenuPoints.length === 0
                        ?
                            <li
                                onClick={() => setIsAuthVisible(true)}
                            >
                                <div
                                    className={classes.menu_row}
                                >
                                    <div className={classes.menu_dot}></div>
                                    Войти
                                </div>
                            </li>
                        :
                            renderedUserMenuPoints
                    }

                    {/*<li>*/}
                    {/*    <Link to={'/faq'}>*/}
                    {/*        <span className={classes.menu_dot}></span>*/}
                    {/*        Вопросы*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to={'support'}>*/}
                    {/*        <span className={classes.menu_dot}></span>*/}
                    {/*        GIFT CARD*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    <li>
                        <Link to={'/about'}>
                            <span className={classes.menu_dot}></span>
                            О нас
                        </Link>
                    </li>
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<li>*/}
                    {/*    <Link to={'support'}>*/}
                    {/*        <span className={classes.menu_dot}></span>*/}
                    {/*        Стать организатором*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to={'support'}>*/}
                    {/*        <span className={classes.menu_dot}></span>*/}
                    {/*        Правила сайта*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {
                        isAuth &&
                        <li>
                            <div
                                className={classes.menu_row}
                                onClick={logout}
                            >
                                <div className={classes.menu_dot}></div>
                                Выход
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </div>

    );
};

export default UserDropdownMenu;