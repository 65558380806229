import React, {useContext} from 'react';
import LogoDesktopPinkNew from "../../../images/logos/LogoDesktopPinkNEW.svg";
import {Link} from "react-router-dom";
import classes from "./FooterLayout.module.scss";
import AuthContext from "../../../context/AuthContext";

// import user_agreement from "../../../documents/user_agreement.pdf";
// import oferta from "../../../documents/oferta.pdf";
// import personal_data from "../../../documents/personal_data.pdf";

const FooterLayout = () => {
    const {...context} = useContext(AuthContext);

    const redirectToSupport = () => {
        if (context.isAuth) {
            window.location.href = `/${context.user.role}/dashboard/support`;
        } else {
            context.setIsAuthVisible(true);
        }
    }

    return (
        <div className={`container-fluid ${classes.footer_fluid}`}>
            <div className={classes.footer_shadow}>

            </div>

            <div className={`container ${classes.footer}`}>
                <Link
                    className={`${classes.footer_logo}`}
                    to={'/'}
                >
                    <img
                        src={LogoDesktopPinkNew} alt={'logo'}
                        height={"15px"}
                    />
                </Link>

                <div>
                    <Link
                        to={'/about'}
                        className={classes.footer_link_h2}
                    >
                        о нас
                    </Link>
                    <br/>
                    <Link to={'/about#contacts'}>стать организатором</Link>
                </div>

                <div>
                    <Link
                        to={'/about#contacts'}
                        className={classes.footer_link_h2}
                    >
                        контакты
                    </Link>
                    <br/>
                    {/*<Link to={'/support'}>*/}
                    {/*    Помощь*/}
                    {/*</Link>*/}
                    <span
                        onClick={redirectToSupport}
                    >
                        помощь
                    </span>
                </div>

                <div>
                    {/*<Link*/}
                    {/*    to={'/'}*/}
                    {/*    className={classes.footer_link_h2}*/}
                    {/*>*/}
                    {/*    договор оферты*/}
                    {/*</Link>*/}
                    {/*<br/>*/}
                    {/*<Link to={'/'}>политика персональных данных</Link>*/}
                    {/*<br/>*/}
                    {/*<Link to={'/'}>пользовательское соглашение</Link>*/}

                    <a
                        href={'/documents/oferta.pdf'}
                        // href={oferta}
                        className={classes.footer_link_h2}
                        target={'_blank'}
                    >
                        договор оферты
                    </a>
                    <br/>
                    <a href={'/documents/personal_data.pdf'} target={'_blank'}>политика персональных данных</a>
                    {/*<a href={personal_data} target={'_blank'}>политика персональных данных</a>*/}
                    <br/>
                    <a href={'/documents/user_agreement.pdf'} target={'_blank'}>пользовательское соглашение</a>
                    {/*<a href={user_agreement} target={'_blank'}>пользовательское соглашение</a>*/}
                </div>
            </div>

            <div className={`container ${classes.copyright}`}>
                © 2024 HIDDENBURG
            </div>
        </div>
    );
};

export default FooterLayout;