import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from "./ReviewsPage.module.scss";
import DraftTopMenuBtns from "../../../components/dashboard/common/DraftTopMenuBtns/DraftTopMenuBtns";
import SearchInput from "../../../components/dashboard/common/SearchInput/SearchInput";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RatingStarsRow from "../../../components/dashboard/common/RatingStarsRow/RatingStarsRow";
import ReviewControlUnit from "../../../components/dashboard/common/ReviewControlUnit/ReviewControlUnit";
import { httpAxios } from "../../../API/HttpAxios";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import { PhotoGallery } from 'components/UI/PhotoGallery/PhotoGallery';

const ReviewsPage = () => {
    const { page } = useParams();
    const navigate = useNavigate();

    // Get review count for moderation
    const [reviewCountForModeration, setReviewCountForModeration] = useState(0);
    const getReviewCountForModeration = () => {
        httpAxios.get('/api/review/get_review_count_for_moderation')
            .then(response => {
                if (response.status === 200) {
                    setReviewCountForModeration(response.data);
                }
            }
            )
    }

    useEffect(() => {
        getReviewCountForModeration();
    }, []);

    const topMenuBtns = [
        {
            name: "Требуют проверки",
            path: "/manager/dashboard/reviews/new",
            notification_count: reviewCountForModeration
        },

        {
            name: "Проверенные",
            path: "/manager/dashboard/reviews/moderated"
        },
    ];

    // Redirect to /new if no page
    useEffect(() => {
        !page && navigate('/manager/dashboard/reviews/new');
    }, [page])

    // Manipulate search input
    const [excursionId, setExcursionId] = useState('');

    useEffect(() => {
        setReviews([]);
        setCurrentPage(1);
    }, [excursionId])

    // Manipulate rating filter
    const [searchParams, setSearchParams] = useSearchParams();

    // Save previous gallery search param 
    const [isGalleryWasOpen, setIsGalleryWasOpen] = useState(false);

    useEffect(() => {
        if (searchParams.has('reviews_img_src')) {
            setIsGalleryWasOpen(true);
        }
    }, [searchParams])

    const handleRatingFilter = (rating) => {
        searchParams.set('rating', rating);
        setSearchParams(searchParams);
    }

    const checkGalleryStatus = useCallback(() => {
        if (searchParams.has('reviews_img_src')) return false;

        if (!searchParams.has('reviews_img_src') && isGalleryWasOpen) {
            setIsGalleryWasOpen(false);
            return false;
        }

        return true;
    }, [searchParams, isGalleryWasOpen])

    useEffect(() => {
        if (checkGalleryStatus() && searchParams.get('rating') !== undefined) {
            setReviews([]);
            setCurrentPage(1)
        }
    }, [searchParams]);

    // Reset rating
    const resetRating = () => {
        searchParams.delete('rating');
        setSearchParams(searchParams)
    }

    // Get reviews
    const [isReviewsLoading, setIsReviewsLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    // Change statuses by page
    useEffect(() => {
        setReviews([]);

        if (page === 'new') {
            setStatuses(['moderation']);
        } else {
            setStatuses(['active', 'rejected']);
        }

        console.log(statuses)

        setCurrentPage(1);
    }, [page])

    // Get reviews
    const getReviewsByStatuses = () => {

        httpAxios.get('/api/review/get_reviews_by_status', {
            params: {
                page: page,
                statuses: statuses,
                excursion_id: excursionId !== '' ? excursionId : null,
                rating: searchParams.get('rating') ? searchParams.get('rating') : null
            }
        })
            .then((response) => {
                if (currentPage === 1) {
                    setReviews(response.data.data);
                } else {
                    setReviews([...reviews, ...response.data.data]);
                }
                setLastPage(response.data.last_page);
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsReviewsLoading(false);
            })
    }



    useEffect(() => {
        if (!checkGalleryStatus()) return;

        if (statuses.length < 1 || currentPage === null) return;
        setIsReviewsLoading(true);

        let reviewsLoadTimeout = setTimeout(() => {
            getReviewsByStatuses();
        }, 1000)

        return () => {
            if (reviewsLoadTimeout) clearTimeout(reviewsLoadTimeout);
        }
    }, [statuses, currentPage, excursionId, searchParams])

    ///////////////
    // useEffect(() => {
    //     console.log("reviews")
    //     console.log(reviews)
    // }, [reviews])

    // Get review img srcs
    const [reviewImgSrcs, setReviewImgSrcs] = useState([]);

    // Render reviews
    const reviewsList = reviews.map((review, index) => {
        return (
            <ReviewControlUnit
                review={review}
                key={index}
                setReviewImgSrcs={setReviewImgSrcs}
            />
        )
    })

    // Load reviews by scroll
    const observerBlock = useRef();
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('INNN')
                setIsObserverBlockEnter(true);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    useEffect(() => {
        setIsObserverBlockEnter(false);

        if (isReviewsLoading) return;
        if (currentPage === null) return;
        if (lastPage === null) return;

        if (isObserverBlockEnter && currentPage < lastPage) {
            setCurrentPage(currentPage => currentPage + 1);
        }
    }, [isObserverBlockEnter])

    // Review gallery
    const [isReviewGalleryOpen, setIsReviewGalleryOpen] = useState(false);

    useEffect(() => {
        if (searchParams.has('reviews_img_src')) {
            setIsReviewGalleryOpen(true);
        }
    }, [searchParams])


    return (
        <div className={[classes.reviews_container, "temp_scrollbar"].join(" ")}>
            <div className={classes.top_menu_container}>
                <DraftTopMenuBtns
                    menuBtns={topMenuBtns}
                />

                <SearchInput
                    className={classes.search_input}
                    placeholder={"Введите ID впечатения..."}
                    value={excursionId}
                    onChange={setExcursionId}
                />

                <RatingStarsRow
                    selectedRating={searchParams.get('rating')}
                    onStarClick={handleRatingFilter}
                />

                <div
                    className={classes.reset_rating}
                    onClick={resetRating}
                >
                    Все оценки
                </div>
            </div>

            <div className={classes.reviews_block}>
                {reviewsList}
            </div>

            {/* Review photos gallery */}
            {
                isReviewGalleryOpen
                    ?
                    <PhotoGallery
                        setIsVisible={setIsReviewGalleryOpen}
                        galleryName={`reviews`}
                        photos={reviewImgSrcs}
                    />
                    :
                    null
            }

            <div ref={observerBlock}>
                {
                    currentPage !== lastPage || isReviewsLoading
                        ?
                        <PageLoader />
                        :
                        <div className={classes.nothing_found}>
                            Это всё 🤷‍♂️
                        </div>
                }
            </div>
        </div>
    );
};

export default ReviewsPage;