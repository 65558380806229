export const ImgStretchingFixedDiv = (event) => {
    const img = new Image();
    img.src = event.currentTarget.getAttribute('src');

    if (img.height < img.width) {
        event.currentTarget.style.height = '100%';
        event.currentTarget.style.width = 'auto';
    }else {
        event.currentTarget.style.width = '100%';
        event.currentTarget.style.height = 'auto';
    }

    event.currentTarget.classList.remove('hidden');
}