import React from 'react';
import classes from './KeyHolePreloader.module.scss';
import KeyHole from '../../../images/icons/KeyHole.svg';
import { useEffect, useState } from 'react';

const KeyHolePreloader = ({isLoading, className}) => {
    const [keyHoleClasses, setKeyHoleClasses] = useState([classes.key_hole, className]);

    useEffect(() => {
        if (!isLoading) {
            setKeyHoleClasses([...keyHoleClasses, 'hidden']);
        }else {
            setKeyHoleClasses([classes.key_hole, className]);
        }
    }, [isLoading])

    return (
        <div className={keyHoleClasses.join(' ')}>
            <img
                className={classes.key_hole_img}
                src={KeyHole}
                alt={'loading'}
            />
        </div>
    )
}

export default KeyHolePreloader