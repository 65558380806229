import React, {useCallback, useContext, useEffect, useState} from 'react';
import classes from "./CalendarPage.module.scss";
import DraftTopMenuBtns from "../../../components/dashboard/common/DraftTopMenuBtns/DraftTopMenuBtns";
import {useNavigate, useParams} from "react-router-dom";
import MiniCalendarAgni from "../../../components/dashboard/common/MiniCalendarAgni/MiniCalendarAgni";
import moment from "moment";
import {httpAxios} from "../../../API/HttpAxios";
import ExcursionCalendarCard from "../../../components/dashboard/common/ExcursionCalendarCard/ExcursionCalendarCard";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import SearchInput from "../../../components/dashboard/common/SearchInput/SearchInput";
import AuthContext from "../../../context/AuthContext";
import useOutsideClick from "../../../hooks/useOutsideClick";

const CalendarPage = () => {
    const {...context} = useContext(AuthContext);
    const {page} = useParams();
    const navigate = useNavigate();

    const topMenuBtns = [
        {
            name: "С записью",
            path: `/${context.user.role}/dashboard/calendar/bookings`
        },

        {
            name: "Все",
            path: `/${context.user.role}/dashboard/calendar/all`
        },
    ];

    // Get available dates
    let dateNow = moment();
    const availableDates = [];

    for (let i = 0; i < 365; i++) {
        availableDates.push({
            date: dateNow.clone().add(i, 'd').format('YYYY-MM-DD')}
        );
    }

    // Redirect to /new if no page
    useEffect(() => {
        !page && navigate(`/${context.user.role}/dashboard/calendar/bookings`);
    }, [page])

    // Data for get excursions
    const [date, setDate] = useState(moment());
    const [isOnlyWithBookings, setIsOnlyWithBookings] = useState(true);
    const [excursionId, setExcursionId] = useState('');
    const [tempExcursionId, setTempExcursionId] = useState('');
    const [times, setTimes] = useState([]);

    const [isTimesLoading, setIsTimesLoading] = useState(true);

    // Get excursions
    const getTimes = () => {
        setIsTimesLoading(true);
        setTimes([]);

        httpAxios.get('/api/excursions/get_excursions_for_employee_calendar', {
            params: {
                date: date.format('YYYY-MM-DD'),
                only_with_bookings: isOnlyWithBookings,
                excursion_id: excursionId
            }
        }).then(response => {
            setTimes(response.data);
            console.log(response)
        }).catch(error => {
            console.log(error);
        }).finally(() => setIsTimesLoading(false));
    }

    // Timeout for excursionId
    useEffect(() => {
        const excursionIdTimeout = setTimeout(() => {
            setExcursionId(tempExcursionId);
        }, 1000);

        return () => clearTimeout(excursionIdTimeout);
    }, [tempExcursionId])

    useEffect(() => {
        getTimes();
    }, [isOnlyWithBookings, excursionId, date])

    // Change isOnlyWithBookings by page
    useEffect(() => {
        if (page === 'all') {
            setIsOnlyWithBookings(false);
        }else {
            setIsOnlyWithBookings(true);
        }
    }, [page])

    const timesList = times.map((time, index) => {
        return (
            <div
                key={index}
                className={classes.schedule_row}
            >
                <div className={classes.schedule_time}>
                    {moment(date.format('YYYY-MM-DD') + ' ' + time.time).format('HH:mm')}
                </div>

                <div className={classes.bookings_container}>
                    {
                        time.excursions.map((excursion, index) => {
                            return (
                                <ExcursionCalendarCard
                                    key={index}
                                    excursion={excursion}
                                    datetime={moment(date.format('YYYY-MM-DD') + ' ' + time.time)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    })

    // Get excursion count with booking for all month
    // {
    //     date: '',
    //     count: 0
    // }
    const [excursionBookingCount, setExcursionBookingCount] = useState([]);
    const [excursionBookingCountDate, setExcursionBookingCountDate] = useState(moment());
    const getExcursionBookingCount = () => {
        httpAxios.get('/api/excursions/get_excursion_booking_count', {
            params: {
                date: excursionBookingCountDate.format('YYYY-MM-DD')
            }
        }).then(response => {
            console.log('getExcursionBookingCount')
            console.log(response)
            setExcursionBookingCount(response.data);
        }).catch(error => {
            console.log(error);
        });
    }


    useEffect(() => {
        getExcursionBookingCount();
    }, [excursionBookingCountDate])

    // Change calendar visibility
    const [calendarContainerClasses, setCalendarContainerClasses] = useState([classes.mini_calendar_container]);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const showCalendar = (e) => {
        e.stopPropagation();
        setIsCalendarVisible(true);
    }

    const calendarContainer = useOutsideClick(() => {
        setIsCalendarVisible(false)
    });

    useEffect(() => {
        setCalendarContainerClasses(isCalendarVisible ? [classes.mini_calendar_container, classes.mini_calendar_container_active] : [classes.mini_calendar_container])
    }, [isCalendarVisible])

    return (
        <div className={classes.main_container}>
            <div className={classes.top_menu}>
                <DraftTopMenuBtns
                    menuBtns={topMenuBtns}
                />

                <div
                    className={[classes.show_calendar_btn, 'temp_btn'].join(' ')}
                    onClick={showCalendar}
                    id={'show_calendar_btn'}
                >
                    Календарь
                </div>
            </div>


            <div className={classes.calendar_container}>
                <div className={classes.schedule_container}>
                    <div className={classes.schedule_date_container}>
                        {date.format('D MMMM YYYY')}
                    </div>

                    {
                        isTimesLoading
                        &&
                            <PageLoader/>
                    }

                    <div className={classes.schedule_block}>
                        {timesList}
                    </div>
                </div>

                <div
                    className={calendarContainerClasses.join(' ')}
                    ref={calendarContainer}
                >
                    <MiniCalendarAgni
                        availableDates={availableDates}
                        selectedDate={date}
                        onDateClick={setDate}
                        changeMonth={setExcursionBookingCountDate}
                        notificationCount={excursionBookingCount}
                    />

                    <SearchInput
                        className={classes.search_input}
                        placeholder={"Введите ID впечатления"}
                        value={tempExcursionId}
                        onChange={setTempExcursionId}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarPage;