import AuthContext from "./context/AuthContext";
import {
    BrowserRouter,
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Router,
    RouterProvider,
    Routes,
    useLocation
} from "react-router-dom";
import React, {useEffect, useState} from "react";
// import MainLayout from "./components/layouts/MainLayout/MainLayout";
// import ErrorPage from "./pages/ErrorPage/ErrorPage";
// import ExcursionBookingPage from "./pages/ExcursionBookingPage/ExcursionBookingPage";
import Root from "./routes/Root";
import UserService from "./API/UserService";
// import {httpAxios} from "./API/HttpAxios";
import {useFetching} from "./hooks/useFetching";
import PageLoader from "./components/UI/PageLoader/PageLoader";
// import UserAuth from "./components/UserAuth/UserAuth";
// import {useCookies} from "react-cookie";
// import CookieNotification from "./components/UI/CookieNotification/CookieNotification";

function App() {
    const [isAuth, setIsAuth] = useState(false);
    const [isAuthVisible, setIsAuthVisible] = useState(false);
    const [user, setUser] = useState({})
    const router = Root;
    // const [cookies, setCookie, removeCookie] = useCookies();

    // Checking user is auth
    const [authChecking, isAuthCheckingLoading, authCheckingError] = useFetching(async () => {
        const user = await UserService.getUser();
        // console.log(user)
        if (user.status === 200) {
            setIsAuth(true);
            setUser(user.data)
        }
    })

    useEffect( () => {
        authChecking();
    }, [])

    return(
        isAuthCheckingLoading
        ?
        <PageLoader/>
        :
        <AuthContext.Provider value={{
            isAuth,
            setIsAuth,
            isAuthVisible,
            setIsAuthVisible,
            user,
            setUser
        }}>
            <RouterProvider router={router} />
        </AuthContext.Provider>
    )
}

export default App;
