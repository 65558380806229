import React, {useEffect, useRef, useState} from 'react';
import classes from "./ExperiencesBlock.module.scss";
import {useOutletContext, useSearchParams} from "react-router-dom";
import SearchInput from "../../common/SearchInput/SearchInput";
import {useFetching} from "../../../../hooks/useFetching";
import ExcursionService from "../../../../API/ExcursionService";
import moment from "moment";
import {useObserverBlockEntry} from "../../../../hooks/useObserverBlockEntry";
import {log} from "util";

const ExperiencesBlock = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {...outletContext} = useOutletContext();

    // Choose group with excursion_type, date, time
    const chooseExperience = (e) => {
        let experienceTypeId = e.currentTarget.getAttribute("data-experience-type-id");
        let date = e.currentTarget.getAttribute("data-date");
        let time = e.currentTarget.getAttribute("data-time");
        searchParams.set("experience_type_id", experienceTypeId);
        searchParams.set("date", date);
        searchParams.set("time", time);
        setSearchParams(searchParams);
    }

    // Function for fetch bookings
    const [bookings, setBookings] = useState([]);
    const [requestParams, setRequestParams] = useState({
        currentPage: 1,
        excursionId: '',
    });
    const [lastPage, setLastPage] = useState(1);

    const [isBookingsLoading, setIsBookingsLoading] = useState(true);
    const fetchBookings = async () => {
        setIsBookingsLoading(true);

        const response = await ExcursionService.getBookingsGroupBy(['excursion_type_id', 'date', 'time'], requestParams.excursionId, requestParams.currentPage);
        console.log(response)
        // const data = response.data;

        if (requestParams.currentPage === 1) {
            setBookings(response.data.data);
        }else {
            setBookings([...bookings, ...response.data.data]);
        }

        setLastPage(response.data.last_page);
        setIsBookingsLoading(false);
    }

    // Fetch bookings
    useEffect(() => {
        if (requestParams.excursionId !== '') {
            var changeExcursionIdTimeout = setTimeout(() => {
                fetchBookings();
            }, 1000)
        }else {
            fetchBookings();
        }

        return () => {
            if (changeExcursionIdTimeout !== undefined) {
                clearTimeout(changeExcursionIdTimeout)
            }
        }
    }, [requestParams])


    // Change excursion id
    const changeExcursionId = (value) => {
        setRequestParams({
            currentPage: 1,
            excursionId: value
        })
    }

    // useEffect(() => {
    //
    // }, [requestParams])

    useEffect(() => {
        console.log("Experiences block:")
        console.log(bookings)
    }, [bookings])

    // Render bookings
    const renderBookings = bookings.map((booking, i) => {
        let datetime = moment(booking.date + ' ' + booking.time);
        let experienceBlockClasses = [classes.experience_block];

        // console.log('TYT EPTA')
        // console.log(booking.id)
        // console.log(outletContext.bookingNotifications.find(el => el.booking_id == booking.id))

        let bookingNotification = outletContext.bookingNotifications.find(el => {
            if (el.excursion_type_id == booking.excursion_type.id && el.date == booking.date && el.time == booking.time) {
                return el;
            }
        })

        if (bookingNotification !== undefined && bookingNotification.unread_messages_count > 0) {
            experienceBlockClasses.push(classes.experience_block_active)
            // console.log('TYT EPTA')
            // console.log(outletContext.bookingNotifications.find(el => el.booking_id == booking.id))

        }

        return (
            <div
                className={experienceBlockClasses.join(" ")}
                key={`exp_${booking.id}_${datetime.format('HH:mm')}`}
                data-experience-type-id={booking.excursion_type.id}
                data-date={booking.date}
                data-time={booking.time}
                onClick={e => chooseExperience(e)}
            >
                <div className={classes.experience_top_block}>
                    <div className={classes.experience_title}>
                        {booking.excursion.title}
                    </div>

                    <div className={classes.experience_type}>
                        {booking.type_info.name}
                    </div>
                </div>

                <div className={classes.experience_bottom_block}>
                    <div className={classes.experience_datetime}>
                        {datetime.format('DD MMMM')} в {datetime.format('HH:mm')}
                    </div>

                    <div className={classes.experience_new_message_count}>
                        {
                            bookingNotification !== undefined && bookingNotification.unread_messages_count > 0 &&
                            'Новые сообщения!'

                            // booking.messages_count > 0 &&
                            // `Новые сообщения!`

                            // `Новых сообщений: ${booking.messages_count}`
                        }
                    </div>
                </div>
            </div>
        )
    })

    const observerBlock = useRef(null);
    const isObserverBlockEnter = useObserverBlockEntry(observerBlock);

    useEffect(() => {
        if (isObserverBlockEnter && isBookingsLoading === false && requestParams.currentPage < lastPage) {
            console.log('INNN WORK')
            setRequestParams(requestParams => ({...requestParams, currentPage: requestParams.currentPage + 1}))
        }
    }, [isObserverBlockEnter, isBookingsLoading, requestParams])

    return (
        <div className={classes.experiences_container}>
            <SearchInput
                placeholder={"Введите ID экскурсии..."}
                value={requestParams.excursionId}
                onChange={changeExcursionId}
            />

            {/*Experiences*/}
            {
                renderBookings
            }

            <div ref={observerBlock} style={{height: '10px'}}></div>
        </div>
    );
};

export default ExperiencesBlock;