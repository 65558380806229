import React, {useContext, useEffect, useState} from 'react';
import NewChatBlock from "../NewChatBlock/NewChatBlock";
import AuthContext from "../../../../context/AuthContext";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {httpAxios} from "../../../../API/HttpAxios";
import LaravelEcho from "../../../../API/LaravelEcho";
import classes from "./ClientChatContainer.module.scss";
import ArrowLeft from "../../../../images/icons/ArrowLeft.svg";

const ClientChatContainer = ({bookingId, bookings, setBookings}) => {
    const {...context} = useContext(AuthContext);
    const {...outletContext} = useOutletContext();
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isNewMessagesRequest, setIsNewMessagesRequest] = useState(false);
    const [myNewMessage, setMyNewMessage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set message as read
    const setMessageAsRead = () => {
        httpAxios.post('/api/booking_message/set_message_as_read', {
            booking_id: bookingId,
        })
    }

    // Set current page
    useEffect(() => {
        if (bookingId !== undefined && bookingId !== null) {
            if (currentPage === 1) {
                getMessages(1);
            }else {
                setCurrentPage(1);
            }

            setMessageAsRead();

            outletContext.setBookingNotifications(bookingNotifications => {
                return bookingNotifications.map(bookingNotification => {
                    if (bookingNotification.booking_id == bookingId) {
                        return {
                            ...bookingNotification,
                            unread_messages_count: 0
                        }
                    }

                    return bookingNotification
                })
            })
        }
    }, [bookingId])

    // Get messages portion
    const [isMessagesLoading, setIsMessagesLoading] = useState(true);
    const getMessages = (page) => {
        setIsMessagesLoading(true);
        httpAxios.get(`/api/booking_message/get_messages`, {
            params: {
                booking_id: bookingId,
                page: page
            }
        }).then(response => {
            setCurrentPage(response.data.current_page);
            setLastPage(response.data.last_page);
            console.log(response)
            if (currentPage === 1) {
                setMessages(response.data.data.reverse());
            }else {
                setMessages([...response.data.data.reverse(), ...messages]);
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsMessagesLoading(false))
    }

    useEffect(() => {
        if (currentPage !== null) {
            getMessages(currentPage);
        }
    }, [currentPage])

    // Handle new messages request
    useEffect(() => {
        if (isNewMessagesRequest && currentPage < lastPage && isMessagesLoading !== true) {
            setCurrentPage(currentPage => currentPage + 1);
            setIsNewMessagesRequest(false);
        }
    }, [isNewMessagesRequest, currentPage, isMessagesLoading])

    // Send new message function
    const sendNewMessage = (message) => {
        httpAxios.post('/api/booking_message/save_message', {
            booking_id: bookingId,
            message: message
        }, {headers: {'X-Socket-ID': LaravelEcho.socketId()}}).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    // Handle my new message
    useEffect(() => {
        if (myNewMessage !== null) {
            console.log('myNewMessage')
            console.log(myNewMessage)
            setMessages([...messages, myNewMessage]);
            sendNewMessage(myNewMessage);
            setMyNewMessage(null);
        }
    }, [myNewMessage]);

    // Listen new message
    const [newMessage, setNewMessage] = useState(null);
    useEffect(() => {
        if (bookingId === undefined) return;


        LaravelEcho.private(`booking.messages.${bookingId}`)
            .listen('.booking.message.send', (e) => {
                console.log(e);
                setNewMessage(e.message)

                setMessageAsRead();
            });

        console.log('CONNECT CHANNEL')

        return () => {
            LaravelEcho.leave(`booking.messages.${bookingId}`);
            console.log('LEAVE CHANNEL')
        }
    }, [bookingId]);

    useEffect(() => {
        if (newMessage !== null) {
            setMessages([...messages, newMessage]);
            setNewMessage(null);
        }
    }, [newMessage])

    const closeChat = () => {
        searchParams.delete('booking_id');
        setSearchParams(searchParams);
    }

    // const [isCallManagerVisible, setIsCallManagerVisible] = useState(false);

    const callManager = () => {
        httpAxios.post('/api/booking/call_manager', {
            booking_id: bookingId
        }).then(response => {
            if (response.status === 200) {
                let callManagerMessage = {
                    body: "Прошу менеджера присоединиться к нам и помочь с решением вопроса!",
                    booking_id: bookingId,
                    by_user_id: context.user.id,
                    user: context.user
                }

                setMessages([...messages, callManagerMessage])

                sendNewMessage(callManagerMessage)

                const nextBookings = bookings.map(booking => {
                    if (booking.booking.id == bookingId) {
                        return {
                            ...booking,
                            booking: {
                                ...booking.booking,
                                is_manager_called: 1
                            }
                        }
                    }else {
                        return booking
                    }
                })

                setBookings(nextBookings);
            }
            console.log(response)
        })
    }

    return (
        <div className={classes.chat_container}>
            <div className={classes.chat_control_row}>
                <div
                    className={classes.close_chat_btn}
                    onClick={closeChat}
                >
                    <img src={ArrowLeft} alt={'<'}/>
                </div>

                {
                    // bookings.find(booking => booking.booking.id == bookingId) !== undefined && bookings.find(booking => booking.booking.id == bookingId).booking.is_manager_called == 0
                    outletContext.bookingNotifications.find(el => el.booking_id == bookingId) !== undefined
                    ?
                        outletContext.bookingNotifications.find(el => el.booking_id == bookingId).is_manager_called == false
                        ?
                            <div
                                className={classes.call_manager_btn}
                                onClick={callManager}
                            >
                                Позвать менеджера
                            </div>
                        :
                            <div
                                className={classes.call_manager_btn}
                            >
                                Менеджер вызван
                            </div>
                    : ''
                }
            </div>

            <NewChatBlock
                messages={messages}
                setIsNewMessageRequest={setIsNewMessagesRequest}
                setMyNewMessage={setMyNewMessage}
            />
        </div>
    );
};

export default ClientChatContainer;