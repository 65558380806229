import {useEffect} from "react";

const useScript = (src, async = true, defer = true, onload = () => console.log("Script loaded!")) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = src;
        script.async = async;
        script.defer = defer;
        script.onload = onload();

        // document.body.appendChild(script);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])
}

export default useScript;