import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './CustomerSurvey.module.scss';
import confused from '../../../images/emoji/confused.png';
import gift from '../../../images/emoji/gift.png';
import like from '../../../images/emoji/like.png';
import man_shrugging from '../../../images/emoji/man_shrugging.png';
import no_good from '../../../images/emoji/no_good.png';
import pray from '../../../images/emoji/pray.png';
import HbButton from '../HbButton/HbButton';
import { Link } from 'react-router-dom';
import Close from '../../../images/icons/Close.svg';
import { useCookies } from 'react-cookie';
import UserService from 'API/UserService';

export const CustomerSurvey = () => {
    // Collect cookies
    const [cookies, setCookie, removeCookie] = useCookies();
    const answersContainer = useRef();
    const giftContainer = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isVisible && !cookies.customer_survey) {
            setCookie('customer_survey', true, { path: '/' });
            setCookie('customer_survey_id', Date.now() + '_' + Math.round(Math.random() * 1000), { path: '/' });
        } 
        // else {
        //     isVisible = false;
        // }
    }, [isVisible])

    // Collect answers
    const [answers, setAnswers] = useState([]);

    const handleAnswer = (e, answer) => {
        if (e.currentTarget.classList.contains(classes.answer_active)) {
            e.currentTarget.classList.remove(classes.answer_active);
        } else {
            e.currentTarget.classList.add(classes.answer_active);
        }

        if (!answers.includes(answer)) {
            setAnswers([...answers, answer]);
        } else {
            setAnswers(answers.filter(el => el !== answer));
        }
    }

    // useEffect(() => {
    //     console.log('answers')
    //     console.log(answers)
    // }, [answers])

    // Show/hide survey
    const [containerFluidClasses, setContainerFluidClasses] = useState([classes.container_fluid, 'hidden']);

    useEffect(() => {
        if (isVisible) {
            setContainerFluidClasses([classes.container_fluid]);
        } else {
            setContainerFluidClasses([classes.container_fluid, 'hidden']);
        }
    }, [isVisible])

    const closeSurvey = () => {
        setContainerFluidClasses([classes.container_fluid, 'hidden']);
    }

    const closeSurveyByContainer = (e) => {
        if (e.target === e.currentTarget) {
            closeSurvey();
        }
    }

    // Send answers to server
    const sendAnswers = useCallback(async () => {
        if (answers.length === 0) {
            return;
        }

        console.log('answers')
        console.log(answers)

        console.log('cookies.customer_survey_id')
        console.log(cookies.customer_survey_id)

        const response = await UserService.createOrUpdateCustomerServey(cookies.customer_survey_id, answers);
        console.log(response)
    }, [answers, cookies])

    useEffect(() => {
        const sendAnswersTimer = setTimeout(() => {
            sendAnswers();
        }, 1000);

        return () => {
            clearTimeout(sendAnswersTimer);
        }
    }, [answers])

    // Show gift
    const showGift = () => {
        answersContainer.current.classList.add('hidden');
        giftContainer.current.classList.remove('hidden');
    }

    // Show survey
    useEffect(() => {
        const handleMouseLeave = (event) => {
            if (!cookies.customer_survey && event.clientY <= 0 ) {
                setIsVisible(true);
                setContainerFluidClasses([classes.container_fluid]);
            }
        };

        // Добавляем обработчик события
        document.addEventListener('mouseout', handleMouseLeave);

        const handleVisibilityChange = () => {
            if (!cookies.customer_survey && document.visibilityState === 'hidden') {
                setIsVisible(true);
                setContainerFluidClasses([classes.container_fluid]);
            }
        };

        // Добавляем обработчик события
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Убираем обработчик при размонтировании компонента
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('mouseout', handleMouseLeave);
        };
    }, [cookies])

    return (
        <div
            className={containerFluidClasses.join(" ")}
            onClick={closeSurveyByContainer}
        >
            <div className={classes.container}>
                <div
                    className={classes.close_btn}
                    onClick={closeSurvey}
                >
                    <img className={classes.close_btn_img} src={Close} alt="x" />
                </div>

                <div
                    ref={answersContainer}
                // className={'hidden'}
                >
                    <div className={classes.header}>
                        Уделите нам 1&nbsp;секунду <img className={classes.pray_img} src={pray} alt="🙏🏻" />
                    </div>

                    <div className={classes.gift_text}>
                        а с нас - подарок!
                    </div>

                    <div className={classes.question}>
                        Как вам наш сайт?
                    </div>

                    <div className={classes.answers_container}>
                        <div
                            className={classes.answer}
                            onClick={(event) => handleAnswer(event, 'всё классно!')}
                        >
                            <p className={classes.btn_text}>
                                всё классно!&nbsp;<img className={classes.btn_emoji_img} src={like} alt="👍" />
                            </p>
                        </div>

                        <div
                            className={classes.answer}
                            onClick={(event) => handleAnswer(event, 'непонятно, как бронировать')}
                        >
                            <p className={classes.btn_text}>
                                непонятно, как бронировать&nbsp;<img className={classes.btn_emoji_img} src={man_shrugging} alt="🤷‍♂️" />
                            </p>
                        </div>

                        <div
                            className={classes.answer}
                            onClick={(event) => handleAnswer(event, 'не хватило информации')}
                        >
                            <p className={classes.btn_text}>
                                не хватило информации&nbsp;<img className={classes.btn_emoji_img} src={confused} alt="😕" />
                            </p>
                        </div>

                        <div
                            className={classes.answer}
                            onClick={(event) => handleAnswer(event, 'не доверяю')}
                        >
                            <p className={classes.btn_text}>
                                не доверяю&nbsp;<img className={classes.btn_emoji_img} src={no_good} alt="🙅‍♀️" />
                            </p>
                        </div>
                    </div>

                    <div className={classes.footer_container}>
                        <div className={classes.gift_container}>
                            <div className={classes.thanks}>
                                Спасибо за обратную связь!
                            </div>

                            <HbButton
                                className={classes.gift_btn}
                                color={'blue'}
                                onClick={showGift}
                            >
                                <p className={classes.btn_text}>
                                    Ваш подарок!&nbsp;<img className={classes.btn_emoji_img} src={gift} alt="🎁" />
                                </p>
                            </HbButton>
                        </div>


                        <a
                            className={classes.additional_text}
                            href={'https://forms.yandex.ru/u/6707ba7f73cee78e4e01bde2/'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Вам есть что добавить?
                        </a>
                    </div>
                </div>


                <div
                    ref={giftContainer}
                    className={'hidden'}
                >
                    <div className={classes.gift_header}>
                        Вам скидка 20%
                    </div>

                    <div className={classes.gift_text_result}>
                        на любую экскурсию на нашем сайте!
                    </div>

                    <div className={classes.gift_text_result_2}>
                        сохраняйте промокод:
                    </div>

                    <div className={classes.gift_code_container}>
                        <p className={classes.gift_code}>
                            THANKYOU20
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
