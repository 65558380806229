import React, { useCallback, useEffect, useState } from 'react';
import classes from './ReviewControlUnit.module.scss';
import { ReactComponent as RatingStar } from '../../../../images/icons/RatingStar.svg';
import moment from "moment";
import { ReviewStatusInfos } from "../../../../enums/ReviewStatusInfos";
import { baseURL, httpAxios } from "../../../../API/HttpAxios";
import { ImgStretchingFixedDiv } from 'helpers/ImgStretching';
import { useSearchParams } from 'react-router-dom';
import { PhotoGallery } from 'components/UI/PhotoGallery/PhotoGallery';

const ReviewControlUnit = ({ review, setReviewImgSrcs }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Rating
    const rating = 4;

    const starsList = [];
    for (let i = 1; i <= review.rating; i++) {
        starsList.push(<RatingStar key={i} className={classes.rating_star_icon_active} />);
    }

    // Change organizer answer
    const [isOrganizerResponseEditing, setIsOrganizerResponseEditing] = useState(false);
    const [newOrganizerResponse, setNewOrganizerResponse] = useState(review.reviewResponse ? review.reviewResponse.body : '');

    // Publish review
    const [isReviewPublishing, setIsReviewPublishing] = useState(false);
    const publishReview = () => {
        setIsReviewPublishing(true);
        httpAxios.post('/api/review/publish_review', {
            review_id: review.id,
        }).then(response => {
            if (response.status === 200) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsReviewPublishing(false));
    }

    // Reject review
    const [isReviewRejecting, setIsReviewRejecting] = useState(false);
    const rejectReview = () => {
        setIsReviewRejecting(true);
        httpAxios.post('/api/review/reject_review', {
            review_id: review.id,
        }).then(response => {
            if (response.status === 200) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsReviewRejecting(false));
    }

    // Publish review answer
    const [isReviewResponsePublishing, setIsReviewResponsePublishing] = useState(false);
    const publishReviewResponse = () => {
        setIsReviewResponsePublishing(true);
        httpAxios.post('/api/review/publish_review_response', {
            review_response_id: review.reviewResponse && review.reviewResponse.id,
            body: isOrganizerResponseEditing ? newOrganizerResponse : review.reviewResponse ? review.reviewResponse.body : '',
        }).then(response => {
            if (response.status === 200) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsReviewResponsePublishing(false));
    }

    // Reject review answer
    const [isReviewResponseRejecting, setIsReviewResponseRejecting] = useState(false);
    const rejectReviewResponse = () => {
        setIsReviewResponseRejecting(true);
        httpAxios.post('/api/review/reject_review_response', {
            review_response_id: review.reviewResponse && review.reviewResponse.id,
        }).then(response => {
            if (response.status === 200) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsReviewResponseRejecting(false));
    }

    // Click on review img
    const clickOnReviewImg = (e) => {
        let parent = e.currentTarget.parentElement;
        let imgSrcs = [];

        Array.from(parent.children).forEach(element => {
            imgSrcs.push(element.children[0].getAttribute('src'));
        });

        setReviewImgSrcs(imgSrcs);

        searchParams.set('reviews_img_src', e.currentTarget.getAttribute('data-img-src'));
        setSearchParams(searchParams);
    }

    return (
        <div className={classes.review_container}>
            <div className={classes.client_review_container}>
                <div className={classes.experience_title_date_row}>
                    <div className={classes.experience_title}>
                        {review.excursion.title}
                    </div>

                    <div className={classes.experience_date}>
                        {moment(review.updated_at).format('DD.MM.YYYY')}
                    </div>
                </div>

                <div className={classes.rating_row}>
                    {starsList}
                </div>

                <div className={classes.client_name_row}>
                    {review.is_anonymously ? 'Аноним' : review.user.name}
                </div>

                <div className={classes.review_text_block}>
                    {review.body}
                </div>

                {/* Render photos */}
                <div className={classes.review_photos_block}>
                    {
                        review.review_imgs.map((photo, index) => {
                            return (
                                <div
                                    key={`review_photo_${index}`}
                                    className={classes.review_photo}
                                    data-img-src={photo.img_src.match(/\/([\w\d]+)\.webp$/)[1]}
                                    onClick={clickOnReviewImg}
                                >
                                    <img
                                        src={baseURL + photo.preview_img_src}
                                        alt={'review_photo'}
                                        onLoad={ImgStretchingFixedDiv}
                                        data-block={'review_photo'}
                                    />
                                </div>
                            );
                        })
                    }
                </div>

                <div className={classes.review_status_row}>
                    {ReviewStatusInfos[review.status]}
                </div>

                {
                    review.status === 'moderation' &&
                    <div className={classes.client_manipulate_btns_row}>
                        <div
                            className={["temp_btn", "temp_btn_success"].join(" ")}
                            onClick={publishReview}
                        >
                            Опубликовать
                        </div>

                        <div
                            className={["temp_btn", "temp_btn_delete"].join(" ")}
                            onClick={rejectReview}
                        >
                            Отклонить
                        </div>
                    </div>
                }
            </div>

            {
                review.reviewResponse &&
                <div className={classes.organizer_answer_container}>
                    <div className={classes.organizer_answer_top_row}>
                        <div className={classes.organizer_name_block}>
                            <span className={classes.organizer_name}>{review.reviewResponse.user.name}</span> ответил на отзыв
                        </div>

                        <div className={classes.organizer_answer_date}>
                            {moment(review.reviewResponse.updated_at).format('DD.MM.YYYY')}
                        </div>
                    </div>

                    {
                        !isOrganizerResponseEditing &&
                        <div className={classes.organizer_answer_block}>
                            {review.reviewResponse.body}
                        </div>
                    }

                    {
                        isOrganizerResponseEditing &&
                        <textarea
                            rows={15}
                            className={classes.organizer_answer_textarea}
                            value={newOrganizerResponse}
                            onChange={e => setNewOrganizerResponse(e.target.value)}
                        ></textarea>
                    }

                    <div className={classes.answer_status_row}>
                        {ReviewStatusInfos[review.reviewResponse.status]}
                    </div>

                    {
                        review.reviewResponse.status === 'moderation' &&
                        <div className={classes.organizer_manipulate_btns_row}>
                            <div
                                className={["temp_btn", "temp_btn_success"].join(" ")}
                                onClick={publishReviewResponse}
                            >
                                Опубликовать
                            </div>

                            <div
                                className={["temp_btn", "temp_btn_delete"].join(" ")}
                                onClick={rejectReviewResponse}
                            >
                                Отклонить
                            </div>

                            <div
                                className={["temp_btn"].join(" ")}
                                onClick={() => setIsOrganizerResponseEditing(!isOrganizerResponseEditing)}
                            >
                                {
                                    isOrganizerResponseEditing ? 'Отменить редактирование' : 'Редактировать'
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default ReviewControlUnit;