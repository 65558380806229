import React, {useContext, useEffect} from 'react';
import AuthContext from "../../context/AuthContext";
import {Outlet, useNavigate} from "react-router-dom";

const UserRoutes = ({userRole, path = '/'}) => {
    const navigate = useNavigate();
    const {...context} = useContext(AuthContext);

    useEffect(() => {
        console.log('!!!');
        console.log(context);
        if (!context.user || context.user.role !== userRole) {
            navigate(path);
        }
    }, [context])

    return (
        <div>
            <Outlet/>
        </div>
    );
};

export default UserRoutes;