import React, {useContext, useEffect, useState} from 'react';
import classes from './ExperienceInfoBlock.module.scss';
import {baseURL} from "../../../../API/HttpAxios";
import ExcursionService from "../../../../API/ExcursionService";
import {useFetching} from "../../../../hooks/useFetching";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import RatingStarFilled from "../../../../images/icons/RatingStarFilled.svg"
import {ImgStretchingFixedDiv} from "../../../../helpers/ImgStretching";

const ExperienceInfoBlock = ({isVisible, excursion, switchExperienceContent}) => {
    const {...context} = useContext(AuthContext);

    const navigate = useNavigate();
    const [experienceContentClasses, setExperienceContentClasses] = useState([classes.experience_content]);

    // Set visibility experience content
    useEffect(() => {
        setExperienceContentClasses(isVisible ? [classes.experience_content, classes.experience_content_active] : [classes.experience_content]);
    }, [isVisible]);

    // Stop excursion
    const [fetchStopExcursion, isStopExcursionLoading, stopExcursionError] = useFetching(async () => {
        const response = await ExcursionService.stopExcursion(excursion.id);
        console.log(response);
        window.location.reload(); // TODO: change to normal react render excursions
        // navigate();
    })

    // Activate excursion
    const [fetchActivateExcursion, isActivateExcursionLoading, activateExcursionError] = useFetching(async () => {
        const response = await ExcursionService.activateExcursion(excursion.id);
        console.log(response);
        window.location.reload(); // TODO: change to normal react render excursions

    })

    // Switch experience btns
    const [experienceBtns, setExperienceBtns] = useState([]);
    useEffect(() => {
        if (excursion.status.alias === "active") {
            setExperienceBtns([
                {
                    name: "Редактировать",
                    onClick: switchExperienceContent
                },

                {
                    name: "Приостановить",
                    onClick: fetchStopExcursion
                }
            ])
        }else if (['draft', 'rejected', 'stopped'].includes(excursion.status.alias)) {
            if (excursion.status_edited_by === context.user.id) {
                setExperienceBtns([
                    {
                        name: "Редактировать",
                        onClick: switchExperienceContent
                    },

                    {
                        name: "Опубликовать",
                        onClick: fetchActivateExcursion
                    },
                ])
            }else {
                setExperienceBtns([
                    {
                        name: "Редактировать",
                        onClick: switchExperienceContent
                    }
                ]);
            }
        }else if (excursion.status.alias === "moderation") {
            setExperienceBtns([
                {
                    name: "Открыть",
                    onClick: switchExperienceContent
                }
            ])
        }
    }, [excursion])

    const listExperienceBtns = experienceBtns.map((btn, index) => {
        return (
            <div
                className={`temp_btn`}
                key={index}
                onClick={btn.onClick}
            >
                {btn.name}
            </div>
        )
    })

    return (
        <div className={experienceContentClasses.join(" ")}>
            <a href={`/e/${excursion.id}`} className={classes.experience_img_block}>
                <img
                    src={excursion.first_img && baseURL + excursion.first_img.img_path}
                    onLoad={ImgStretchingFixedDiv}
                />
            </a>

            <div className={classes.experience_info_container}>
                <div className={classes.experience_info_block}>
                    <div className={classes.experience_title}>
                        {excursion.title}
                    </div>

                    <div className={classes.experience_rating}>
                        <img src={RatingStarFilled} alt={'rating'}/>
                        {excursion.rating % 1 === 0 ? `${excursion.rating}.0` : excursion.rating}
                    </div>
                </div>

                <div className={classes.experience_btns_block}>
                    {listExperienceBtns}
                </div>
            </div>

            <div className={classes.experience_status_block}>
                {excursion.status_info.name}
            </div>
        </div>
    );
};

export default ExperienceInfoBlock;