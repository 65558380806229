import Echo from "laravel-echo";
import { httpAxios } from "./HttpAxios";

window.Pusher = require('pusher-js');

const LaravelEcho = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    wsHost: process.env.REACT_APP_PUSHER_HOST,
    wsPort: process.env.REACT_APP_PUSHER_PORT,
    wssPort: process.env.REACT_APP_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                httpAxios.post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        callback(false, response.data);
                        console.log(response);
                    })
                    .catch(error => {
                        callback(true, error);
                        console.log(error);
                    });
            }
        };
    },
});


export default LaravelEcho;
