import React, {useEffect, useMemo, useRef, useState} from 'react';
import classes from './ExcursionBookingTypes.module.scss';
import {Types} from "../../../lang/ru/Types";
import {useSearchParams} from "react-router-dom";

const ExcursionBookingTypes = ({excursion, excursionType, setExcursionType}) => {

    // Displaying type by excursionType
    useEffect(() => {
        Types.map((typeEl) => {
            if (excursion.types.find(el => el.type_name === typeEl.type) !== undefined) {
                if (excursionType.type_name === typeEl.type) {
                    document.getElementById(`${typeEl.type.toLowerCase()}Selector`).classList.add(classes.type_selector_active);
                }else {
                    document.getElementById(`${typeEl.type.toLowerCase()}Selector`).classList.remove(classes.type_selector_active);
                }
            }
        })
    }, [excursionType])

    // Select type by click
    const selectType = (event) => {
        setExcursionType(excursion.types.find(el => el.type_name === event.currentTarget.getAttribute('data-excursion-type')))
    }

    const getTypeList = () => {
        let typesHtml = [];
        Types.map((typeEl, typeIndex) => {
            if (excursion.types.find(el => el.type_name === typeEl.type) !== undefined) {
                typesHtml.push(
                    <div
                        className={classes.type_row}
                        key={`type_${typeIndex}`}
                        id={`${typeEl.type.toLowerCase()}Row`}
                        data-excursion-type={typeEl.type}
                        onClick={selectType}
                    >
                        <div className={classes.type_selector}>
                            <div
                                id={`${typeEl.type.toLowerCase()}Selector`}
                            >

                            </div>
                        </div>

                        <div className={classes.type_info}>
                            <div className={classes.type_title}>
                                {typeEl.name}
                            </div>

                            <div className={classes.type_description}>
                                {typeEl.description}
                            </div>
                        </div>
                    </div>
                )
            }
        })

        return typesHtml;
    }

    const typesList = useMemo(getTypeList, [excursionType]);


    return (
        <div className={classes.types_block}>
            {typesList}
        </div>
    );
};

export default ExcursionBookingTypes;