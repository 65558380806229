import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from "./UserAuth.module.scss";
import Close from '../../images/icons/Close.svg';
import HbInput from "../UI/HbInput/HbInput";
import HbButton from "../UI/HbButton/HbButton";
import { useCookies } from "react-cookie";
import UserService from "../../API/UserService";
import { useReceipting } from "../../hooks/useReceipting";
import AuthContext from "../../context/AuthContext";
import moment from "moment";
import { EmailNotifications } from "../../lang/ru/EmailNotifications";
// import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
// import {google} from "googleapis";
import VkIconSmall from "../../images/icons/VkIconSmall.svg";
import YaIconSmall from "../../images/icons/YaIconSmall.png";
import Success from "../../images/icons/Success.svg";
import { YaMetrikaGoal } from 'helpers/YaMetrikaGoal';
import { useUserWishlist } from 'hooks/useUserWishlist';

const UserAuth = () => {
    const [cookie, setCookie, removeCookie] = useCookies(['isUserExist', 'isCodeSent', 'codeTime', 'email']);
    const [authContainerFluidClasses, setAuthContainerFluidClasses] = useState([classes.auth_container_fluid, 'hidden']);
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    let location = useLocation();

    // Set last page to cookie
    useEffect(() => {
        if (window.location.pathname !== '/user/vk_auth' && window.location.pathname !== '/user/yandex_auth') {
            setCookie('last_page', window.location.pathname + window.location.search, { path: '/' });
        }
    }, [location])


    useEffect(() => {
        console.log('AUTH: ', isAuth)
    }, [isAuth])


    // Hidden auth visibility
    const hiddenAuth = () => {
        console.log(isAuthVisible)
        if (isAuthVisible) {
            setIsAuthVisible(false);
        }
    }

    const outsideAuth = useRef();
    const outsideAuthClick = (event) => {
        if (event.target === outsideAuth.current) {
            setIsAuthVisible(false);
        }
    }

    useEffect(() => {
        if (isAuthVisible && !isAuth) {
            setAuthContainerFluidClasses([classes.auth_container_fluid]);
        } else {
            setAuthContainerFluidClasses([...authContainerFluidClasses, 'hidden']);
        }
    }, [isAuthVisible, isAuth])


    //User's data
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //Errors
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const passwordResendTime = 20;

    const successRegistrationBlock = useRef();
    const buttonsRow = useRef();
    const fastAuthContainer = useRef();
    const orBlock = useRef();

    // Find user or create
    const [userFinding, isUserFindingLoading, userFindingError] = useReceipting(async () => {
        setEmailError('');
        const response = await UserService.findUserOrCreate(email);
        // const data = response.data;
        if (response.status === 201) {
            // setCookie('email', email);
            console.log(response.data);

            successRegistrationBlock.current.classList.add(classes.success_registration_active);
            buttonsRow.current.classList.add(classes.buttons_row_disabled);
            fastAuthContainer.current.classList.add(classes.fast_auth_container_disabled);
            orBlock.current.classList.add(classes.or_block_disabled);

            YaMetrikaGoal({ goalName: "SUCCESS_REGISTRATION" });

            setTimeout(() => {
                setUser(response.data.user);
                setIsAuth(true);
            }, 4000);

            // setCookie('codeTime', moment().add(passwordResendTime, 'second').unix());
            // setNewPasswordNotification(EmailNotifications.newPasswordSent);

            // setTimeLeft(passwordResendTime);
        } else if (response.status === 200) {
            // sendNewPassword();
            if (response.data.result === 'false') {
                setEmailError('Неудалось отправить пароль! Проверьте адрес почты и попробуйте еще раз.');
            } else {
                setCookie('email', email);
                setNewPasswordNotification('Вы уже бронировали с этого Email, поэтому введите пароль из прошлого письма или нажмите "Получить новый пароль".');
            }
        }

        console.log('USER DATA:')
        console.log(response)
    })

    useEffect(() => {
        console.log(userFindingError)
    }, [userFindingError])

    // Finding email
    const finding = () => {
        userFinding();
    }

    // Authentication
    const [passwordInputError, setPasswordInputError] = useState('');

    const [userAuthentication, isUserAuthenticationLoading, userAuthenticationError] = useReceipting(async () => {
        setPasswordInputError('');

        const response = await UserService.authentication(email, password);
        // console.log(userAuthenticationError)
        const data = response.data;
        console.log('USER AUTHENTICATION:')
        console.log(response)
        setUser(response.data.user);
        setIsAuth(true);
        console.log('LOGIN:')
        console.log(data);
        // window.location.reload();
        // const user = await UserService.getUser();
        // console.log('USER', user)

        // if (response.data.is_new_user === true) {
        //     YaMetrikaGoal({ goalName: "SUCCESS_REGISTRATION" });
        // }

        // if (response.status === 201) {
        // let email_verified_at = moment(data.email_verified_at).add(1, 'hour');

        // if (moment().isBefore(email_verified_at)) {
        //     YaMetrikaGoal("SUCCESS_REGISTRATION");
        // }
    })

    // useEffect(() => {
    //     alert(user.email)
    // }, [user])

    // Authentication
    const authentication = () => {
        userAuthentication();
    }


    useEffect(() => {
        console.log('userAuthenticationError')
        console.log(userAuthenticationError)

        if (userAuthenticationError.email !== undefined) {
            setPasswordInputError(userAuthenticationError.email[0]);
        }

        if (userAuthenticationError.password !== undefined) {
            setPasswordInputError(userAuthenticationError.password[0]);
        }
    }, [userAuthenticationError])

    // Handle login click
    const handleLoginClick = () => {
        // console.log(cookie.email)
        // console.log(email)
        cookie.email ? authentication() : finding();
    }

    // Handle login on enter press
    const [isEnterPressed, setIsEnterPressed] = useState(false);

    useEffect(() => {
        if (isAuthVisible && !isAuth) {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    setIsEnterPressed(true);
                }
            })
        }

        return () => {
            window.removeEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    setIsEnterPressed(true);
                }
            })
        }
    }, [isAuthVisible, isAuth])
    // }, [isAuthVisible, isAuth, email, password])

    useEffect(() => {
        if (isEnterPressed) {
            handleLoginClick();

            setIsEnterPressed(false);
        }
    }, [isEnterPressed])

    // Send new password
    const [newPasswordNotification, setNewPasswordNotification] = useState('');
    const [newPasswordSending, isNewPasswordSendingLoading, newPasswordSendingError] = useReceipting(async () => {
        if (!cookie.codeTime) {
            setCookie('codeTime', moment().add(passwordResendTime, 'second').unix());
            const csrf = await UserService.csrf();
            const newPassword = await UserService.sendNewPassword(email);
            if (newPassword.data.result === true) {
                setNewPasswordNotification(EmailNotifications.newPasswordSent)
            }
            console.log(newPassword);
        }
    })

    const sendNewPassword = () => {
        newPasswordSending();
    }

    //Email observe
    const changeEmail = (data) => {
        setEmail(data);
        setNewPasswordNotification('');
        if (cookie.email) {
            console.log('cookie email isset')
            removeCookie('email');
        }
    }

    // Password visibility observer
    const [passwordClasses, setPasswordClasses] = useState(['']);

    useEffect(() => {
        if (cookie.isCodeSent) console.log('code sent')
        if (cookie.codeTime) console.log(cookie.codeTime)
        if (cookie.email) {
            console.log(email);
            setPasswordClasses([]);
            setEmail(cookie.email);
        } else {
            setPasswordClasses([...passwordClasses, 'hidden'])
        }

    }, [cookie])

    // Time to send new code
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        let time = cookie.codeTime - moment().unix();
        const codeInterval = setInterval(() => {
            if (time >= 0) {
                setTimeLeft(time);
            }
        }, 1000)

        if (time < 0) {
            setTimeLeft(0);
            removeCookie('codeTime');
            clearInterval(codeInterval);
        }

        return () => {
            clearInterval(codeInterval);
        }
    }, [cookie.codeTime, timeLeft])

    // Gmail Auth
    const goGoogleAuth = () => {
        // window.location.href = "/user/google_auth";
        navigate("/user/google_auth");
    }

    // Yandex auth
    const yandexClientId = "dc2d93abc97a426a84f3d02f3d5f0012";
    // const yandexRedirectUrl = "https://testweb.hidden-burg.com:3000/user/yandex_auth"
    const yandexRedirectUrl = `https://${window.location.host}/user/yandex_auth`;
    // const yandexRedirectUrl = `https://hidden-burg.com/user/yandex_aut`
    // const yandexRedirectUrl = "https://testweb.hidden-burg.com/user/yandex_auth"
    const yandexAuth = () => {
        window.location.replace(`https://oauth.yandex.ru/authorize?response_type=code&client_id=${yandexClientId}&redirect_uri=${yandexRedirectUrl}`)
    }

    useEffect(() => {
        console.log('location.pathname')
        console.log(window.location.pathname)
        console.log('location.href')
        console.log(window.location.href)
        console.log('location.host')
        console.log(window.location.host)
        console.log('window.location.hostname')
        console.log(window.location.hostname)
    }, [])

    // Get wishlist
    useUserWishlist();

    return (
        <div
            className={authContainerFluidClasses.join(' ')}
            onClick={outsideAuthClick}
            ref={outsideAuth}
        >
            <div
                className={`roundblock ${classes.auth_container}`}
            >
                <div className={classes.close_row}>
                    <div
                        className={classes.close_button}
                        onClick={hiddenAuth}
                    >
                        <img
                            className={classes.close_img}
                            src={Close}
                            alt={'close'}
                        />
                    </div>
                </div>

                <div className={classes.auth_inner_container}>
                    <div className={classes.auth_title}>
                        Вход / Регистрация
                    </div>

                    <div className={classes.input_data_block}>
                        {/*<div className={classes.input_data_title}>*/}
                        {/*    Email*/}
                        {/*</div>*/}

                        <HbInput
                            type={'email'}
                            className={classes.input_data}
                            error={emailError}
                            mask={false}
                            getData={changeEmail}
                            // getData={(data) => setEmail(data)}
                            parentData={email}
                            placeholder={'Email'}
                        />

                        <div className={classes.email_error}>
                            {userFindingError.email}
                        </div>

                        <div
                            className={classes.success_registration}
                            ref={successRegistrationBlock}
                        >
                            Вы вошли!
                            {/* Спасибо за регистрацию! */}
                            <br />
                            Пароль у Вас на почте
                            {/* Выслали пароль Вам на почту! */}
                            <br />
                            Бронируйте впечатление!
                            <br />
                            <img
                                src={Success}
                                className={classes.success_img}
                            />
                            {/* Вы успешно зарегистрировались! Бронируйте впечатление😍👍 */}
                        </div>
                        {/*<div className={classes.email_error}>*/}
                        {/*    {emailError}*/}
                        {/*</div>*/}
                    </div>

                    <div className={passwordClasses.join(' ')}>
                        {/*<div className={classes.input_data_title}>*/}
                        {/*    Пароль*/}
                        {/*</div>*/}

                        <HbInput
                            type={'password'}
                            className={classes.input_data}
                            error={passwordInputError}
                            // error={passwordError}
                            mask={false}
                            getData={(data) => setPassword(data)}
                            parentData={password}
                            placeholder={'Пароль'}
                            pattern={[0 - 9]}
                            inputMode={'numeric'}
                        />

                        {/* <div className={classes.email_error}>
                            {userAuthenticationError.email}
                        </div>

                        <div className={classes.email_error}>
                            {userAuthenticationError.password}
                        </div> */}

                        <div className={classes.new_password_sent}>
                            {newPasswordNotification}
                        </div>
                    </div>

                    <div
                        className={classes.buttons_row}
                        ref={buttonsRow}
                    >
                        <HbButton
                            className={classes.hb_button}
                            color={'pink'}
                            isLoading={isUserFindingLoading || isUserAuthenticationLoading}
                            onClick={handleLoginClick}
                        // onKeyUp={handleLoginClick}
                        // onClick={cookie.email ? authentication : finding}
                        >
                            {cookie.email ? 'Войти' : 'Далее'}
                        </HbButton>

                        {
                            cookie.email
                                ?
                                <div
                                    className={
                                        cookie.codeTime
                                            ? [classes.new_password, classes.new_password_disabled].join(' ')
                                            : classes.new_password
                                    }
                                    onClick={sendNewPassword}

                                >
                                    Получить новый пароль
                                    <br />
                                    {timeLeft > 0 ? `через ${timeLeft}` : ''}
                                </div>
                                : ''
                        }
                    </div>

                    <div 
                        className={classes.or_block}
                        ref={orBlock}    
                    >
                        войти с помощью
                    </div>

                    <div 
                        className={classes.fast_auth_container}
                        ref={fastAuthContainer}
                    >
                        <div className={classes.vk_row}>
                            <a
                                className={classes.vk_auth}
                                href={`https://oauth.vk.com/authorize?client_id=51747710&redirect_uri=https%3A%2F%2F${window.location.hostname}%2Fuser%2Fvk_auth&display=page&scope=4194304&state=secret_state_code&response_type=code&v=5.131`}
                            // href={`https://oauth.vk.com/authorize?client_id=51747710&redirect_uri=https%3A%2F%2F${window.location.host}%2Fuser%2Fvk_auth&display=page&scope=4194304&state=secret_state_code&response_type=code&v=5.131`}
                            // href={'https://oauth.vk.com/authorize?client_id=51747710&redirect_uri=https%3A%2F%2Ftestweb.hidden-burg.com%2Fuser%2Fvk_auth&display=page&scope=4194304&state=secret_state_code&response_type=code&v=5.131'}
                            // href={'https://oauth.vk.com/authorize?client_id=51747710&redirect_uri=https%3A%2F%2Fhidden-burg.com%2Fuser%2Fvk_auth&display=page&scope=4194304&state=secret_state_code&response_type=code&v=5.131'}
                            >
                                <img
                                    src={VkIconSmall}
                                    alt={'VK'}
                                />
                            </a>
                            {/*<a href={'https://oauth.vk.com/authorize?client_id=51747710&redirect_uri=http%3A%2F%2F95.163.240.237%3A3000%2Fuser%2Fvk_auth&display=page&scope=4194304&state=secret_state_code&response_type=code&v=5.131'}>VK</a>*/}
                        </div>

                        {/*<div*/}
                        {/*    className={classes.google_row}*/}
                        {/*>*/}
                        {/*    /!*<GoogleLogin*!/*/}
                        {/*    <div*/}
                        {/*        onClick={goGoogleAuth}*/}
                        {/*    >*/}
                        {/*        Google*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div
                            className={classes.yandex_row}
                        >
                            <div
                                className={classes.yandex_auth}
                                onClick={yandexAuth}
                            >
                                <img
                                    src={YaIconSmall}
                                    alt={'Yandex'}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default UserAuth;