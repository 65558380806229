import React, { useEffect, useRef, useState } from 'react';
import classes from "./BookingBlock.module.scss";
import HbButton from "../../../UI/HbButton/HbButton";
import PinkDot from "../../../UI/PinkDot/PinkDot";
import YandexIcon from "../../../../images/icons/YandexIcon.png";
import YandexNavigatorLogo from "../../../../images/icons/YandexNavigatorLogo.png";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import RatingStarsRow from "../../common/RatingStarsRow/RatingStarsRow";
import HbCheckbox from "../../../UI/HbCheckbox/HbCheckbox";
import moment from "moment";
import { baseURL, httpAxios } from "../../../../API/HttpAxios";
import { BookingStatusInfos } from "../../../../enums/BookingStatusInfos";
import { ReviewStatusInfos } from "../../../../enums/ReviewStatusInfos";
import HbLikeIcon from "../../../UI/HbLikeIcon/HbLikeIcon";
import Close from "../../../../images/icons/Close.svg";
import { ImgStretchingFixedDiv } from 'helpers/ImgStretching';


const BookingBlock = ({ booking }) => {
    const hiddenInfoRef = useRef();
    const [isHiddenInfoVisible, setIsHiddenInfoVisible] = useState(false);
    const { status } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { ...outletContext } = useOutletContext();
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        let bookingNotification = outletContext.bookingNotifications.find(el => el.booking_id == booking.booking.id);
        if (bookingNotification !== undefined) {
            setNotificationCount(bookingNotification.unread_messages_count);
        }
    }, [outletContext.bookingNotifications])

    // Switch hidden info visibility
    const switchHiddenInfoVisibility = () => {
        setIsHiddenInfoVisible(!isHiddenInfoVisible);
    }
    const [openTicketBtnText, setOpenTicketBtnText] = useState("Открыть билет");

    useEffect(() => {
        if (isHiddenInfoVisible) {
            hiddenInfoRef.current.classList.add(classes.hidden_info_container_active);
            setOpenTicketBtnText("Свернуть билет");
        } else {
            hiddenInfoRef.current.classList.remove(classes.hidden_info_container_active);
            setOpenTicketBtnText("Открыть билет");
        }
    }, [isHiddenInfoVisible])

    // Set booking_id
    const setBookingId = () => {
        searchParams.set('booking_id', booking.booking.id);
        setSearchParams(searchParams);
    }


    //// REVIEW BLOCK ////
    // Add new photos
    const [reviewPhotos, setReviewPhotos] = useState([]);
    const handleClickAddPhotos = () => {
        document.getElementById(`input_photos_${booking.id}`).click();
    }

    const addPhotos = (e) => {
        const files = e.target.files;
        setReviewPhotos([...reviewPhotos, ...Array.from(files).map((file, index) => {
            return {
                file: file,
                img_path: URL.createObjectURL(file),
                img_order: reviewPhotos.length + index + 1,
                from_user: true
            }
        })])
        e.target.value = null;
    }

    // Delete photo
    const deletePhoto = (indexInList) => {
        setReviewPhotos(reviewPhotos.filter((photo, index) => {
            return index != indexInList;
        }))
    }

    // Render photos
    let photoBlocks = reviewPhotos.map((photo, index) => {
        return (
            <div
                className={classes.review_photo}
                key={`review_photo_${index}`}
            >
                <img
                    src={photo.from_user ? photo.img_path : baseURL + photo.img_path}
                    onLoad={ImgStretchingFixedDiv}
                />

                <div
                    className={classes.remove_review_photo_btn}
                    data-photo-index={index}
                    onClick={() => deletePhoto(index)}
                >
                    <img src={Close} alt="x" />
                </div>
            </div>
        );
    })

    // Save review
    const [isReviewVisible, setIsReviewVisible] = useState(false);
    const [reviewText, setReviewText] = useState("");
    const [reviewRating, setReviewRating] = useState(5);
    const [isReviewAnonymous, setIsReviewAnonymous] = useState(false);

    const [isReviewSaving, setIsReviewSaving] = useState(false);
    const [reviewError, setReviewError] = useState('');

    const saveReview = () => {
        setIsReviewSaving(true);
        setReviewError('');

        const formData = new FormData();
        reviewPhotos.forEach((img, index) => {
            formData.append('reviewPhotos[]', img.file);
        })

        formData.append('reviewData', JSON.stringify({
            booking_id: booking.booking.id,
            excursion_type_id: booking.booking.excursion_type_id,
            text: reviewText,
            rating: reviewRating,
            is_anonymous: isReviewAnonymous
        }))


        httpAxios.post('/api/review/client/save_review', formData)
        .then((response) => {
            if (response.status === 201) {
                // booking.review = {
                //
                // }
                window.location.reload();
            }
            console.log(response)
        }).catch(e => {
            setReviewError(e.response.data.message);
        }).finally(() => {
            setIsReviewSaving(false);
        })
    }

    // useEffect(() => {
    //     console.log(reviewRating)
    // }, [reviewRating])

    // Like block
    const [likeClick, setLikeClick] = useState(false);
    const handleLikeClick = (event) => {
        event.stopPropagation();
        setLikeClick(true);
    }

    // Render photos
    let savedPhotos = [];
    
    if (booking.review) {
        savedPhotos = booking.review.review_imgs.map((photo, index) => {
            return (
                <div
                    className={classes.review_photo}
                    key={`saved_photo_${index}`}
                >
                    <img
                        src={baseURL + photo.preview_img_src}
                        onLoad={ImgStretchingFixedDiv}
                    />
                </div>
            );
        })
    }


    return (
        <div className={classes.container_fluid}>
            {/* Booking block */}
            <div className={classes.booking_block}>
                <div className={classes.experience_img_column}>
                    <img
                        src={baseURL + booking.first_photo}
                        className={classes.experience_img}
                    />
                </div>

                <div className={classes.booking_main_info_column}>
                    <div className={classes.experience_name_like_container}>
                        <div className={classes.experience_name}>
                            {booking.excursion_title}
                        </div>

                        <div
                            className={classes.add_to_favorites_btn}
                            onClick={handleLikeClick}
                        >
                            <HbLikeIcon
                                likeClick={likeClick}
                                setLikeClick={setLikeClick}
                                excursion_id={booking.excursion_id}
                            />
                            {/*<LikeOutline/>*/}
                        </div>
                    </div>

                    <div className={classes.rating_place_row}>
                        <div className={classes.rating_block}>
                            <PinkDot />

                            <div className={classes.rating}>
                                {booking.excursion_rating.toFixed(1)}
                            </div>

                            <div className={classes.review_count}>
                                ({booking.excursion_review_count})
                            </div>
                        </div>

                        <div className={classes.place_block}>
                            {booking.city_name}
                        </div>
                    </div>

                    <div className={classes.datetime_block}>
                        {moment(`${booking.booking.date} ${booking.booking.time}`).format("DD MMMM HH:mm")}
                    </div>

                    <div className={classes.experience_type}>
                        {booking.excursion_type_name}
                    </div>
                </div>


                {/*<div>*/}
                {/*    /!* Empty block *!/*/}
                {/*</div>*/}

                <div className={classes.booking_info_column}>
                    {/* Hidden info block */}
                    <div
                        className={classes.hidden_info_container}
                        ref={hiddenInfoRef}
                    >
                        <div className={classes.organizer_container}>
                            <div className={classes.organizer_img_container}>
                                <img
                                    src={baseURL + booking.organizer.avatar_path}
                                    alt=""
                                    className={classes.organizer_img}
                                />
                            </div>

                            <div className={classes.organizer_info_block}>
                                <div className={classes.organizer_type}>
                                    Организатор:
                                </div>

                                <div className={classes.organizer_name_phone_container}>
                                    <div className={classes.organizer_name}>
                                        {booking.organizer.name}
                                    </div>

                                    <div>
                                        &#x2022;
                                    </div>

                                    <div className={classes.organizer_phone}>
                                        {booking.organizer.phone_number}
                                    </div>
                                </div>

                                <div className={classes.rating_block}>
                                    <PinkDot />

                                    <div className={classes.rating}>
                                        {booking.organizer.rating.toFixed(1)}
                                    </div>

                                    {/*<div className={classes.review_count}>*/}
                                    {/*    (1000)*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <div className={classes.experience_info_container}>
                            {/* Important info block */}
                            <div className={classes.important_info_container}>
                                <span className={classes.important_info_title}>
                                    Информация от организатора:
                                </span>

                                <span className={classes.important_info}>
                                    {booking.after_booking_important && booking.after_booking_important}
                                </span>
                            </div>

                            {/* Meeting place block */}
                            <div className={classes.meeting_place_container}>
                                <span className={classes.meeting_place_title}>
                                    Место встречи:
                                </span>

                                <span className={classes.meeting_place}>
                                    {booking.start_address}
                                </span>
                            </div>

                            {/* Navigation buttons block */}
                            <div className={classes.meeting_place_nav_container}>
                                <a
                                    href={booking.link_yandex_map}
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                    className={classes.meeting_place_map_btn}
                                >
                                    <img height={"24px"} src={YandexIcon} alt="" />
                                </a>

                                <a
                                    href={booking.link_yandex_navigator}
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                    className={classes.meeting_place_nav_btn}
                                >
                                    <img height={"22px"} src={YandexNavigatorLogo} alt="" />
                                </a>
                            </div>

                            {/* Tickets block */}
                            <div className={classes.tickets_container}>
                                {
                                    booking.booking.members.map((member, index) => {
                                        return (
                                            <div
                                                className={classes.ticket_row}
                                                key={index}
                                            >
                                                {member.count} x {member.member_name}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* Payment block */}
                            <div className={classes.payment_container}>
                                <div className={classes.prepayment_block}>
                                    Предоплата на сайте: {booking.booking.prepayment_amount}
                                    {/*₽*/}
                                </div>

                                <div className={classes.postpayment_block}>
                                    Доплата на месте: {booking.booking.total_amount - booking.booking.prepayment_amount}
                                    {/*₽*/}
                                </div>
                            </div>

                            {/* Print ticket block */}
                            {/*<div className={classes.print_ticket_container}>*/}
                            {/*    распечатать билет*/}
                            {/*</div>*/}

                            <div className={classes.support_info_container}>
                                Поддержка и условия отмены:<br />
                                В описании экскурсии указано минимальное время для гарантированного возврата средств, эту информацию вы можете найти в разделе Важная инофрмация. Если у вас возникнут вопросы пишите нам в Поддержку или звоните по номеру 8 (812) 220-60-98
                            </div>
                        </div>
                    </div>

                    {/* Manipulate btns block */}
                    <div className={classes.manipulate_btns_block}>
                        {
                            status === "upcoming" &&
                            <HbButton
                                color={"trans_blue"}
                                className={classes.open_ticket_btn}
                                onClick={switchHiddenInfoVisibility}
                            >
                                {openTicketBtnText}
                            </HbButton>
                        }

                        {
                            status === "past" &&
                            <HbButton
                                color={"green"}
                                className={classes.show_review_area_btn}
                                onClick={() => setIsReviewVisible(!isReviewVisible)}
                            >
                                {
                                    booking.review === null
                                        ? "Оценить"
                                        : "Посмотреть отзыв"
                                }
                            </HbButton>
                        }

                        <HbButton
                            color={"trans_blue"}
                            className={classes.send_message_btn}
                            onClick={setBookingId}
                            notificationCount={notificationCount}
                        >
                            Написать организатору
                        </HbButton>
                    </div>

                    <div className={classes.booking_status}>
                        {BookingStatusInfos[booking.booking.status]}
                    </div>
                </div>
            </div>

            {
                status === "past" && isReviewVisible &&
                <div className={classes.review_container}>
                    {
                        booking.review === null
                            ?
                            <div className={classes.review_block}>
                                <div className={classes.rating_container}>
                                    <div className={classes.rating_title}>
                                        Ваша оценка:
                                    </div>

                                    <RatingStarsRow
                                        selectedRating={reviewRating}
                                        onStarClick={setReviewRating}
                                    />

                                    <div className={classes.rating}>
                                        {Number(reviewRating).toFixed(1)}
                                    </div>
                                </div>

                                <textarea
                                    className={classes.review_textarea}
                                    placeholder={"Поделитесь впечатлениями?"}
                                    rows={7}
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                ></textarea>

                                {/* Load photos */}
                                <div className={classes.photos_container}>
                                    {/* <div className={classes.photos_btn}>
                                        Загрузить фотографии
                                    </div> */}
                                    <input
                                        // data-img_input-id={'img'}
                                        className={"hidden"}
                                        id={`input_photos_${booking.id}`}
                                        onChange={addPhotos}
                                        type={"file"}
                                        accept="image/*"
                                        multiple
                                    />

                                    <div className={classes.photos_btn_block}>
                                        <HbButton
                                            className={classes.photos_btn}
                                            color='trans_blue'
                                            onClick={handleClickAddPhotos}
                                        >
                                            Загрузить фотографии
                                        </HbButton>

                                        <div className={classes.photos_limit}>
                                            Максимум 10 фотографий
                                        </div>
                                    </div>


                                    <div className={classes.review_photos_block}>
                                        {photoBlocks}
                                    </div>
                                </div>

                                <div className={classes.review_error}>
                                    {reviewError}
                                </div>

                                <div className={classes.rating_manipulate_btns_container}>
                                    <HbButton
                                        className={classes.send_review_btn}
                                        onClick={saveReview}
                                        isLoading={isReviewSaving}
                                    >
                                        Отправить
                                    </HbButton>

                                    <HbCheckbox
                                        text={"Анонимно"}
                                        isChecked={isReviewAnonymous}
                                        setIsChecked={setIsReviewAnonymous}
                                    />
                                </div>
                            </div>
                            :
                            <div className={classes.review_block}>
                                <div className={classes.rating_container}>
                                    <div className={classes.rating_title}>
                                        Ваша оценка:
                                    </div>

                                    <RatingStarsRow
                                        selectedRating={booking.review.rating}
                                    />

                                    <div className={classes.rating}>
                                        {Number(booking.review.rating).toFixed(1)}
                                    </div>
                                </div>

                                <div>
                                    {booking.review.body}
                                </div>

                                <div className={classes.review_photos_block}>
                                    {savedPhotos}
                                </div>

                                <div className={classes.booking_status}>
                                    {ReviewStatusInfos[booking.review.status]}
                                </div>
                            </div>
                    }

                </div>
            }
        </div>

    );
};

export default BookingBlock;