import React, {useContext, useEffect, useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import classes from "./MainLayout.module.scss";
import UserDropdownMenu from "../../UI/UserDropdownMenu/UserDropdownMenu";
import Logo from "../../UI/Logo/Logo";
import SearchLine from "../../UI/SearchLine/SearchLine";
import MobileNavigationBar from "../MobileNavigationBar/MobileNavigationBar";
import FooterLayout from "../FooterLayout/FooterLayout";

const MainLayout = () => {

    const [cityName, setCityName] = useState('');
    const [dates, setDates] = useState({date_start: '', date_end: ''});
    const [visibleLocations, setVisibleLocations] = useState(false);
    const [visibleDates, setVisibleDates] = useState(false);
    const [cities, setCities] = useState([]);

    const selectCityName = (cityName) => {
        setCityName(cityName);
    }

    const selectDates = (dates) => {
        setDates(dates);
    }

    const allCities = (cities) => {
        setCities(cities);
    }

    return (
        <div>
            {/* Header */}
            <div className={classes.navbar_place}>

            </div>

            <div className={`container_fluid ${classes.navbar_fluid}`}>
                <nav className={`container ${classes.navbar}`}>
                    <Logo/>
                    <SearchLine
                        selectCity={selectCityName}
                        selectDates={selectDates}
                        visibleLocations={visibleLocations}
                        visibleDates={visibleDates}
                        cities={allCities}
                    />
                    <UserDropdownMenu/>
                </nav>
            </div>

            <MobileNavigationBar/>

            <Outlet context={[
                cityName, setCityName,
                dates, setDates,
                visibleLocations, setVisibleLocations,
                visibleDates, setVisibleDates,
                cities, setCities
            ]}/>

            {/* Footer */}
            <FooterLayout/>
        </div>
    );
};

export default MainLayout;