import React, {useState} from 'react';
import classes from './ExperienceControlUnit.module.scss';
import ExperienceEditArea from "../ExperienceEditArea/ExperienceEditArea";
import ExperienceInfoBlock from "../ExperienceInfoBlock/ExperienceInfoBlock";
import experienceEditAreaManipulateBtns from "../../../../enums/ExperienceEditAreaManipulateBtns";

const ExperienceControlUnit = ({excursion}) => {
    const [isExperienceInfoBlockVisible, setIsExperienceInfoBlockVisible] = useState(true);
    const [isExperienceEditAreaVisible, setIsExperienceEditAreaVisible] = useState(false);

    // const switchExperienceContent = () => {
    //     setIsExperienceInfoBlockVisible(!isExperienceInfoBlockVisible);
    //     setIsExperienceEditAreaVisible(!isExperienceEditAreaVisible);
    // }

    function switchExperienceContent() {
        setIsExperienceInfoBlockVisible(!isExperienceInfoBlockVisible);
        setIsExperienceEditAreaVisible(!isExperienceEditAreaVisible);
    }

    return (
        <div className={classes.experience_block}>
            <ExperienceInfoBlock
                isVisible={isExperienceInfoBlockVisible}
                excursion={excursion}
                switchExperienceContent={switchExperienceContent}
            />

            <ExperienceEditArea
                excursion={excursion}
                isVisible={isExperienceEditAreaVisible}
                // manipulateBtns={
                //     [
                //         experienceEditAreaManipulateBtns.PUBLISH,
                //         experienceEditAreaManipulateBtns.CANCEL_CHANGES
                //     ]
                // }
                // switchExperienceContent={switchExperienceContent}
                setIsExperienceInfoBlockVisible={setIsExperienceInfoBlockVisible}
                setIsExperienceEditAreaVisible={setIsExperienceEditAreaVisible}
            />
        </div>
    );
};

export default ExperienceControlUnit;