import React, {useEffect, useRef, useState} from 'react';
import classes from './FavoritesPage.module.scss';
import ExcursionPreview from "../../../components/UI/ExcursionPreview/ExcursionPreview";
import {httpAxios} from "../../../API/HttpAxios";
import {useUserWishlist} from "../../../hooks/useUserWishlist";
import {useObserverBlockEntry} from "../../../hooks/useObserverBlockEntry";

const FavoritesPage = () => {
    const [isExcursionsLoading, setIsExcursionsLoading] = useState(true);
    const [excursions, setExcursions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useUserWishlist();

    const getFavoriteExcursions = () => {
        setIsExcursionsLoading(true);

        httpAxios.get('/api/excursions/get_favorite_excursions',
            {
                params: {
                    page: currentPage
                }
            })
            .then((response) => {
                console.log(response)
                setExcursions([...excursions, ...response.data.data]);
                setLastPage(response.data.last_page);
            }).finally(() => setIsExcursionsLoading(false));
    }

    useEffect(() => {
        getFavoriteExcursions();
        console.log('currentPage')
        console.log(currentPage)
    }, [currentPage])

    const loadMoreBlock = useRef();
    const isLoadMore = useObserverBlockEntry(loadMoreBlock);

    useEffect(() => {
        if (isLoadMore && currentPage < lastPage && !isExcursionsLoading)
        {
            setCurrentPage(currentPage + 1);
        }
    }, [isLoadMore, currentPage, lastPage, isExcursionsLoading])

    const renderExcursions = excursions.map((excursion, index) => {
        return (
             <ExcursionPreview
                key={excursion.excursion_id}
                excursion_id={excursion.excursion_id}
                imgs={excursion.imgs}
                rating={excursion.rating}
                reviews_count={excursion.reviews_count}
                city_name={excursion.city_name}
                title={excursion.title}
                duration={excursion.duration}
                type={excursion.type}
                initial_price={excursion.initial_price}
                discount_price={excursion.discount_price}
                discount_value={excursion.discount_value}
            />
        )
    })

    // Get wishlist
    useUserWishlist();

    return (
        <div className={[classes.container_fluid, "temp_scrollbar"].join(" ")}>
            <div className={"excursions_grid"}>
                {renderExcursions}
            </div>

            <div ref={loadMoreBlock} style={{height: "10px"}}></div>
        </div>
    );
};

export default FavoritesPage;