import {useState} from "react";

export const useFetching = (callback) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const fetching = async (...args) => {
        try {
            setIsLoading(true)
            await callback(...args);
        } catch (e) {
            console.log(e);
            console.log('krya')

            if (e.response.data.message !== '') {
                setError(e.response.data.message);
            }else {
                setError(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return [fetching, isLoading, error];
}