export async function getExcursionCount() {
    const width = window.innerWidth;
    // console.log(width)
    let excursionCount = 0;

    if (width > 1880) {
        excursionCount = 16;
    } else if (width > 1640) {
        excursionCount = 13;
    } else if (width > 1250) {
        excursionCount = 10;
    } else if (width > 950) {
        excursionCount = 7;
    } else if (width > 600) {
        excursionCount = 4; // maybe 6
    } else {
        excursionCount = 4; // maybe 5
    }

    // console.log(excursionCount)
    return excursionCount;
}