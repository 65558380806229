export const telMask = (data) => {
    let x = data.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

    return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : ''));
}

// export const fullTelMask = (data) => {
//     // const regex = /(\+\d)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/gm;
//     //
//     // const subst = `$1($2)-$3-$4-$5`;
//     // const result = data.replace(regex, subst);
//     // // const result = data.replace(/\D/g, "").replace(regex, subst);
//     //
//     // console.log('Substitution result: ', result);
//     //
//     // return result;
//
//     let x = data.replace(/(?![0-9+])./gm, '').match(/([+7])(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
//
//     console.log(x)
//     return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : ''));
// }

export const showTelMask = (data) => {
    let x = data.match(/(\+7)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)

    return (x[1] + ' (' + x[2] + ') ' + x[3] + '-' + x[4] + '-' + x[5]);
}