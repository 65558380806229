export const PaymentMethods = [
    {
        type: 'full_payment',
        title: 'Полная оплата онлайн',
        description: '',
        percent: 1
    },

    {
        type: 'prepayment',
        title: 'Предоплата онлайн 20%',
        description: 'остальное на месте гиду картой или наличными',
        percent: 0.2
    },

    {
        type: 'postpaid',
        title: 'Оплата на месте',
        description: 'на месте гиду картой или наличными',
        percent: 0
    }
];