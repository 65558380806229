import React, {useState} from 'react';
import classes from './OrganizerReviewControlUnit.module.scss';
import {ReactComponent as RatingStar} from '../../../../images/icons/RatingStar.svg';
import moment from "moment";
import {ReviewStatusInfos} from "../../../../enums/ReviewStatusInfos";
import {httpAxios} from "../../../../API/HttpAxios";

const OrganizerReviewControlUnit = ({review, setReviews}) => {

    // Rating
    const starsList = [];
    for (let i = 1; i <= review.rating; i++) {
        starsList.push(<RatingStar key={i} className={classes.rating_star_icon_active}/>);
    }

    const [isResponseTextareaVisible, setIsResponseTextareaVisible] = useState(false);
    const [organizerResponse, setOrganizerResponse] = useState('');

    // Publish review answer
    const [isReviewResponseSending, setIsReviewResponseSending] = useState(false);
    const [sendReviewResponseError, setSendReviewResponseError] = useState('');
    const sendReviewResponse = () => {
        setIsReviewResponseSending(true);
        setSendReviewResponseError('');

        httpAxios.post('/api/review/save_review_response', {
            review_id: review.id,
            body: organizerResponse,
        }).then(response => {
            if (response.status === 201) {
                // window.location.reload();
                setReviews(reviews => reviews.filter(rev => {
                    return rev.id !== review.id;
                }))

                setOrganizerResponse('');
                setIsResponseTextareaVisible(false);
            }
        }).catch(error => {
            console.log(error)
            setSendReviewResponseError(error.response.data.message);
        }).finally(() => setIsReviewResponseSending(false));
    }

    return (
        <div className={classes.review_container}>
            <div className={classes.client_review_container}>
                <div className={classes.experience_title_date_row}>
                    <div className={classes.experience_title}>
                        {review.excursion.title}
                    </div>

                    <div className={classes.experience_date}>
                        {moment(review.updated_at).format('DD.MM.YYYY')}
                    </div>
                </div>

                <div className={classes.rating_row}>
                    {starsList}
                </div>

                <div className={classes.client_name_row}>
                    {review.is_anonymously ? 'Аноним' : review.user.name}
                </div>

                <div className={classes.review_text_block}>
                    {review.body}
                </div>

                {/*<div className={classes.review_status_row}>*/}
                {/*    {ReviewStatusInfos[review.status]}*/}
                {/*</div>*/}

                {
                    review.review_response === null && !isResponseTextareaVisible &&
                    <div className={classes.client_manipulate_btns_row}>
                        <div
                            className={["temp_btn", "temp_btn_success"].join(" ")}
                            onClick={() => setIsResponseTextareaVisible(true)}

                        >
                            Ответить
                        </div>
                    </div>
                }
            </div>

            {
                review.review_response !== null
                ?
                    <div className={classes.organizer_answer_container}>
                        <div className={classes.organizer_answer_top_row}>
                            <div className={classes.organizer_name_block}>
                                Вы ответили на отзыв
                            </div>

                            <div className={classes.organizer_answer_date}>
                                {moment(review.review_response.updated_at).format('DD.MM.YYYY')}
                            </div>
                        </div>

                        <div className={classes.organizer_answer_block}>
                            {review.review_response.body}
                        </div>

                        <div className={classes.answer_status_row}>
                            {ReviewStatusInfos[review.review_response.status]}
                        </div>
                    </div>
                :
                    isResponseTextareaVisible &&
                    <div className={classes.organizer_answer_container}>
                        <textarea
                            rows={15}
                            className={classes.organizer_answer_textarea}
                            value={organizerResponse}
                            onChange={e => setOrganizerResponse(e.target.value)}
                        ></textarea>

                        <div className={classes.organizer_answer_error}>
                            {sendReviewResponseError}
                        </div>

                        <div className={classes.organizer_manipulate_btns_row}>
                            <div
                                className={["temp_btn", "temp_btn_success"].join(" ")}
                                onClick={sendReviewResponse}
                            >
                                Ответить
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default OrganizerReviewControlUnit;