import React, { useContext, useEffect, useState } from 'react';
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetching } from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import AuthContext from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { YaMetrikaGoal } from 'helpers/YaMetrikaGoal';

const UserVkAuthPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();


    // const [vkCode, setVkCode] = useState("");
    // console.log(searchParams.get('auth'))
    const [vkAuthing, isVkAuthing, vKAuthingError] = useFetching(async () => {
        const response = await UserService.vkAuth(searchParams.get("code"));
        console.log(response);
        setUser(response.data.user);
        setIsAuth(true);

        // YaMetrika
        if (response.data.is_new_user) {
            YaMetrikaGoal({ goalName: 'SUCCESS_REGISTRATION' })
        }

        // Change to previous page from cookie
        if (cookies.excursion_back_route) {
            navigate(cookies.last_page);
            // navigate(decodeURI(cookies.last_page));
        } else {
            navigate('/');
        }
    })

    useEffect(() => {
        if (searchParams.get('code') !== "") {
            // setVkCode(searchParams.get("code"));
            vkAuthing();
        }
    }, [searchParams])

    // useEffect(() => {
    //     if (vkCode !== "") {
    //         vkAuthing()
    //     }
    // }, [vkCode])

    return (
        <div>
            <PageLoader />
        </div>
    );
};

export default UserVkAuthPage;