import React, {useEffect, useState} from 'react';
import classes from "./ExcursionCalendarCard.module.scss";
import moment from "moment";
import ExcursionTypeNames from "../../../../enums/ExcursionTypeNames";
import {httpAxios} from "../../../../API/HttpAxios";

const ExcursionCalendarCard = ({excursion, datetime}) => {
    const [bookingBlockClasses, setBookingBlockClasses] = useState([classes.booking_block]);

    const [isBookingOpen, setIsBookingOpen] = useState(excursion.is_booking_open);

    useEffect(() => {
        let temp_classes = [classes.booking_block];

        if (excursion.booking_count === 0) {
            temp_classes.push(classes.empty_booking_block);
        }

        if (isBookingOpen === false) {
            temp_classes.push(classes.closed_booking_block);
        }

        setBookingBlockClasses(temp_classes);
    }, [excursion, isBookingOpen])

    // Change booking availability
    const [isChangingAvailability, setIsChangingAvailability] = useState(false);
    const changeBookingAvailability = () => {
        setIsChangingAvailability(true);

        httpAxios.post('/api/booking/change_availability', {
            'excursion_type_id': excursion.excursion_type_id,
            'date': datetime.format('YYYY-MM-DD'),
            'time': datetime.format('HH:mm:ss'),
        }).then(response => {
            console.log(response);
            setIsBookingOpen(!isBookingOpen);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsChangingAvailability(false);
        })

    }

    return (
        <div className={bookingBlockClasses.join(" ")}>
            <div className={classes.booking_time_type_container}>
                <div>
                    {datetime.format('HH:mm')} <u>{excursion.duration}</u>
                </div>

                <div>
                    {ExcursionTypeNames[excursion.type]}
                </div>
            </div>

            <div className={classes.experience_name}>
                {excursion.title}
            </div>

            <div className={classes.member_count}>
                {excursion.booking_count} <span className={classes.full_member_count}>из {excursion.booking_limit} чел.</span>
            </div>

            <div className={classes.manipulate_btn_container}>
                <div
                    className={classes.manipulate_btn}
                    onClick={isChangingAvailability === false ? changeBookingAvailability : null}
                >
                    {isBookingOpen ? 'Закрыть запись' : 'Открыть запись'}
                </div>
            </div>
        </div>
    );
};

export default ExcursionCalendarCard;