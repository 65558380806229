import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from "./BookingsPage.module.scss";
import {useNavigate, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import TopMenuBtns from "../../../components/dashboard/client/TopMenuBtns/TopMenuBtns";
import BookingBlock from "../../../components/dashboard/client/BookingBlock/BookingBlock";
import {httpAxios} from "../../../API/HttpAxios";
import {useObserverBlockEntry} from "../../../hooks/useObserverBlockEntry";
import ClientChatContainer from "../../../components/dashboard/client/ClientChatContainer/ClientChatContainer";
import {useUserWishlist} from "../../../hooks/useUserWishlist";
import moment from "moment";

const BookingsPage = () => {
    const {status} = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {...outletContext} = useOutletContext();

    useUserWishlist();

    useEffect(() => {
        !status && navigate('/client/dashboard/bookings/upcoming');
    }, [status])

    // Get bookings
    const [bookings, setBookings] = useState([]);
    const [isBookingsLoading, setIsBookingsLoading] = useState(true);
    // const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [requestParams, setRequestParams] = useState({
        status: status,
        currentPage: null
    })

    useEffect(() => {
        setBookings([]);
        setRequestParams({
            status: status,
            currentPage: 1
        })
    }, [status])

    const getBookings = () => {
        setIsBookingsLoading(true);

        httpAxios.get('/api/booking/client/get_bookings', {
            params: {
                page: requestParams.currentPage,
                status: requestParams.status
            }
        }).then((response) => {
            // if (requestParams.currentPage === 1) {
            //     setBookings(response.data.data);
            // }else {
                setBookings([...bookings, ...response.data.data]);
            // }

            setLastPage(response.data.last_page);
            console.log(response)
        }).finally(() => {
            setIsBookingsLoading(false);
        })
    }

    useEffect(() => {
        if (requestParams.currentPage !== null) {
            getBookings();
        }
    }, [requestParams])

    const renderBookingBlocks = bookings.map((booking, index) => {
        return (
            <BookingBlock
                key={index}
                booking={booking}
            />
        )
    })

    // Load more bookings by scroll
    const observerBlock = useRef();
    const isObserverBlockEnter = useObserverBlockEntry(observerBlock);

    useEffect(() => {
        if (isObserverBlockEnter && requestParams.currentPage < lastPage) setRequestParams({...requestParams, currentPage: requestParams.currentPage + 1})
    }, [isObserverBlockEnter, requestParams, lastPage])

    // CHAT

    // Get notification count for top menu
    const [upcomingBookingNotifications, setUpcomingBookingNotifications] = useState(0);
    const [pastBookingNotifications, setPastBookingNotifications] = useState(0);

    useEffect(() => {
        let tempUpcomingBookingNotifications = 0;
        let tempPastBookingNotifications = 0;
        let now = moment();

        for (const bookingNotification of outletContext.bookingNotifications) {
            if (bookingNotification.unread_messages_count > 0) {
                if (moment(`${bookingNotification.date} ${bookingNotification.time}`).isAfter(now)) {
                    tempUpcomingBookingNotifications++;
                }else {
                    tempPastBookingNotifications++;
                }
            }
        }

        setUpcomingBookingNotifications(tempUpcomingBookingNotifications);
        setPastBookingNotifications(tempPastBookingNotifications);
    }, [outletContext])


    return (
        <div className={classes.bookings_container_fluid}>
            <div className={classes.top_menu_container}>
                <TopMenuBtns
                    menuBtns={[
                        {
                            name: "Предстоящие",
                            path: "/client/dashboard/bookings/upcoming",
                            notificationCount: upcomingBookingNotifications
                        },

                        {
                            name: "Прошедшие",
                            path: "/client/dashboard/bookings/past",
                            notificationCount: pastBookingNotifications
                        },
                    ]}
                />
            </div>

            <div className={classes.bookings_container}>
                <div className={[classes.bookings_block, "temp_scrollbar"].join(" ")}>
                    {renderBookingBlocks}

                    <div ref={observerBlock} style={{height: "10px"}}></div>
                </div>

                {
                    searchParams.has("booking_id") &&
                    <div className={classes.chat_block}>


                        <ClientChatContainer
                            bookingId={searchParams.get("booking_id")}
                            bookings={bookings}
                            setBookings={setBookings}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default BookingsPage;