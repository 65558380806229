import React, { useEffect, useState } from 'react';
import classes from "./ExcursionImgCarousel.module.scss";
import { baseURL } from "../../../API/HttpAxios";
import ArrowLeft from "../../../images/icons/ArrowLeft.svg";
import ArrowRight from "../../../images/icons/ArrowRight.svg";
import { useSearchParams } from 'react-router-dom';
import { PhotoGallery } from 'components/UI/PhotoGallery/PhotoGallery';

const ExcursionImgCarousel = ({ ...props }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Add src to img
    const setImgSrc = (id) => {
        const imgs = document.getElementById(id).children;

        for (let i = 0; i < imgs.length; i++) {
            let imgElement = imgs[i].firstChild;
            if (imgElement.getAttribute('src') === '') {
                imgElement.setAttribute('src', imgElement.getAttribute('data-src'));
            }
        }
    }
    // Show img after loading
    const handleLoadImg = (event) => {
        event.currentTarget.parentElement.classList.replace(classes.img_block_preload, classes.img_block_loaded);

        event.currentTarget.classList.remove('hidden');

        // Set img size
        let imgWidth = event.currentTarget.clientWidth;
        let imgHeight = event.currentTarget.clientHeight;

        if (imgHeight < imgWidth) {
            event.currentTarget.classList.add(classes.img_height_100);
        } else {
            event.currentTarget.classList.add(classes.img_width_100);
        }

        let parentHeight = event.currentTarget.parentElement.clientHeight;
        let renderedImgHeight = event.currentTarget.clientHeight;

        if (parentHeight > renderedImgHeight) {
            event.currentTarget.classList.remove(classes.img_width_100);
            event.currentTarget.classList.add(classes.img_height_100);
        }

        const nextParent = event.currentTarget.parentElement.nextElementSibling;
        if (nextParent !== null) {
            const nextImg = nextParent.firstChild;
            nextImg.setAttribute('src', nextImg.getAttribute('data-src'));
        }
    }

    // Func for hidden arrows
    const hiddenArrows = () => {
        const scrollLeft = document.getElementById(`img_carousel_row`).scrollLeft;

        if (scrollLeft === 0) {
            document.getElementById(`left_arrow_block`).classList.add('hidden');
        } else {
            document.getElementById(`left_arrow_block`).classList.remove('hidden');
        }

        if (scrollLeft === document.getElementById(`img_carousel_row`).scrollWidth - document.getElementById(`img_carousel_row`).clientWidth) {
            document.getElementById(`right_arrow_block`).classList.add('hidden');
        } else {
            document.getElementById(`right_arrow_block`).classList.remove('hidden');
        }
    }

    // Func for scrolling by arrows
    const scrollByArrows = (event) => {
        const scrollLeft = document.getElementById(`img_carousel_row`).scrollLeft;
        const imgs = document.querySelectorAll('div[data-block="excursion_img_block"]');

        for (const img of imgs) {
            if (scrollLeft >= img.offsetLeft - 10 && scrollLeft <= img.offsetLeft + img.clientWidth) {
                if (event.currentTarget.id === `right_arrow_block` && img.nextElementSibling !== null) {
                    document.getElementById(`img_carousel_row`).scrollLeft = img.nextElementSibling.offsetLeft;
                }

                if (event.currentTarget.id === `left_arrow_block` && img.previousElementSibling !== null) {
                    document.getElementById(`img_carousel_row`).scrollLeft = img.previousElementSibling.offsetLeft;
                }
            }
        }

    }

    useEffect(() => {
        hiddenArrows();
    }, [])

    // Change img number
    const [imgNumber, setImgNumber] = useState(1);
    const changeImgNumber = (event) => {
        let carouselScrollLeft = event.currentTarget.scrollLeft;
        let carouselChildren = event.currentTarget.children;

        for (let i = 0; i < carouselChildren.length; i++) {
            if (carouselScrollLeft > carouselChildren[i].offsetLeft - 30 && carouselScrollLeft < carouselChildren[i].offsetLeft + 30) {
                setImgNumber(i + 1);

                break;
            }
        }

        hiddenArrows();
    }

    // Excursion imgs gallery
    const [isExcursionGalleryOpen, setIsExcursionGalleryOpen] = useState(false);

    useEffect(() => {
        if (searchParams.has('excursion_img_src')) {
            setIsExcursionGalleryOpen(true);
        }
    }, [searchParams])

    // Click on img
    const clickOnReviewImg = (e) => {
        e.stopPropagation();
        setSearchParams({ ...searchParams, 'excursion_img_src': e.currentTarget.getAttribute('data-img-src') });
    }

    // Excursion imgs
    const [excursionImgSrcs, setExcursionImgSrcs] = useState([]);

    useEffect(() => {
        let imgSrcs = [];

        if (props.excursion !== undefined) {
            props.excursion.imgs.forEach(element => {
                imgSrcs.push(baseURL + element.img_path);
            });

            setExcursionImgSrcs(imgSrcs);
        }
    }, [props.excursion])

    return (
        <div className={[`container`, classes.container].join(' ')}>
            <div className={classes.img_carousel_block}>
                <div
                    id={`left_arrow_block`}
                    className={classes.carousel_arrow_column}
                    onClick={scrollByArrows}
                >
                    <div
                        className={[classes.carousel_arrow_block, classes.carousel_arrow_left_block].join(' ')}
                    >
                        <img
                            src={ArrowLeft}
                            height={15}
                            alt={'left'}
                        />
                    </div>
                </div>

                <div
                    id={`right_arrow_block`}
                    className={[classes.carousel_arrow_column, classes.carousel_arrow_right_column].join(" ")}
                    onClick={scrollByArrows}
                >
                    <div
                        className={[classes.carousel_arrow_block, classes.carousel_arrow_right_block].join(' ')}
                    >
                        <img
                            src={ArrowRight}
                            height={15}
                            alt={'right'}
                        />
                    </div>
                </div>

                <div
                    id={`img_carousel_row`}
                    className={classes.img_carousel_row}
                    onScroll={changeImgNumber}
                >
                    {
                        props.excursion.imgs.map((img, index) => {
                            return (
                                <div
                                    data-name={'img_block'}
                                    className={classes.img_block_preload}
                                    key={`img_block_${img.id}`}
                                    data-img-src={img.img_path.match(/\/([\w\d]+)\.webp$/)[1]}
                                    onClick={clickOnReviewImg}
                                    data-block={'excursion_img_block'}
                                >
                                    <img
                                        onLoad={handleLoadImg}
                                        className={`roundblock ${classes.img} hidden`}
                                        src={index < 1 ? baseURL + img.img_path : ''}
                                        data-src={baseURL + img.img_path}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Gallery block */}
            {
                isExcursionGalleryOpen
                    ?
                    <PhotoGallery
                        setIsVisible={setIsExcursionGalleryOpen}
                        galleryName={'excursion'}
                        photos={excursionImgSrcs}
                    />
                    :
                    null
            }

            <div className={classes.img_number}>{imgNumber}/{props.excursion.imgs.length}</div>
        </div>
    );
};

export default ExcursionImgCarousel;