export const ImgStretching2 = (ref) => {
    console.log(ref.current);
    
    const img = new Image();
    img.src = ref.current.getAttribute('data-src');

    if (img.height < img.width) {
        img.style.height = '100%';
        img.style.width = 'auto';
    }else {
        img.style.width = '100%';
        img.style.height = 'auto';
    }

    img.style.objectFit = 'contain';

    // ref.current.classList.remove('hidden');
    ref.current.appendChild(img);
}