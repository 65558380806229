import React, {useEffect, useRef, useState} from 'react';
import classes from "./ProfilesPage.module.scss";
import SearchInput from "../../../components/dashboard/common/SearchInput/SearchInput";
import {ReactComponent as RatingStarFilled} from "../../../images/icons/RatingStarFilled.svg";
import {baseURL, httpAxios} from "../../../API/HttpAxios";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import {useNavigate, useParams} from "react-router-dom";
import ProfileEditBlock from "../../../components/dashboard/manager/ProfileEditBlock/ProfileEditBlock";
import {ImgStretchingFixedDiv} from "../../../helpers/ImgStretching";

const ProfilesPage = () => {
    const {organizerId} = useParams();
    const navigate = useNavigate();
    const [organizers, setOrganizers] = useState([])

    // Get organizer infos
    // const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isOrganizersLoading, setIsOrganizersLoading] = useState(true);
    // const [userId, setUserId] = useState('');
    const [getOrganizersParams, setGetOrganizersParams] = useState({
        currentPage: 1,
        userId: '',
    })
    const [userId, setUserId] = useState('');

    useEffect(() => {
        let userIdTimeout = setTimeout(() => {
            setGetOrganizersParams({
                currentPage: 1,
                userId: userId
            })
        }, 1000)

        return () => {
            clearTimeout(userIdTimeout);
        }
    }, [userId])

    const getOrganizers = async () => {
        setIsOrganizersLoading(true);

        httpAxios.get('/api/user/get_organizers', {
            params: {
                page: getOrganizersParams.currentPage,
                user_id: getOrganizersParams.userId
            }
        }).then(response => {
            console.log(response)
            if (getOrganizersParams.userId !== '' || getOrganizersParams.currentPage === 1) {
                setOrganizers(response.data.data)
            }else {
                setOrganizers(([...organizers, ...response.data.data]));
            }
            setLastPage(response.data.last_page);
        }).finally(() => {
            setIsOrganizersLoading(false);
        })
    }

    useEffect(() => {
        getOrganizers();
    }, [getOrganizersParams])

    useEffect(() => {
        console.log(organizers)
    }, [organizers])

    const [organizer, setOrganizer] = useState(undefined);

    useEffect(() => {
        let foundOrganizer = organizers.find(organizer => organizer.id === +organizerId);

        setOrganizer(foundOrganizer);
    }, [organizerId, organizers])

    // Render organizer profiles
    const organizersList = organizers.map((organizer, index) => {
        return (
            <div
                key={index}
                className={classes.profile_block}
            >
                <div className={classes.profile_img_container}>
                    <div className={classes.profile_img_block}>
                        {
                            organizer.avatar_path !== null &&
                            <img
                                className={'center_overflow_img'}
                                src={organizer.avatar_path.includes('http') ? organizer.avatar_path : baseURL + organizer.avatar_path}
                                onLoad={ImgStretchingFixedDiv}
                            />
                        }
                    </div>
                </div>

                <div className={classes.profile_info_block}>
                    <div className={classes.profile_top_row}>
                        <div className={classes.profile_name}>
                            {organizer.name}
                        </div>

                        <div className={classes.profile_rating}>
                            {organizer.rating}
                        </div>

                        <RatingStarFilled/>
                    </div>

                    <div className={classes.profile_middle_row}>
                        <div className={classes.organizer_company}>
                            {organizer.organizer_info && organizer.organizer_info.company_name}
                        </div>

                        <div className={classes.organizer_inn}>
                            ИНН {organizer.organizer_info && organizer.organizer_info.inn}
                        </div>
                    </div>

                    <div className={classes.profile_bottom_row}>
                        <div className={classes.account_status}>
                            {
                                organizer.organizer_info && organizer.organizer_info.account_verified_at === null ? "✖️️аккаунт не подтвержден" : "✔️аккаунт подтвержден"
                            }
                        </div>

                        <div
                            className={"temp_btn"}
                            onClick={() => navigate(`/manager/dashboard/profiles/${organizer.id}`)}
                        >
                            Редактировать
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    
    // Update organizers after send to server
    const updateOrganizers = (profile) => {
        setOrganizers(organizers.map(organizer => {
            if (organizer.id === profile.id) {
                return profile;
            }else {
                return organizer;
            }
        }));
    }

    // Load more organizers on scroll
    const observerBlock = useRef();
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('INNN')
                setIsObserverBlockEnter(true);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    useEffect(() => {
        if (isObserverBlockEnter && getOrganizersParams.currentPage < lastPage && isOrganizersLoading === false) {
            setGetOrganizersParams({
                ...getOrganizersParams, currentPage: getOrganizersParams.currentPage + 1});
            setIsObserverBlockEnter(false);
        }
    }, [isObserverBlockEnter, getOrganizersParams, isOrganizersLoading]);

    return (
        <div className={classes.container}>
            <div className={classes.filters_container}>
                <SearchInput
                    placeholder={"Введите ID организатора"}
                    className={classes.search_input}
                    value={userId}
                    onChange={setUserId}
                />
            </div>

            <div className={classes.profiles_container}>
                <div className={classes.profiles_block}>
                    {organizersList}

                    {
                        organizers.length === 0 &&
                        "Организатор не найден!"
                    }

                    <div ref={observerBlock} style={{height: '10px'}}></div>

                    {isOrganizersLoading && <PageLoader/>}
                </div>

                {/* Edit Profile */}
                {
                    organizer !== undefined &&
                    <ProfileEditBlock
                        profile={organizer}
                        setProfile={setOrganizer}
                        updateProfiles={updateOrganizers}
                    />
                }
            </div>
        </div>
    );
};

export default ProfilesPage;