import React, { useCallback, useContext, useEffect, useState } from 'react';
import classes from './ExcursionBookingUserData.module.scss';
import HbInput from "../../UI/HbInput/HbInput";
import { showTelMask, telMask } from "../../../helpers/Masks";
import { useCookies } from "react-cookie";
import AuthContext from "../../../context/AuthContext";
import UserService from "../../../API/UserService";
import KeyHole from '../../../images/icons/KeyHole.svg';
import KeyHolePreloader from 'components/UI/KeyHolePreloader/KeyHolePreloader';
import { YaMetrikaGoal } from 'helpers/YaMetrikaGoal';


const ExcursionBookingUserData = () => {
    const { isAuth, setIsAuth, user, setUser } = useContext(AuthContext);
    const [cookie, setCookie, removeCookie] = useCookies(['user_name', 'user_phone', 'user_email']);
    const [name, setName] = useState(user.name !== null ? user.name : '');
    const [phone, setPhone] = useState(user.phone_number !== null ? showTelMask(user.phone_number).replace(/\+7 /g, '') : '');

    const [contactsSavingResult, setContactsSavingResult] = useState('');
    const [isContactsSavingLoading, setContactsSavingLoading] = useState(false);
    const [contactsSavingError, setContactsSavingError] = useState(null);

    const contactsSaving = useCallback(async () => {
        try {
            setContactsSavingResult('');
            setContactsSavingLoading(true);
            setContactsSavingError(null);

            const response = await UserService.saveUserContacts(name, '+7' + phone.replace(/\D/g, ''));

            console.log(response)
            if (response.status === 200) {
                if (user.phone_number === null) {
                    YaMetrikaGoal({ goalName: 'SUCCESS_SAVE_CONTACTS' });
                }

                setUser({
                    ...response.data,
                    contacts_error: {
                        name: false,
                        phone: false
                    }
                });
                setContactsSavingResult('Сохранено!');
            }

            // if (typeof window !== 'undefined' && typeof window.ym !== undefined && response.status === 200) {
            //     window.ym(process.env.REACT_APP_YANDEX_METRIKA_ID, 'reachGoal', 'SUCCESS_SAVE_CONTACTS');
            //     console.log('YA SUCCESS_SAVE_CONTACTS');
            // }
            // if (response.status === 200) {
            //     YaMetrikaGoal({ goalName: 'SUCCESS_SAVE_CONTACTS' });
            // }
        } catch (e) {
            let response = e.response;
            setContactsSavingError(response.data.errors);
            console.log(e);

            if (response.status === 422 || response.status === 403) {
                console.log(response.status);

                setUser({
                    ...user,
                    contacts_error: {
                        name: response.data.errors.name ? true : false,
                        phone: response.data.errors.phone ? true : false
                    }
                });
            }
        } finally {
            setContactsSavingLoading(false);
        }
    }, [name, phone, user]);

    // })

    // Set cookies and save user data
    useEffect(() => {
        if (name !== cookie.user_name) setCookie('user_name', name);
        if (phone !== cookie.user_phone) setCookie('user_phone', phone);
        // if (email !== '' && email !== cookie.user_email) setCookie('user_email', email);

        setCookie(cookie);

        // Save user data
        document.getElementById('input_name').style.borderColor = '';
        document.getElementById('input_phone').style.borderColor = '';
        document.getElementById('contacts_null').style.display = 'none';

        setContactsSavingResult('');

        setUser({
            ...user,
            contacts_error: {
                name: (name.length >= 2 && name.length <= 25) ? false : true,
                phone: phone.replace(/\D/g, '').match(/\d{10}/g) ? false : true
            }
        });

        if (phone.replace(/\D/g, '').match(/\d{10}/g)) {
            // Settimeout for save user data
            var contactsSavingTimeout = setTimeout(() => {
                contactsSaving();
            }, 700)
            console.log('save contacts');
        }

        return () => {
            if (contactsSavingTimeout !== undefined) {
                clearTimeout(contactsSavingTimeout);
            }
        }
    }, [name, phone])

    return (
        <div className={classes.input_data_container}>
            <div className={classes.input_data_block}>
                <div className={classes.input_data_title}>
                    Имя
                </div>

                <HbInput
                    id={'input_name'}
                    type={'text'}
                    className={classes.input_data}
                    error={contactsSavingError !== null && contactsSavingError.name ? contactsSavingError.name : ''}
                    minLength={2}
                    maxLength={25}
                    mask={false}
                    getData={(data) => setName(data)}
                    parentData={name}
                />
            </div>

            <div className={classes.input_data_block}>
                <div className={classes.input_data_title}>
                    Телефон
                </div>

                <div className={classes.input_data}>
                    <HbInput
                        id={'input_phone'}
                        type={'tel'}
                        className={classes.input_data_phone}
                        error={contactsSavingError !== null && contactsSavingError.phone ? contactsSavingError.phone : ''}
                        mask={telMask}
                        getData={(data) => setPhone(data)}
                        parentData={phone}
                    />

                    {isContactsSavingLoading &&
                        <KeyHolePreloader
                            isLoading={true}
                            className={classes.key_hole_preloader}
                        />
                    }
                </div>
            </div>

            <div
                id='contacts_null'
                className={classes.contacts_null}
            >
                Введите контактные данные, чтобы гид с Вами связался в случае необходимости!
            </div>

            <div className={classes.contacts_saving_result}>
                {contactsSavingResult}
            </div>
        </div>
    );
};

export default ExcursionBookingUserData;