import React from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const BitrixWidget = () => {
    let location = useLocation();

    // Bitrix contact button
    useEffect(() => {
        let bitrixButton = document.querySelector(".b24-widget-button-wrapper");
        if (bitrixButton) {
            if (location.pathname.includes('client') || location.pathname.includes('manager') || location.pathname.includes('organizer')) {
                document.querySelector(".b24-widget-button-wrapper").classList.add('hidden');
            }else {
                document.querySelector(".b24-widget-button-wrapper").classList.remove('hidden');
            }
        }
    }, [location.pathname])
}
