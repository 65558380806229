import React, {useEffect, useRef, useState} from 'react';

const ImgAutoSize = ({image}) => {
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const img = useRef();

    useEffect(() => {
        if (img.current.complete) setIsImgLoaded(true);
    }, [])

    useEffect(() => {
        if (isImgLoaded) {
            if (img.current.height < img.current.width) {
                img.current.style.height = '100%';
                img.current.style.width = 'auto';
            }else {
                img.current.style.width = '100%';
                img.current.style.height = 'auto';
            }

            img.current.classList.remove('hidden');
        };
    }, [isImgLoaded])

    return (
        <img
            onLoad={() => setIsImgLoaded(true)}
            className={'center_img hidden'}
            ref={img}
            src={image}
            alt={'image'}
        />
    );
};

export default ImgAutoSize;