import { useEffect, useRef, useState } from "react";

export const useObserverBlockEntry = (ref) => {
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
    }, []);

    useEffect(() => {
        if (!ref.current) return;

        const callback = function (entries) {
            if (entries[0].isIntersecting) {
                setIsObserverBlockEnter(true);
            } else {
                setIsObserverBlockEnter(false);
            }
        };

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(ref.current);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [ref]);

    return isObserverBlockEnter;



    // if (ref.current == null) return false;
    // const observerBlock = useRef();



    // const observer = useRef();
    // const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    // useEffect(() => {
    //     if (observer.current) observer.current.disconnect();

    //     let callback = function (entries, observer) {
    //         if (entries[0].isIntersecting) {
    //             console.log('INNN')
    //             setIsObserverBlockEnter(true);
    //         }
    //     }

    //     observer.current = new IntersectionObserver(callback);
    //     observer.current.observe(ref.current);
    // }, [])

    // useEffect(() => {
    //     if (isObserverBlockEnter) {
    //         setIsObserverBlockEnter(false);
    //     }
    // }, [isObserverBlockEnter])

    // return isObserverBlockEnter;
}