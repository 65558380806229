import React, {useContext, useEffect, useRef, useState} from 'react';
import NewChatBlock from "../../client/NewChatBlock/NewChatBlock";
import {useNavigate, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import {httpAxios} from "../../../../API/HttpAxios";
import LaravelEcho from "../../../../API/LaravelEcho";
import classes from "./WorkerChatContainer.module.scss";

const WorkerChatContainer = () => {
    const {...context} = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isNewMessagesRequest, setIsNewMessagesRequest] = useState(false);
    const [myNewMessage, setMyNewMessage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const bookingId = searchParams.get('chat');
    const {...outletContext} = useOutletContext();

    // Set current page
    useEffect(() => {
        if (bookingId !== undefined && bookingId !== null) {
            if (currentPage === 1) {
                getMessages(1);
            }else {
                setCurrentPage(1);
            }

            setMessageAsRead();

            outletContext.setBookingNotifications(bookingNotifications => {
                return bookingNotifications.map(bookingNotification => {
                    if (bookingNotification.booking_id == bookingId) {
                        return {
                            ...bookingNotification,
                            unread_messages_count: 0
                        }
                    }

                    return bookingNotification
                })
            })
        }
    }, [bookingId])

    // Get messages portion
    const [isMessagesLoading, setIsMessagesLoading] = useState(true);
    const getMessages = (page) => {
        setIsMessagesLoading(true);
        httpAxios.get(`/api/booking_message/get_messages`, {
            params: {
                booking_id: bookingId,
                page: page
            }
        }).then(response => {
            setCurrentPage(response.data.current_page);
            setLastPage(response.data.last_page);
            console.log(response)
            if (currentPage === 1) {
                setMessages(response.data.data.reverse());
            }else {
                setMessages([...response.data.data.reverse(), ...messages]);
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => setIsMessagesLoading(false))
    }

    useEffect(() => {
        if (currentPage !== null) {
            getMessages(currentPage);
        }
    }, [currentPage])

    // Handle new messages request
    useEffect(() => {
        if (isNewMessagesRequest && currentPage < lastPage && isMessagesLoading !== true) {
            setCurrentPage(currentPage => currentPage + 1);
            setIsNewMessagesRequest(false);
        }
    }, [isNewMessagesRequest, currentPage, isMessagesLoading])

    // Send new message function
    const sendNewMessage = (message) => {
        httpAxios.post('/api/booking_message/save_message', {
            booking_id: bookingId,
            message: message
        }, {headers: {'X-Socket-ID': LaravelEcho.socketId()}}).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    // Handle my new message
    useEffect(() => {
        if (myNewMessage !== null) {
            console.log('myNewMessage')
            console.log(myNewMessage)
            setMessages([...messages, myNewMessage]);
            sendNewMessage(myNewMessage);
            setMyNewMessage(null);
        }
    }, [myNewMessage]);

    // Set message as read
    const setMessageAsRead = () => {
        httpAxios.post('/api/booking_message/set_message_as_read', {
            booking_id: bookingId,
        })
    }

    // Listen new message
    const [newMessage, setNewMessage] = useState(null);
    useEffect(() => {
        if (bookingId === undefined || bookingId === null) return;

        LaravelEcho.private(`booking.messages.${bookingId}`)
            .listen('.booking.message.send', (e) => {
                console.log(e);
                setNewMessage(e.message)

                setMessageAsRead();
            });

        console.log('CONNECT CHANNEL')

        return () => {
            LaravelEcho.leave(`booking.messages.${bookingId}`);
            console.log('LEAVE CHANNEL')
        }
    }, [bookingId]);

    useEffect(() => {
        if (newMessage !== null) {
            setMessages([...messages, newMessage]);

            outletContext.setBookingNotifications(bookingNotifications => {
                return bookingNotifications.map(bookingNotification => {
                    if (bookingNotification.booking_id == bookingId) {
                        return {
                            ...bookingNotification,
                            unread_messages_count: 0
                        }
                    }

                    return bookingNotification
                })
            })

            setNewMessage(null);
        }
    }, [newMessage, outletContext])

    // Get is manager called
    const [isManagerCalled, setIsManagerCalled] = useState(false);

    const getIsManagerCalled = () => {
        httpAxios.get('/api/booking/get_is_manager_called', {
            params: {
                booking_id: bookingId
            }
        }).then(response => {
            setIsManagerCalled(response.data.is_manager_called);
            console.log(response)
        })
    }

    // Set manager not called
    const setManagerNotCalled = () => {
        httpAxios.post('/api/booking/set_manager_not_called', {
            booking_id: bookingId
        }).then(response => {
            if (response.status === 200) {
                setIsManagerCalled(false);

                let byeMessage = {
                    body: "Вопрос решен! Спасибо, что обратились к нам!",
                    booking_id: bookingId,
                    by_user_id: context.user.id,
                    user: context.user
                }

                setMessages([...messages, byeMessage])

                sendNewMessage(byeMessage)

                // window.location.reload();
            }
        })
    }

    // Call manager

    const callManager = () => {
        httpAxios.post('/api/booking/call_manager', {
            booking_id: bookingId
        }).then(response => {
            if (response.status === 200) {
                let callManagerMessage = {
                    body: "Прошу менеджера присоединиться к нам и помочь с решением вопроса!",
                    booking_id: bookingId,
                    by_user_id: context.user.id,
                    user: context.user
                }

                setMessages([...messages, callManagerMessage])

                sendNewMessage(callManagerMessage)
            }
        })
    }

    useEffect(() => {
        if (bookingId === undefined || bookingId === null) return;
        getIsManagerCalled();
    }, [bookingId])
    
    return (
        <div className={classes.chat_block_container}>
            {/* Change is manager called by manager */}
            {
                context.user.role === 'manager' && outletContext.bookingNotifications.find(el => el.booking_id == bookingId) !== undefined &&
                <div className={classes.call_manager_block}>
                    <div
                        className={['temp_btn', 'temp_btn_success'].join(' ')}
                        onClick={setManagerNotCalled}
                    >
                        Вопрос решен!
                    </div>
                </div>
            }

            {/* Change is manager called by organizer */}
            {
               context.user.role === 'organizer' &&
                <div className={classes.call_manager_block}>
                    {
                        outletContext.bookingNotifications.find(el => el.booking_id == bookingId) !== undefined && outletContext.bookingNotifications.find(el => el.booking_id == bookingId).is_manager_called == true
                            ?
                            <div
                                className={['temp_btn', 'temp_btn_success'].join(' ')}
                            >
                                Менеджер вызван!
                            </div>
                        :
                            <div
                                className={['temp_btn', 'temp_btn_success'].join(' ')}
                                onClick={callManager}
                            >
                                Позвать менеджера!
                            </div>
                    }


                </div>
            }


            <NewChatBlock
                messages={messages}
                setIsNewMessageRequest={setIsNewMessagesRequest}
                setMyNewMessage={setMyNewMessage}
            />
        </div>
    );
};

export default WorkerChatContainer;