import React, {useEffect, useRef, useState} from 'react';
import classes from "./SideMenu.module.scss";
import {Link, useLocation, useMatches, useParams, useSearchParams} from "react-router-dom";
import UserMenuPoints from "../../../../enums/UserMenuPoints";
import Hamburger from "../../../../images/icons/Hamburger.svg";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const SideMenu = ({role, bookingNotificationCount = 0, excursionNotificationCount = 0, reviewNotificationCount = 0, supportNotificationCount = 0}) => {
    const location = useLocation();
    const matches = useMatches();
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const hamburger = useRef(null);
    const menuContainer = useOutsideClick(() => setIsMenuVisible(false));
    // const menuContainer = useRef(null);

    // Manager menu
    const managerMenuPoints = UserMenuPoints.MANAGER;

    // Organizer menu
    const organizerMenuPoints = UserMenuPoints.ORGANIZER;

    // Client menu
    const clientMenuPoints = UserMenuPoints.CLIENT;

    const [activeMenuPoint, setActiveMenuPoint] = useState('');

    // Render menu points
    const renderMenuPoints = (menuPoints) => {
        return menuPoints.map((menuPoint) => {
            let notificationCount = 0;

            switch (menuPoint.alias) {
                case "bookings":
                    notificationCount = bookingNotificationCount;
                    break;

                case "experiences":
                    notificationCount = excursionNotificationCount;
                    break;

                case "reviews":
                    notificationCount = reviewNotificationCount;
                    break;

                case "support":
                    notificationCount = supportNotificationCount;
                    break;

                default:
                    break;
            }

            return (
                <Link
                    to={menuPoint.to}
                    className={classes.menu_row}
                    data-main-path={menuPoint["data-main-path"]}
                    key={menuPoint.to}
                    data-alias={menuPoint.alias}
                    onClick={() =>setIsMenuVisible(false)}
                >
                    <div className={activeMenuPoint === menuPoint.alias ? classes.menu_row_icon + ' ' + classes.menu_row_icon_active : classes.menu_row_icon}>
                        <img
                            src={
                            activeMenuPoint === menuPoint.alias
                            ?
                                menuPoint.active_icon_src
                            :
                                menuPoint.icon_src
                        }
                            // src={messageIcon}
                        />

                        {
                            notificationCount > 0 &&
                            <div
                                className={classes.notification_block}
                            >
                                {notificationCount}
                            </div>
                        }
                    </div>

                    <div className={classes.menu_row_title}>
                        {menuPoint.title}
                    </div>


                </Link>
            )
        })
    }

    let menuPoints;

    switch (role) {
        case "manager":
            menuPoints = renderMenuPoints(managerMenuPoints);
            break;

        case "organizer":
            menuPoints = renderMenuPoints(organizerMenuPoints);
            break;

        case "client":
            menuPoints = renderMenuPoints(clientMenuPoints);
            break;
    }


    useEffect(() => {
        const menuRows = document.getElementsByClassName(classes.menu_row);

        Array.prototype.forEach.call(menuRows, (menuRow) => {
            if (location.pathname.includes(menuRow.getAttribute('data-main-path'))) {
                setActiveMenuPoint(menuRow.getAttribute('data-alias'));
                // menuRow.classList.add(classes.menu_row_active);
            }
            // else {
            //     menuRow.classList.remove(classes.menu_row_active);
            // }
        });
    }, [location])

    // Switch menu visibility
    const switchMenuVisibility = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    useEffect(() => {
        if (isMenuVisible) {
            hamburger.current.classList.add(classes.hamburger_block_active);
            menuContainer.current.classList.add(classes.container_active);
        } else {
            hamburger.current.classList.remove(classes.hamburger_block_active);
            menuContainer.current.classList.remove(classes.container_active);
        }

    }, [isMenuVisible])

    return (
        <div className={classes.place_area}>
            <div
                className={classes.container}
                ref={menuContainer}
            >
                {/*<div className={}>*/}
                {/*    */}
                {/*</div>*/}

                <div className={classes.menu_block}>
                    <div
                        className={classes.menu_row}
                        onClick={switchMenuVisibility}
                    >
                        <div
                            className={[classes.menu_row_icon, classes.hamburger_block].join(" ")}
                            ref={hamburger}
                        >
                            <img
                                src={Hamburger}
                                alt={'ham'}
                            />
                        </div>
                    </div>

                    {menuPoints}
                </div>
            </div>
        </div>
    );
};

export default SideMenu;