import React, {useEffect, useRef, useState} from 'react';
import classes from "./HbInput.module.scss";

const HbInput = ({id, type, className, error, minLength, maxLength, mask, getData, parentData, placeholder = '', pattern, inputMode}) => {
    const [inputClasses, setInputClasses] = useState([classes.hb_input, className]);
    const [data, setData] = useState('');
    const input = useRef();

    // Display errors
    // useEffect(() => {
    //     if (error !== '') {
    //         setInputClasses([...inputClasses, classes.hb_input_error]);
    //     }else {
    //         setInputClasses([classes.hb_input, className]);
    //     }
    // }, [error])

    // Use mask on input & change it
    const changeInput = () => {
        let inputData = input.current.value
        if (mask !== false) {
            inputData = mask(inputData);
        }

        setData(inputData);
        getData(inputData);
    }

    // Change input padding by type and display errors
    useEffect(() => {
        let tempInputClasses = [];

        if (error !== '') {
            tempInputClasses = [...inputClasses, classes.hb_input_error];
        } else {
            tempInputClasses = [classes.hb_input, className];
        }

        type === 'tel' ? tempInputClasses = [...tempInputClasses, classes.tel_input_padding] : tempInputClasses = [...tempInputClasses, classes.input_padding];


        setInputClasses(tempInputClasses);


        // type === 'tel' ? setInputClasses([...inputClasses, classes.tel_input_padding]) : setInputClasses([...inputClasses, classes.input_padding]);

        // if (error !== '') {
        //     setInputClasses([...inputClasses, classes.hb_input_error]);
        // } else {
        //     setInputClasses([classes.hb_input, className]);
        // }
    }, [type, error])

    return (
        <div className={classes.input_block}>
            <input
                id={id && id}
                ref={input}
                className={inputClasses.join(' ')}
                type={type}
                onChange={changeInput}
                value={parentData}
                minLength={minLength}
                maxLength={maxLength}
                placeholder={type === 'tel' ? '(___) ___-__-__' : placeholder}
                pattern={pattern ? pattern : null}
                inputMode={inputMode ? inputMode : null}
            />
            {
                type === 'tel'
                    ?
                    <div className={classes.tel_code}>
                        +7
                    </div>
                    : ''
            }
            <div className={classes.hb_input_error_text}>
                {error}
            </div>
        </div>

    );
};

export default HbInput;