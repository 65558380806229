import HbInput from '../../../components/UI/HbInput/HbInput';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classes from './ExcursionBookingPromocode.module.scss';
import { httpAxios } from '../../../API/HttpAxios';
import KeyHole from '../../../images/icons/KeyHole.svg';


export const ExcursionBookingPromocode = ({excursionType, setExcursionType, promocode, setPromocode}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyHoleClasses, setKeyHoleClasses] = useState([classes.key_hole]);

    // Set promocode by search params
    useEffect(() => {
        if (searchParams.has('promocode') && searchParams.get('promocode') !== '' && searchParams.get('promocode') !== promocode) {
            setPromocode(searchParams.get('promocode'));
        };
    }, [searchParams])

    // Get promocode info
    const [isPromocodeLoading, setIsPromocodeLoading] = useState(false);
    const [promocodeError, setPromocodeError] = useState('');
    const [promocodeSuccess, setPromocodeSuccess] = useState('');

///////////////////////////////////////////////////////
    // useEffect(() => {
    //     console.log(`excursionType!!!!!`);
    //     console.log(excursionType);
    // }, [excursionType])

    const deletePromocodePrices = () => {
        let tempExcursionType = structuredClone(excursionType);

        delete tempExcursionType.promocode_prices;

        setExcursionType(tempExcursionType);
    }

    const getPromocodeInfo = () => {
        httpAxios.get('/api/excursions/get_promocode_info', {
            params: {
                promocode: promocode,
                excursion_type_id: excursionType.id
            }
        }).then((response) => {
            let data = response.data;

            if (data.error !== undefined) {
                setPromocodeError(data.error);
                deletePromocodePrices();
            }else {
                var tempExcursionType = structuredClone(excursionType);

                tempExcursionType.promocode_prices = [];

                tempExcursionType.current_prices.map((current_price) => {
                    let discount_price = tempExcursionType.discount_prices.find(el => el.member_type === current_price.member_type);

                    let preparedPrice;

                    if (discount_price === undefined) {
                        preparedPrice = structuredClone(current_price);
                    } else {
                        preparedPrice = structuredClone(discount_price);
                    }

                    preparedPrice.price = Math.floor(preparedPrice.price * (1 - data.value));
                    tempExcursionType.promocode_prices.push(preparedPrice);
                })

                setExcursionType(tempExcursionType);
                // setExcursion(excursion);
                setPromocodeSuccess('Промокод применён!✨');
            }

            console.log("PROMOCODE INFO: ", data);
            
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsPromocodeLoading(false);
        })
    }

    // Set search params by promocode and load to server
    useEffect(() => {
        setPromocodeError('');
        setPromocodeSuccess('');
            
        if (promocode !== '') {
            setIsPromocodeLoading(true);
        }else {
            setIsPromocodeLoading(false);
        }

        // Timer for load promocode
        let promocodeTimeout = setTimeout(() => {
            if (promocode !== '') {
                searchParams.set('promocode', promocode);

                // Load promocode info
                getPromocodeInfo();
            }

            if (promocode === '') {
                deletePromocodePrices();
                searchParams.delete('promocode');
            }
            
            setSearchParams(searchParams);
        }, 1500);

        return () => {
            clearTimeout(promocodeTimeout);
        }
    }, [promocode, excursionType.id])

    // Show loading
    useEffect(() => {
        if (!isPromocodeLoading) {
            setKeyHoleClasses([...keyHoleClasses, 'hidden']);
        }else {
            setKeyHoleClasses([classes.key_hole]);
        }
    }, [isPromocodeLoading])

    return (
        <div className={classes.promocode_container}>
            <div className={classes.promocode_input_container}>
                <HbInput
                    className={classes.promocode_input}
                    type="text"
                    parentData={promocode}
                    getData={setPromocode}
                    mask={false}
                    error={promocodeError}
                />

                <div className={keyHoleClasses.join(' ')}>
                    <img
                        className={classes.key_hole_img}
                        src={KeyHole}
                        alt={'loading'}
                    />
                </div>
            </div>

            <div className={classes.promocode_success}>
                {promocodeSuccess}
            </div>
        </div>
    )
}
