import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classes from "./ExcursionBookingResult.module.scss";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import HbButton from "../../UI/HbButton/HbButton";
import moment from "moment";
import { priceFormatting } from "../../../helpers/PriceHelper";
import AuthContext from "../../../context/AuthContext";
// import {paymentMethods} from "../../../lang/ru/paymentMethods";
import ExcursionService from "../../../API/ExcursionService";
import { YaMetrikaGoal } from 'helpers/YaMetrikaGoal';
import { useYandexSplit } from 'hooks/useYandexSplit';
import "./ExcursionBookingResult.css";

// const ExcursionBookingResult = ({members, date, time, excursionType}) => {
const ExcursionBookingResult = ({ members, excursionType, paymentMethods, promocode }) => {
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [memberCount, setMemberCount] = useState({});
    // const [members, setMembers] = useState([]);
    const [date, setDate] = useState(moment());
    const [time, setTime] = useState('');
    const navigate = useNavigate();

    // Initial states
    useEffect(() => {
        // let tempMembers = [];
        // // console.log(excursionType)
        // MemberTypes.map((memberEl) => {
        //     if (searchParams.has(memberEl.type) && searchParams.get(memberEl.type) > 0) {
        //         let price = excursionType.discount_prices.find(el => el.member_type === memberEl.type);
        //         if (price === undefined) {
        //             price = excursionType.current_prices.find(el => el.member_type === memberEl.type);
        //         }
        //         tempMembers.push({
        //             type: memberEl.type,
        //             name: memberEl.name,
        //             description: memberEl.description,
        //             count: searchParams.get(memberEl.type),
        //             pricePerPerson: price,
        //             priceSum: price.price * Number(searchParams.get(memberEl.type))
        //         });
        //     }
        // })
        //
        // setMembers(tempMembers);

        if (searchParams.has('date')) {
            setDate(moment(searchParams.get('date')));
        }

        if (searchParams.has('time')) {
            setTime(decodeURIComponent(searchParams.get('time')));
        }
    }, [searchParams])

    // Tickets list
    const getTicketsList = () => {
        let content = [];
        if (members.length !== 0) {
            members.map((member, index) => {
                if (member.count === 0) return;

                content.push(
                    <div
                        className={classes.ticket_row}
                        key={`ticket_row_${index}`}
                    >
                        <div className={classes.ticket_count}>
                            {member.count}
                        </div>

                        <div className={classes.x}>
                            x
                        </div>

                        <div className={classes.ticket_type}>
                            {member.name}
                        </div>

                        <div className={classes.ticket_price}>
                            {priceFormatting(member.priceSum)}
                            {/*<span className={"verdana_regular"}>₽</span>*/}
                        </div>
                    </div>
                )
            })
        }

        return content;
    }

    const ticketsList = useMemo(getTicketsList, [members]);

    // Payment list
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0].type);

    // Displaying payment method
    useEffect(() => {
        document.getElementById(`${paymentMethod}Selector`).classList.add(classes.payment_selector_dot_active);
    }, [])

    // Select method
    const selectPaymentMethod = (event) => {
        setPaymentMethod(event.currentTarget.getAttribute('data-method'));

        paymentMethods.map((methodEl) => {
            if (event.currentTarget.id === `${methodEl.type}Row`) {
                document.getElementById(`${methodEl.type}Selector`).classList.add(classes.payment_selector_dot_active);
                setPaymentMethod(methodEl.type);
            } else {
                document.getElementById(`${methodEl.type}Selector`).classList.remove(classes.payment_selector_dot_active);
            }
        })
    }

    // Payment list
    const getpaymentMethodsList = () => {
        let content = [];

        paymentMethods.map((methodEl, index) => {
            content.push(
                <div
                    className={classes.payment_row}
                    key={`method_row_${index}`}
                    id={`${methodEl.type}Row`}
                    onClick={selectPaymentMethod}
                    data-method={methodEl.type}
                >
                    <div className={classes.payment_selector_bg}>
                        <div
                            className={classes.payment_selector_dot}
                            id={`${methodEl.type}Selector`}
                        >

                        </div>
                    </div>

                    <div className={classes.payment_text}>
                        <div className={classes.payment_title}>
                            {methodEl.title}
                        </div>

                        <div className={classes.payment_description}>
                            {methodEl.description}
                        </div>
                    </div>
                </div>
            )
        })

        return content;
    }

    const paymentMethodsList = useMemo(getpaymentMethodsList, [paymentMethod]);

    // Total price
    const [totalSum, setTotalSum] = useState(0);
    const [totalPromocodeSum, setPromocodeSum] = useState(0);
    const [surcharge, setSurcharge] = useState(0);
    const [payNow, setPayNow] = useState(0);
    const [totalAmountClasses, setTotalAmountClasses] = useState([classes.total_amount]);

    useEffect(() => {
        // console.log('members');
        // console.log(members);

        let sum = 0;
        let promocodeSum = 0;

        members.map(el => {
            sum += el.priceSum;

            if (el.promocodePriceSum) {
                promocodeSum += el.promocodePriceSum;
            }
        })

        // console.log('sum')
        // console.log(sum)
        // console.log('promocodeSum')
        // console.log(promocodeSum)

        let paymentMethodObject = paymentMethods.find(el => el.type === paymentMethod);

        setPayNow(Math.floor((promocodeSum ? promocodeSum : sum) * paymentMethodObject.percent / 100));
        setSurcharge(Math.floor((promocodeSum ? promocodeSum : sum) - ((promocodeSum ? promocodeSum : sum) * paymentMethodObject.percent / 100)));

        setTotalSum(sum);
        setPromocodeSum(promocodeSum);

        if (promocodeSum === 0) {
            setTotalAmountClasses([classes.total_amount]);
        } else {
            setTotalAmountClasses([classes.total_amount, classes.total_amount_crossed]);
        }
    }, [paymentMethod, members])

    // Booking
    const [isExcursionBookingLoading, setIsExcursionBookingLoading] = useState(false);
    const [excursionBookingError, setExcursionBookingError] = useState('');

    const excursionBooking = useCallback(async () => {
        setIsExcursionBookingLoading(true);
        setExcursionBookingError('');

        const response = await ExcursionService.excursionBooking(excursionType.id, date.format('YYYY-MM-DD'), time, members, paymentMethod, promocode);

        if (response.data.hasOwnProperty('errors')) {
            setExcursionBookingError(response.data.errors);
        } else {
            try {
                YaMetrikaGoal({ goalName: 'SUCCESS_BOOKING' });
            } catch (e) {
                console.log(e);
            } finally {
                navigate(`/e/b/${response.data}`);
            }
        }

        setIsExcursionBookingLoading(false);


    }, [excursionType, date, time, members, paymentMethod, promocode])

    // const [excursionBooking, isExcursionBookingLoading, excursionBookingError] = useReceipting(async () => {
    //     const response = await ExcursionService.excursionBooking(excursionType.id, date.format('YYYY-MM-DD'), time, members, paymentMethod, promocode);

    //     navigate(`/e/b/${response.data}`);
    // });

    // useEffect(() => {
    //     console.log('promocode');
    //     console.log(promocode);
    // }, [promocode])
    // const yaMetrikaTryBooking = () => {
    //     if (typeof window !== 'undefined' && typeof window.ym !== undefined) {
    //         window.ym(process.env.REACT_APP_YANDEX_METRIKA_ID, 'reachGoal', 'TRY_BOOKING');
    //         console.log('YA TRY_BOOKING');
    //     }
    // }

    const bookingBtnClick = () => {
        if (isAuth) {
            document.getElementById('members_container').style.color = '';

            if (user.name === null || user.phone_number === null || user.contacts_error.name === true || user.contacts_error.phone === true) {
                let userDataOffset = document.getElementById('user_data_container').offsetTop;
                document.getElementsByTagName('html').item(0).style.scrollBehavior = 'smooth';
                window.scrollTo(0, userDataOffset - 100);
                if (user.name === null || user.contacts_error.name === true) {
                    document.getElementById('input_name').style.borderColor = 'rgb(204, 0, 153)';
                }

                if (user.phone_number === null || user.contacts_error.phone === true) {
                    document.getElementById('input_phone').style.borderColor = 'rgb(204, 0, 153)';
                }

                if (user.name === null || user.phone_number === null) {
                    document.getElementById('contacts_null').style.display = 'block';
                }

                YaMetrikaGoal({ goalName: 'TRY_BOOKING' });
            } else {
                let memberCount = 0;
                let priceSum = 0;

                for (const member of members) {
                    memberCount += member.count;
                    priceSum += member.priceSum;
                }

                if (memberCount === 0 || priceSum === 0) {
                    document.getElementById('members_container').style.color = 'red';
                    let userDataOffset = document.getElementById('members_container').offsetTop;
                    document.getElementsByTagName('html').item(0).style.scrollBehavior = 'smooth';
                    window.scrollTo(0, userDataOffset - 100);

                    YaMetrikaGoal({ goalName: 'TRY_BOOKING' });
                } else {
                    excursionBooking();
                }
            }
        } else {
            setIsAuthVisible(true);

            YaMetrikaGoal({ goalName: 'TRY_BOOKING' });
        }
    }

    const [isBtnDisabled, setBtnDisabled] = useState(true);

    // Add Yandex split widget
    const [YaPay, paymentData, setPaymentData] = useYandexSplit({ timeOut: 1000 });

    useEffect(() => {
        if (YaPay !== null) {
            setPaymentData({
                ...paymentData,
                totalAmount: payNow.toFixed(2)
            });
        }

        console.log('payNow');
        console.log(payNow.toFixed(2));
        
    }, [YaPay, payNow])

    // Обработчик на клик по кнопке
    // Функция должна возвращать промис которые резолвит ссылку на оплату полученную от бэкенда Яндекс Пэй
    // Подробнее про создание заказа: https://pay.yandex.ru/ru/docs/custom/backend/yandex-pay-api/order/merchant_v1_orders-post
    async function onPayButtonClick() {
        // Создание заказа...
        // и возврат URL на оплату вида 'https://pay.ya.ru/l/XXXXXX'
    }

    // Обработчик на ошибки при открытии формы оплаты
    function onFormOpenError(reason) {
        // Выводим информацию о недоступности оплаты в данный момент
        // и предлагаем пользователю другой способ оплаты.
        console.error(`Payment error — ${reason}`);
    }

    const [activePaymentSession, setActivePaymentSession] = useState(null);

    useEffect(() => {
        if (activePaymentSession !== null) {
            activePaymentSession.destroy();
        }

        if (paymentData !== null && paymentData.totalAmount !== null) {
            console.log('Creating session...');

            YaPay.createSession(paymentData, {
                onPayButtonClick: onPayButtonClick,
                onFormOpenError: onFormOpenError,
            })
                .then((paymentSession) => {
                    setActivePaymentSession(paymentSession);

                    paymentSession.mountWidget(
                        document.querySelector('#yandex_split_widget'),
                        {
                            widgetType: YaPay.WidgetType.Simple,
                            widgetTheme: YaPay.WidgetTheme.Dark,
                        }
                    )
                })
        }
    }, [paymentData])


    return (
        <div className={`roundblock_angle_lb ${classes.result_container_fluid}`}>
            <div className={classes.result_container}>
                <div className={classes.result_title}>
                    Билеты
                </div>

                <div className={classes.tickets_container}>
                    {ticketsList}
                </div>

                <div className={classes.date_time_container}>
                    <div className={classes.time}>
                        <div>Время:</div>

                        <div>{time.slice(0, 5)}</div>
                    </div>

                    <div className={classes.date}>
                        <div>Дата:</div>

                        <div>{date.format('DD.MM.YY')}</div>
                    </div>
                </div>

                <div className={classes.total_amount_container}>
                    <div
                        className={[classes.total_amount_block, totalPromocodeSum !== 0 ? classes.total_amount_block_three : classes.total_amount_block_two].join(" ")}
                    >
                        <span className={classes.total_amount_text}>Итого:</span>

                        <span className={totalAmountClasses.join(' ')}>{priceFormatting(totalSum)}</span>

                        {
                            totalPromocodeSum !== 0 &&
                            <span className={classes.total_amount_promocode}>{priceFormatting(totalPromocodeSum)}</span>
                        }
                    </div>

                    {
                        totalPromocodeSum !== 0 &&
                        <div className={classes.promocode_applied}>
                            Вы применили промокод!
                        </div>
                    }
                </div>

                <div className={classes.payment_methods_container}>
                    <div className={classes.payment_methods_title}>
                        Способ оплаты:
                    </div>

                    <div className={classes.payment_methods_block}>
                        {paymentMethodsList}
                    </div>
                </div>

                <div className={classes.total_container}>
                    {
                        surcharge !== 0 &&
                        <div className={classes.surcharge_block}>
                            <div className={classes.surcharge_text}>
                                Доплатить:
                            </div>

                            <div className={classes.surcharge_price}>
                                {priceFormatting(surcharge)}
                                {/*<span className={"verdana_regular"}>₽</span>*/}
                            </div>
                        </div>
                    }


                    <div className={classes.total_block}>
                        <div className={classes.total_text}>
                            К оплате сейчас:
                        </div>

                        <div className={classes.total_price}>
                            {priceFormatting(payNow)}
                            {/*<span className={"verdana_regular"}>₽</span>*/}
                        </div>
                    </div>
                </div>

                <div className={classes.yandex_split_widget}>
                    <div id="yandex_split_widget"></div>
                </div>

                <HbButton
                    className={classes.booking_button}
                    color={'green'}
                    onClick={bookingBtnClick}
                    isLoading={isExcursionBookingLoading}
                >
                    Забронировать
                </HbButton>

                <div className={`error_text ${classes.booking_error}`}>
                    {excursionBookingError}
                </div>
            </div>
        </div>
    );
};

export default ExcursionBookingResult;