import React from 'react';
import { useRouteError } from "react-router-dom";
import classes from "./ErrorPage.module.scss";
import HbButton from "../../components/UI/HbButton/HbButton";
import Error404 from '../../images/ErrorPage/Error404.png';

const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page" className={classes.container_fluid}>
            {/*<h1>Oops!11</h1>*/}
            {/*<p>Sorry, an unexpected error has occurred.</p>*/}
            {/*<p>*/}
            {/*    <i>{error.statusText || error.message}</i>*/}
            {/*</p>*/}

            <div className={classes.info_container}>
                <div className={classes.info_title}>
                    Ой! Кажется, вы
                    попали не туда...
                </div>

                <HbButton
                    color={'green'}
                    className={classes.info_button}
                    onClick={() => window.location.replace('/')}
                >
                    К впечатлениям!
                </HbButton>

                <div className={classes.info_text}>
                    Ошибка 404.
                    К сожалению страницы нет на нашем сайте.
                    Страница была удалена либо не существует.
                </div>
            </div>

            <img
                className={classes.img}
                src={Error404}
                alt={'404'}
            />
        </div>
    );
};

export default ErrorPage;