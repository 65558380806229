import React, {useEffect, useMemo, useState} from 'react';
import classes from './ExcursionBookingTimes.module.scss';
import moment from "moment";
import {useSearchParams} from "react-router-dom";

const ExcursionBookingTimes = ({schedule, date, excursionType, setTime, time}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        console.log(schedule[excursionType.type_name].find(el => el.time === decodeURIComponent(searchParams.get('time')) && el.date === date.format('YYYY-MM-DD')))


        // if (searchParams.has('time') && schedule[excursionType.type_name].find(el => el.time === decodeURIComponent(searchParams.get('time')) && el.date === date.format('YYYY-MM-DD')) !== undefined) {
        //     setTime(decodeURIComponent(searchParams.get('time')));
        // }else {
        //     // setTime(schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')).time);
        //     // setTime(schedule[excursionType.type_name][0].time);
        // }

        if (searchParams.has('time')) {
            if (schedule[excursionType.type_name].find(el => el.time === decodeURIComponent(searchParams.get('time')) && el.date === date.format('YYYY-MM-DD')) !== undefined) {
                setTime(decodeURIComponent(searchParams.get('time')));
            }else {
                setTime(schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')) !== undefined ? schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')).time : '');
            }
        }else {
            if (schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')) !== undefined) {
                setTime(schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')).time);
                // searchParams.set('time', schedule[excursionType.type_name].find(el => el.date === date.format('YYYY-MM-DD')).time);
                // setSearchParams(searchParams);
            }
        }

        console.log('excursionType')
        console.log(excursionType)
        console.log('schedule')
        console.log(schedule)
    }, [searchParams, excursionType, date])
    // }, [searchParams, excursionType, date])

    // useEffect(() => {
    //     if (time !== undefined && time !== '') {
    //         searchParams.set('time', time);
    //         setSearchParams(searchParams);
    //     }
    // }, [time])


    // Get times by date
    const filterTimes = () => {
        let filteredTimes = [];
        let filteredSchedule = [];

        if (schedule[excursionType.type_name].length !== 0) {
            let now = moment();
            schedule[excursionType.type_name].map((day, index_day) => {
                if (day.date === date.format('YYYY-MM-DD') && now.isBefore(moment(`${day.date} ${day.time}`))) {
                // if (day.date === date.format('YYYY-MM-DD') && day.time === date.format('HH:mm:ss') && now.isBefore(moment(`${day.date} ${day.time}`))) {
                    filteredSchedule.push(day);
                }
            })

            if (filteredSchedule.length !== 0) {
                filteredSchedule.map((day, index_day) => {
                    let timeClasses = [classes.time];

                    if (day.time === time) {
                        timeClasses.push(classes.selected_time)
                    }

                    filteredTimes.push(
                        <div
                            className={timeClasses.join(' ')}
                            key={`time_${index_day}`}
                            data-dayweek={day.day_week}
                            data-date={day.date}
                            data-time={day.time}
                            onClick={(event) => setTime(event.currentTarget.getAttribute('data-time'))}
                        >
                            {day.time.slice(0, 5)}
                        </div>
                    )
                })
            }else {
                filteredTimes.push(
                    <div
                        className={classes.no_times}
                        key={`time_666`}
                    >
                        Недоступно для бронирования! Выберите другую дату
                    </div>
                )
            }
        }else {
            filteredTimes.push(
                <div className={classes.no_times}>
                    Недоступно для бронирования! Выберите другую дату
                </div>
            )
        }

        return filteredTimes;
    }

    const times = useMemo(filterTimes, [excursionType, date, time]);



    return (
        <div className={classes.times_block}>
            {times}
        </div>
    );
};

export default ExcursionBookingTimes;