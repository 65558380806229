import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from './OrganizerSupportPage.module.scss';
import {httpAxios} from "../../../API/HttpAxios";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import LaravelEcho from "../../../API/LaravelEcho";
import NewChatBlock from "../../../components/dashboard/client/NewChatBlock/NewChatBlock";
import Close from "../../../images/icons/Close.svg";
import ArrowDrop from "../../../images/icons/ArrowDrop.svg";

const OrganizerSupportPage = () => {
    const {clientId} = useParams();
    const navigate = useNavigate();
    const {...context} = useContext(AuthContext);
    const {...outletContext} = useOutletContext();

    useEffect(() => {
        if (clientId === undefined || clientId !== context.user.id) {
            navigate(`/${context.user.role}/dashboard/support/${context.user.id}`);
        }
    }, [clientId])

    // Set message as read
    const setMessageAsRead = () => {
        httpAxios.post('/api/support/set_message_as_read', {
            client_id: clientId,
        }).then(response => {
            console.log(response)
            // outletContext.setSupportNotifications(supportNotifications => supportNotifications.filter(el => el.client_id != clientId));
            outletContext.setSupportNotifications(supportNotifications => {
                return supportNotifications.map(supportNotification => {
                    if (supportNotification.client_id == clientId) {
                        return {
                            ...supportNotification,
                            unread_message_count: 0
                        }
                    }

                    return supportNotification
                })
            })
        }).catch(error => {
            console.log(error)
        })
    }

    const [messages, setMessages] = useState([]);

    // Get previous messages
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isPreviousMessagesLoading, setIsPreviousMessagesLoading] = useState(true);
    const getPreviousMessages = () => {
        setIsPreviousMessagesLoading(true);

        httpAxios.get('/api/support/get_messages', {
            params: {
                client_id: clientId,
                page: currentPage
            }
        }).then(response => {
            setMessages([...response.data.data.reverse(), ...messages]);
            setLastPage(response.data.last_page);
            // console.log(response.data)
            setMessageAsRead();
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => setIsPreviousMessagesLoading(false))
    }

    useEffect(() => {
        if (+clientId === context.user.id) {
            getPreviousMessages();
        }
    }, [currentPage, clientId])

    // Send my message
    const [isSendingMyMessage, setIsSendingMyMessage] = useState(false);
    const sendMyMessage = (message) => {
        setIsSendingMyMessage(true);

        httpAxios.post('/api/support/save_message', {
            client_id: clientId,
            message: message
        }).then(response => {
            setMessages([...messages, response.data]);
            console.log(response);
        }).finally(() => setIsSendingMyMessage(false))
    }

    const [myNewMessage, setMyNewMessage] = useState(null);

    useEffect(() => {
        if (myNewMessage !== null) {
            sendMyMessage(myNewMessage);
            setMyNewMessage(null);
        }
    }, [myNewMessage])


    // Listen new messages
    const [newMessage, setNewMessage] = useState(null);

    useEffect(() => {
        if (clientId === undefined) return;


        LaravelEcho.private(`support.${clientId}`)
            .listen('.support.message.received', (e) => {
                setNewMessage(e.message)
                setMessageAsRead();
            });

        console.log('CONNECT CHANNEL')

        return () => {
            LaravelEcho.leave(`support.${clientId}`);
            console.log('LEAVE CHANNEL')
        }
    }, [clientId]);

    useEffect(() => {
        if (newMessage !== null && newMessage.by_user_id !== context.user.id) {
            setMessages([...messages, newMessage]);
            setNewMessage(null);
        }
    }, [newMessage])

    // Switch chat visibility
    const [isChatVisible, setIsChatVisible] = useState(true);
    const chatFaqContainer = useRef();
    const faqContainer = useRef();

    useEffect(() => {
        if (isChatVisible) {
            chatFaqContainer.current.classList.add(classes.chat_faq_container_active);
            chatFaqContainer.current.classList.remove(classes.chat_faq_container_inactive);
            faqContainer.current.classList.add(classes.faq_container_hidden);
        }else {
            chatFaqContainer.current.classList.remove(classes.chat_faq_container_active);
            chatFaqContainer.current.classList.add(classes.chat_faq_container_inactive);
            faqContainer.current.classList.remove(classes.faq_container_hidden);
        }
    }, [isChatVisible]);

    // Handle new messages request
    const [isNewMessagesRequest, setIsNewMessagesRequest] = useState(false);

    useEffect(() => {
        if (isNewMessagesRequest && currentPage < lastPage && isPreviousMessagesLoading === false) {
            setCurrentPage(currentPage => currentPage + 1);
            setIsNewMessagesRequest(false);
        }
    }, [isNewMessagesRequest, currentPage, lastPage, isPreviousMessagesLoading])

    // Prepare and render questions
    let questions = [];

    if (context.user.role === 'client') {
        questions = [
            {
                title: 'Вопросы перед бронированием',
                text: 'Если у вас возникли трудности и вопросы перед бронированием впечатления, мы оперативно проконсультируем вас в чате поддержки или по телефону.'
            },

            {
                title: 'Отзывы',
                text: 'Оставьте отзыв о ваших впечатлениях после события. Отзыв должен быть объективным по отношению к организатору и его впечатлению.'
            },

            {
                title: 'Как происходит оплата',
                text: 'В каждом впечатлении предусмотрено индивидуальное условие оплаты. Возможны частичная предоплата, полная оплата на месте и полная оплата онлайн. Организатор сам устанавливает способ оплаты.'
            },

            {
                title: 'Отмена заказа и возврат средств',
                text: 'Отменить заказ можно в личном кабинете на сайте. Полный возврат предоплаты возможен в течение определённого периода, указанного организатором. Информация об этом есть в описании впечатления, где вы бронировали её. Ознакомьтесь с нашей политикой возвратов для подробностей.'
            },

            {
                title: 'Впечатление не оправдало ожиданий?',
                text: 'Экскурсия не понравилась? Так бывает! Наверное, она понравится кому-то ещё. А вам мы вернем деньги без чека. Напишите нам в поддержку!'
            },

            {
                title: 'Бронирование впечатления',
                text: 'После бронирования впечатления в вашем личном кабинете появится заказ, а также будет направлена организационная информация на указанную вами почту при регистрации на сайте.'
            },

            {
                title: 'Изменения данных в заказе',
                text: 'Измените контактные данные в заказе через службу поддержки сайта. При необходимости поменять другие детали (дату, время, категорию билетов или впечатление) свяжитесь с организатором. Мы учтём ваши пожелания и обновим заказ.'
            },
        ];
    }else if (context.user.role === 'organizer') {
        questions = [
            {
                title: 'Ваш главный инструмент - расписание!',
                text: 'Отслеживайте ваше расписание и не забывайте добавлять актуальные даты и время.'
            },

            {
                title: 'Ваши впечатления - ваши правила!',
                text: 'Мы против сложной бюрократии в вопросах организации впечатлений, организатор сам устанавливает условия для проведения впечатления.'
            },

            {
                title: 'Размещение впечатления',
                text: 'Мы сотрудничаем только с организаторами мероприятий напрямую, исключая посредников.'
            },

            {
                title: 'Ответы на отзывы гостей',
                text: 'При ответе на отзыв соблюдайте несколько правил: будьте вежливы и любезны, не вступайте в спор, обязательно поблагодарите гостя за оставленный отзыв.'
            },

            {
                title: 'Работа с гостями',
                text: 'Вы полностью отвечаете за результат предоставления услуг для гостей и сопровождение заявки.'
            },

            {
                title: 'Отмена заказа организатором',
                text: 'Мы рекомендуем избегать отмен, но если экскурсия для клиентов не может состояться, предложите им другой вариант и сообщите нам об этом. Мы не вводим за санкций и штрафы, пока что...'
            },

            {
                title: 'Отмена заказ Гостем',
                text: 'В случае отмены заказа Гостем, если это произошло не позднее времени бесплатной отмены, установленного вами, мы компенсируем вам сумму, равную размеру вашей комиссии за этот заказ независимо от размера предоплаты Гостя.'
            },

            {
                title: 'Качество',
                text: 'Мы публикуем только самые яркие впечатления и экскурсии, ориентируясь на отзывы и положительные стороны работы организаторов.'
            },

            {
                title: 'Фотографии',
                text: 'Размещайте самые впечатляющие фотографии, которые заинтересуют посетителей. Наши специалисты смогут отредактировать или дополнить их.'
            },

            {
                title: 'Описание',
                text: 'Загружайте описание самостоятельно, наши редакторы скорректируют его согласно требованиям сайта. Не беспокойтесь, описание будет точным отражением ваших впечатлений.'
            },

            {
                title: 'Рекомендация от Команды!',
                text: 'Повышайте свой рейтинг и репутацию, поддерживайте высокое качество обслуживания Гостей и следите за тем, чтобы их впечатления были положительными. Так вы сможете достичь успеха и сотрудничать с нами на протяжении долгого времени!'
            },
        ];
    }

    const renderQuestions = () => {
        return questions.map((question, index) => {
            return (
                <div
                    className={classes.question_container}
                    key={index}
                >
                    <div
                        className={classes.question_title}
                        onClick={switchQuestionVisibility}
                    >
                        <div>
                            {question.title}
                        </div>

                        <div className={classes.arrow}>
                            <img
                                src={ArrowDrop}
                                alt="-"
                                width={10}
                            />
                        </div>
                    </div>

                    <div className={classes.question_text}>
                        &#x2022; {question.text}
                    </div>
                </div>
            )
        })
    }

        // Show faq question
        const switchQuestionVisibility = (e) => {
            // Hide all questions
            const questionTitles = document.querySelectorAll(`.${classes.question_title}`);
            questionTitles.forEach(questionTitle => {
                questionTitle.classList.remove(classes.question_title_active);
                questionTitle.lastElementChild.classList.remove(classes.arrow_active);
            })

            const questions = document.querySelectorAll(`.${classes.question_text}`);
            questions.forEach(question => {
                question.classList.remove(classes.question_text_active);
            })

            // Add grey background and rotate arrow
            e.currentTarget.classList.add(classes.question_title_active);

            const arrow = e.currentTarget.lastElementChild;
            arrow.classList.add(classes.arrow_active);

            // Show current question
            const currentQuestion = e.currentTarget.nextSibling;
            currentQuestion.classList.add(classes.question_text_active);
        }


        return (
            <div className={classes.fluid_container}>
                {
                    !isChatVisible &&
                    <div className={classes.show_chat_btn_container}>
                        <div
                            className={[classes.show_chat_btn, 'temp_btn temp_btn_success'].join(' ')}
                        onClick={() => setIsChatVisible(true)}
                    >
                        Написать в поддержку
                    </div>
                </div>
            }

            <div
                className={classes.chat_faq_container}
                ref={chatFaqContainer}
            >
                {
                    isChatVisible &&
                    <div className={classes.chat_container}>
                        <div
                            className={[classes.close_btn, 'temp_close_btn'].join(' ')}
                            onClick={() => setIsChatVisible(false)}
                        >
                            <img
                                src={Close}
                                alt="x"
                                width={10}
                            />
                        </div>

                        <NewChatBlock
                            messages={messages}
                            setIsNewMessageRequest={setIsNewMessagesRequest}
                            setMyNewMessage={setMyNewMessage}
                        />
                    </div>
                }

                <div
                    className={classes.faq_container}
                    ref={faqContainer}
                >
                    <div className={classes.faq_title}>
                        {
                            context.user.role === 'client' &&
                            'Важная информация для гостей от отдела заботы'
                        }

                        {
                            context.user.role === 'organizer' &&
                            'Полезная информация для организаторов от отдела заботы'
                        }
                    </div>

                    <div className={classes.faq_block}>
                        {renderQuestions()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizerSupportPage;