import React, { useRef, useState } from 'react'
import classes from './CompanyPositioning2.module.scss';

export const CompanyPositioning2 = () => {
    return (
        <div
            className={classes.company_positioning_container}
        >
            <div className={['roundblock_angle_lb', classes.company_positioning_block].join(" ")}>
            {/* <div className={['roundblock_angle_lb', classes.company_positioning_block].join(" ")}> */}
                <ul className={classes.company_positioning_block_ul}>
                    <li className={classes.garantee_text}>
                        Только <span className={classes.medium}>проверенные экскурсии!</span>
                    </li>
                    <li className={classes.garantee_text}>
                        Пишите, звоните - <span className={classes.medium}>сразу ответим!</span>
                    </li>
                    <li className={classes.garantee_text}>
                        Впечатляйтесь сейчас, <span className={classes.medium}>платите потом!</span> Яндекс Сплит
                    </li>
                    <li className={classes.garantee_text}>
                        Не понравится - <span className={classes.medium}>вернём деньги.</span> Без чека.
                    </li>
                </ul>
                {/* <p className={classes.garantee_text}>
                    Гарантируем <span className={classes.medium}>яркие впечатления!</span>
                </p>

                <p className={classes.garantee_text}>
                    Пишите, звоните - <span className={classes.medium}>сразу ответим!</span>
                </p>

                <p className={classes.garantee_text}>
                    Впечатляйтесь сейчас, <span className={classes.medium}>платите потом!</span> Яндекс Сплит
                </p>

                <p className={classes.garantee_text}>
                    Не понравится - <span className={classes.medium}>вернём деньги.</span> Без чека.
                </p> */}
            </div>

            {/* <div className={['roundblock_angle_lb', classes.company_positioning_block].join(" ")}>
                <p className={classes.garantee_text}>
                    Гарантируем <span className={classes.medium}>яркие впечатления!</span>
                </p>

                <p className={classes.garantee_text}>
                    Пишите, звоните - <span className={classes.medium}>сразу ответим!</span>
                </p>

                <p className={classes.garantee_text}>
                    Впечатляйтесь сейчас, <span className={classes.medium}>платите потом!</span> Яндекс Сплит
                </p>

                <p className={classes.garantee_text}>
                    Не понравится - <span className={classes.medium}>вернём деньги.</span> Без чека.
                </p>
            </div> */}
        </div>
    )
}
