import React, {useContext, useEffect, useState} from 'react';
import classes from './OrganizerProfilePage.module.scss';
import {baseURL, httpAxios} from "../../../API/HttpAxios";
import AuthContext from "../../../context/AuthContext";
import RatingStarFilled from '../../../images/icons/RatingStarFilled.svg'
import {ImgStretchingFixedDiv} from "../../../helpers/ImgStretching";

const OrganizerProfilePage = () => {
    const {...context} = useContext(AuthContext);
    const [organizer, setOrganizer] = useState({});
    const [isOrganizerInfoLoading, setIsOrganizerInfoLoading] = useState(true);
    const getOrganizerInfo = () => {
        setIsOrganizerInfoLoading(true);

        httpAxios.get('/api/user/get_organizers', {
            params: {
                user_id: context.user.id
            }
        })
            .then((response) => {
                setOrganizer(response.data.data[0]);
                console.log(response)
            })
            .finally(() => setIsOrganizerInfoLoading(false))
    }

    useEffect(() => {
        getOrganizerInfo();
    }, [])

    // Change avatar
    const [avatar, setAvatar] = useState(null);
    const addAvatar = (e) => {
        setAvatar(e.target.files[0]);
        setOrganizer({...organizer, avatar_path: URL.createObjectURL(e.target.files[0])});
    }

    // Save changes
    const [isChangesSaving, setIsChangesSaving] = useState(false);
    const [saveChangesError, setSaveChangesError] = useState('');
    const saveChanges = () => {
        setIsChangesSaving(true);
        setSaveChangesError('');

        const formData = new FormData();
        if (avatar !== null) {
            formData.append('avatar', avatar);
        }

        formData.append('user', JSON.stringify({
            email: organizer.email,
            phone_number: organizer.phone_number,
        }));

        httpAxios.post('/api/user/update_organizer', formData)
            .then((response) => {
                console.log(response.data)
                window.location.reload();
            })
            .catch((error) => {
                setSaveChangesError(error.response.data.message);
                console.log(error)
            })
            .finally(() => {
                setIsChangesSaving(false)
            })

    }

    useEffect(() => {
        console.log(organizer)
    }, [organizer])

    return (
        <div className={classes.container_fluid}>
            <div className={classes.organizer_profile_container}>
                <div className={classes.avatar_main_info_container}>
                    <div
                        className={classes.avatar_container}
                        onClick={() => document.getElementById('upload_avatar_input').click()}
                    >
                        <img
                            className={'center_img'}
                            src={organizer.avatar_path && organizer.avatar_path.includes("http") ? organizer.avatar_path : `${baseURL}/${organizer.avatar_path}`}
                            alt=""
                            onLoad={ImgStretchingFixedDiv}
                        />

                        <div className={classes.upload_avatar_text}>
                            Загрузить фото
                        </div>

                        <input
                            type="file"
                            accept="image/*"
                            className={classes.upload_avatar_input}
                            id={'upload_avatar_input'}
                            onChange={addAvatar}
                        />
                    </div>

                    <div className={classes.main_info_container}>
                        <div className={classes.name_rating_container}>
                            <div className={classes.name_container}>
                                {organizer.name}
                            </div>

                            <div className={classes.rating_container}>
                                <img src={RatingStarFilled} alt=""/>

                                <div>
                                    {organizer.rating % 1 === 0 ? `${organizer.rating}.0` : `${organizer.rating}`}
                                </div>
                            </div>
                        </div>

                        <div className={classes.company_container}>
                            {organizer.organizer_info && organizer.organizer_info.company_name}
                        </div>

                        <div className={classes.inn_container}>
                            {organizer.organizer_info && organizer.organizer_info.inn}
                        </div>

                        <div className={classes.is_account_verified_container}>
                            {
                                !organizer.organizer_info || organizer.organizer_info.account_verified_at === null
                                ? '✖️️аккаунт не подтвержден'
                                : '✔️аккаунт подтвержден'
                            }
                        </div>

                        <div className={classes.is_contract_signed_container}>
                            {
                                !organizer.organizer_info || organizer.organizer_info.contract_signed_at === null
                                    ? '✖️️договор не подписан'
                                    : '✔️договор подписан'
                            }
                        </div>
                    </div>
                </div>

                <div className={classes.additional_info_container}>
                    <div className={classes.phone_container}>
                        <div>
                            Номер телефона
                        </div>

                        <input
                            className={'temp_input'}
                            value={organizer.phone_number || ''}
                            placeholder={'+79000000000'}
                            onChange={(e) => {setOrganizer({...organizer, phone_number: e.target.value})}}
                        />
                    </div>

                    <div className={classes.email_container}>
                        <div>
                            Email
                        </div>

                        <input
                            className={'temp_input'}
                            value={organizer.email || ''}
                            placeholder={'pupkin@mail.ru'}
                            onChange={(e) => {setOrganizer({...organizer, email: e.target.value})}}
                        />
                    </div>

                    <div className={classes.save_changes_error}>
                        {saveChangesError}
                    </div>

                    <div
                        className={[classes.change_organizer_info_btn, 'temp_btn temp_btn_success'].join(' ')}
                        onClick={isChangesSaving ? null : saveChanges}
                    >
                        Изменить
                    </div>

                    {
                        organizer.organizer_info && organizer.organizer_info.fee !== null &&
                        <div className={classes.fee_container}>
                            Ваша комиссия: {organizer.organizer_info && 100 - organizer.organizer_info.fee * 100}%
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OrganizerProfilePage;