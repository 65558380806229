import React from 'react';
import classes from './YandexSplitBadge.module.scss';
import YandexBadge from '../../../images/icons/YandexBadge.svg';
import { priceFormatting } from 'helpers/PriceHelper';

export const YandexSplitBadge = ({price = 3500}) => {
  return (
    <div className={classes.container}>
        <div className={classes.price_container}>
            <img
                className={classes.price_img}
                src={YandexBadge}
                alt="Я"
            />

            <div className={classes.price}>
                {priceFormatting(Math.floor(price / 6))}
            </div>

            <div className={classes.price_currency}>
                ₽
            </div>
        </div>

        <div className={classes.x}>
            ×
        </div>

        <div className={classes.text}>
            6 платежей
        </div>
    </div>
  )
}
