import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from "./FilteredExcursionsPage.module.scss";
import { useLocation, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import ExcursionService from "../../API/ExcursionService";
import ExcursionsFilters from "../../components/ExcrusionsFilters/ExcursionsFilters";
import ExcursionPreview from "../../components/UI/ExcursionPreview/ExcursionPreview";
import { baseURL } from "../../API/HttpAxios";
import { useCookieHistory } from "../../hooks/useCookieHistory";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { useReceipting } from "../../hooks/useReceipting";
import { useFetching } from "../../hooks/useFetching";
import { getExcursionCount } from "../../helpers/WindowSizeHelper";
import { CompanyPositioning } from 'components/UI/CompanyPositioning/CompanyPositioning';
import HbButton from 'components/UI/HbButton/HbButton';
import { useCookies } from 'react-cookie';
import { useYandexSplit } from 'hooks/useYandexSplit';
import { CompanyPositioning2 } from 'components/UI/CompanyPositioning2/CompanyPositioning2';
import { CustomerSurvey } from 'components/UI/CustomerSurvey/CustomerSurvey';
// import { Helmet } from 'react-helmet';


const FilteredExcursionsPage = (props) => {
    // const [cookies, setCookie, removeCookie] = useCookies();
    const [categories, setCategories] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    let mainParams = useParams();
    const [
        cityName, setCityName,
        dates, setDates,
        visibleLocations, setVisibleLocations,
        visibleDates, setVisibleDates
    ] = useOutletContext()

    // Func for location visibility
    const handleVisibleLocation = (event) => {
        if (event.target === event.currentTarget) {
            event.stopPropagation()
        }

        setVisibleLocations(true);
    }

    useEffect(() => {
        if (visibleLocations) {
            setVisibleLocations(false);
        }
    }, [visibleLocations])

    // Func for calendar visibility
    const handleVisibleDates = (event) => {
        if (event.target === event.currentTarget) {
            event.stopPropagation()
        }

        setVisibleDates(true);
    }

    useEffect(() => {
        if (visibleDates) {
            setVisibleDates(false);
        }
    }, [visibleDates])

    // Func for loading categories from server
    async function fetchCategories() {
        const response = await ExcursionService.getAllCategories('ru', mainParams.city);
        const data = response.data;
        setCategories(data);
    }

    useEffect(() => {
        fetchCategories()
    }, [mainParams.city]);

    // Select category by search param 'category'
    useEffect(() => {
        console.log('categories')
        console.log(categories)
        console.log(searchParams.get('category'));


        if (categories.length !== 0) {
            const els = document.getElementsByClassName(classes.category_img)

            for (const el of els) {
                el.classList.remove(classes.category_img_active)
            }

            let category = undefined;

            if (searchParams.has('category') && searchParams.get('category') !== 'all') {
                const el = document.getElementById(`category_img_${searchParams.get('category')}`);

                if (el !== null) {
                    el.classList.add(classes.category_img_active)
                }

                // Set title, h1, description and keywords
                category = categories.find(category => category.alias === searchParams.get('category'));
            } else {
                const el = document.getElementById(`category_img_all`);
                el.classList.add(classes.category_img_active)

                // Set title, h1, description and keywords
                category = categories.find(category => category.alias === 'all');
            }

            if (category !== undefined) {
                document.title = category.title;
                document.querySelector('#main_title').innerHTML = category.h1;

                let metaDescription = document.querySelector('meta[name="description"]');

                if (metaDescription === null) {
                    metaDescription = document.createElement('meta');
                    metaDescription.setAttribute('name', 'description');
                }

                let metaKeywords = document.querySelector('meta[name="keywords"]');

                if (metaKeywords === null) {
                    metaKeywords = document.createElement('meta');
                    metaKeywords.setAttribute('name', 'keywords');
                }

                metaDescription.setAttribute('content', category.description);
                metaKeywords.setAttribute('content', category.keywords);

                if (typeof document.querySelector('meta[name="description"]') !== 'undefined') {
                    document.head.appendChild(metaDescription);
                }

                if (typeof document.querySelector('meta[name="keywords"]') !== 'undefined') {
                    document.head.appendChild(metaKeywords);
                }
            }
        }
    }, [categories, searchParams, mainParams.city])

    // Set title by search param 'category'


    // useEffect(() => {
    //     if (searchParams.has('category') && searchParams.get('category') === 'dvori') {
    //         document.title = "Экскурсии по дворам Санкт Петербурга цена от HIDDENBURG";
    //         // const metaRobots = document.querySelector('meta[name="robots"]');
    //         // if (metaRobots) {
    //         // metaRobots.setAttribute('content', 'index, follow');
    //         // }
    //     }
    // }, [searchParams])

    // Set to search string param 'category'
    const [isCategoriesMoving, setIsCategoriesMoving] = useState(false);

    const setCategoryQuery = useCallback((event) => {
        // Check if mouse moved in window
        if (isCategoriesMoving) return;

        setSearchParams(searchParams => {
            if (event.currentTarget.getAttribute('name') === 'all') {
                searchParams.delete('category');
            } else {
                searchParams.set('category', event.currentTarget.getAttribute('name'));
            }
            return searchParams;
        })
    }, [isCategoriesMoving, searchParams, mainParams])

    // Fetching excursions with all parameters
    const [excursions, setExcursions] = useState([]);
    const [fetchPage, setFetchPage] = useState(1);
    const [lastPage, setLastPage] = useState(Number);

    const prepareFetchParams = async (isNewSearch) => {
        const fetchParams = {};
        const limit = await getExcursionCount();
        mainParams.country && (fetchParams.country = mainParams.country);
        mainParams.city && (fetchParams.city = mainParams.city);
        searchParams.has('date_start') && (fetchParams.date_start = searchParams.get('date_start'));
        searchParams.has('date_end') && (fetchParams.date_end = searchParams.get('date_end'));
        searchParams.has('category') && (fetchParams.category = searchParams.get('category'));
        searchParams.has('price_start') && (fetchParams.price_start = searchParams.get('price_start'));
        searchParams.has('price_end') && (fetchParams.price_end = searchParams.get('price_end'));
        searchParams.has('group') && (fetchParams.group = searchParams.get('group'));
        searchParams.has('individual') && (fetchParams.individual = searchParams.get('individual'));
        searchParams.has('minigroup') && (fetchParams.minigroup = searchParams.get('minigroup'));
        fetchParams.limit = limit;
        if (isNewSearch) {
            fetchParams.page = 1;
        } else {
            fetchParams.page = fetchPage;
        }

        setFetchPage(fetchParams.page);

        const response = await ExcursionService.getExcursions(fetchParams);
        setLastPage(response.data.last_page);

        console.log(response);
        return response;
    }

    const [fetchNewExcursions, isFetchNewExcursionsLoading, fetchNewExcursionsError] = useFetching(async () => {
        const response = await prepareFetchParams(true);

        setExcursions(response.data.data);
    })

    // const [fetchExcursions, isFetchExcursionsLoading, fetchExcursionsError] = useReceipting(async () => {
    //     const response = await prepareFetchParams(false);

    //     setExcursions([...excursions, ...response.data.data]);
    // })

    const [isFetchExcursionsLoading, setIsFetchExcursionsLoading] = useState(false);

    const fetchExcursions = async () => {
        setIsFetchExcursionsLoading(true);
        const response = await prepareFetchParams(false);
        setExcursions([...excursions, ...response.data.data]);
        setIsFetchExcursionsLoading(false);
    }

    useEffect(() => {
        fetchNewExcursions()
    }, [mainParams, searchParams])

    // Load excursion by scroll
    const observerBlock = useRef();
    const observer = useRef();

    const [isInsideLoadMore, setIsInsideLoadMore] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('inside load more');
                setIsInsideLoadMore(true);
            }

        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    useEffect(() => {
        if (isInsideLoadMore && fetchPage < lastPage && fetchPage >= 2 && !isFetchExcursionsLoading && !isFetchNewExcursionsLoading) {
            setFetchPage(fetchPage => fetchPage + 1);
        }

        setIsInsideLoadMore(false);
    }, [isFetchNewExcursionsLoading, isFetchExcursionsLoading, fetchPage, lastPage, isInsideLoadMore])

    // Page loader visibility
    useEffect(() => {
        // console.log('fetchPage');
        // console.log(fetchPage);
        // console.log('lastPage');
        // console.log(lastPage);
        // console.log('isFetchExcursionsLoading');
        // console.log(isFetchExcursionsLoading);

        if ((fetchPage < lastPage && fetchPage >= 2) || (fetchPage >= lastPage && isFetchExcursionsLoading)) {
            observerBlock.current.classList.remove('hidden');
        } else {
            observerBlock.current.classList.add('hidden');
        }
    }, [fetchPage, lastPage, excursions, isFetchExcursionsLoading])

    // Load more excursions on click
    const loadMoreExcursions = useCallback(() => {
        console.log('loadMoreExcursions');

        if (fetchPage < lastPage) {
            setFetchPage(fetchPage => fetchPage + 1);
        }

    }, [fetchPage, lastPage])

    useEffect(() => {
        if (fetchPage > 1) {
            fetchExcursions();
        }
    }, [fetchPage])

    // Set back route to cookie
    const backRoute = useCookieHistory();

    // Horizontal scroll by mouse grab
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const scrollContainerRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);

        // if (e.target.children[0] === e.target) {
        //     // e.target.children[0].onClick = () => null;
        //     e.preventDefault();
        // }
    };

    const handleMouseLeave = () => {
        setIsDown(false);
        setTimeout(() => setIsCategoriesMoving(false), 200);
    };

    const handleMouseUp = () => {
        setIsDown(false);
        setTimeout(() => setIsCategoriesMoving(false), 200);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        setIsCategoriesMoving(true);
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX); // Скорость прокрутки
        // const walk = (x - startX) * 3; // Скорость прокрутки
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    // Cancel catagory horizontal scroll animation
    const cancelCategoriesAnimation = () => {
        scrollContainerRef.current.classList.remove(classes.categories_carousel_animation);
    }

    const scrollCategories = () => {
        const scrollContainer = document.getElementById('scroll-container');
        const maxScrollLeft = scrollContainer.children[2].offsetLeft / 4;
        // const maxScrollLeft = scrollContainer.children[2].offsetLeft / 2;
        const scrollStep = 1;
        let scrollAmount = 0;
        let direction = 1;

        const scrollInterval = setInterval(function () {
            scrollContainer.scrollLeft += scrollStep * direction;
            scrollAmount += scrollStep * direction;

            if (scrollAmount >= maxScrollLeft) {
                direction = -1;
            } else if (scrollAmount <= 0 && direction === -1) {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    useEffect(() => {
        // if (cookies.categories_scroll_times === undefined || cookies.categories_scroll_times < 1) {
        if (categories.length > 0) {
            var scrollCategoriesTimeout = setTimeout(() => {
                scrollCategories();
            }, 1500);
        }
        // let scrollCategoriesTimeout = setTimeout(() => {
        //     scrollCategories();
        // }, 2000);

        //     setCookie('categories_scroll_times', cookies.categories_scroll_times + 1, { path: '/' });
        // }


        return () => {
            // if (cookies.categories_scroll_times !== undefined && cookies.categories_scroll_times === 1) {
            if (scrollCategoriesTimeout !== undefined) {
                clearTimeout(scrollCategoriesTimeout);
            }
            // }
        }
    }, [categories])

    return (
        <div>
            {/* <Helmet>
                <title>{`Экскурсии по дворам Санкт Петербурга цена от HIDDENBURG`}</title>
            </Helmet> */}

            {/* Main title */}
            <div className={`container`}>
                <h1
                    className={classes.main_title}
                    id='main_title'
                >
                    {/* маркетплейс <span className={'main_title_excursions'}>экскурсий</span> и&nbsp;впечатлений */}
                </h1>
            </div>

            {/* Categories carousel */}
            <div className={`container`}>
                <div
                    id='scroll-container'
                    className={[classes.categories_carousel, classes.categories_carousel_animation].join(" ")}
                    ref={scrollContainerRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    onScroll={cancelCategoriesAnimation}
                >
                    {categories.map((category) => {
                        return category.is_main
                            ?
                            (
                                <div
                                    className={`${classes.category_card}`}
                                    key={`category_card_${category.id}`}
                                    name={category.alias}
                                    onClick={setCategoryQuery}
                                >
                                    <div
                                        className={classes.category_img}
                                        key={`category_img_${category.id}`}
                                        id={`category_img_${category.alias}`}
                                    >
                                        <img
                                            src={baseURL + category.img_path}
                                            alt={category.alias}
                                            draggable="false"
                                        />
                                    </div>

                                    <div className={`${classes.category_name}`} key={`category_name_${category.id}`}>
                                        {category.name}
                                    </div>
                                </div>
                            )
                            : null
                    })}
                </div>
            </div>

            {/*Excursions filters*/}
            <div>
                <ExcursionsFilters
                    cityName={cityName}
                    dates={dates}
                    visibleLoc={handleVisibleLocation}
                    visibleCalendar={handleVisibleDates}
                />
            </div>

            {/* Excursions */}

            <div className={`container`}>
                <div className={'excursions_grid'}>
                    {/*<GiftPreview/>*/}
                    {/* <CompanyPositioning /> */}
                    <CompanyPositioning2 />

                    {excursions.map((excursion) => {
                        return <ExcursionPreview
                            key={excursion.excursion_id}
                            excursion_id={excursion.excursion_id}
                            imgs={excursion.imgs}
                            rating={excursion.rating}
                            reviews_count={excursion.reviews_count}
                            city_name={excursion.city_name}
                            title={excursion.title}
                            duration={excursion.duration}
                            type={excursion.type}
                            initial_price={excursion.initial_price}
                            discount_price={excursion.discount_price}
                            discount_value={excursion.discount_value}
                        />
                    })}
                </div>
            </div>

            {/* Load more button */}
            {
                fetchPage < lastPage && fetchPage < 2 &&
                <div className={`container ${classes.load_excursions_block}`}>
                    <HbButton
                        className={classes.load_more_button}
                        color={'green'}
                        disabled={false}
                        onClick={loadMoreExcursions}
                        isLoading={isFetchExcursionsLoading}
                    >
                        Показать больше...
                    </HbButton>
                </div>
            }

            <div
                ref={observerBlock}
            // className={'invisible'}
            >
                <PageLoader />
            </div>

            {/* SEO text */}
            <div
                className={[classes.seo_text, 'container'].join(" ")}
                id='seo_text'
            ></div>

            <CustomerSurvey/>

        </div>
    );
};

export default FilteredExcursionsPage;