import React, {useEffect, useState} from 'react';
import classes from "./BookingsPage.module.scss";
import {useOutletContext, useSearchParams} from "react-router-dom";
import ExperiencesBlock from "../../../components/dashboard/manager/ExperiencesBlock/ExperiencesBlock";
import BookingsBlock from "../../../components/dashboard/manager/BookingsBlock/BookingsBlock";
import WorkerChatContainer from "../../../components/dashboard/common/WorkerChatContainer/WorkerChatContainer";
import HbCloseButton from "../../../components/UI/HbCloseButton/HbCloseButton";

const BookingsPage = () => {
    const role = useOutletContext();
    const [bookingsRowClasses, setBookingsRowClasses] = useState([classes.bookings_row, classes.experiences_active]);

    const [experiencesHeaderClasses, setExperiencesHeaderClasses] = useState([classes.experiences_header]);
    const [bookingsHeaderClasses, setBookingsHeaderClasses] = useState([classes.bookings_header, "hidden"]);
    const [chatHeaderClasses, setChatHeaderClasses] = useState([classes.chat_header, "hidden"])

    // const [experiencesBlockClasses, setExperiencesBlockClasses] = useState([classes.experiences_block, "temp_scrollbar"]);
    const [bookingsBlockClasses, setBookingsBlockClasses] = useState([classes.bookings_block, "temp_scrollbar", "hidden"]);
    const [chatBlockClasses, setChatBlockClasses] = useState([classes.chat_block, "hidden"]);

    const [isChatVisible, setIsChatVisible] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        console.log(role);
    }, [])

    const closeChat = () => {
        searchParams.delete("chat");
        setSearchParams(searchParams);
    }


    const closeBookings = () => {
        searchParams.delete("experience_type_id");
        searchParams.delete("date");
        searchParams.delete("time");
        searchParams.delete("chat");
        setSearchParams(searchParams);
    }

    // Handle blocks visibility
    useEffect(() => {
        // Handle bookings visibility
        if (searchParams.get("experience_type_id") && searchParams.get("date") && searchParams.get("time")) {
            setBookingsRowClasses([classes.bookings_row, classes.experiences_bookings_active]);
            setBookingsBlockClasses([classes.bookings_block, "temp_scrollbar", "show"]);
            setBookingsHeaderClasses([classes.bookings_header, "show_flex"]);
        }else {
            setBookingsRowClasses([classes.bookings_row, classes.experiences_active]);
            setBookingsBlockClasses(["hidden"]);
            setBookingsHeaderClasses(["hidden"]);
        }

        // Handle chat visibility
        if (searchParams.get("chat")) {
            setBookingsRowClasses([classes.bookings_row, classes.experiences_bookings_chat_active]);
            setChatBlockClasses([classes.chat_block, "show"]);
            setChatHeaderClasses([classes.chat_header, "show_flex"]);
            setIsChatVisible(true);
        }else {
            setBookingsRowClasses([classes.bookings_row, classes.experiences_bookings_active]);
            setChatBlockClasses(["hidden"]);
            setChatHeaderClasses(["hidden"]);
            setIsChatVisible(false);
        }
    }, [searchParams])


    // Booking info for BookingsBlock header
    const [bookingsBlockHeaderText, setBookingsBlockHeaderText] = useState('')

    // ChatContainerHeader text
    const [chatContainerHeaderText, setChatContainerHeaderText] = useState('')

    return (
        // <div className={[classes.container_fluid].join(" ")}>
        // {/*<div className={[classes.container_fluid, "temp_bg"].join(" ")}>*/}
        // {/*    <div className="temp_wave_bg"></div>*/}
        // {/*    <div className="temp_wave_bg"></div>*/}
        // {/*    <div className="temp_wave_bg"></div>*/}
        //     <SideMenu/>

            <div className={classes.bookings_container}>
                <div className={bookingsRowClasses.join(" ")}>
                    {/*Experiences section*/}
                    <div className={classes.experiences_block}>
                        {/*Experiences header*/}
                        <div className={experiencesHeaderClasses.join(" ")}>
                            Заказы на впечатления
                        </div>

                        <ExperiencesBlock
                        />
                    </div>

                    {/*Bookings section*/}
                    <div className={bookingsBlockClasses.join(" ")}>
                        {/*Bookings header*/}
                        <div className={bookingsHeaderClasses.join(" ")}>
                            <div>
                                Список гостей: <b>{bookingsBlockHeaderText}</b>
                            </div>

                            <HbCloseButton
                                className={classes.close_btn}
                                onClick={closeBookings}
                            />
                        </div>

                        <BookingsBlock
                            setBookingsBlockHeaderText={setBookingsBlockHeaderText}
                            setChatContainerHeaderText={setChatContainerHeaderText}
                        />
                    </div>

                    {/*Chat section*/}
                    <div className={chatBlockClasses.join(" ")}>
                        {/*Chat header*/}
                        <div  className={chatHeaderClasses.join(" ")}>
                            <div>
                                <b>{chatContainerHeaderText}</b>
                            </div>

                            <HbCloseButton
                                className={classes.close_btn}
                                onClick={closeChat}
                            />
                        </div>

                        {isChatVisible && <WorkerChatContainer isVisible={isChatVisible}/>}
                        {/*{isChatVisible && <NewChatBlock isVisible={isChatVisible}/>}*/}
                        {/*{isChatVisible && <ChatBlock isVisible={isChatVisible}/>}*/}
                    </div>
                </div>

            </div>
        // </div>
    );
};

export default BookingsPage;