import ExcursionService from 'API/ExcursionService';
import ExcursionPreviewMin from 'components/UI/ExcursionPreviewMin/ExcursionPreviewMin';
import React, { useEffect, useRef, useState } from 'react';
import classes from './AdditionalExcursions.module.scss';
import ArrowLeft from 'images/icons/ArrowLeft.svg';
import ArrowRight from 'images/icons/ArrowRight.svg';
import { useObserverBlockEntry } from 'hooks/useObserverBlockEntry';

export const AdditionalExcursions = ({ excursion_id }) => {
    const [excursions, setExcursions] = useState([]);
    const [isExcursionsLoading, setIsExcursionsLoading] = useState(true);

    const getAdditionalExcursions = async () => {
        setIsExcursionsLoading(true);

        const result = await ExcursionService.getAdditionalExcursions(excursion_id);
        console.log(result);

        setExcursions(result.data);

        setIsExcursionsLoading(false);
    }

    useEffect(() => {
        getAdditionalExcursions();
    }, [])

    // Scroll excursion by arrow
    const leftArrow = useRef();
    const rightArrow = useRef();
    const additionalBlock = useRef();

    const scrollByArrows = (e) => {
        if (e.currentTarget === leftArrow.current) {
            additionalBlock.current.scrollLeft -= additionalBlock.current.clientWidth;
        }else if (e.currentTarget === rightArrow.current) {
            additionalBlock.current.scrollLeft += additionalBlock.current.clientWidth;
        }
    }

    // Hide arrows
    const hideArrows = () => {
        if (additionalBlock.current.scrollLeft === 0) {
            leftArrow.current.classList.add('invisible');
        }else {
            leftArrow.current.classList.remove('invisible');
        }

        if (additionalBlock.current.scrollLeft === additionalBlock.current.scrollWidth - additionalBlock.current.clientWidth) {
            rightArrow.current.classList.add('invisible');
        }else {
            rightArrow.current.classList.remove('invisible');
        }
    }

    useEffect(() => {
        hideArrows();
    }, [excursions])

    return (
        <>
            <div className={classes.additional_excursions_header}>
                <h2 className={classes.excursion_title}>Похожие впечатления</h2>

                <div className={classes.additional_scroll_block}>
                    <div 
                        className={classes.scroll_btn}
                        ref={leftArrow}
                        onClick={scrollByArrows}
                    >
                        <img
                            src={ArrowLeft}
                            alt={'<'}
                        />
                    </div>

                    <div 
                        className={classes.scroll_btn}
                        ref={rightArrow}    
                        onClick={scrollByArrows}
                    >
                        <img
                            src={ArrowRight}
                            alt={'>'}
                        />
                    </div>
                </div>
            </div>

            <div 
                className={classes.excursions_container}
                ref={additionalBlock}
                onScroll={hideArrows}
            >
                {
                    excursions.map(excursion => {
                        return (
                            <ExcursionPreviewMin
                                key={excursion.excursion_id}
                                className={classes.excursion_preview_min}
                                excursion_id={excursion.excursion_id}
                                imgs={excursion.imgs}
                                rating={excursion.rating}
                                reviews_count={excursion.reviews_count}
                                city_name={excursion.city_name}
                                title={excursion.title}
                                duration={excursion.duration}
                                type={excursion.type}
                                initial_price={excursion.initial_price}
                                discount_price={excursion.discount_price}
                                discount_value={excursion.discount_value}
                            />
                        )
                    })
                }
            </div>
        </>

    )
}
