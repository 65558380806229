import React from 'react';
import logoDesktop from "../../../images/logos/LogoDesktopNEW.svg";
import logoMobile from "../../../images/logos/LogoMobile.svg";
import {useLocation} from "react-router-dom";
import classes from './Logo.module.scss';

const Logo = () => {
    let location = useLocation();
    

    return (
        // <Link to={'/'} className={classes.logo_link}>
        //     <img src={logoDesktop} className={classes.logo_desktop} alt={'HIDDENBURG'}/>
        //     <img src={logoMobile} className={classes.logo_mobile} alt={'HB'}/>
        // </Link>

        <a href={location.pathname === '/' ? null : '/'} className={classes.logo_link}>
            <img src={logoDesktop} className={classes.logo_desktop} alt={'HIDDENBURG'}/>
            <img src={logoMobile} className={classes.logo_mobile} alt={'HB'}/>
        </a>
    );
};

export default Logo;