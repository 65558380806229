import React, {useContext, useEffect} from 'react';
import classes from "./MobileNavigationBar.module.scss";
import LikeOutline from '../../../images/icons/LikeOutline.svg';
import User from '../../../images/icons/User.svg';
import AuthContext from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const MobileNavigationBar = () => {
    const {isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const searchClick = () => {
        // if (window.location.pathname === '/') {
        //
        // }

        navigate('/');
    }

    // Click on favorites icon
    const favoritesClick = () => {
        if (isAuth) {
            navigate(`/${user.role}/dashboard/favorites`);
        }else {
            setIsAuthVisible(true);
        }
    }

    // Click on user icon
    const userClick = () => {
        if (isAuth) {
            navigate(`/${user.role}/dashboard/bookings`);
        }else {
            setIsAuthVisible(true);
        }
    }

    return (
        <div className={`container_fluid ${classes.mobile_navigation_row}`}>
            <div className={`container ${classes.mobile_navigation_bar}`}>
                {/*<div*/}
                {/*    className={classes.mobile_navigation_button}*/}
                {/*    onClick={searchClick}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={SearchBlack}*/}
                {/*        alt={'search'}*/}
                {/*    />*/}
                {/*</div>*/}

                {
                    isAuth && user.role === 'client' &&
                    <div
                        className={classes.mobile_navigation_button}
                        onClick={favoritesClick}
                    >
                        <img
                            src={LikeOutline}
                            alt={'likes'}
                        />
                    </div>
                }


                {/*<div*/}
                {/*    className={classes.mobile_navigation_button}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={Gift}*/}
                {/*        alt={'gift'}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className={classes.mobile_navigation_button}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={Message}*/}
                {/*        alt={'message'}*/}
                {/*    />*/}
                {/*</div>*/}

                <div
                    className={classes.mobile_navigation_button}
                    onClick={userClick}
                >
                    <img
                        src={User}
                        alt={'user'}
                    />
                </div>
            </div>
        </div>
    );
};

export default MobileNavigationBar;