import { useEffect, useState } from 'react';

export const useYandexSplit = ({timeOut = 1000}) => {
    // const [isYandexSplitLoaded, setIsYandexSplitLoaded] = useState(false);
    const [YaPay, setYaPay] = useState(null);
    // const [paymentSession, setPaymentSession] = useState(null);
    const [paymentData, setPaymentData] = useState(null);

    // const addPaymentEnv = () => {
    //     setPaymentData({
    //         ...paymentData,
    //         env: window.YaPay.PaymentEnv.Sandbox
    //     })
    // }




    // // Обработчик на клик по кнопке
    // // Функция должна возвращать промис которые резолвит ссылку на оплату полученную от бэкенда Яндекс Пэй
    // // Подробнее про создание заказа: https://pay.yandex.ru/ru/docs/custom/backend/yandex-pay-api/order/merchant_v1_orders-post
    // // async function onPayButtonClick() {
    // //     // Создание заказа...
    // //     // и возврат URL на оплату вида 'https://pay.ya.ru/l/XXXXXX'
    // // }

    // // Обработчик на ошибки при открытии формы оплаты
    // function onFormOpenError(reason) {
    //     // Выводим информацию о недоступности оплаты в данный момент
    //     // и предлагаем пользователю другой способ оплаты.
    //     console.error(`Payment error — ${reason}`);
    // }



    function onYaPayLoad() {
        // const YaPay = window.YaPay;

        // const paymentData = {
        //     // Для отладки нужно явно указать `SANDBOX` окружение,
        //     // для продакшена параметр можно убрать или указать `PRODUCTION`
        //     env: YaPay.PaymentEnv.Sandbox,

        //     // Версия 4 указывает на тип оплаты сервисом Яндекс Пэй
        //     // Пользователь производит оплату на форме Яндекс Пэй,
        //     // и мерчанту возвращается только результат проведения оплаты
        //     version: 4,

        //     // Код валюты в которой будете принимать платежи
        //     currencyCode: YaPay.CurrencyCode.Rub,

        //     // Идентификатор продавца, который получают при регистрации в Яндекс Пэй
        //     merchantId: process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID,

        //     // Сумма к оплате
        //     // Сумма которая будет отображена на форме зависит от суммы переданной от бэкенда
        //     // Эта сумма влияет на отображение доступности Сплита
        //     totalAmount: null,

        //     // Доступные для использования методы оплаты
        //     // Доступные на форме способы оплаты также зависят от информации переданной от бэкенда
        //     // Данные передаваемые тут влияют на внешний вид кнопки или виджета
        //     availablePaymentMethods: ['CARD', 'SPLIT'],
        // };

        // Создаем платежную сессию
        // if (needCreateSession) {
        //     // Данные платежа
        //     const paymentData = {
        //         // Для отладки нужно явно указать `SANDBOX` окружение,
        //         // для продакшена параметр можно убрать или указать `PRODUCTION`
        //         env: YaPay.PaymentEnv.Sandbox,

        //         // Версия 4 указывает на тип оплаты сервисом Яндекс Пэй
        //         // Пользователь производит оплату на форме Яндекс Пэй,
        //         // и мерчанту возвращается только результат проведения оплаты
        //         version: 4,

        //         // Код валюты в которой будете принимать платежи
        //         currencyCode: YaPay.CurrencyCode.Rub,

        //         // Идентификатор продавца, который получают при регистрации в Яндекс Пэй
        //         merchantId: process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID,

        //         // Сумма к оплате
        //         // Сумма которая будет отображена на форме зависит от суммы переданной от бэкенда
        //         // Эта сумма влияет на отображение доступности Сплита
        //         totalAmount: totalAmount,

        //         // Доступные для использования методы оплаты
        //         // Доступные на форме способы оплаты также зависят от информации переданной от бэкенда
        //         // Данные передаваемые тут влияют на внешний вид кнопки или виджета
        //         availablePaymentMethods: ['CARD', 'SPLIT'],
        //     };

        //     // Обработчик на клик по кнопке
        //     // Функция должна возвращать промис которые резолвит ссылку на оплату полученную от бэкенда Яндекс Пэй
        //     // Подробнее про создание заказа: https://pay.yandex.ru/ru/docs/custom/backend/yandex-pay-api/order/merchant_v1_orders-post
        //     async function onPayButtonClick() {
        //         // Создание заказа...
        //         // и возврат URL на оплату вида 'https://pay.ya.ru/l/XXXXXX'
        //     }

        //     // Обработчик на ошибки при открытии формы оплаты
        //     function onFormOpenError(reason) {
        //         // Выводим информацию о недоступности оплаты в данный момент
        //         // и предлагаем пользователю другой способ оплаты.
        //         console.error(`Payment error — ${reason}`);
        //     }

        //     YaPay.createSession(paymentData, {
        //         onPayButtonClick: onPayButtonClick,
        //         onFormOpenError: onFormOpenError,
        //     })
        //         .then(function (paymentSession) {
        //             setPaymentSession(paymentSession);
        //             // Показываем кнопку Яндекс Пэй на странице.
        //             // paymentSession.mountButton(document.querySelector('#button_container'), {
        //             //     type: YaPay.ButtonType.Pay,
        //             //     theme: YaPay.ButtonTheme.Black,
        //             //     width: YaPay.ButtonWidth.Auto,
        //             // });





        //             // paymentSession.mountWidget(
        //             //     document.querySelector('#widget_container'),
        //             //     { widgetType: YaPay.WidgetType.BnplPreview }
        //             // )

        //             // paymentSession.mountBadge(
        //             //     document.querySelector('#badge_container'),
        //             //     {
        //             //         type: 'bnpl',
        //             //         amount: '100.00',
        //             //         size: 'l',
        //             //         variant: 'detailed',
        //             //         color: 'primary',
        //             //         merchantId: '0e76aaa3-3bf6-4e0e-b142-4c6d5792af10',
        //             //     }
        //             // )

        //             // YaPay.mountBadge(
        //             //     document.querySelector('#badge_container'),
        //             //     {
        //             //         type: 'bnpl',
        //             //         amount: '100.00',
        //             //         size: 'l',
        //             //         variant: 'detailed',
        //             //         color: 'primary',
        //             //         merchantId: '0e76aaa3-3bf6-4e0e-b142-4c6d5792af10',
        //             //     }
        //             // )
        //         })
        //         .catch(function (err) {
        //             // Не получилось создать платежную сессию.
        //             console.error(err);
        //         });
        // }
    }

    useEffect(() => {
        console.log(process.env.REACT_APP_YANDEX_SPLIT_BADGE_SANDBOX)
        console.log(typeof process.env.REACT_APP_YANDEX_SPLIT_BADGE_SANDBOX)
        if (process.env.REACT_APP_YANDEX_SPLIT_BADGE_SANDBOX === 'true') {
            window.__YAPAY_BADGE_SANDBOX = true;
        }

        const timer = setTimeout(() => {
            let yandexPayScript = document.getElementById("yandexPayScript");

            if (yandexPayScript === null) {
                const script = document.createElement('script');
                script.id = "yandexPayScript";
                script.src = "https://pay.yandex.ru/sdk/v1/pay.js";
                script.async = true;
                script.onload = () => {
                    // onYaPayLoad({ totalAmount: '800.00' });
                    setYaPay(window.YaPay);
                    setPaymentData({
                        // Для отладки нужно явно указать `SANDBOX` окружение,
                        // для продакшена параметр можно убрать или указать `PRODUCTION`
                        env: process.env.REACT_APP_YANDEX_SPLIT_BADGE_SANDBOX === 'true' ? window.YaPay.PaymentEnv.Sandbox : window.YaPay.PaymentEnv.Production,

                        // Версия 4 указывает на тип оплаты сервисом Яндекс Пэй
                        // Пользователь производит оплату на форме Яндекс Пэй,
                        // и мерчанту возвращается только результат проведения оплаты
                        version: 4,

                        // Код валюты в которой будете принимать платежи
                        currencyCode: window.YaPay.CurrencyCode.Rub,

                        // Идентификатор продавца, который получают при регистрации в Яндекс Пэй
                        merchantId: process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID,

                        // Сумма к оплате
                        // Сумма которая будет отображена на форме зависит от суммы переданной от бэкенда
                        // Эта сумма влияет на отображение доступности Сплита
                        totalAmount: null,

                        // Доступные для использования методы оплаты
                        // Доступные на форме способы оплаты также зависят от информации переданной от бэкенда
                        // Данные передаваемые тут влияют на внешний вид кнопки или виджета
                        availablePaymentMethods: ['CARD', 'SPLIT'],
                    });
                    // setIsYandexSplitLoaded(true);

                    console.log('YaPay is loaded');
                    console.log(window.YaPay);

                }

                document.body.appendChild(script);
            }


            // // Убираем скрипт при размонтировании компонента
            // return () => {

            // };
        }, timeOut); // 5000 миллисекунд = 5 секунд

        // Очищаем таймер при размонтировании компонента
        return () => {
            clearTimeout(timer);

            let yandexPayScript = document.getElementById("yandexPayScript");
            console.log('yandexPayScript unmounting');
            console.log(yandexPayScript);


            if (yandexPayScript !== null) {
                document.body.removeChild(yandexPayScript);
                setYaPay(null);
            }
        };
    }, [])

    return [YaPay, paymentData, setPaymentData];
    // return [YaPay, paymentSession];
    // return [isYandexSplitLoaded, YaPay, paymentSession];
}
