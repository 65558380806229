import React, {useEffect, useRef, useState} from 'react';
import classes from "./SupportPage.module.scss";
import NewChatBlock from "../../../components/dashboard/common/NewChatBlock/NewChatBlock";
import SupportChatContainer from "../../../components/dashboard/common/SupportChatContainer/SupportChatContainer";
import {useNavigate, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import {httpAxios} from "../../../API/HttpAxios";
import moment from "moment";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import DraftTopMenuBtns from "../../../components/dashboard/common/DraftTopMenuBtns/DraftTopMenuBtns";

const SupportPage = () => {
    const {page} = useParams();
    const navigate = useNavigate();
    // const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [messages, setMessages] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const {...outletContext} = useOutletContext();

    const [clientNotificationCount, setClientNotificationCount] = useState(0);
    const [organizerNotificationCount, setOrganizerNotificationCount] = useState(0);

    // Show unread messages count in top menu
    useEffect(() => {
        let tempClientNotificationCount = 0;
        let tempOrganizerNotificationCount = 0;

        for (const supportNotification of outletContext.supportNotifications) {
            if (supportNotification.role === 'client' && supportNotification.unread_message_count > 0) {
                tempClientNotificationCount += 1;
            }else if (supportNotification.role === 'organizer' && supportNotification.unread_message_count > 0) {
                tempOrganizerNotificationCount += 1;
            }
        }

        setClientNotificationCount(tempClientNotificationCount);
        setOrganizerNotificationCount(tempOrganizerNotificationCount);
    }, [outletContext])

    const topMenuBtns = [
        {
            name: "Гость",
            path: "/manager/dashboard/support/client",
            notification_count: clientNotificationCount
        },

        {
            name: "Организатор",
            path: "/manager/dashboard/support/organizer",
            notification_count: organizerNotificationCount
        },

        {
            name: "Архив",
            path: "/manager/dashboard/support/archive",
            // notification_count: reviewCountForModeration
        },
    ];

    // Redirect to /client if no page
    useEffect(() => {
        !page && navigate('/manager/dashboard/support/client');
    }, [page])

    // Get messages list
    const [paramsForGetMessages, setParamsForGetMessages] = useState({
        currentPage: null,
        userRole: page === 'client' || page === 'organizer' ? page : false,
        isArchived: page !== 'client' && page !== 'organizer' ? true : false
    })

    useEffect(() => {
        if (page === 'client' || page === 'organizer') {
            setMessages([]);
            setParamsForGetMessages({
                currentPage: 1,
                userRole: page,
                isArchived: false
            });
            setIsArchiveChatBtnVisible(true);
        }else {
            setMessages([]);
            setParamsForGetMessages({
                currentPage: 1,
                userRole: false,
                isArchived: true
            });
            setIsArchiveChatBtnVisible(false);
        }
    }, [page])

    const [isMessagesLoading, setIsMessagesLoading] = useState(true);
    const getMessages = () => {
        setIsMessagesLoading(true);

        httpAxios.get(`/api/support/get_message_previews`, {
            params: {
                page: paramsForGetMessages.currentPage,
                user_role: paramsForGetMessages.userRole,
                is_archived: paramsForGetMessages.isArchived
            }
        }).then(response => {
            console.log(response)
            setMessages([...messages, ...response.data.data]);
            setLastPage(response.data.last_page);
        }).finally(() => setIsMessagesLoading(false))
    }

    useEffect(() => {
        if (paramsForGetMessages.currentPage !== null) {
            getMessages();
        }
    }, [paramsForGetMessages])

    useEffect(() => {
        console.log('messages')
        console.log(messages)
    }, [messages])

    // Add new chat block preview by soketi
    // !!!!!
    // useEffect(() => {
    //
    //     if (outletContext.supportNotifications === undefined || outletContext.supportNotifications.length === 0) {
    //         return;
    //     }
    //
    //     console.log('outletContext.supportNotifications')
    //     console.log(outletContext.supportNotifications)
    //     console.log('messages!!!')
    //     console.log(messages)
    //
    //     outletContext.supportNotifications.forEach(supportNotification => {
    //         let result = false;
    //         let newChatBlock = undefined;
    //
    //         if (messages.find(message => message.client_id === supportNotification.client_id)) {
    //             newChatBlock = supportNotification;
    //             result = true;
    //         }
    //
    //         if (result === false) {
    //             console.log('supportNotification!!!!!!')
    //             console.log(supportNotification)
    //             // setMessages([{
    //             //
    //             // }, ...messages])
    //         }
    //     })
    // }, [outletContext, messages])

    const messagesList = messages.map((message, index) => {
        let supportNotification = outletContext.supportNotifications.find(notification => {
            return notification.client_id == message.client_id
        })
        let chatSelectBlockClasses = [classes.chat_select_block];

        if (supportNotification !== undefined && supportNotification.unread_message_count > 0) {
            chatSelectBlockClasses.push(classes.unread_chat_select_block);
        }

        return (
            <div
                key={index}
                className={chatSelectBlockClasses.join(" ")}
                onClick={() => {searchParams.set('client_id', message.client_id); setSearchParams(searchParams)}}
            >
                <div className={classes.user_name}>
                    {message.user.name}
                </div>

                <div className={[classes.message_text, "truncate"].join(" ")}>
                    {supportNotification !== undefined ? supportNotification.last_message : message.last_message.body}
                </div>

                <div className={classes.message_time}>
                    {supportNotification !== undefined ? moment(supportNotification.updated_at).format("DD.MM.Y HH:mm") : moment(message.last_message.created_at).format("DD.MM.Y HH:mm")}
                </div>

                {
                    supportNotification !== undefined && supportNotification.unread_message_count > 0 &&
                    <div className={classes.unread_count}>{supportNotification.unread_message_count}</div>
                }
            </div>
        )
    })

    // Load messages by scroll
    const observerBlock = useRef();
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('INNN')
                setIsObserverBlockEnter(true);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    useEffect(() => {
        if (isObserverBlockEnter && paramsForGetMessages.currentPage < lastPage && isMessagesLoading === false) {
            setParamsForGetMessages({
                ...paramsForGetMessages,
                currentPage: paramsForGetMessages.currentPage + 1
            })
            setIsObserverBlockEnter(false);
        }
    }, [isObserverBlockEnter, paramsForGetMessages.currentPage, isMessagesLoading]);

    const [isArchiveChatBtnVisible, setIsArchiveChatBtnVisible] = useState(true);

    return (
        <div className={classes.container}>
            {/* Chat select */}
            <div className={classes.chats_container}>
                <div className={classes.top_btns_container}>
                    <DraftTopMenuBtns menuBtns={topMenuBtns}/>
                </div>

                <hr/>

                <div className={classes.chat_select_container}>
                    {messagesList}

                    <div ref={observerBlock} style={{height: '10px'}}></div>

                    {isMessagesLoading && <PageLoader/>}
                </div>
            </div>


            {/* Chat */}
            {
                searchParams.get('client_id') !== null
                &&
                    <SupportChatContainer
                        isArchiveChatBtnVisible={isArchiveChatBtnVisible}
                        setIsArchiveChatBtnVisible={setIsArchiveChatBtnVisible}
                        clientId={searchParams.get('client_id')}
                    />
            }

        </div>
    );
};

export default SupportPage;