import React, {useContext, useEffect, useState} from 'react';
import classes from "./BookingEditBlock.module.scss";
import ExperienceTypeDotSelector from "../../common/ExperienceTypeDotSelector/ExperienceTypeDotSelector";
import moment from "moment";
import {fullTelMask, telMask} from "../../../../helpers/Masks";
import MiniCalendar from "../../common/MiniCalendar/MiniCalendar";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import ExcursionService from "../../../../API/ExcursionService";
import {useFetching} from "../../../../hooks/useFetching";
import {timeParse} from "../../../../helpers/TimeHelper";
import TimeSelector from "../../common/TimeSelector/TimeSelector";
import {httpAxios} from "../../../../API/HttpAxios";
import AuthContext from "../../../../context/AuthContext";

const BookingEditBlock = ({booking, switchBookingEditingVisibility}) => {
    const {...context} = useContext(AuthContext);
    // let datetime = moment(booking.date + ' ' + booking.time);
    const [userName, setUserName] = useState(booking.user.name);
    const [userPhoneNumber, setUserPhoneNumber] = useState(booking.user.phone_number);
    const [excursionId, setExcursionId] = useState(booking.excursion.id);
    const [previousExcursionId, setPreviousExcursionId] = useState(booking.excursion.id);
    const [excursionAvailableTypes, setExcursionAvailableTypes] = useState([]);
    const [bookingType, setBookingType] = useState(booking.type.name);
    const [excursionSchedule, setExcursionSchedule] = useState([]);
    const [excursionTitle, setExcursionTitle] = useState(booking.excursion.title);
    // const [experienceType, setExperienceType] = useState(booking.type_info.name);
    // const [bookingDatetime, setBookingDatetime] = useState(moment(booking.date + ' ' + booking.time));
    const [bookingDate, setBookingDate] = useState(moment(booking.date));
    const [bookingTime, setBookingTime] = useState(moment(booking.date + ' ' + booking.time));
    const [bookingMembers, setBookingMembers] = useState(booking.members);
    const [bookingPrepaymentAmount, setBookingPrepaymentAmount] = useState(booking.prepayment_amount);
    const [bookingTotalAmount, setBookingTotalAmount] = useState(booking.total_amount);
    const [employeeComment, setEmployeeComment] = useState(booking.employee_comment);

    // Send to server for change booking
    // [{"count": 2, "discount_id": null, "member_type": "adults", "discount_type": null, "initial_price": 2900, "discount_price": 2900, "discount_value": null, "member_type_id": 1, "discount_active": null, "discount_ended_at": null, "discount_started_at": null, "teplohod_category_id": 1}]
    let data = {
        user: {
            name: userName,
            phone: userPhoneNumber
        },

        excursionId: excursionId,
        typeAlias: bookingType,
        // typeAlias: currentSchedule,
        date: bookingDate.format("YYYY-MM-DD"),
        time: bookingTime.format("HH:mm:ss"),
        members: bookingMembers.map((member) => {
            return {
                count: member.count,
                member_type: member.member_type,
                initial_price: member.initial_price,
                discount_price: member.discount_price
            }
        }),
        // members: [
        //     {
        //         count: 2,
        //         member_type: '',
        //         initial_price: 2900,
        //         discount_price: 2900,
        //     }
        // ],
        prepayment_amount: bookingPrepaymentAmount,
        total_amount: bookingTotalAmount,
        manager_comment: employeeComment,

    }

    // Prepare booking data
    const [bookingData, setBookingData] = useState({});

    useEffect(() => {
        setBookingData({
            bookingId: booking.id,
            user: {
                name: userName,
                phone: userPhoneNumber
            },

            excursionId: excursionId,
            typeAlias: bookingType,
            // typeAlias: currentSchedule,
            date: bookingDate.format("YYYY-MM-DD"),
            time: bookingTime.format("HH:mm:ss"),
            members: bookingMembers.map((member) => {
                return {
                    count: member.count,
                    member_type: member.member_type,
                    member_name: member.name,
                    initial_price: member.initial_price,
                    discount_price: member.discount_price
                }
            }),
            // members: [
            //     {
            //         count: 2,
            //         member_type: '',
            //         initial_price: 2900,
            //         discount_price: 2900,
            //     }
            // ],
            prepayment_amount: bookingPrepaymentAmount,
            total_amount: bookingTotalAmount,
            manager_comment: employeeComment,

        })
    }, [userName, userPhoneNumber, excursionId, bookingType, bookingDate, bookingTime, bookingMembers, bookingPrepaymentAmount, bookingTotalAmount, employeeComment])

    const [fetchEditBooking, isEditBookingLoading, editBookingError] = useFetching(async () => {
        let response = await ExcursionService.editBooking(bookingData);

        if (response.status === 200) {
            window.location.reload();
        }
        // console.log(response);
    })

    // useEffect(() => {
    //     console.log("Booking:")
    //     console.log(booking)
    // }, [booking])

    // useEffect(() => {
    //     console.log('bookingDate')
    //     console.log(bookingDate.format("YYYY-MM-DD"))
    // }, [bookingDate])

    // Change user name
    const changeUserName = (e) => {
        setUserName(e.target.value);
    }

    // Change user phone number
    const changeUserPhoneNumber = (e) => {
        setUserPhoneNumber(e.target.value);
    }

    // Change excursion id
    const changeExcursionId = (e) => {
        let intValue = e.target.value.replace(/\D/g, "");
        setExcursionId(intValue);
        // get excursion title
    }

    // Get excursion info by change excursion id
    const [fetchExcursionInfo, isExcursionInfoLoading, excursionInfoError] = useFetching(async () => {
        let response = await ExcursionService.getCurrentExcursion(excursionId);
        let data = response.data;
        setExcursionTitle(data.title);
        setExcursionAvailableTypes(data.types);
        // console.log(data)
    })

    // Get excursion schedule
    const [fetchExcursionSchedule, isExcursionScheduleLoading, excursionScheduleError] = useFetching(async () => {
        const response = await ExcursionService.getSchedule(excursionId);
        const data = response.data;
        setExcursionSchedule(data);
        // console.log('excursion schedule')
        // console.log(data)
    })

    // Get excursion info by change excursion id
    useEffect(() => {
        fetchExcursionInfo();
        fetchExcursionSchedule();
    }, [excursionId])

    // Get new schedule by change excursion type
    useEffect(() => {
        fetchExcursionSchedule();
        // console.log('bookingType')
        // console.log(bookingType)
    }, [bookingType])

    // const changeBookingDate = (e) => {
    //
    // }

    // const changeBookingTime = (e) => {
    //
    // }

    const [availableTimes, setAvailableTimes] = useState([]);

    useEffect(() => {
        if (excursionSchedule.hasOwnProperty(`${bookingType}`)) {
            let times = excursionSchedule[`${bookingType}`].filter((day) => {
                return day.date === bookingDate.format("YYYY-MM-DD");
            })

            setAvailableTimes(times.map((day) => {
                return moment(`${day.date} ${day.time}`, 'YYYY-MM-DD HH:mm:ss');
            }))
        }
    }, [excursionSchedule, bookingType, bookingDate])

    // useEffect(() => {
    //     console.log('availableTimes')
    //     console.log(availableTimes)
    // }, [availableTimes])

    // const [currentSchedule, setCurrentSchedule] = useState([]);
    //
    // useEffect(() => {
    //     if (excursionSchedule.hasOwnProperty(`${bookingType}`)) {
    //         setCurrentSchedule(excursionSchedule[`${bookingType}`])
    //     }
    // }, [excursionSchedule, bookingType])
    //
    // useEffect(() => {
    //     console.log('currentSchedule')
    //     console.log(currentSchedule)
    // }, [currentSchedule])


    const changeMemberName = (e) => {
        let i = e.target.getAttribute("data-member-index");

        const newBookingMembers = bookingMembers.map((member, index) => {
            if (index == i) {
                return {
                    ...member,
                    name: e.target.value
                }
            }else {
                return member;
            }
        })

        setBookingMembers(newBookingMembers);
    }

    const changeMemberDiscountPrice = (e) => {
        let intValue = +e.target.value.replace(/\D/g, "");
        e.target.value = intValue;
        let i = e.target.getAttribute("data-member-index");

        const newBookingMembers = bookingMembers.map((member, index) => {
            if (index == i) {
                return {
                    ...member,
                    discount_price: intValue
                }
            }else {
                return member;
            }
        })

        setBookingMembers(newBookingMembers);
    }

    const changeMemberCount = (e) => {
        let intValue = +e.target.value.replace(/\D/g, "");
        e.target.value = intValue;
        let i = e.target.getAttribute("data-member-index");

        const newBookingMembers = bookingMembers.map((member, index) => {
            if (index == i) {
                return {
                    ...member,
                    count: intValue
                }
            }else {
                return member;
            }
        })

        setBookingMembers(newBookingMembers);
    }

    const removeMember = (e) => {
        setBookingMembers(bookingMembers.filter((member, index) => index != e.target.getAttribute("data-member-index")));
    }

    const addMember = () => {
        setBookingMembers([...bookingMembers, {
            name: "",
            discount_price: 0,
            count: 0
        }]);
    }

    const changeBookingPrepaymentAmount = (e) => {
        let intValue = +e.target.value.replace(/\D/g, "");
        if (bookingTotalAmount - intValue < 0) {
            intValue = bookingTotalAmount;
        }
        e.target.value = intValue;
        setBookingPrepaymentAmount(intValue);
    }

    // Change booking total amount
    useEffect(() => {
        let tempTotalAmount = 0;

        bookingMembers.map((member) => {
            if (member.discount_price && member.count) {
                tempTotalAmount += member.discount_price * member.count;
            }
        })

        setBookingTotalAmount(tempTotalAmount);
    }, [bookingMembers])


    // Change employee comment
    const changeEmployeeComment = (e) => {
        setEmployeeComment({...employeeComment, body: e.target.value});
    }

    // useEffect(() => {
    //     console.log("Booking members:")
    //     console.log(bookingMembers)
    // }, [bookingMembers])

    // Switch calendar visibility
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [miniCalendarClasses, setMiniCalendarClasses] = useState([classes.mini_calendar_container]);

    const switchCalendarVisibility = (e) => {
        setIsCalendarVisible(!isCalendarVisible);
    }

    const hideCalendar = (e) => {
        setIsCalendarVisible(false);
    }

    useEffect(() => {
        if (isCalendarVisible) {
            setMiniCalendarClasses([classes.mini_calendar_container]);
        }else {
            setMiniCalendarClasses([classes.mini_calendar_container, "hidden"]);
        }
    }, [isCalendarVisible])

    const miniCalendar = useOutsideClick(hideCalendar);

    // Cancel booking
    const [cancelBtnText, setCancelBtnText] = useState('Отменить заказ');
    const [cancellationReason, setCancellationReason] = useState('');
    const [isCancellationVisible, setIsCancellationVisible] = useState(false);

    const [isCancelBookingLoading, setIsCancelBookingLoading] = useState(false);
    const [cancelBookingError, setCancelBookingError] = useState('');
    const cancelBooking = () => {
        setIsCancelBookingLoading(true);
        setCancelBookingError('');

        httpAxios.post('/api/booking/cancel_booking', {
            booking_id: booking.id,
            reason: cancellationReason
        }).then(response => {
            console.log(response);
            window.location.reload();
        }).catch(error => {
            console.log(error);
            setCancelBookingError(error.response.data.message);
            setIsCancellationVisible(true);
            setCancelBtnText('Точно отменить заказ!');
        }).finally(() => {
            setIsCancelBookingLoading(false);
        })
    }



    return (
        <div
            className={[classes.booking_edit_block, "hidden"].join(" ")}
            id={`booking_edit_block_${booking.id}`}
        >
            {/* Edit client personal info */}
            <div className={classes.edit_client_info_row}>
                <div className={classes.edit_client_name_block}>
                    <div>
                        Имя гостя:
                    </div>

                    <input
                        className={"temp_input"}
                        type={"text"}
                        value={userName}
                        onChange={changeUserName}
                    />
                </div>

                <div className={classes.edit_client_phone_block}>
                    <div>
                        Телефон гостя:
                    </div>

                    <input
                        className={"temp_input"}
                        type={"text"}
                        value={userPhoneNumber}
                        onChange={changeUserPhoneNumber}
                    />
                </div>
            </div>

            {/* Edit experience id */}
            <div className={classes.experience_title_row}>
                {excursionTitle}
            </div>

            <div className={classes.experience_id_row}>
                <div>
                    ID экскурсии:
                </div>

                <input
                    className={["temp_input", classes.experience_id_input].join(" ")}
                    type={"text"}
                    placeholder={"Введите ID"}
                    value={excursionId}
                    onChange={changeExcursionId}
                />

                <div
                    className={[classes.experience_id_btn, "temp_btn"].join(' ')}
                    onClick={() => setExcursionId(previousExcursionId)}
                >
                    Изначальный ID
                </div>
            </div>

            {/* Edit experience type */}
            <ExperienceTypeDotSelector
                availableTypes={excursionAvailableTypes}
                selectedType={bookingType}
                setSelectedType={setBookingType}
                // onChange={changeType}
            />

            {/* Edite experience datetime */}
            <div className={classes.experience_date_row}>
                <div>
                    Дата впечатления:
                </div>

                <div
                    className={[classes.show_calendar_btn_container].join(" ")}
                    ref={miniCalendar}
                >
                    <div className={classes.show_calendar_btn_block}>
                        <div
                            onClick={switchCalendarVisibility}
                            className={"temp_btn"}
                        >
                            {bookingDate.format('DD.MM.YYYY')}
                        </div>

                        <div className={classes.date_unavailable_msg}>
                            {excursionSchedule[`${bookingType}`] && excursionSchedule[`${bookingType}`].find((availableDate) => availableDate.date === bookingDate.format('YYYY-MM-DD')) ? "" : "Выбранная дата недоступна"}
                        </div>
                    </div>


                    <div
                        className={miniCalendarClasses.join(" ")}
                    >
                        <MiniCalendar
                            availableDates={excursionSchedule[`${bookingType}`]}
                            onDateClick={setBookingDate}
                            selectedDate={bookingDate}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.experience_time_row}>
                <div>
                    Время впечатления:
                </div>

                <TimeSelector
                    availableTimes={availableTimes}
                    selectedTime={bookingTime}
                    selectTime={setBookingTime}
                />
            </div>

            {/* Previous booking info */}
            <div className={classes.previous_booking_info_container}>
                <div className={classes.previous_booking_info_title}>
                    Предыдущее бронирование и стоимость:
                </div>

                <div className={classes.previous_booking_info_block}>
                    {
                        booking.members.map((member, i) => {
                            return (
                                <div
                                    className={classes.previous_booking_info_row}
                                    key={`previous_booking_info_row_${i}`}
                                >
                                    <div className={classes.ticket_type}>
                                        {member.name}:
                                    </div>

                                    <div className={classes.ticket_price}>
                                        {member.discount_price} руб.
                                    </div>

                                    <div>x</div>

                                    <div className={classes.ticket_count}>
                                        {member.count}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Edit member types */}
            <div className={classes.edit_member_types_block}>
                <div className={classes.edit_member_types_title}>
                    Категории билетов
                </div>

                {
                    bookingMembers.map((member, i) => {
                        return (
                            <div
                                className={classes.edit_member_type_row}
                                key={i}
                            >
                                <input
                                    className={[classes.edit_member_type_input, "temp_input"].join(' ')}
                                    type={"text"}
                                    value={member.name}
                                    data-member-index={i}
                                    onChange={(e) => changeMemberName(e)}
                                />

                                <div className={classes.member_price_block}>
                                    <input
                                        className={["temp_input", classes.member_price_input].join(" ")}
                                        type={"text"}
                                        value={member.discount_price}
                                        data-member-index={i}
                                        onChange={changeMemberDiscountPrice}
                                    />

                                    <span>
                                        руб.
                                    </span>
                                </div>

                                <div className={classes.member_count_icon}>
                                    x
                                </div>

                                <div className={classes.edit_member_count_block}>
                                    {/*<div className={["temp_btn", classes.member_count_btn].join(" ")}>-</div>*/}

                                    {/*<div>*/}
                                        <input
                                            className={["temp_input", classes.member_count_input].join(" ")}
                                            type={"text"}
                                            data-member-index={i}
                                            value={member.count}
                                            onChange={e => changeMemberCount(e)}
                                        />

                                        <span>
                                            чел.
                                        </span>
                                    {/*</div>*/}


                                    {/*<div className={["temp_btn", classes.member_count_btn].join(" ")}>+</div>*/}
                                </div>

                                <div
                                    className={["temp_btn", classes.member_type_delete_btn].join(" ")}
                                    data-member-index={i}
                                    onClick={removeMember}
                                >
                                    {/*Удалить*/}
                                    x
                                </div>
                            </div>
                        )
                    })
                }


                <div
                    className={["temp_btn", classes.member_count_btn].join(" ")}
                    onClick={addMember}
                >
                    +
                </div>
            </div>

            <div>
                Гость оставил предоплату: {booking.prepayment_amount} руб.
            </div>

            <div className={classes.prepayment_row}>
                <div>
                    Изменить предоплату:
                </div>

                <input
                    className={[classes.prepayment_input, "temp_input"].join(' ')}
                    type={"number"}
                    value={bookingPrepaymentAmount}
                    onChange={changeBookingPrepaymentAmount}
                />

                <div>
                    руб.
                </div>
            </div>

            <div className={classes.postpayment_row}>
                <div>
                    Сумма оплаты на месте:
                </div>

                <div>
                    {bookingTotalAmount - bookingPrepaymentAmount}
                </div>

                <div>
                    руб.
                </div>
            </div>

            <div className={classes.manager_comment_edit_block}>
                <div className={classes.manager_comment_edit_title}>
                    Комментарий менеджера:
                </div>

                {
                    context.user.role === "manager"
                    ?
                        <textarea
                            className={["temp_input", classes.manager_comment_edit_textarea].join(" ")}
                            value={employeeComment ? employeeComment.body : ""}
                            rows={5}
                            onChange={changeEmployeeComment}
                        ></textarea>
                    :
                        <div>{employeeComment ? employeeComment.body : ""}</div>
                }

            </div>

            {
                isCancellationVisible &&
                <div className={classes.cancellation_reason_block}>
                    <div className={classes.cancellation_reason_title}>
                        Причина отмены заказа:
                    </div>

                    <textarea
                        className={["temp_input", classes.manager_comment_edit_textarea].join(" ")}
                        value={cancellationReason}
                        rows={5}
                        onChange={e => setCancellationReason(e.target.value)}
                    ></textarea>
                </div>
            }


            <div className={classes.booking_error}>
                {cancelBookingError}
            </div>

            <div className={classes.edit_btn_block}>
                <div className={classes.edit_btns}>
                    <div
                        className={"temp_btn"}
                        onClick={fetchEditBooking}
                    >
                        Сохранить
                    </div>

                    <div
                        className={"temp_btn"}
                        data-booking-id={booking.id}
                        onClick={e => switchBookingEditingVisibility(e)}
                    >
                        Отменить изменения
                    </div>
                </div>

                <div
                    className={[classes.cancel_booking_btn, "temp_btn", 'temp_btn_delete'].join(' ')}
                    onClick={cancelBooking}
                >
                    {cancelBtnText}
                </div>
            </div>
        </div>
    );
};

export default BookingEditBlock;