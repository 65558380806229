import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from './HbLikeIcon.module.scss';
import Like from "../../../images/icons/Like.svg";
import LikeFill from "../../../images/icons/LikeFill.svg";
import LikeOutline from "../../../images/icons/LikeOutline.svg";
import UserService from "../../../API/UserService";
import AuthContext from "../../../context/AuthContext";
import {useUserWishlist} from "../../../hooks/useUserWishlist";
import {isFullObj} from "../../../helpers/ObjHelper";

const HbLikeIcon = ({likeClick, setLikeClick, excursion_id, likeImg = 'like'}) => {
    const {...context} = useContext(AuthContext);
    // const {isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser} = useContext(AuthContext);
    const likeRef = useRef();

    // Save/delete excursion to favorite
    const savingDeleteToFavorites = async (event) => {
        if (context.isAuth) {
            try {
                likeRef.current.setAttribute('src', LikeFill);
                likeRef.current.classList.add(classes.like_btn_block_loading);

                const response = await UserService.saveDeleteWishExcursion(excursion_id);

                if (response.data === 'deleted') {
                    likeRef.current.setAttribute('src', likeImg === 'outline' ? LikeOutline : Like);
                }
            }catch (e) {
                likeRef.current.setAttribute('src', likeImg === 'outline' ? LikeOutline : Like);
            }finally {
                likeRef.current.classList.remove(classes.like_btn_block_loading);

                const response = await UserService.getUserWishlist();
                context.setUser({...context.user, wishlist: response.data.wishlist});
            }
        }else {
            context.setIsAuthVisible(true);
        }

        setLikeClick(false);
    }

    useEffect(() => {
        if (likeClick) {
            savingDeleteToFavorites();
        }
    }, [likeClick])

    // Find in wishlist
    useEffect(() => {
        if (context.isAuth && context.user.hasOwnProperty('wishlist')) {
            const findWish = context.user.wishlist.find(el => el.excursion_id === excursion_id);
            if (findWish !== undefined) {
                likeRef.current.setAttribute('src', LikeFill);
            }else {
                likeRef.current.setAttribute('src', likeImg === 'outline' ? LikeOutline : Like);
            }
        }
    }, [context, excursion_id])

    // useEffect(() => {
    //     console.log('context')
    //     console.log(context)
    // }, [context]);

    return (
        <div
            className={classes.like_btn_block}
        >
            <img
                className={classes.like_img}
                ref={likeRef}
                src={likeImg === 'outline' ? LikeOutline : Like}
                width={25}
                alt={'like'}
            />
        </div>
    );
};

export default HbLikeIcon;