import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from "./ExcursionPreviewMin.module.scss";
import { baseURL } from "../../../API/HttpAxios";
import ArrowLeft from '../../../images/icons/ArrowLeft.svg';
import ArrowRight from '../../../images/icons/ArrowRight.svg';
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import HbLikeIcon from "../HbLikeIcon/HbLikeIcon";
import { priceFormatting } from "../../../helpers/PriceHelper";
import { useYandexSplit } from 'hooks/useYandexSplit';
import RatingStarFilled from "../../../images/icons/RatingStarFilled.svg";
import { useObserverBlockEntry } from 'hooks/useObserverBlockEntry';

const ExcursionPreviewMin = (props) => {
    // const navigate = useNavigate();
    // const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    
    // const excursionPreviews = {};

    // excursionPreviews[props.excursion_id] = useRef();
    // const isExcursionPreviewVisible = useObserverBlockEntry(excursionPreviews[props.excursion_id]);

    const excursionPreview = useRef();
    const isExcursionPreviewVisible = useObserverBlockEntry(excursionPreview);

    useEffect(() => {
        console.log(isExcursionPreviewVisible);
        const excursionImg = document.getElementById(`excursion_img_block_${props.excursion_id}`).firstChild.firstChild.firstChild;

        if (isExcursionPreviewVisible && excursionImg !== undefined) {
            if (excursionImg.getAttribute('src') === '') {
                excursionImg.setAttribute('src', excursionImg.getAttribute('data-src'));
            }
        }
        
    }, [isExcursionPreviewVisible]);

    // Resize img onload
    const resizeImg = (event, index) => {
        // Resize img
        const currentImg = event.currentTarget;
        if (currentImg.getAttribute('src') === '') return;

        if (currentImg.width > currentImg.height) {
            currentImg.classList.add(classes.excursion_img_width_larger);
        } else {
            currentImg.classList.add(classes.excursion_img_height_larger);
        }

        currentImg.classList.remove('hidden');

        // Add src to next img
        if (index > 0) {
            const nextParent = currentImg.parentElement.nextElementSibling;

            if (nextParent !== null) {
                const nextImg = nextParent.firstChild;
                nextImg.setAttribute('src', nextImg.getAttribute('data-src'));
            }
        }
    }

    // Func for scrolling by arrows
    // const scrollByArrows = (event) => {
    //     event.stopPropagation();
    //     event.preventDefault();

    //     const scrollSize = document.getElementById(props.excursion_id).clientWidth;
    //     const scrollLeft = document.getElementById(`excursion_img_block_${props.excursion_id}`).scrollLeft;
    //     const imgRowSize = document.getElementById(`img_row_${props.excursion_id}`).clientWidth;
    //     const activeNumber = Math.ceil(scrollLeft / scrollSize);

    //     if (event.currentTarget.id === `right_arrow_block_${props.excursion_id}` && scrollLeft < imgRowSize - scrollSize) {
    //         document.getElementById(`excursion_img_block_${props.excursion_id}`).scrollLeft += scrollSize;
    //     }

    //     if (event.currentTarget.id === `left_arrow_block_${props.excursion_id}` && scrollLeft > 0) {
    //         document.getElementById(`excursion_img_block_${props.excursion_id}`).scrollLeft -= scrollSize;
    //     }
    // }

    // Func for visible arrows by hover
    // const visibleArrows = () => {
    //     const scrollSize = document.getElementById(props.excursion_id).clientWidth;
    //     const scrollLeft = document.getElementById(`excursion_img_block_${props.excursion_id}`).scrollLeft;
    //     const imgRowSize = document.getElementById(`img_row_${props.excursion_id}`).clientWidth;

    //     if (scrollLeft >= scrollSize - 10) {
    //         document.getElementById(`left_arrow_block_${props.excursion_id}`).classList.remove('hidden');
    //     } else {
    //         document.getElementById(`left_arrow_block_${props.excursion_id}`).classList.add('hidden');
    //     }

    //     if (scrollLeft < imgRowSize - scrollSize) {
    //         document.getElementById(`right_arrow_block_${props.excursion_id}`).classList.remove('hidden');
    //     } else {
    //         document.getElementById(`right_arrow_block_${props.excursion_id}`).classList.add('hidden');
    //     }
    // }

    // Func for hidden arrows
    // const invisibleArrows = () => {
    //     document.getElementById(`left_arrow_block_${props.excursion_id}`).classList.add('hidden');
    //     document.getElementById(`right_arrow_block_${props.excursion_id}`).classList.add('hidden');
    // }

    // Add src to second img
    // const setImgSrc = () => {
    //     const excursionImgs = document.getElementById(`img_row_${props.excursion_id}`).children;
    //     if (excursionImgs.length > 1) {
    //         let imgElement = excursionImgs[1].firstChild;

    //         if (imgElement.getAttribute('src') === '') {
    //             imgElement.setAttribute('src', imgElement.getAttribute('data-src'));
    //         }
    //     }
    // }

    // Func for animate indicators & hidden arrows
    // const highlightIndicatorByScroll = (event) => {
    //     visibleArrows();
    //     const scrollSize = document.getElementById(props.excursion_id).clientWidth;
    //     const scrollLeft = event.target.scrollLeft;
    //     const imgRowSize = document.getElementById(`img_row_${props.excursion_id}`).clientWidth;
    //     const activeNumber = Math.ceil(scrollLeft / scrollSize);
    //     const lastNumber = Math.ceil(imgRowSize / scrollSize);

    //     const indicatorElements = document.getElementsByClassName(`indicators_${props.excursion_id}`);
    //     for (let indicatorElement of indicatorElements) {
    //         indicatorElement.classList.remove(classes.carousel_indicator_active);
    //     }

    //     if (activeNumber === 0) {
    //         document.getElementById(`indicator_${props.excursion_id}_0`).classList.add(classes.carousel_indicator_active);
    //     } else if (activeNumber === 1) {
    //         document.getElementById(`indicator_${props.excursion_id}_1`).classList.add(classes.carousel_indicator_active);
    //     } else if (activeNumber >= 2 && activeNumber <= lastNumber - 3) {
    //         document.getElementById(`indicator_${props.excursion_id}_2`).classList.add(classes.carousel_indicator_active);
    //     } else if (activeNumber === lastNumber - 2) {
    //         document.getElementById(`indicator_${props.excursion_id}_3`).classList.add(classes.carousel_indicator_active);
    //     } else if (activeNumber === lastNumber - 1) {
    //         document.getElementById(`indicator_${props.excursion_id}_4`).classList.add(classes.carousel_indicator_active);
    //     }

    //     setImgSrc();
    // }

    // Save/delete excursion to favorite
    const [likeClick, setLikeClick] = useState(false);
    const handleLikeClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        setLikeClick(true);
    }

    // Set yandex split badge
    // const [YaPay, paymentData, setPaymentData] = useYandexSplit({timeOut: 3000});


    return (
        <Link
            ref={excursionPreview}
            id={props.excursion_id}
            className={[classes.excursion_block, props.className].join(' ')}
            // onMouseEnter={visibleArrows}
            // onMouseLeave={invisibleArrows}
            to={`/e/${props.excursion_id}?type=${props.type}`}
            reloadDocument
        >
            <div
                className={`roundblock_angle_lb ${classes.round_block_preview}`}
            >
                <div
                    id={`excursion_img_block_${props.excursion_id}`}
                    className={classes.excursion_img_block}
                    // onScroll={highlightIndicatorByScroll}
                >
                    <div
                        id={`img_row_${props.excursion_id}`}
                        className={classes.excursion_img_row}
                        style={{ width: `${props.imgs.length * 100}%` }}
                    >
                        {
                            props.imgs.map((img, index) => {
                                return (
                                    <div
                                        id={`${props.excursion_id}__${img.id}`}
                                        key={`block_${img.img_path}`}
                                        className={['roundblock_angle_lb', classes.excursion_img, 'img_block_preload'].join(' ')}
                                    >
                                        <img
                                            className={'hidden'}
                                            id={`${props.excursion_id}_${img.id}`}
                                            key={img.img_path}
                                            src={''}
                                            // src={index < 1 ? `${baseURL}${img.img_path}` : ''}
                                            alt={''}
                                            data-src={`${baseURL}${img.img_path}`}
                                            onLoad={(event) => resizeImg(event, index)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div
                    onClick={handleLikeClick}
                    className={classes.like_btn_block}
                >
                    <HbLikeIcon
                        likeClick={likeClick}
                        setLikeClick={setLikeClick}
                        excursion_id={props.excursion_id}
                    />
                </div>

                {/*<div className={`${classes.places_limit}`}>*/}
                {/*    Осталось мало мест!*/}
                {/*</div>*/}
{/* 
                <div
                    id={`left_arrow_block_${props.excursion_id}`}
                    className={[classes.carousel_arrow_block, classes.carousel_arrow_left_block, 'hidden'].join(' ')}
                    onClick={scrollByArrows}
                >
                    <img
                        src={ArrowLeft}
                        height={15}
                        alt={'left'}
                    />
                </div>

                <div
                    id={`right_arrow_block_${props.excursion_id}`}
                    className={[classes.carousel_arrow_block, classes.carousel_arrow_right_block, 'hidden'].join(' ')}
                    onClick={scrollByArrows}
                >
                    <img
                        src={ArrowRight}
                        height={15}
                        alt={'right'}
                    />
                </div> */}

                {/* <div className={classes.carousel_indicators_row}>
                    <div className={classes.carousel_indicators_block}>
                        <div
                            id={`indicator_${props.excursion_id}_0`}
                            className={[classes.carousel_indicator, classes.carousel_indicator_active, `indicators_${props.excursion_id}`].join(' ')}>

                        </div>
                        <div
                            id={`indicator_${props.excursion_id}_1`}
                            className={[classes.carousel_indicator, `indicators_${props.excursion_id}`].join(' ')}>

                        </div>
                        <div
                            id={`indicator_${props.excursion_id}_2`}
                            className={[classes.carousel_indicator, `indicators_${props.excursion_id}`].join(' ')}>

                        </div>
                        <div
                            id={`indicator_${props.excursion_id}_3`}
                            className={[classes.carousel_indicator, `indicators_${props.excursion_id}`].join(' ')}>

                        </div>
                        <div
                            id={`indicator_${props.excursion_id}_4`}
                            className={[classes.carousel_indicator, `indicators_${props.excursion_id}`].join(' ')}>

                        </div>
                    </div>
                </div> */}

                {
                    props.discount_value !== null && props.discount_value > 0 &&
                    <div className={classes.discount_badge}>
                        -{props.discount_value}%
                    </div>
                }
            </div>



            <div className={`${classes.excursion_rating_location}`}>
                <div className={`${classes.excursion_rating_block}`}>
                    <img
                        className={classes.excursion_rating_star}
                        src={RatingStarFilled}
                    />
                    {/* <div className={`${classes.excursion_rating_dot}`}></div> */}

                    <span className={`${classes.excursion_rating}`}>
                        {props.rating.toFixed(1)}
                    </span>

                    <span className={`${classes.excursion_reviews_count}`}>
                        ({props.reviews_count})
                    </span>
                </div>

                <div className={`${classes.excursion_location}`}>
                    {props.city_name}
                </div>
            </div>

            <div className={`${classes.excursion_title} truncate_multiline`}>
                {props.title}
            </div>

            {/* <div className={`${classes.excursion_duration_group}`}>
                <span className={`${classes.excursion_duration}`}>
                    {props.duration}
                </span> */}

                {/*<span className={`${classes.excursion_group}`}>*/}
                {/*    <span className={`${classes.excursion_group_dot}`}></span>*/}
                {/*    {props.type}*/}
                {/*</span>*/}
            {/* </div> */}

            <div className={`${classes.excursion_price_block}`}>
                {/* <span>
                    от
                </span> */}

                <span className={`${classes.excursion_price}`}>
                    {
                        props.discount_price && props.discount_price !== props.initial_price &&
                        <span className={[classes.excursion_old_price, 'strike-center'].join(' ')}>
                            {priceFormatting(props.initial_price)}
                            {/*<span className={"verdana_regular"}>₽</span>*/}
                        </span>
                    }

                    {
                        props.discount_price && props.discount_price !== props.initial_price
                            ?
                            <span className={classes.discount_price}>
                                {priceFormatting(props.discount_price)}
                            </span>

                            :
                            priceFormatting(props.initial_price)
                    }
                    {/*<span className={"verdana_regular"}>₽</span>*/}
                </span>

                <span className={`${classes.excursion_price_per}`}>
                    за {props.type === "INDIVIDUAL" ? "впечатление" : "гостя"}
                </span>

            </div>

            {/* <div className={classes.yandes_split_badge}>
                <yandex-pay-badge
                    type="bnpl"
                    amount={props.discount_price ? props.discount_price : props.initial_price}
                    size="m"
                    variant="detailed"
                    color="primary"
                    merchant-id={process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID}
                />
            </div> */}
        </Link>
    );
};

export default ExcursionPreviewMin;