import React, {createContext, useContext, useEffect, useState} from 'react';
import classes from "./WorkerDashboardLayout.module.scss";
import Close from "../../../images/icons/Close.svg";
import {Link, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import LogoDesktop from "../../../images/logos/LogoDesktopNEW.svg";
import UserDropdownMenu from "../../UI/UserDropdownMenu/UserDropdownMenu";
import MobileNavigationBar from "../MobileNavigationBar/MobileNavigationBar";
import FooterLayout from "../FooterLayout/FooterLayout";
import SideMenu from "../../dashboard/common/SideMenu/SideMenu";
import AuthContext from "../../../context/AuthContext";
import {log} from "util";
import {httpAxios} from "../../../API/HttpAxios";
import LaravelEcho from "../../../API/LaravelEcho";

const WorkerDashboardLayout = ({role}) => {
    const {...context} = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [bookingNotifications, setBookingNotifications] = useState([]);

    const [bookingNotificationCount, setBookingNotificationCount] = useState(0);
    const [excursionsNotificationCount, setExcursionsNotificationCount] = useState(0);
    const [reviewNotificationCount, setReviewNotificationCount] = useState(0);

    //// COMMON NOTIFICATIONS

    // Get booking notification count
    const getBookingNotifications = () => {
        httpAxios.get('/api/booking/get_booking_notifications')
            .then(response => {
                console.log('getBookingNotifications')
                console.log(response)
                    if (response.status === 200) {
                        setBookingNotifications(response.data);
                    }
                }
            )
    }

    // Get booking notification count
    useEffect(() => {
        console.log('bookingNotifications')
        console.log(bookingNotifications)
        let tempBookingNotificationCount = 0;

        for (const bookingNotification of bookingNotifications) {
            if (bookingNotification.unread_messages_count > 0) {
                tempBookingNotificationCount += 1;
            }
        }

        setBookingNotificationCount(tempBookingNotificationCount);
    }, [bookingNotifications])

    //// MANAGER NOTIFICATIONS

    // Get suggested excursion count
    const getSuggestedExcursionCount = () => {
        httpAxios.get('/api/excursions/get_suggested_excursion_count')
            .then(response => {
                if (response.status === 200) {
                    setExcursionsNotificationCount(response.data.suggested_count);
                }
            }
        )
    }
    
    useEffect(() => {
        if (context.user.role === 'manager') getSuggestedExcursionCount();
    }, [])


    // Get review count for moderation for manager
    const getReviewCountForModeration = () => {
        httpAxios.get('/api/review/get_review_count_for_moderation')
            .then(response => {
                if (response.status === 200) {
                    setReviewNotificationCount(response.data);
                }
            }
        )
    }

    useEffect(() => {
        if (context.user.role === 'manager') getReviewCountForModeration();
    }, [])

    //// ORGANIZER NOTIFICATIONS

    // Get review count that needs answer for ORGANIZER
    const getReviewCountThatNeedsAnswer = () => {
        httpAxios.get('/api/review/get_review_count_that_needs_answer')
            .then(response => {
                if (response.status === 200) {
                    setReviewNotificationCount(response.data);
                }
            }
        )
    }

    useEffect(() => {
        if (context.user.role === 'organizer') getReviewCountThatNeedsAnswer();
    }, [])


    useEffect(() => {
        console.log("KEK")
        getBookingNotifications();
    }, [])

    // useEffect(() => {
    //     console.log(context)
    //     // getBookingNotifications();
    //
    //     if (context.user.role === 'manager') {
    //         getReviewCountForModeration();
    //     }else if(context.user.role === 'organizer') {
    //         getReviewCountThatNeedsAnswer();
    //     }
    //
    // }, [context])

    // Get support notifications
    const [supportNotifications, setSupportNotifications] = useState([]);
    const [supportNotificationCount, setSupportNotificationCount] = useState(0);

    const getSupportNotifications = () => {
        httpAxios.get('/api/support/get_support_notifications')
            .then(response => {
                if (response.status === 200) {
                    setSupportNotifications(response.data);
                }
            })
    }

    useEffect(() => {
        getSupportNotifications();
    }, [])

    useEffect(() => {
        console.log('supportNotifications')
        console.log(supportNotifications)

        // setSupportNotificationCount(supportNotifications.length);
        let tempSupportNotificationCount = 0;

        for (const supportNotification of supportNotifications) {
            if (supportNotification.unread_message_count > 0) {
                tempSupportNotificationCount += 1;
            }

        }

        setSupportNotificationCount(tempSupportNotificationCount);
    }, [supportNotifications])

    const [tempBookingNotification, setTempBookingNotification] = useState(null);
    const [tempIsManagerCalled, setTempIsManagerCalled] = useState(null);
    const [tempSupportNotification, setTempSupportNotification] = useState(null);

    useEffect(() => {
        // Listen new booking message for count
        LaravelEcho.private(`dashboard.notifications`)
            .listen('.new.booking.message.send', (e) => {
                console.log("New MESSAGE RECEIVED")
                console.log(e);
                setTempBookingNotification(e.message)
            }).listen('.is_manager_called.changed', (e) => {
                console.log("Manager called changed")
                console.log(e)
                setTempIsManagerCalled(e)
            });

        // Listen new support message for manager
        if (context.user.role === 'manager') {
            LaravelEcho.private(`dashboard.supp_notifications`)
                .listen('.support.message.received', (e) => {
                    console.log("New SUPPORT MESSAGE RECEIVED")
                    console.log(e);

                    setTempSupportNotification(e.message)
                })
        }

        // Listen new support message for client
        if (context.user.role !== 'manager' ) {
            LaravelEcho.private(`dashboard.client_supp_notifications.${context.user.id}`)
                .listen('.support.message.received', (e) => {
                    console.log(e);
                    setTempSupportNotification(e.message);
                });
        }


        console.log('CONNECT CHANNEL')

        return () => {
            LaravelEcho.leave(`dashboard.notifications`);

            if (context.user.role === 'manager') {
                LaravelEcho.leave(`dashboard.supp_notifications`);
            }

            if (context.user.role !== 'manager') {
                LaravelEcho.leave(`dashboard.client_supp_notifications.${context.user.id}`);
            }

            console.log('LEAVE CHANNEL')
        }
    }, []);

    // Handle booking notification
    useEffect(() => {
        if (tempBookingNotification !== null && searchParams.get('chat') != tempBookingNotification.booking_id) {
            if (context.user.role !== 'manager' || tempBookingNotification.is_manager_called == true ) {
                if (bookingNotifications.find(el => el.booking_id == tempBookingNotification.booking_id) === undefined) {
                    console.log('IT IS')
                    setBookingNotifications([...bookingNotifications, {
                        booking_id: tempBookingNotification.booking_id,
                        unread_messages_count: 1,
                        excursion_type_id: tempBookingNotification.excursion_type_id,
                        date: tempBookingNotification.date,
                        time: tempBookingNotification.time,
                        is_manager_called: tempBookingNotification.is_manager_called
                    }]);
                } else {
                    console.log('IT NOT')
                    setBookingNotifications(bookingNotifications => bookingNotifications.map(el => {
                        if (el.booking_id == tempBookingNotification.booking_id) {
                            return {
                                ...el,
                                unread_messages_count: el.unread_messages_count + 1
                            }
                        }
                        return el
                    }));
                }
            }

            setTempBookingNotification(null);
        }
    }, [bookingNotifications, tempBookingNotification, searchParams])

    // Handle is manager called
    useEffect(() => {
        if (tempIsManagerCalled !== null) {
        // if (tempIsManagerCalled !== null && context.user.role === 'manager') {
            if (tempIsManagerCalled.is_manager_called === false) {
                console.log('ВОПРОС РЕШЕН')
                setBookingNotifications(bookingNotifications => bookingNotifications.filter(el => el.booking_id != tempIsManagerCalled.booking_id));
                // bookingNotifications.find(el.booking_id == tempIsManagerCalled.booking_id)
            }else {
                console.log('ВОПРОС ОТКРЫТ')
                getBookingNotifications();
            }

            setTempIsManagerCalled(null);
        }
    }, [bookingNotifications, tempIsManagerCalled])

    // Prepare support notification
    useEffect(() => {
        if (tempSupportNotification !== null) {
            let temp = supportNotifications.find(el => el.client_id == tempSupportNotification.client_id);
            console.log(temp)
            let tempUnreadMessageCount = 0;

            if (searchParams.get('client_id') != tempSupportNotification.client_id) {
                tempUnreadMessageCount = 1;
            }

            if (tempSupportNotification.user.role !== context.user.role) {
                if (temp === undefined) {
                    setSupportNotifications([...supportNotifications, {
                        client_id: tempSupportNotification.client_id,
                        last_message: tempSupportNotification.body,
                        role: tempSupportNotification.user.role,
                        unread_message_count: tempUnreadMessageCount,
                        updated_at: tempSupportNotification.updated_at
                    }]);
                }else {
                    setSupportNotifications(supportNotifications => supportNotifications.map(el => {
                        if (el.client_id == tempSupportNotification.client_id) {
                            return {
                                ...el,
                                last_message: tempSupportNotification.body,
                                unread_message_count: el.unread_message_count + tempUnreadMessageCount,
                                updated_at: tempSupportNotification.updated_at,
                            }
                        }
                        return el
                    }));
                }
            }

            setTempSupportNotification(null);
        }
    }, [tempSupportNotification, supportNotifications])


    return (
        <div className={classes.container}>
            <div className={`container_fluid ${classes.header_fluid}`}>
                <div className={`container ${classes.header}`}>
                    <a href={'/'}>
                        <img
                            src={LogoDesktop}
                            alt={'HIDDENBURG'}
                        />
                    </a>

                    <UserDropdownMenu/>
                </div>
            </div>

            <div className={[classes.container_fluid].join(" ")}>
            {/*<div className={[classes.container_fluid, "temp_bg"].join(" ")}>*/}
            {/*    <div className="temp_wave_bg"></div>*/}
            {/*    <div className="temp_wave_bg"></div>*/}
            {/*    <div className="temp_wave_bg"></div>*/}

                <SideMenu
                    role={role}
                    bookingNotificationCount={bookingNotificationCount}
                    excursionNotificationCount={excursionsNotificationCount}
                    reviewNotificationCount={reviewNotificationCount}
                    supportNotificationCount={supportNotificationCount}
                />

                <Outlet
                    // role={role}
                    context={{role,
                        bookingNotifications,
                        setBookingNotifications,
                        excursionsNotificationCount,
                        reviewNotificationCount,
                        supportNotifications,
                        setSupportNotifications
                    }}
                    // context={[role, bookingNotifications, setBookingNotifications, excursionsNotificationCount, reviewNotificationCount]}
                    // context={role}
                    // reviewNotificationCount={reviewNotificationCount}
                />
            </div>
            {/*<FooterLayout/>*/}
        </div>
    );
};

export default WorkerDashboardLayout;