import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

// const axiosBody = async () => {
//     const csrf = await axios.create({
//         baseURL: baseURL,
//         headers: {
//             'X-Requested-With': 'XMLHttpRequest',
//             'Access-Control-Allow-Origin': '*',
//         },
//         withCredentials: true,
//      }).get('/sanctum/csrf-cookie');
//
//     const body = {
//         baseURL: baseURL,
//         headers: {
//             'X-Requested-With': 'XMLHttpRequest',
//             'Access-Control-Allow-Origin': '*',
//             'X-CSRF-TOKEN': csrf
//         },
//         withCredentials: true,
//     };
//
//     return body;
// }
//
// export const httpAxios = async () => {
//     const body = await axiosBody();
//     axios.create(body);
// }


export const httpAxios = axios.create({
    baseURL: baseURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        // 'X-Socket-ID': true
        // 'X-CSRF-TOKEN': csrf
        // 'X-Requested-With': ['XMLHttpRequest', 'Bypass-Tunnel-Reminder'],
    },
    withCredentials: true,
    withXSRF: true
});

// const csrf = await httpAxios.get('/sanctum/csrf-cookie');

// const csrf = await httpAxios.get('/sanctum/csrf-cookie');
