import React, { useEffect, useMemo, useState } from 'react';
import classes from "./ExcursionBookingMembers.module.scss";
import { MemberTypes } from "../../../lang/ru/MemberTypes";
import { useSearchParams } from "react-router-dom";
import { priceFormatting } from "../../../helpers/PriceHelper";

const ExcursionBookingMembers = ({ excursionType, getMembers }) => {
    // const [members, setMembers] = useState({});
    // const [memberLimits, setMemberLimits] = useState({
    //     babies: 2
    // });
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter current types
    // const excursionMemberTypes = [];
    //
    // for (const backMemberTypes of excursionType.member_types) {
    //     for (const frontMemberType of MemberTypes) {
    //         if (backMemberTypes.name === frontMemberType.type) {
    //             excursionMemberTypes.push(frontMemberType);
    //         }
    //     }
    // }

    const [excursionMemberTypes, setExcursionMemberTypes] = useState([]);

    useEffect(() => {
        console.log(excursionType)
        const tempExcursionMemberTypes = [];

        for (let i = 0; i < excursionType.member_types.length; i++) {
            let searchParam = searchParams.get(excursionType.member_types[i].type);
            let count = isNaN(Number(searchParam)) ? 0 : Number(searchParam);

            if (i === 0 && count === 0) count = 1;

            let discountPrice = excursionType.discount_prices.find(el => el.member_type === excursionType.member_types[i].type);
            let currentPrice = excursionType.current_prices.find(el => el.member_type === excursionType.member_types[i].type);
            if (excursionType.promocode_prices !== undefined) {
                var promocodePrice = excursionType.promocode_prices.find(el => el.member_type === excursionType.member_types[i].type);
            }

            tempExcursionMemberTypes.push({
                type: excursionType.member_types[i].type,
                name: excursionType.member_types[i].name,
                count: count,
                price: discountPrice ? discountPrice.price : currentPrice ? currentPrice.price : 0,
                priceSum: discountPrice ? discountPrice.price * count : currentPrice ? currentPrice.price * count : 0,
                promocodePrice: promocodePrice ? promocodePrice.price : 0,
                promocodePriceSum: promocodePrice ? promocodePrice.price * count : 0
            })
        }

        setExcursionMemberTypes(tempExcursionMemberTypes);
    }, [excursionType])

    // Change members count
    const incrementMembers = (e) => {
        document.getElementById('members_container').style.color = '';

        let memberType = e.target.getAttribute('data-member-type');

        setExcursionMemberTypes(excursionMemberTypes => excursionMemberTypes.map(el => {
            if (el.type === memberType) {
                return {
                    ...el,
                    priceSum: el.price * (el.count + 1),
                    promocodePriceSum: el.promocodePrice * (el.count + 1),
                    count: el.count + 1
                }
            } else {
                return el
            }
        }))
    }

    const decrementMembers = (e) => {
        document.getElementById('members_container').style.color = '';

        let memberType = e.target.getAttribute('data-member-type');

        setExcursionMemberTypes(excursionMemberTypes => excursionMemberTypes.map((el, index) => {
            // if ((index !== 0 && el.type === memberType && el.count > 0) || (el.type === memberType && el.count > 1)) {
            if ((el.type === memberType && el.count > 0)) {
                return {
                    ...el,
                    priceSum: el.price * (el.count - 1),
                    promocodePriceSum: el.promocodePrice * (el.count - 1),
                    count: el.count - 1
                }
            } else {
                return el
            }
        }))
    }




    // Set search params by state
    useEffect(() => {
        for (let memberType of excursionMemberTypes) {
            searchParams.set(memberType.type, memberType.count);
        }

        setSearchParams(searchParams);
        getMembers(excursionMemberTypes);
    }, [excursionMemberTypes])

    // MemberTypesList
    const getMemberTypesList = () => {
        let content = [];
        excursionMemberTypes.map((memberType, index) => {
            let newPrice = excursionType.discount_prices.find(el => el.member_type === memberType.type);
            let oldPrice = excursionType.current_prices.find(el => el.member_type === memberType.type);

            let price = 0;

            if (newPrice === undefined) {
                price = oldPrice;
            } else {
                price = newPrice;
            }

            content.push(
                <div
                    className={classes.member_row}
                    key={`member_row_${index}`}
                >
                    <div className={classes.member_info}>
                        <div className={classes.member_price}>
                            <span className={newPrice !== undefined && oldPrice.price !== newPrice.price ? classes.new_price : ''}>
                                {priceFormatting(price.price)}
                            </span>
                            {/*<span className={"verdana_regular"}>₽</span>*/}
                            {
                                newPrice !== undefined && oldPrice.price !== newPrice.price
                                &&
                                // <span className={classes.old_price}>
                                <span className={[classes.old_price, 'strike-center'].join(' ')}>
                                    {priceFormatting(oldPrice.price)}
                                </span>
                            }
                        </div>

                        <div className={classes.member_description}>
                            {memberType.name}
                        </div>
                    </div>

                    <div className={classes.members_count_container}>
                        <div
                            className={classes.members_decrement_block}
                            // onClick={decrementMembers[memberType.type]}
                            onClick={decrementMembers}
                            data-member-type={memberType.type}
                        >
                            -
                        </div>

                        <div className={classes.members_count_block}>
                            {memberType.count}
                            {/*{members[memberType.type]}*/}
                        </div>

                        <div
                            className={classes.members_increment_block}
                            onClick={incrementMembers}
                            // onClick={incrementMembers[memberType.type]}
                            data-member-type={memberType.type}
                        >
                            +
                        </div>
                    </div>
                </div>
            )
        })

        return content;
    }

    const memberTypesList = useMemo(getMemberTypesList, [excursionMemberTypes]);
    // const memberTypesList = useMemo(getMemberTypesList, [excursionMemberTypes, members]);

    return (
        <div className={classes.members_block}>
            {memberTypesList}
        </div>
    );
};

export default ExcursionBookingMembers;