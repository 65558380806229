const experienceEditAreaManipulateBtns = Object.freeze({
    SAVE: {
        title: "Сохранить",
        action: "save",
        classNames: ["temp_btn_success"]
    },

    PUBLISH: {
        title: "Опубликовать",
        action: "publish",
        classNames: ["temp_btn_success"]
    },

    CANCEL_CHANGES: {
        title: "Отменить изменения",
        action: "cancel_changes",
        classNames: ["temp_btn_delete"]
    },

    REJECT: {
        title: "Отклонить",
        action: "reject",
        classNames: ["temp_btn_delete"]
    },

    SAVE_TO_DRAFT: {
        title: "Сохранить в черновики",
        action: "save_to_draft",
        classNames: ["temp_btn_success"]
    }
});

export default experienceEditAreaManipulateBtns;