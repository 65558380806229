import React, {useEffect, useRef, useState} from 'react';
import classes from './AboutUsPage.module.scss';
import LogoDesktopNew from '../../images/logos/LogoDesktopNEW.svg';
import about1 from '../../images/about/about1.jpeg';
import about2 from '../../images/about/about2.jpeg';
import about3 from '../../images/about/about3.jpeg';
import ImgAutoSize from "../../components/UI/ImgAutoSize/ImgAutoSize";

const AboutUsPage = () => {
    const contacts = useRef(null);
    const logoContainer = useRef(null);

    useEffect(() => {
        if (contacts.current && window.location.hash === '#contacts') {
            console.log(window.location.hash)
            contacts.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        }else {
            logoContainer.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }, [contacts, logoContainer]);

    return (
        <div className={'container_fluid'}>
            <div className={'container'}>
                <div
                    className={classes.big_logo_container}
                    ref={logoContainer}
                >
                    <img
                        src={LogoDesktopNew}
                        alt={'HIDDENBURG'}
                    />
                </div>

                <div className={classes.description_container}>
                    <div className={classes.description_block}>
                        <div className={classes.description_title_container}>
                            Хидденбург
                        </div>

                        <div className={classes.description_text_container}>
                            это современный сервис экскурсий и впечатлений. Вместе с опытными гидами мы разработали для вас самые интересные прогулки, которые подарят яркие эмоции надолго и заразят любовью к новым маршрутам и спрятанным от других уголкам любимых городов. Стать нашим гостем и главным героем собственного приключения легко: выбирайте понравившуюся экскурсию, бронируйте ее буквально в несколько кликов и делайте открытия с нами!
                        </div>
                    </div>
                </div>



                <div className={classes.benefits_carousel_container}>
                    <div className={classes.benefits_carousel_block}>
                        <div className={[classes.benefit_container, 'roundblock_angle_lb'].join(" ")}>
                            <div className={classes.benefit_block}>
                                <div className={classes.benefit_title_container}>
                                    гарантия возврата
                                </div>

                                <div className={classes.benefit_text_container}>
                                    экскурсия не понравилась? Так бывает! Наверное, она понравится кому-то ещё. А вам мы
                                    вернём деньги без чека
                                </div>
                            </div>
                        </div>

                        <div className={[classes.benefit_container, 'roundblock_angle_lb'].join(" ")}>
                            <div className={classes.benefit_block}>
                                <div className={classes.benefit_title_container}>
                                    дружелюбная служба поддержки
                                </div>

                                <div className={classes.benefit_text_container}>
                                    запутались в выборе, способе оплаты или принесли необычный запрос? Отдел заботы
                                    ответит
                                    на все тревожащие вопросы оперативно и доброжелательно
                                </div>
                            </div>
                        </div>

                        <div className={[classes.benefit_container, 'roundblock_angle_lb'].join(" ")}>
                            <div className={classes.benefit_block}>
                                <div className={classes.benefit_title_container}>
                                    увлеченные гиды
                                </div>

                                <div className={classes.benefit_text_container}>
                                    гиды Хидденбурга любят и уважают своё дело: это знатоки истории и современности,
                                    которые
                                    умеют рассказывать и отвечать, увлекать и удивлять
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.mission_container}>
                    <div className={classes.mission_block}>
                        <div className={classes.mission_title_container}>
                            наша миссия:
                        </div>

                        <div className={classes.mission_text_container}>
                            мы помогаем гостям делать уверенный и простой выбор в поисках ярких эмоций и впечатлений!
                        </div>
                    </div>
                </div>

                <div className={classes.photos_carousel_container}>
                    <div className={classes.photos_carousel_block}>
                        <div
                            className={[classes.photo_container, 'roundblock_angle_lb', 'img_block_preload'].join(' ')}>
                            <ImgAutoSize
                                image={about1}
                            />
                        </div>

                        <div
                            className={[classes.photo_container, 'roundblock_angle_lb', 'img_block_preload'].join(' ')}>
                            <ImgAutoSize
                                image={about2}
                            />
                        </div>

                        <div
                            className={[classes.photo_container, 'roundblock_angle_lb', 'img_block_preload'].join(' ')}>
                            <ImgAutoSize
                                image={about3}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={classes.contacts_container}
                    ref={contacts}
                >
                    <div className={classes.contacts_column}>
                        <div className={classes.contacts_title_container}>
                            свяжитесь с нами!
                        </div>

                        <div className={classes.phone_email_container}>
                            <div className={classes.phone_container}>
                                {/*<div className={classes.phone_title}>*/}
                                телефон: <a href={'tel:+79315916098'}>+7 (931) 591 6098</a>
                                {/*</div>*/}
                            </div>

                            <div className={classes.phone_container}>
                                {/*<div className={classes.email_title}>*/}
                                почта: <a href={'mailto:support@hidden-burg.com'}>support@hidden-burg.com</a>
                                {/*</div>*/}
                            </div>

                            <div className={classes.phone_container}>
                                {/*<div className={classes.telegram_title}>*/}
                                    телеграм: <a href={'https://t.me/HIDDENBURG_bot'}>@HIDDENBURG_bot</a>
                                {/*</div>*/}
                            </div>
                        </div>

                        {/*<div className={classes.address_container}>*/}
                        {/*    <div className={classes.address_title}>*/}
                        {/*        офис:*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className={classes.contacts_column}>
                        <div className={classes.want_to_join_container}>
                            <div className={classes.want_to_join_title}>
                                хочу с вами работать:
                            </div>

                            <div className={classes.want_to_join_email_container}>
                                <div className={classes.want_to_join_email_title}>
                                    почта: <a href={'mailto:support@hidden-burg.com'}>support@hidden-burg.com</a>
                                </div>

                                {/*<div className={classes.want_to_join_email}>*/}
                                {/*</div>*/}
                            </div>

                            <div className={classes.want_to_join_email_container}>
                                <div className={classes.want_to_join_email_title}>
                                    телеграм: <a href={'https://t.me/HIDDENBURG_2_bot'}>@HIDDENBURG_2_bot</a>
                                </div>

                                {/*<div className={classes.want_to_join_email}>*/}
                                {/*    support@hidden-burg.com*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className={classes.want_to_be_organizer_container}>
                            {/*<div className={classes.want_to_be_organizer_title}>*/}
                            {/*    стать организатором:*/}
                            {/*</div>*/}

                            {/*<div className={classes.want_to_be_organizer_email_container}>*/}
                            {/*    <div className={classes.want_to_be_organizer_email_title}>*/}
                            {/*        почта:*/}
                            {/*    </div>*/}

                            {/*    <div className={classes.want_to_be_organizer_email}>*/}

                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className={classes.organizer_rules_container}>*/}
                            {/*    <div className={classes.organizer_rules_title}>*/}
                            {/*        правила организатора:*/}
                            {/*    </div>*/}

                            {/*    <div className={classes.organizer_rules_text}>*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;