export const Types = [
    {
        type: 'GROUP',
        name: 'Групповая экскурсия',
        description: 'группа до 30 человек'
    },

    {
        type: 'MINIGROUP',
        name: 'Мини-группа',
        description: 'мини-группа до 15 человек'
    },

    {
        type: 'INDIVIDUAL',
        name: 'Индивидуальная экскурсия',
        description: 'будете только вы, ваша компания и гид'
    }
];