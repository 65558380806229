import React, {useEffect, useState} from 'react';
import classes from "./DotCheckbox.module.scss";

const DotCheckbox = ({
     availableCheckboxes = [
         {name: "1", alias: "val1", description: ""},
         {name: "2", alias: "val2", description: ""}
     ],
     defaultChecked = ["val2"],
     setChecked = () => {}
}) => {
    const [checkedState, setCheckedState] = useState(defaultChecked);

    // Switch checkboxes by click
    const switchCheckbox = (e) => {
        e.currentTarget.firstChild.firstChild.classList.toggle(classes.dot_active);
        if (checkedState.includes(e.currentTarget.getAttribute("data-checkbox-alias"))) {
            setCheckedState(checkedState.filter(el => el !== e.currentTarget.getAttribute("data-checkbox-alias")));
        }else {
            setCheckedState([...checkedState, e.currentTarget.getAttribute("data-checkbox-alias")]);
        }
    }

    useEffect(() => {
        setChecked(checkedState);
    }, [checkedState])

    // Render experience types
    const listCheckboxes = availableCheckboxes.map((checkbox, index) => {
        return (
            <div
                className={classes.type_row}
                key={index}
                onClick={switchCheckbox}
                data-checkbox-alias={checkbox.alias}
            >
                <div className={classes.dot_block}>
                    <div
                        className={defaultChecked.includes(checkbox.alias) ? [classes.dot, classes.dot_active].join(" ") : classes.dot}
                    >

                    </div>
                </div>

                <div className={classes.info_block}>
                    <div className={classes.type_title}>
                        {checkbox.name}
                    </div>

                    {/* <div
                        className={classes.type_description}
                        dangerouslySetInnerHTML={{ __html: checkbox.description }}
                    >
                    </div> */}
                </div>
            </div>
        )
    })

    return (
        <>
            {listCheckboxes}
        </>
    );
};

export default DotCheckbox;