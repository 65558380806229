import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import {httpAxios} from "../../../API/HttpAxios";
import ReviewControlUnit from "../../../components/dashboard/common/ReviewControlUnit/ReviewControlUnit";
import classes from "./OrganizerReviewsPage.module.scss";
import DraftTopMenuBtns from "../../../components/dashboard/common/DraftTopMenuBtns/DraftTopMenuBtns";
import SearchInput from "../../../components/dashboard/common/SearchInput/SearchInput";
import RatingStarsRow from "../../../components/dashboard/common/RatingStarsRow/RatingStarsRow";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import AuthContext from "../../../context/AuthContext";
import OrganizerReviewControlUnit
    from "../../../components/dashboard/organizer/OrganizerReviewControlUnit/OrganizerReviewControlUnit";
import ExcursionSelector from "../../../components/dashboard/common/ExcursionSelector/ExcursionSelector";

const OrganizerReviewsPage = ({}) => {
    const {...context} = useContext(AuthContext);
    const {page} = useParams();
    const navigate = useNavigate();
    // const [role, reviewNotificationCount] = useOutletContext();
    const {...outletContext} = useOutletContext();

    // useEffect(() => {
    //     console.log('reviewNotificationCount')
    //     console.log(reviewNotificationCount)
    // }, [reviewNotificationCount])

    // Get review count for moderation
    const [reviewCountForModeration, setReviewCountForModeration] = useState(0);
    // const getReviewCountForModeration = () => {
    //     httpAxios.get('/api/review/get_review_count_for_moderation')
    //         .then(response => {
    //                 if (response.status === 200) {
    //                     setReviewCountForModeration(response.data);
    //                 }
    //             }
    //         )
    // }
    //
    // useEffect(() => {
    //     getReviewCountForModeration();
    // }, []);

    const topMenuBtns = [
        {
            name: "Требуют ответа",
            path: `/${context.user.role}/dashboard/reviews/need_answer`,
            notification_count: outletContext.reviewNotificationCount
        },

        {
            name: "С ответом",
            path: `/${context.user.role}/dashboard/reviews/answered`,
        },
    ];

    // Redirect to /new if no page
    useEffect(() => {
        !page && navigate(`/${context.user.role}/dashboard/reviews/need_answer`);
    }, [page])

    // Manipulate search input
    const [excursionId, setExcursionId] = useState('');

    useEffect(() => {
        setReviews([]);
        setCurrentPage(1);
    }, [excursionId])

    // Manipulate rating filter
    const [searchParams, setSearchParams] = useSearchParams();

    const handleRatingFilter = (rating) => {
        searchParams.set('rating', rating);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (searchParams.get('rating') !== undefined) {
            setReviews([]);
            setCurrentPage(1)
        }
    }, [searchParams]);

    // Reset rating
    const resetRating = () => {
        searchParams.delete('rating');
        setSearchParams(searchParams)
    }

    // Get reviews
    const [isReviewsLoading, setIsReviewsLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    // const [statuses, setStatuses] = useState([]);
    const [isAnswered, setIsAnswered] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    // Change statuses by page
    useEffect(() => {
        setReviews([]);

        if (page === 'need_answer') {
            setIsAnswered(false);
        }else {
            setIsAnswered(true);
        }

        setCurrentPage(1);
    }, [page])

    // Get reviews
    const getReviewsByAnswered = () => {

        httpAxios.get('/api/review/get_reviews_by_answered', {
            params: {
                page: page,
                is_answered: isAnswered,
                excursion_id: excursionId !== '' ? excursionId : null,
                rating: searchParams.get('rating') ? searchParams.get('rating') : null
            }
        })
            .then((response) => {
                if (currentPage === 1) {
                    setReviews(response.data.data);
                }else {
                    setReviews([...reviews, ...response.data.data]);
                }
                setLastPage(response.data.last_page);
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsReviewsLoading(false);
            })
    }

    useEffect(() => {
        if (currentPage === null) return;
        setIsReviewsLoading(true);

        getReviewsByAnswered();
    }, [currentPage, excursionId, searchParams, isAnswered])

    ///////////////
    useEffect(() => {
        console.log(reviews)
    }, [reviews])

    // Render reviews
    const reviewsList = reviews.map((review, index) => {
        return (
            <OrganizerReviewControlUnit
                review={review}
                key={index}
                setReviews={setReviews}
            />
        )
    })

    // Load reviews by scroll
    const observerBlock = useRef();
    const observer = useRef();
    const [isObserverBlockEnter, setIsObserverBlockEnter] = useState(false);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            if (entries[0].isIntersecting) {
                console.log('INNN')
                setIsObserverBlockEnter(true);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(observerBlock.current);
    }, [])

    useEffect(() => {
        setIsObserverBlockEnter(false);

        if (isReviewsLoading) return;
        if (currentPage === null) return;
        if (lastPage === null) return;

        if (isObserverBlockEnter && currentPage < lastPage) {
            setCurrentPage(currentPage => currentPage + 1);
        }
    }, [isObserverBlockEnter])

    // Get organizer excursions
    const [excursions, setExcursions] = useState([]);

    const getExcursions = () => {
        httpAxios.get('/api/excursions/get_excursions_for_organizer')
            .then((response) => {
                setExcursions(response.data);
                console.log(response)
            })
    }

    useEffect(() => {
        getExcursions();
    }, [])


    return (
        <div className={[classes.reviews_container, "temp_scrollbar"].join(" ")}>
            <div className={classes.top_menu_container}>
                <DraftTopMenuBtns
                    menuBtns={topMenuBtns}
                />

                <ExcursionSelector
                    excursions={excursions}
                    excursionId={excursionId}
                    setExcursionId={setExcursionId}
                />

                <div className={classes.rating_container}>
                    <RatingStarsRow
                        selectedRating={searchParams.get('rating')}
                        onStarClick={handleRatingFilter}
                    />

                    <div
                        className={classes.reset_rating}
                        onClick={resetRating}
                    >
                        Все оценки
                    </div>
                </div>
            </div>

            <div className={classes.reviews_block}>
                {reviewsList}
            </div>

            <div ref={observerBlock}>
                {
                    currentPage !== lastPage || isReviewsLoading
                        ?
                        <PageLoader/>
                        :
                        <div className={classes.nothing_found}>
                            Это всё 🤷‍♂️
                        </div>
                }
            </div>
        </div>
    );
};

export default OrganizerReviewsPage;