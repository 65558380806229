import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import classes from './ExperiencesPage.module.scss';
import DraftTopMenuBtns from "../../../components/dashboard/common/DraftTopMenuBtns/DraftTopMenuBtns";
import SearchInput from "../../../components/dashboard/common/SearchInput/SearchInput";
import ExperienceControlUnit from "../../../components/dashboard/common/ExperienceControlUnit/ExperienceControlUnit";
import {useNavigate, useParams} from "react-router-dom";
import {useFetching} from "../../../hooks/useFetching";
import ExcursionService from "../../../API/ExcursionService";
import ExperienceInfoBlock from "../../../components/dashboard/common/ExperienceInfoBlock/ExperienceInfoBlock";
import {log} from "util";
import PageLoader from "../../../components/UI/PageLoader/PageLoader";
import ExperienceEditArea from "../../../components/dashboard/common/ExperienceEditArea/ExperienceEditArea";
import ExperienceEditAreaManipulateBtns from "../../../enums/ExperienceEditAreaManipulateBtns";
import {httpAxios} from "../../../API/HttpAxios";
import AuthContext from "../../../context/AuthContext";
import {useReceipting} from "../../../hooks/useReceipting";

const ExperiencesPage = () => {
    const {page} = useParams();
    const navigate = useNavigate();
    const {...context} = useContext(AuthContext);

    // Get suggested excursion count
    const [suggestedExcursionCount, setSuggestedExcursionCount] = useState(0);
    const [fetchSuggestedExcursionCount, isSuggestedExcursionCountLoading, suggestedExcursionCountError] = useFetching(async () => {
        const response = await ExcursionService.getSuggestedExcursionCount();

        setSuggestedExcursionCount(response.data.suggested_count);
    })

    useEffect(() => {
        fetchSuggestedExcursionCount();
    }, [])

    // Set top menu btns for different roles
    let topMenuBtns = [
        {
            name: "Активные",
            path: `/${context.user.role}/dashboard/experiences/active`
        },

        {
            name: "Не опубликованы",
            path: `/${context.user.role}/dashboard/experiences/unpublished`
        },

        {
            name: "Предложенные",
            path: `/${context.user.role}/dashboard/experiences/suggested`,
            notification_count: suggestedExcursionCount
        },

        {
            name: "Создать",
            path: `/${context.user.role}/dashboard/experiences/create_new`
        }
    ]

    if (context.user.role === 'organizer') {
        topMenuBtns = topMenuBtns.filter(item => item.name !== 'Предложенные');
    }

    // Redirect to /new if no page
    useEffect(() => {
        !page && navigate(`/${context.user.role}/dashboard/experiences/active`);
    }, [page])

    // Find excursion by id
    const [foundExcursions, setFoundExcursions] = useState([])
    const [searchExcursionId, setSearchExcursionId] = useState('');
    const [fetchSearchExcursion, isFetchSearchExcursionLoading, fetchSearchExcursionError] = useFetching(async () => {
    // const [fetchSearchExcursion, isFetchSearchExcursionLoading, fetchSearchExcursionError] = useReceipting(async () => {
        setFoundExcursions([]);
        const response = await ExcursionService.getExcursionBySearchForEmployee(searchExcursionId);

        if (response.data.status !== false) {
            setFoundExcursions([response.data]);
        }
        console.log(response)
    })

    useEffect(() => {
        let searchTimer = setTimeout(() => {
            if (searchExcursionId !== '') {
                fetchSearchExcursion();
            }
        }, 1000)

        console.log(searchExcursionId)

        return () => clearTimeout(searchTimer);
    }, [searchExcursionId])

    // Render found excursions
    let foundExcursionsList = foundExcursions.map((excursion, index) => {
        return (
            <ExperienceControlUnit
                key={`found_exp_${excursion.id}`}
                excursion={excursion}
            />
        )
    })

    // Get experiences by page
    const [excursions, setExcursions] = useState([]);

    // Get excursions by status
    const [fetchPage, setFetchPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    // const controller = new AbortController();
    const [fetchExcursions, isExcursionsLoading, excursionsError] = useFetching(async ({...args}, page) => {
        const response = await ExcursionService.getExcursionsByStatus({...args}, page);
        const data = response.data.data;
        console.log("Fetch excursions")
        console.log(response)
        console.log(data)
        if (page === 1) {
            setExcursions(data);
        }else {
            setExcursions([...excursions, ...data]);
        }
        setLastPage(response.data.last_page);
    })

    // const [tempExcursions, setTempExcursions] = useState([]);
    // const [isExcursionsLoading, setIsExcursionsLoading] = useState(false);
    // // const controller = new AbortController();
    // const fetchExcursions = () => {
    //     setIsExcursionsLoading(true);
    //
    //     httpAxios.get('/api/excursions/get_excursions_by_status', {
    //         // signal: controller.signal,
    //         params: {
    //             statuses: fetchExcursionStatuses,
    //             page: fetchPage
    //         },
    //     }).then(response => {
    //         if (fetchPage === 1) {
    //             setExcursions(response.data.data);
    //         }else {
    //             setExcursions([...excursions, ...response.data.data]);
    //         }
    //         setLastPage(response.data.last_page);
    //         setIsExcursionsLoading(false);
    //     });
    // }

    // Excursion manipulate btns
    // const [experienceManipulateBtns, setExperienceManipulateBtns] = useState([]);

    // Switch excursions, manipulate btns by page
    const [fetchExcursionStatuses, setFetchExcursionStatuses] = useState([]);

    useEffect(() => {
        // if (isExcursionsLoading === true) return;
        //     controller.abort();
            setExcursions([]);
            // setFetchExcursionStatuses([]);
            setFetchPage(1);
            setLastPage(1);

            if (page === 'active') {
                setFetchExcursionStatuses(['active']);
            }else if (page === 'unpublished') {
                if (context.user.role === 'manager') {
                    setFetchExcursionStatuses(['draft', 'rejected', 'stopped']);
                }else if (context.user.role === 'organizer') {
                    setFetchExcursionStatuses(['draft', 'rejected', 'stopped', 'moderation']);
                }
            }else if (page === 'suggested' && context.user.role === 'manager') {
                setFetchExcursionStatuses(['moderation']);
            }
        // }
    }, [page]);
    // }, [page, isExcursionsLoading]);

    useEffect(() => {
        if (fetchExcursionStatuses.length !== 0) {
            fetchExcursions(fetchExcursionStatuses, fetchPage);
        }
    }, [fetchExcursionStatuses, fetchPage])

    // useEffect(() => {
    //     setExcursions([...excursions, ...tempExcursions])
    // }, [tempExcursions])


    // Render excursions
    let renderExcursions = useMemo(() => excursions.map((excursion, i) => {
        return (
            <ExperienceControlUnit
                key={`exp_${excursion.id}`}
                excursion={excursion}
            />
        )
    }), [excursions])

    useEffect(() => console.log(isExcursionsLoading), [isExcursionsLoading])

    // test load more
    const loadMore = () => {
        setFetchPage(fetchPage => fetchPage + 1);
    }

    // Load excursion by scroll
    const observerBlock = useRef();
    const observer = useRef();

    useEffect(() => {
        if (isExcursionsLoading) return;
        if (observer.current) observer.current.disconnect();

        let callback = function (entries, observer) {
            // if (entries[0].isIntersecting && fetchPage < lastPage) {
            if (entries[0].isIntersecting && fetchPage < lastPage && searchExcursionId === '' && page !== 'create_new') {
                setFetchPage(fetchPage => fetchPage + 1);
            }
        }

        // if (observer.current) {
            observer.current = new IntersectionObserver(callback);
            observer.current.observe(observerBlock.current);
        // }
    }, [isExcursionsLoading, excursions, searchExcursionId, page])

    return (
        <div className={[classes.experiences_container, "temp_scrollbar"].join(" ")}>
            <div className={classes.top_menu_container}>
                <DraftTopMenuBtns
                    menuBtns={topMenuBtns}
                />

                <SearchInput
                    className={classes.search_input}
                    placeholder={"Введите ID или название..."}
                    value={searchExcursionId}
                    onChange={setSearchExcursionId}
                />
            </div>

            {/*// Активные: 2 кнопки - опубликовать, отменить изменения*/}
            {/*// Не опубликованы: 2 кнопки - сохранить, отменить изменения*/}
            {/*// Предложенные: 3 кнопки - опубликовать, отклонить, отменить изменения*/}

            <div className={classes.experiences_block}>
                {
                    page !== "create_new"
                    ?
                        searchExcursionId === ''
                        ?
                            renderExcursions
                        :
                            foundExcursionsList
                    :
                        <div className={classes.experience_block}>
                            <ExperienceEditArea
                                isVisible={true}
                                // manipulateBtns={experienceManipulateBtns}
                            />
                        </div>
                }


                {
                    // page !== "create_new" &&

                    <div
                        ref={observerBlock}
                    >

                    </div>

                }

                {
                    page !== 'create_new'
                    ?
                        searchExcursionId === ''
                        ?
                            excursions.length === 0 && !isExcursionsLoading
                            ?
                                "Ничего не найдено"
                            :
                                fetchPage < lastPage || isExcursionsLoading
                                    ?
                                    <PageLoader/>
                                    :
                                    "Больше ничего не найдено"
                        :
                            isFetchSearchExcursionLoading
                            ?
                                <PageLoader/>
                            :
                                foundExcursionsList.length === 0
                                ?
                                    "Ничего не найдено"
                                :
                                    'Больше ничего не найдено'
                    :
                        ''
                }
            </div>
        </div>
    );
};

export default ExperiencesPage;