import React, {useEffect, useRef, useState} from 'react';
import HbButton from "../UI/HbButton/HbButton";
import classes from "./ExcursionsFilters.module.scss";
import CloseButton from '../../images/icons/Close.svg';
import MultiRangeSlider from "../UI/MultiRangeSlider/MultiRangeSlider";
import {priceFormatting, priceParse} from "../../helpers/PriceHelper";
import {useSearchParams} from "react-router-dom";
import ExcursionService from "../../API/ExcursionService";

const ExcursionsFilters = (props) => {
    const [priceFilterClasses, setPriceFilterClasses] = useState([classes.filters_select_element]);
    const [serviceFilterClasses, setServiceFilterClasses] = useState([classes.filters_select_element]);
    const [filterContainerClasses, setFilterContainerClasses] = useState([classes.filters_container_fluid, 'hidden']);

    const [pricesVisible, setPricesVisible] = useState(false);
    const [servicesVisible, setServicesVisible] = useState(false);

    const [priceBlockClasses, setPriceBlockClasses] = useState([classes.filter_price_block, 'hidden'])
    const [serviceBlockClasses, setServiceBlockClasses] = useState([classes.filter_service_block, 'hidden'])

    // Func for show price filter
    const priceSelectShow = () => {
        setFilterContainerClasses([classes.filters_container_fluid]);
        setServiceFilterClasses([classes.filters_select_element]);
        setPriceFilterClasses([...priceFilterClasses, classes.filters_select_element_active]);
        setPricesVisible(true);
        setServicesVisible(false);
    }

    // Func for show service filter
    const serviceSelectShow = () => {
        setFilterContainerClasses([classes.filters_container_fluid]);
        setPriceFilterClasses([classes.filters_select_element]);
        setServiceFilterClasses([...serviceFilterClasses, classes.filters_select_element_active]);
        setPricesVisible(false);
        setServicesVisible(true);
    }

    // Func for close all filters
    const closeFilterContainer = () => {
        if (pricesVisible || servicesVisible) {
            setFilterContainerClasses([classes.filters_container, 'hidden']);
            setPricesVisible(false);
            setServicesVisible(false);
        }
    }

    const filterContainer = useRef();
    const outsideFiltersClose = (event) => {
        if (filterContainer.current === event.target) {
            closeFilterContainer();
        }
    }

    useEffect(() => {
        pricesVisible ? setPriceBlockClasses([classes.filter_price_block]) : setPriceBlockClasses([classes.filter_price_block, 'hidden']);

        servicesVisible ? setServiceBlockClasses([classes.filter_service_block]) : setServiceBlockClasses([classes.filter_service_block, 'hidden']);

    }, [pricesVisible, servicesVisible])


    let [searchParams, setSearchParams] = useSearchParams();

    const [priceLimits, setPriceLimits] = useState({start_price: 0, end_price: 1});
    const [prices, setPrices] = useState({start_price: Number, end_price: Number})

    // Get price limits from server
    async function fetchPriceLimits() {
        const response = await ExcursionService.getPriceLimits();
        const data = response.data;
        // console.log(response);
        setPriceLimits({start_price: data.min, end_price: data.max});
        setPrices({
            start_price: searchParams.has('price_start') ? Number(searchParams.get('price_start')) : data.min,
            end_price: searchParams.has('price_end') ? Number(searchParams.get('price_end')) : data.max
        });
    }

    useEffect(() => {
        fetchPriceLimits()
    }, [])

    // Callback for MultiRangeSlider
    const pricesCallback = (startPrice, endPrice) => {
        setPrices({start_price: startPrice, end_price: endPrice});
    }

    // Set start & end prices by inputs
    const setStartPrice = (event) => {
        let price = priceParse(event.target.value);
        if (price >= priceLimits.end_price) {
            setPrices({...prices, start_price: priceLimits.end_price - 1});
        }else {
            setPrices({...prices, start_price: price});
        }
    }

    const setEndPrice = (event) => {
        let price = priceParse(event.target.value);

        if (price > priceLimits.end_price) {
            setPrices({...prices, end_price: priceLimits.end_price});
        } else{
            setPrices({...prices, end_price: price});
        }
    }

    // Click to reset price diapason
    const resetPrices = () => {
        setPrices({
            start_price: priceLimits.start_price,
            end_price: priceLimits.end_price
        });
    }

    // Services filter
    const [group, setGroup] = useState(false);
    const [individual, setIndividual] = useState(false);
    const [minigroup, setMinigroup] = useState(false);

    const [groupClasses, setGroupClasses] = useState([classes.checkbox]);
    const [individualClasses, setIndividualClasses] = useState([classes.checkbox]);
    const [minigroupClasses, setMinigroupClasses] = useState([classes.checkbox]);
    const [servicesNames, setServicesNames] = useState([]);

    const handleGroupClick = () => setGroup(!group);
    const handleIndividualClick = () => setIndividual(!individual);
    const handleMinigroupClick = () => setMinigroup(!minigroup);

    useEffect(() => {
        let services = [];
        if (searchParams.has('group')) {
            setGroup(true);
            services.push('группа');
        }

        if (searchParams.has('individual')) {
            setIndividual(true);
            services.push('индивидуально');
        }

        if (searchParams.has('minigroup')) {
            setMinigroup(true);
            services.push('мини-группа');
        }

        setServicesNames(services);
    }, [searchParams])

    // Change checkbox by clicks
    useEffect(() => {
        group ? setGroupClasses([...groupClasses, classes.checkbox_active]) : setGroupClasses([classes.checkbox])
        individual ? setIndividualClasses([...individualClasses, classes.checkbox_active]) : setIndividualClasses([classes.checkbox])
        minigroup ? setMinigroupClasses([...groupClasses, classes.checkbox_active]) : setMinigroupClasses([classes.checkbox])
    }, [group, individual, minigroup])

    // Click reset services
    const resetServices = () => {
        setGroup(false);
        setIndividual(false);
        setMinigroup(false);
    }

    // Click search button inside filter bubble block
    const handleSearchClick = () => {
        setFilterContainerClasses([classes.filters_container_fluid, 'hidden']);

        prices.start_price !== priceLimits.start_price
            ? searchParams.set('price_start', prices.start_price.toString())
            : searchParams.delete('price_start');

        prices.end_price !== priceLimits.end_price
            ? searchParams.set('price_end', prices.end_price.toString())
            : searchParams.delete('price_end');

        group
            ? searchParams.set('group', group.toString())
            : searchParams.delete('group');

        individual
            ? searchParams.set('individual', individual.toString())
            : searchParams.delete('individual');

        minigroup
            ? searchParams.set('minigroup', minigroup.toString())
            : searchParams.delete('minigroup');

        setSearchParams(searchParams);
    }

    return (
        <div>
            <div className={`container`}>
                <div className={classes.filters_string}>
                    <HbButton
                        color={'blue'}
                        className={classes.location_button}
                        onClick={props.visibleLoc}
                    >
                        {props.cityName ? props.cityName : 'Выберите город'}
                    </HbButton>

                    <HbButton
                        color={'trans_blue'}
                        className={classes.filter_button}
                        onClick={props.visibleCalendar}
                    >
                        {props.dates.date_start !== undefined ? props.dates.date_start : ''}
                        {props.dates.date_end !== undefined ? `-${props.dates.date_end}` : ''}
                        {props.dates.date_start === undefined && props.dates.date_end === undefined ? 'Даты' : ''}
                    </HbButton>

                    <HbButton
                        color={'trans_blue'}
                        className={classes.filter_button}
                        onClick={priceSelectShow}

                    >
                        {
                            (searchParams.has('price_start') && searchParams.has('price_end'))
                            ? <span>{priceFormatting(searchParams.get('price_start'))} - {priceFormatting(searchParams.get('price_end'))} </span>
                            // ? <span>{priceFormatting(searchParams.get('price_start'))} <span className={"verdana_regular"}>₽</span> - {priceFormatting(searchParams.get('price_end'))} <span className={"verdana_regular"}>₽</span></span>
                            : searchParams.has('price_start')
                                ? <span>от {priceFormatting(searchParams.get('price_start'))} </span>
                                // ? <span>от {priceFormatting(searchParams.get('price_start'))} <span className={"verdana_regular"}>₽</span></span>
                                : searchParams.has('price_end')
                                    ? <span>до {priceFormatting(searchParams.get('price_end'))} </span>
                                    // ? <span>до {priceFormatting(searchParams.get('price_end'))} <span className={"verdana_regular"}>₽</span></span>
                                    : 'Цена'
                        }
                    </HbButton>

                    <HbButton
                        color={'trans_blue'}
                        className={classes.filter_button}
                        onClick={serviceSelectShow}

                    >
                        {
                            servicesNames.length !== 0
                            ? servicesNames.join(', ')
                            : 'Услуги'
                        }
                    </HbButton>
                </div>
            </div>

            <div
                ref={filterContainer}
                onClick={outsideFiltersClose}
                className={filterContainerClasses.join(' ')}
            >
                <div  className={`roundblock ${classes.filters_block}`}>
                    <div className={classes.filters_container}>
                        <div className={classes.filters_title_row}>
                            <div
                                className={classes.filters_close_button}
                                onClick={closeFilterContainer}
                            >
                                <img src={CloseButton} alt={`close`}/>
                            </div>

                            <div className={classes.filters_title}>
                                Фильтры
                            </div>
                        </div>

                        <div className={classes.filters_select_row}>
                            <div className={classes.filters_select}>
                                <div
                                    className={priceFilterClasses.join(' ')}
                                    onClick={priceSelectShow}
                                >
                                    Цены
                                </div>

                                <div
                                    className={serviceFilterClasses.join(' ')}
                                    onClick={serviceSelectShow}
                                >
                                    Услуги
                                </div>
                            </div>
                        </div>

                        <div className={classes.filters_function_block}>
                            <div className={priceBlockClasses.join(' ')}>
                                <div className={classes.filters_price_amounts}>
                                    <div>{priceFormatting(priceLimits.start_price)}
                                        {/*<span className={"verdana_regular"}>₽</span>*/}
                                    </div>

                                    <div>{priceFormatting(priceLimits.end_price)}
                                        {/*<span className={"verdana_regular"}>₽</span>*/}
                                    </div>
                                </div>

                                <div className={classes.filters_price_range}>
                                    <MultiRangeSlider
                                        min={priceLimits.start_price}
                                        max={priceLimits.end_price}
                                        setPrices={pricesCallback}
                                        currentMin={prices.start_price}
                                        currentMax={prices.end_price}
                                        visible={pricesVisible}
                                    />
                                </div>

                                <div className={classes.filters_price_input_row}>
                                    <div className={classes.price_col}>
                                        <span className={classes.price_label}>
                                        минимум
                                        </span>

                                        <span className={classes.price_currency_block}>
                                            <span className={classes.price_currency_amount}>
                                                {priceFormatting(prices.start_price)}
                                            </span>
                                            <span className={classes.price_currency}>
                                                {/*<span className={"verdana_regular"}>₽</span>*/}
                                            </span>
                                        </span>

                                        <input
                                            className={classes.filters_price_input}
                                            type={"text"}
                                            onChange={setStartPrice}
                                            value={priceFormatting(prices.start_price)}
                                        />
                                    </div>

                                    <div className={classes.price_col}>
                                    <span className={classes.price_label}>
                                        максимум
                                    </span>

                                        <span className={classes.price_currency_block}>
                                        <span className={classes.price_currency_amount}>
                                            {priceFormatting(prices.end_price)}
                                        </span>
                                        <span className={classes.price_currency}>
                                            {/*<span className={"verdana_regular"}>₽</span>*/}
                                        </span>
                                    </span>

                                        <input
                                            className={classes.filters_price_input}
                                            type={"text"}
                                            onChange={setEndPrice}
                                            value={priceFormatting(prices.end_price)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={serviceBlockClasses.join(' ')}>
                                <div
                                    className={classes.check_row}
                                    onClick={handleGroupClick}
                                >
                                    <div className={groupClasses.join(' ')}></div>

                                    <div className={classes.check_description}>
                                        <div className={classes.check_title}>
                                            В группе
                                        </div>

                                        <div className={classes.check_body}>
                                            с вами будут другие участники
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={classes.check_row}
                                    onClick={handleIndividualClick}
                                >
                                    <div className={individualClasses.join(' ')}></div>

                                    <div className={classes.check_description}>
                                        <div className={classes.check_title}>
                                            Индивидуально
                                        </div>

                                        <div className={classes.check_body}>
                                            только вы или вы и ваша компания
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={classes.check_row}
                                    onClick={handleMinigroupClick}
                                >
                                    <div className={minigroupClasses.join(' ')}></div>

                                    <div className={classes.check_description}>
                                        <div className={classes.check_title}>
                                            Мини-группа
                                        </div>

                                        <div className={classes.check_body}>
                                            небольшая группа
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.filter_buttons_block}>
                            <div
                                className={classes.reset_filters_button}
                                onClick={
                                    pricesVisible
                                        ? resetPrices
                                        : servicesVisible
                                            ? resetServices
                                            : undefined
                                }
                            >
                                Сбросить
                            </div>

                            <HbButton
                                color={'pink'}
                                className={classes.success_filters_button}
                                onClick={handleSearchClick}
                            >
                                Поиск
                            </HbButton>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default ExcursionsFilters;