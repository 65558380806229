import React, {useEffect, useState} from 'react';
import classes from "./TypeSelector.module.scss";
import {Types} from '../../../lang/ru/Types';
import {useSearchParams} from "react-router-dom";
import ArrowDrop from '../../../images/icons/ArrowDrop.svg';
import useOutsideClick from "../../../hooks/useOutsideClick";

const TypeSelector = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const excursionTypes = [];
    props.excursion.types.map((type) => {
        excursionTypes.push(Types.find(el => el.type === type.type_name))
    })

    const findType = excursionTypes.find(element => element.type === props.excrusionType.type_name);
    const [currentType, setCurrentType] = useState(findType);
    const [otherTypes, setOtherTypes] = useState(excursionTypes.filter(el => el !== findType));
    const [isTypesVisible, setIsTypesVisible] = useState(false);
    const [typesClasses, setTypesClasses] = useState([classes.type_selector_block]);

    // Select type by search params
    useEffect(() => {
        if (searchParams.has('type')) {
            let findName = excursionTypes.find(element => element.type === searchParams.get('type'));

            if (findName !== undefined) {
                setCurrentType(findName);
                setOtherTypes(excursionTypes.filter(el => el !== findName))
            }
        }
    }, [props.excrusionType, searchParams])

    // Change types list visibility
    useEffect(() => {
        if (isTypesVisible && otherTypes.length > 0) {
            setTypesClasses([...typesClasses, classes.type_selector_block_active]);
        }else {
            setTypesClasses([classes.type_selector_block]);
        }
    }, [isTypesVisible])

    // Func for select type
    const selectType = (event) => {
        searchParams.set('type', event.currentTarget.getAttribute('id'))
        setSearchParams(searchParams)
    }

    const selectorOutsideClick = () => {
        setIsTypesVisible(false);
    }

    const selectorMenu = useOutsideClick(selectorOutsideClick)

    return (
        <div className={classes.type_selector_container}>
            <div
                className={typesClasses.join(' ')}
                onClick={() => setIsTypesVisible(!isTypesVisible)}
                ref={selectorMenu}
            >
                <div className={classes.type_selector}>
                    <div className={classes.current_type_title}>
                        {currentType.name}
                    </div>

                    {
                        otherTypes.length > 0 &&
                        <div className={classes.type_selector_arrow}>
                            <img
                                className={classes.type_selector_arrow_img}
                                src={ArrowDrop}
                                alt={'down'}
                            />
                        </div>
                    }

                </div>

                {
                    otherTypes.map((type, index) => {
                        return (
                            <div
                                key={`other_type_${index}`}
                                className={classes.type_row}
                                id={type.type}
                                onClick={selectType}
                            >
                                <div className={classes.type_dot}>

                                </div>

                                <div className={classes.type_block}>
                                    <div className={classes.type_title}>
                                        {type.name}
                                    </div>

                                    <div className={classes.type_description}>
                                        {type.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className={classes.current_type_description}>
                {currentType.description}
            </div>
        </div>

    );
};

export default TypeSelector;