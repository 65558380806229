import React, { useEffect, useState } from 'react';
import classes from './ExperienceTypeEditArea.module.scss';
import { DaysWeek } from "../../../../lang/ru/DaysWeek";
import { timeParse } from "../../../../helpers/TimeHelper";
import Close from "../../../../images/icons/Close.svg";

const ExperienceTypeEditArea = ({ currentAvailableType, excursionTypesData, setExcursionTypesData }) => {
    const [excursionTypeData, setExcursionTypeData] = useState({
        excursion_type_alias: '',
        active: 0,
        members: [],
        schedule_type: {
            type_alias: 'null',
            dates: [],
            max_member_count: 10,
            min_hours_before_booking: 2
        }
    });
    // const [excursionTypeData, setExcursionTypeData] = useState(excursionTypesData.find(el => el.excursion_type_alias === currentAvailableType.name));

    useEffect(() => {
        if (excursionTypesData.length > 0) {
            setExcursionTypeData(excursionTypesData.find(el => el.excursion_type_alias === currentAvailableType.name))
        }
    }, [excursionTypesData])
    //
    useEffect(() => {
        console.log('excursionTypeData:')
        console.log(excursionTypeData)
    }, [excursionTypeData])
    //
    // useEffect(() => {
    //     console.log(currentAvailableType)
    //     console.log(excursionTypesData)
    //     console.log(excursionTypeData)
    // }, [excursionTypesData, excursionTypeData])
    // const [excursionType, setExcursionType] = useState('');
    // const [excursionType, setExcursionType] = useState(excursion.type_ids.find(el => el.type_id === currentAvailableType.id));

    // useEffect(() => {
    //     console.log(excursionType)
    // }, [excursionType])

    ////// Schedule status
    const changeScheduleStatus = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    active: +e.target.value
                }
            } else {
                return el;
            }
        }))
    }

    // Change member name
    const changeMemberName = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    members: el.members.map((member, index) => {
                        if (index === +e.target.getAttribute('data-member-index')) {
                            return {
                                ...member,
                                name: e.target.value
                            }
                        } else {
                            return member;
                        }
                    })
                }
            } else {
                return el;
            }
        }))
    }

    // Change member price
    const changeMemberPrice = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    members: el.members.map((member, index) => {
                        if (index === +e.target.getAttribute('data-member-index')) {
                            return {
                                ...member,
                                price: +e.target.value
                            }
                        } else {
                            return member;
                        }
                    })
                }
            } else {
                return el;
            }
        }))
    }

    // Change member discount
    const changeMemberDiscount = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    members: el.members.map((member, index) => {
                        if (index === +e.target.getAttribute('data-member-index')) {
                            return {
                                ...member,
                                discount: Math.abs(+e.target.value)
                            }
                        } else {
                            return member;
                        }
                    })
                }
            } else {
                return el;
            }
        }))
    }

    // Add new member
    const addMember = () => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    members: [
                        ...el.members,
                        {
                            name: "",
                            price: 0,
                            discount: 0
                        }
                    ]
                }
            } else {
                return el;
            }
        }))
    }

    // Delete member
    const deleteMember = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    members: el.members.filter((member, index) => {
                        return index !== +e.target.getAttribute('data-member-index');
                    })
                }
            } else {
                return el;
            }
        }))
    }

    // Render members
    const memberRows = excursionTypeData.members.map((member, index) => {
        return (
            <div
                className={classes.price_row}
                key={index}
            >
                <input className={[classes.price_label, "temp_input"].join(" ")}
                    type={"text"}
                    placeholder={"Название билета"}
                    value={member.name}
                    data-member-index={index}
                    onChange={changeMemberName}
                />

                <input
                    className={[classes.price_amount_input, "temp_input"].join(" ")}
                    type={"number"}
                    placeholder={"Цена"}
                    value={member.price}
                    data-member-index={index}
                    onChange={changeMemberPrice}
                />

                <div>
                    руб.
                </div>

                <div className={classes.price_percent_row}>
                    <input
                        className={[classes.discount_percent_input, "temp_input"].join(" ")}
                        type={"number"}
                        placeholder={"15"}
                        value={member.discount}
                        data-member-index={index}
                        onChange={changeMemberDiscount}
                    />

                    <div className={classes.percent_sign}>
                        %
                    </div>
                </div>

                <div
                    className={[classes.delete_price_btn, "temp_btn", "temp_btn_delete"].join(" ")}
                    onClick={deleteMember}
                    data-member-index={index}
                >
                    <img
                        src={Close}
                        alt="x"
                        width={10}
                    />
                </div>
            </div>
        )
    })

    ////// Work with schedule

    // Change schedule type
    const changeScheduleType = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        type_alias: e.target.value
                    }
                }
            } else {
                return el;
            }
        }))
    }

    // Change time
    const changeTime = (e) => {
        e.target.value = timeParse(e.target.value);

        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        dates: el.schedule_type.dates.map((date, index) => {
                            if (date.id === +e.target.getAttribute('data-time-index')) {
                                return {
                                    ...date,
                                    time: e.target.value
                                }
                            } else {
                                return date
                            }
                        })
                    }
                }
            } else {
                return el;
            }
        }))
    }

    // Delete time
    const deleteTime = (e) => {
        console.log('delete time')
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        dates: el.schedule_type.dates.filter((date, index) => {
                            return date.id !== +e.currentTarget.getAttribute('data-time-index');
                        })
                    }
                }
            } else {
                return el;
            }
        }))
    }

    // Add time
    const addTime = (e) => {
        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        dates: [
                            ...el.schedule_type.dates,
                            {
                                id: Date.now(),
                                day_week: e.target.getAttribute('data-day-week'),
                                time: "00:00"
                            }
                        ]
                    }
                }
            } else {
                return el;
            }
        }))
    }

    // Render permanent schedule
    const weekDayRows = DaysWeek.map((day, index) => {
        return (
            <div
                className={classes.week_day_row}
                key={`week_day_row_${index}`}
            >
                <div className={classes.week_day_block}>
                    <div className={classes.week_day}>
                        {day.name}
                    </div>
                </div>

                <div className={classes.times_container}>
                    {
                        excursionTypeData && excursionTypeData.schedule_type.dates.length > 0
                        &&
                        excursionTypeData.schedule_type.dates.filter(el => el.day_week === day.day).map((date, index) => {
                            return (
                                <div
                                    className={classes.time_block}
                                    key={`time_block_${index}`}
                                >
                                    <input
                                        className={["temp_input", classes.time_input].join(" ")}
                                        value={timeParse(date.time)}
                                        onChange={changeTime}
                                        data-time-index={date.id}
                                        type={'time'}
                                        step={'60'}
                                    />

                                    <div
                                        className={[classes.delete_time_btn, "temp_btn", "temp_btn_delete"].join(" ")}
                                        onClick={deleteTime}
                                        data-time-index={date.id}
                                    >
                                        <img
                                            src={Close}
                                            alt="x"
                                            width={8}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }


                    <div
                        className={[classes.add_time_btn, "temp_btn"].join(" ")}
                        onClick={addTime}
                        data-day-week={day.day}
                    >
                        +
                    </div>
                </div>
            </div>
        );
    })

    // Render flexible schedule
    const changePersonCount = (e) => {
        e.target.value = +(e.target.value.replace(/\D/g, ""));

        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        max_member_count: +e.target.value
                    }
                }
            } else {
                return el;
            }
        }))
    }

    // Change min hours before booking
    const changeMinHoursBeforeBooking = (e) => {
        e.target.value = +(e.target.value.replace(/\D/g, ""));

        setExcursionTypesData(excursionTypesData.map(el => {
            if (el.excursion_type_alias === currentAvailableType.name) {
                return {
                    ...el,
                    schedule_type: {
                        ...el.schedule_type,
                        min_hours_before_booking: +e.target.value
                    }
                }
            } else {
                return el;
            }
        }))
    }


    return (
        <div className={classes.container}>
            {/*<div className={[classes.container_collapse_btn, "temp_btn"].join(" ")}>*/}
            {/*    Свернуть*/}
            {/*</div>*/}

            <div className={classes.type_label}>
                {currentAvailableType.info.name}
            </div>

            {/* Activity status */}
            <div className={classes.status_container}>
                <select
                    className={[classes.status_select, "temp_input"].join(" ")}
                    value={excursionTypeData ? excursionTypeData.active : 0}
                    onChange={changeScheduleStatus}
                >
                    <option value={1}>
                        Активно
                    </option>

                    <option value={0}>
                        Не активно
                    </option>
                </select>
            </div>

            {/* Prices */}
            <div className={classes.prices_container}>
                <div className={classes.prices_label}>
                    Билеты
                    {/* Билеты, цены, скидки */}
                </div>

                <div className={classes.price_descriptions_row}>
                    <div className={classes.ticket_name_label}>
                        Название
                    </div>

                    <div className={classes.ticket_price_label}>
                        Стоимость
                    </div>

                    <div className={classes.ticket_discount_label}>
                        Скидка(%)
                    </div>
                </div>

                {memberRows}

                <div
                    className={[classes.add_price_btn, "temp_btn"].join(" ")}
                    onClick={addMember}
                >
                    +
                </div>
            </div>

            {/* Schedule */}
            <div className={classes.schedule_container}>
                <div className={classes.schedule_label}>
                    Расписание
                </div>

                <select
                    className={["temp_input", classes.schedule_select].join(" ")}
                    value={excursionTypeData.schedule_type.type_alias}
                    onChange={changeScheduleType}
                >
                    <option value={'null'}>
                        Выберите тип
                    </option>

                    <option value={'permanent_schedule'}>
                        Постоянное расписание
                    </option>

                    {/*Flexible schedule only for minigroup and private*/}
                    {/*{*/}
                    {/*    (excursionType === undefined || excursionType.type_id !== 1)*/}
                    {/*    &&*/}
                    {/*    <option value={'flexible_schedule'} style={{color: 'red'}}>*/}
                    {/*        Без расписания НЕ ТРОГАТЬ*/}
                    {/*    </option>*/}
                    {/*}*/}
                </select>

                {/* Permanent schedule */}
                {
                    excursionTypeData.schedule_type.type_alias === 'permanent_schedule'
                    &&
                    <div className={classes.permanent_schedule_container}>
                        {weekDayRows}
                    </div>
                }

                {/*/!* Without schedule *!/*/}
                {/*{*/}
                {/*    scheduleType === 'flexible_schedule'*/}
                {/*    &&*/}
                {/*    <div className={classes.without_schedule_container}>*/}
                {/*        <span style={{color: 'red'}}>НЕ ТРОГАТЬ ОТСЮДА</span>*/}
                {/*        <div className={classes.unavailable_dates_container}>*/}
                {/*            <div className={classes.unavailable_dates_label}>*/}
                {/*                Ограничение по бронированию*/}
                {/*            </div>*/}

                {/*            <div className={classes.unavailable_dates_block}>*/}
                {/*                <input*/}
                {/*                    className={["temp_input", classes.unavailable_date_input].join(" ")}*/}
                {/*                />*/}

                {/*                <div className={"temp_btn"}>*/}
                {/*                    +*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className={classes.available_time_container}>*/}
                {/*            <div className={classes.available_time_label}>*/}
                {/*                Время для возможности бронирования*/}
                {/*            </div>*/}

                {/*            <div className={classes.available_time_block}>*/}
                {/*                <div>*/}
                {/*                    C*/}
                {/*                </div>*/}

                {/*                <input*/}
                {/*                    className={["temp_input", classes.available_time_input].join(" ")}*/}
                {/*                    readOnly={true} //TODO*/}
                {/*                />*/}

                {/*                <div>*/}
                {/*                    До*/}
                {/*                </div>*/}

                {/*                <input*/}
                {/*                    className={["temp_input", classes.available_time_input].join(" ")}*/}
                {/*                    readOnly={true} //TODO*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <span style={{color: 'red'}}>НЕ ТРОГАТЬ ДОСЮДА</span>*/}
                {/*    </div>*/}
                {/*}*/}


                {/*Max members*/}
                <div className={classes.max_members_container}>
                    <div className={classes.max_members_label}>
                        Максимальное количество участников
                    </div>

                    <input
                        className={["temp_input", classes.max_members_input].join(" ")}
                        type={'text'}
                        value={excursionTypeData.schedule_type.max_member_count}
                        onChange={changePersonCount}
                    />
                </div>

                {/*Min time booking*/}
                <div className={classes.min_time_booking_container}>
                    <div className={classes.min_time_booking_label}>
                        Минимальное время бронирования (часы)
                    </div>

                    <input
                        className={["temp_input", classes.min_time_booking_input].join(" ")}
                        type={'text'}
                        value={excursionTypeData.schedule_type.min_hours_before_booking}
                        onChange={changeMinHoursBeforeBooking}
                    />
                </div>
            </div>
        </div>
    );
};

export default ExperienceTypeEditArea;