export const priceFormatting = (price) => {
    // if (price.toString() === "") {
    //     return 0;
    // }
    // return price.toString().replace(/\D/g, '').replace(/^0(\d)+/g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price.toString().replace(/\D/g, '').replace(/^0(\d)+/g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const priceParse = (price) => {
    return Number(price.toString().replace(/\D/g, ''));
}