import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from "./App";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
// const root = ReactDOM.hydrateRoot(document.getElementById('root'));

root.render(
    // <GoogleOAuthProvider clientId={"680576407687-5evpc4hc9h361ifnni4gmg3qlduapf7i.apps.googleusercontent.com"}>
        <App/>
    // </GoogleOAuthProvider>
);
