import React, {useEffect} from 'react';
import classes from "./ExperienceTypeDotSelector.module.scss";
import {log} from "util";

const ExperienceTypeDotSelector = ({availableTypes = [], selectedType, setSelectedType}) => {
    // useEffect(() => {
    //     console.log("Available types:")
    //     console.log(availableTypes)
    //     console.log("Selected type:")
    //     console.log(selectedType)
    // }, [selectedType])

    // Render experience types
    const listTypes = availableTypes.map((type, index) => {
        return (
            <div
                className={classes.type_row}
                key={type.id}
                onClick={e => selectType(e)}
                data-type-name={type.type_name}
            >
                <div className={classes.dot_block}>
                    <div
                        className={type.type_name === selectedType ? [classes.dot, classes.dot_active].join(" ") : classes.dot}
                        data-booking-type={type.type_name}
                    >

                    </div>
                </div>

                <div className={classes.info_block}>
                    <div className={classes.type_title}>
                        {type.info.name}
                    </div>

                    <div
                        className={classes.type_description}
                        dangerouslySetInnerHTML={{ __html: type.info.description }}
                    >
                    </div>
                </div>
            </div>
        )
    })

    // Select type by click
    const selectType = (e) => {
        setSelectedType(e.currentTarget.getAttribute("data-type-name"));

        let typeDots = Array.from(e.currentTarget.parentNode.getElementsByClassName(classes.dot));

        typeDots.map(el => {
            el.classList.remove(classes.dot_active);
        })

        e.currentTarget.firstChild.firstChild.classList.add(classes.dot_active);
    }

    return (
        <div>
            {listTypes}
        </div>
    );
};

export default ExperienceTypeDotSelector;