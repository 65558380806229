import React, { useEffect, useRef, useState } from 'react';
import classes from './PhotoGallery.module.scss';
import ArrowLeft from '../../../images/icons/ArrowLeft.svg';
import ArrowRight from '../../../images/icons/ArrowRight.svg';
import HbCloseButton from '../HbCloseButton/HbCloseButton';
import PageLoader from '../PageLoader/PageLoader';
import { useSearchParams } from 'react-router-dom';

export const PhotoGallery = ({ setIsVisible, galleryName, photos }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const galleryPhotoRow = useRef(null);

    // Scroll by arrows
    const scrollByArrow = (e) => {
        const direction = e.currentTarget.getAttribute('data-direction');

        if (direction === 'left') {
            galleryPhotoRow.current.scrollLeft -= e.currentTarget.parentElement.clientWidth;
        } else if (direction === 'right') {
            galleryPhotoRow.current.scrollLeft += e.currentTarget.parentElement.clientWidth;
        }
    }

    // Render images
    const renderImgs = photos.map((photo, index) => {
        return (
            <div
                className={classes.photo_block}
                key={index}
                data-src={photo}
                data-loading='false'
            >
                <PageLoader />
            </div>
        )
    })

    // Set img number
    const [imgNumber, setImgNumber] = useState(1);
    const [imgTempNumber, setImgTempNumber] = useState(null);

    const getImgNumber = () => {
        let photoRowScrollLeft = galleryPhotoRow.current.scrollLeft;
        if (galleryPhotoRow.current.children[0] !== undefined) {
            setImgNumber(Math.round(photoRowScrollLeft / galleryPhotoRow.current.children[0].clientWidth) + 1);
        }
    }

    useEffect(() => {
        getImgNumber();
    }, [])

    useEffect(() => {
        console.log('imgNumber');
        console.log(imgNumber);
    }, [imgNumber])


    // Enter img
    const enterImg = (imgBlock) => {
        imgBlock.setAttribute('data-loading', 'true');
        const ImgObject = new Image();
        ImgObject.src = imgBlock.getAttribute('data-src');
        ImgObject.alt = '';
        ImgObject.classList.add(classes.photo_gallery_img);

        ImgObject.onload = () => {
            // if (ImgObject.width > ImgObject.height) {
            //     ImgObject.width = imgBlock.clientWidth;
            // } else {
            //     ImgObject.height = imgBlock.clientHeight;
            // }

            imgBlock.appendChild(ImgObject);
            imgBlock.children[0].classList.add('hidden');
        }
    }

    // Load img by imgNumber
    useEffect(() => {
        // Load img
        const loadImg = () => {
            const imgBlock = galleryPhotoRow.current.children[imgNumber - 1];

            if (imgBlock !== undefined) {
                if (imgBlock.getAttribute('data-loading') === 'false') {
                    enterImg(imgBlock);
                }

                const nextBlock = galleryPhotoRow.current.children[imgNumber];

                if (nextBlock !== undefined) {
                    if (nextBlock.getAttribute('data-loading') === 'false') {
                        enterImg(nextBlock);
                    }
                }
            }
        }

        const loadImgTimeout = setTimeout(loadImg, 200);

        return () => clearTimeout(loadImgTimeout);
    }, [imgNumber])

    // Set temp img row scroll by search params
    useEffect(() => {
        if (searchParams.has(`${galleryName}_img_src`) && searchParams.get(`${galleryName}_img_src`) !== null) {
            let currentImgSrc = photos.find(photo => photo.search(searchParams.get(`${galleryName}_img_src`)) !== -1 || photo === searchParams.get(`${galleryName}_img_src`));

            if (currentImgSrc !== undefined) {
                setImgTempNumber(photos.indexOf(currentImgSrc) + 1);
            }
        }
    }, [photos])

    // Scroll by img temp number for first render
    useEffect(() => {
        if (imgTempNumber !== null && galleryPhotoRow.current.scrollLeft !== (imgTempNumber - 1) * galleryPhotoRow.current.children[0].clientWidth) {
            galleryPhotoRow.current.scrollLeft = (imgTempNumber - 1) * galleryPhotoRow.current.children[0].clientWidth;
        }
    }, [imgTempNumber])

    // Set search params by img number
    useEffect(() => {
        if (photos[imgNumber - 1] !== undefined) {
            let imgName = photos[imgNumber - 1].match(/\/([\w\d]+)\.webp$/)[1];
            if (searchParams.get(`${galleryName}_img_src`) !== imgName) {
                searchParams.set(`${galleryName}_img_src`, imgName);
                setSearchParams(searchParams);
            }
        }
    }, [imgNumber])

    // Close gallery
    const galleryContainerFluid = useRef(null);
    const closeBtn = useRef(null);

    const closeGallery = (e) => {
        if (e.target === galleryContainerFluid.current || e.currentTarget === closeBtn.current) {
            searchParams.delete(`${galleryName}_img_src`);
            setSearchParams(searchParams);
            setIsVisible(false);
        }
    }

    return (
        <div
            className={classes.container_fluid}
            ref={galleryContainerFluid}
            onClick={closeGallery}
        >
            <div className={classes.container}>
                <div className={classes.photo_container}>
                    <div
                        className={[classes.photo_arrow_block, classes.photo_arrow_block_left].join(' ')}
                        onClick={scrollByArrow}
                        data-direction='left'
                    >
                        <img
                            src={ArrowLeft}
                            alt='<'
                        />
                    </div>

                    <div
                        className={[classes.photo_arrow_block, classes.photo_arrow_block_right].join(' ')}
                        onClick={scrollByArrow}
                        data-direction='right'
                    >
                        <img
                            src={ArrowRight}
                            alt='>'
                        />
                    </div>

                    <div
                        className={classes.photo_row}
                        ref={galleryPhotoRow}
                        onScroll={getImgNumber}
                    >
                        {renderImgs}
                    </div>
                </div>

                <div className={classes.photo_count}>
                    {imgNumber} из {photos.length}
                </div>
            </div>

            <HbCloseButton
                ref={closeBtn}
                className={classes.close_btn}
                onClick={closeGallery}
            />
        </div>
    )
}
