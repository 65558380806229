import React from 'react';
import classes from "./PinkDot.module.scss";

const PinkDot = () => {
    return (
        <div className={classes.pink_dot}>

        </div>
    );
};

export default PinkDot;