export const DaysWeek = [
    {
        day: 'Monday',
        short_name: 'ПН',
        name: 'Понедельник'
    },

    {
        day: 'Tuesday',
        short_name: 'ВТ',
        name: 'Вторник'
    },

    {
        day: 'Wednesday',
        short_name: 'СР',
        name: 'Среда'
    },

    {
        day: 'Thursday',
        short_name: 'ЧТ',
        name: 'Четверг'
    },

    {
        day: 'Friday',
        short_name: 'ПТ',
        name: 'Пятница'
    },

    {
        day: 'Saturday',
        short_name: 'СБ',
        name: 'Суббота'
    },

    {
        day: 'Sunday',
        short_name: 'ВС',
        name: 'Воскресенье'
    }
];