import React, {useContext, useEffect} from 'react';
import AuthContext from "../context/AuthContext";
import UserService from "../API/UserService";

export const useUserWishlist = () => {
    const {...context} = useContext(AuthContext);

    const userWishlist = async () => {
        const response = await UserService.getUserWishlist();
        console.log(response)
        context.setUser({...context.user, wishlist: response.data.wishlist});
    }

    // useEffect(() => {
    //     if (isAuth) {
    //         userWishlist();
    //     }
    // }, [isAuth])

    useEffect(() => {
        if (context.isAuth) {
            userWishlist();
        }
    }, [context.isAuth])
};

