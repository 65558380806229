import React, {useEffect, useState} from 'react';
import classes from './ExcursionSelector.module.scss';
import ArrowDrop from '../../../../images/icons/ArrowDrop.svg'
import useOutsideClick from "../../../../hooks/useOutsideClick";

const ExcursionSelector = ({excursions, excursionId, setExcursionId}) => {
    const [isListVisible, setIsListVisible] = useState(false);

    useEffect(() => {
        if (isListVisible) {
            selectorList.current.classList.remove('hidden')
        }else {
            selectorList.current.classList.add('hidden')
        }
    }, [isListVisible])

    const handleSelectorClick = (e) => {
        e.stopPropagation();
        setIsListVisible(!isListVisible);
    }

    const selectList = excursions.map(excursion => {
        return (
            <div
                className={classes.selector_item}
                key={excursion.id}
                onClick={() => {
                    setExcursionId(excursion.id);
                    setIsListVisible(false);
                }}
            >
                {excursion.title}
            </div>
        )
    })

    useEffect(() => {
        console.log(excursions.find(excursion => excursionId == excursion.id))
    }, [excursionId]);

    const selectorList = useOutsideClick(() => setIsListVisible(false));


    return (
        <div className={classes.main_container}>
            <div
                className={classes.selector_row}
                onClick={handleSelectorClick}
            >
                <div
                    className={'truncate'}
                >
                    {
                        excursionId !== ''
                        ?
                            excursions.find(excursion => excursion.id == excursionId).title
                        :
                            'Все экскурсии'
                    }
                </div>

                <div>
                    <img src={ArrowDrop} alt=""/>
                </div>
            </div>

            <div
                className={classes.selector_list}
                ref={selectorList}
            >
                <div
                    className={classes.selector_item}
                    onClick={() => {
                        setExcursionId('');
                        setIsListVisible(false);
                    }}
                >
                    Все экскурсии
                </div>

                {selectList}
            </div>
        </div>
    );
};

export default ExcursionSelector;